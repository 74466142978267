<div class="cov-container">
    <p class="title">Inicio de proceso</p>
    <div class="cov-container-button">
        <div class="cov-group">
            <input type="text" class="form-control" placeholder="Filtrar" (keyup)="filter($event)">
            <mat-icon class="blue-color">search</mat-icon>
            <button mat-mini-fab class="blue cov-butonsearch" matTooltip="Recargar" matTooltipPosition="above"
                matTooltipClass="tooltip" (click)="search()">
                <mat-icon>find_replace</mat-icon>
            </button>
            <button mat-mini-fab class="blue" matTooltip="Exportar" matTooltipPosition="above" matTooltipClass="tooltip"
                (click)="download()">
                <mat-icon>cloud_download</mat-icon>
            </button>

        </div>
    </div>
    <table #table class="cov-table" mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>N° de caso</th>
            <td mat-cell *matCellDef="let item">{{item.id}}</td>
        </ng-container>ui
        <ng-container matColumnDef="identificationUser">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>N° identificacion</th>
            <td mat-cell *matCellDef="let item">{{item.identificationUser}}</td>
        </ng-container>
        <ng-container matColumnDef="creationUser">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Nombre y apellido</th>
            <td mat-cell *matCellDef="let item">{{item.creationUser}}</td>
        </ng-container>
        <ng-container matColumnDef="company">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Empresa</th>
            <td mat-cell *matCellDef="let item">{{item.company}}</td>
        </ng-container>
        <ng-container matColumnDef="positions">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Cargo</th>
            <td mat-cell *matCellDef="let item">{{item.positions}}</td>
        </ng-container>
        <ng-container matColumnDef="phone">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Telefono fijo</th>
            <td mat-cell *matCellDef="let item">{{item.phone}}</td>
        </ng-container>
        <ng-container matColumnDef="mobile">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Celular</th>
            <td mat-cell *matCellDef="let item">{{item.mobile}}</td>
        </ng-container>
        <ng-container matColumnDef="city">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Ciudad</th>
            <td mat-cell *matCellDef="let item">{{item.city}}</td>
        </ng-container>
        <ng-container matColumnDef="creationDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha de registro</th>
            <td mat-cell *matCellDef="let item">{{item.creationDate}}</td>
        </ng-container>
        <ng-container matColumnDef="active">
            <th mat-header-cell *matHeaderCellDef>Activo</th>
            <td mat-cell *matCellDef="let item">
                <mat-icon *ngIf="item.active" class="green-color">done</mat-icon>
                <mat-icon *ngIf="!item.active" class="red-color">clear</mat-icon>
            </td>
        </ng-container>

        <ng-container matColumnDef="Acciones">
            <th mat-header-cell *matHeaderCellDef>Acciones</th>
            <td mat-cell *matCellDef="let item">
                <button mat-icon-button matTooltip="Eliminar" matTooltipPosition="above" matTooltipClass="tooltip"
                    (click)="delete(item.id)">
                    <mat-icon class="red-color">delete</mat-icon>
                </button>
                <button mat-icon-button matTooltip="Mas" matTooltipPosition="above" matTooltipClass="tooltip" [matMenuTriggerFor]="menuUser">
                    <mat-icon class="orange-color">more_vert</mat-icon>
                </button>
                <mat-menu #menuUser="matMenu" xPosition="before">
                     <button mat-menu-item  (click)="files(item.identificationUser,item.creationDate)">
                        <mat-icon>attach_file</mat-icon>
                        <span>Archivos</span>
                    </button>
                    <button mat-menu-item  (click)="register(item.id)">
                        <mat-icon>settings</mat-icon>
                        <span>Registros</span>
                    </button>
                    <button mat-menu-item (click)="details(item.id)">
                        <mat-icon>backup_table</mat-icon>
                        <span>Detalle de registros</span>
                    </button>
                </mat-menu>
                <button mat-icon-button matTooltip="{{item.active?'Inactivar':'Activar'}}" matTooltipPosition="above"
                    matTooltipClass="tooltip" (click)="activeInactive(item.identificationUser,item.active)">
                    <mat-icon *ngIf="item.active" class="red-color">close</mat-icon>
                    <mat-icon *ngIf="!item.active" class="green-color">done</mat-icon>
                </button>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns;"></tr>
    </table>
    <div *ngIf="loading" class="report-container-loader">
        <div class="report-loader"></div>
    </div>
    <mat-paginator [pageSizeOptions]="[5, 10, 15, 20, 30, 40, 50, 100]" showFirstLastButtons></mat-paginator>
</div>