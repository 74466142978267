<div mat-dialog-content>
    <p class="title">Detalle </p>
    <div class="act-detail-container">
        <div *ngFor="let c  of activeFixedAssigmentFeatur" class="act-detail-card">
            <div class="act-detail-content-icon">
                <mat-icon [ngStyle]="{'color':'#411881'}">fact_check</mat-icon>
            </div>
            <div class="act-detail-content">
                <p class="act-detail-title">Caracteristicas</p>
                <p class="act-detail-subtitle">{{c.name}}</p>
            </div>
            <div class="act-detail-content-icon">
                <mat-icon [ngStyle]="{'color':'#811849'}">assignment</mat-icon>
            </div>
            <div class="act-detail-content">
                <p class="act-detail-title">Descripcion</p>
                <p class="act-detail-subtitle">{{c.description}}</p>
            </div>
            <div class="act-detail-content-icon">
                <mat-icon [ngStyle]="{'color':'#90B38D'}">horizontal_split</mat-icon>
            </div>
            <div class="act-detail-content">
                <p class="act-detail-title">Serial</p>
                <p class="act-detail-subtitle">{{c.serial}}</p>
            </div>
            
        </div>
        <div class="act-detail-card">
            <div class="act-detail-content">
                <p class="act-detail-title">Archivos</p>
                <div class="act-detail-container-file">
                    <p *ngIf="filetList.length===0" class="act-detail-subtitle">Sin Archivos</p>
                    <div *ngFor="let f of filetList;" class="act-detail-content-file" matTooltip="{{f.name}}"
                        matTooltipPosition="above" matTooltipClass="tooltip">
                        <p>{{f.type}}</p>
                        <button mat-icon-button matTooltip="Descargar" matTooltipPosition="right"
                            matTooltipClass="tooltip" (click)="downloadFile(f)">
                            <mat-icon class="blue-color">cloud_download</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="loading" class="act-detail-container-loader">
        <div class="act-detail-loader"></div>
    </div>
</div>