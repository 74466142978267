<div>
    <p class="title">Listado</p>
    <div class="loc-container">

        <br>
        <br>
        <div class="loc-group-button">
            <br>
            <button reset mat-mini-fab class="blue loc-but" matTooltip="Exportar" matTooltipPosition="above"
                matTooltipClass="tooltip" (click)="download()">
                <mat-icon>cloud_download</mat-icon>
            </button>
        </div>
        <div class="loc-group-button">
            <br>
            <button [disabled]="this.name == null" mat-mini-fab class="loc-color-button loc-but" matTooltip="Generar IQ O9" matTooltipPosition="above"
                matTooltipClass="tooltip" (click)="dataGeneral()">
                <mat-icon>loop</mat-icon>
            </button>
        </div>
        <div class="loc-group">
            <label class="primaryColor">Envio de correo</label>
            <div class="loc-form-control">
                <input [(ngModel)]="name" name="person" type="text" class="form-control" disabled
                    placeholder="Seleccionar Usuario">
                <button mat-mini-fab class="green" matTooltip="Usuarios" matTooltipPosition="above"
                    matTooltipClass="tooltip" (click)="getPerson()">
                    <mat-icon>add</mat-icon>
                </button>
            </div>
        </div>
    </div>
    <table class="loc-table" mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="CLIENTE">
            <th mat-header-cell *matHeaderCellDef>SERIAL EQUIPO</th>
            <td mat-cell *matCellDef="let item">{{item.customer}}</td>
        </ng-container>
        <ng-container matColumnDef="SERIAL EQUIPO">
            <th mat-header-cell *matHeaderCellDef>SERIAL EQUIPO</th>
            <td mat-cell *matCellDef="let item">{{item.serial}}</td>
        </ng-container>
        <ng-container matColumnDef="CODIGO SAP">
            <th mat-header-cell *matHeaderCellDef>CODIGO SAP</th>
            <td mat-cell *matCellDef="let item">{{item.codSap}}</td>
        </ng-container>
        <ng-container matColumnDef="ESTADO">
            <th mat-header-cell *matHeaderCellDef>ESTADO</th>
            <td mat-cell *matCellDef="let item">{{item.status}}</td>
        </ng-container>
        <ng-container matColumnDef="TIPOLOGIA">
            <th mat-header-cell *matHeaderCellDef>TIPOLOGIA</th>
            <td mat-cell *matCellDef="let item">{{item.typology}}</td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns;"></tr>
    </table>
    <div *ngIf="loading==true" class="loc-container-loader">
        <div class="loc-loader"></div>
    </div>
    <mat-paginator #paginator [pageSizeOptions]="[5, 10, 15, 20, 30, 40, 50, 100]" showFirstLastButtons>
    </mat-paginator>
</div>