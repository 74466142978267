import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResponseModel } from 'src/app/shared/models/response.model';
import { environment } from 'src/environments/environment';
import { MilicomColombiaEntity } from '../entities/milicomColombia.entity';
import { MilicomLoadBaseEntity } from '../entities/milicomLoadBase.entity';

@Injectable({
  providedIn: 'root'
})
export class MilicomBaseService {
  private url: string;
  constructor(private http: HttpClient) {
    this.url = environment.api;
  }

  public create(archiveMilicom:MilicomLoadBaseEntity):Observable<ResponseModel>{
    var headers=new HttpHeaders().set('content-type','application/json');
    return this.http.post<ResponseModel>(this.url+'MilicomLoadBase/create',JSON.stringify(archiveMilicom),{headers:headers});
  }

  public update(archiveMilicom:MilicomLoadBaseEntity):Observable<ResponseModel>{
    var headers=new HttpHeaders().set('content-type','application/json');
    return this.http.put<ResponseModel>(this.url+'MilicomLoadBase/update',JSON.stringify(archiveMilicom),{headers:headers});
  }

  public delete(id:number):Observable<ResponseModel>{
    var headers = new HttpHeaders().set('content-Type', 'application/x-www-form-urlencoded');
    return this.http.delete<ResponseModel>(this.url+'MilicomLoadBase/delete/'+id,{headers:headers});
  }

  public list():Observable<ResponseModel>{
    var headers = new HttpHeaders().set('content-Type', 'application/json');
    return this.http.get<ResponseModel>(this.url+'MilicomLoadBase/list',{headers:headers});
  }

  public findById(id:number,reporte:string):Observable<ResponseModel>{
    var headers = new HttpHeaders().set('content-Type', 'application/json');
    return this.http.get<ResponseModel>(this.url+'MilicomLoadBase/findById/'+id+'/'+reporte,{headers:headers});
  }

  public columns(reporte:string):Observable<ResponseModel>{
    var headers = new HttpHeaders().set('content-Type', 'application/json');
    return this.http.get<ResponseModel>(this.url+'MilicomLoadBase/columns/'+reporte,{headers:headers});
  }

}
