export class PqrFilesEntity{

    id: number;
    name: string;
    typeClient: string;
    description: string;
    categoryId: number;
    category: string;
    active: boolean;

    constructor () {}
}