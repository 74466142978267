export class LoadClientFieldsParametrizationEntity{

    id:number;
    parametrizationOne:string;
    functionOne:string;
    resultOne:string;
    fieldId:number;
    fieldParametrizationId:number;
    resultTwo:string;
    constructor (){}
}