<div class="label-container">
    <p class="title">{{priLabelEntity.section}} - {{priLabelEntity.name}}</p>
    <div class="label-form">
        <div class="label-group">
            <label class="primaryColor">Impresora</label>
            <select [disabled]="block" [(ngModel)]="printerId" class="form-control">
                <option class="ligthColor" value="0">Seleccione una impresora</option>
                <option *ngFor="let p of printerList" value="{{p.id}}">{{p.name}}</option>
            </select>
            <mat-hint style="color: rgb(44, 135, 240);" *ngIf="printerId==0">El boton de impimir se habilitara cuando seleccione</mat-hint>
        </div>
        <div class="label-group">
            <label class="primaryColor">Opcion</label>
            <select [disabled]="block" [(ngModel)]="option" class="form-control">
                <option class="ligthColor" value="">Seleccione una opcion</option>
                <option value="Crear ilegibles">Generar ilegibles</option>
                <option value="Busqueda por pallet">Busqueda por pallet</option>
            </select>
        </div>
    </div>
    <ng-container *ngIf="option==='Crear ilegibles'">
        <div class="label-form">
            <div class="label-group">
                <label class="primaryColor">Número de seriales</label>
                <input [disabled]="block" [(ngModel)]="serials" type="number" min="0" class="form-control" placeholder="Número de seriales">
            </div>
            <button [disabled]="block" fxFlexAlign="flex-end" mat-mini-fab class="blue" [ngStyle]="{'margin-right': '10px'}" matTooltip="Crear" matTooltipPosition="above" matTooltipClass="tooltip" (click)="create()">
                <mat-icon>add</mat-icon>
            </button>
            <button [disabled]="block == true || printerId==0?true:false" fxFlexAlign="flex-end" mat-mini-fab [ngClass]="{'ligthBackground':printerId==0,'green':printerId!=0}" matTooltip="Imprimir" matTooltipPosition="above"
                matTooltipClass="tooltip" (click)="print()">
                <mat-icon>print</mat-icon>
            </button>
        </div>
    </ng-container>
    <ng-container *ngIf="option==='Busqueda por pallet'">
        <div class="label-form">
            <div class="label-group">
                <label class="primaryColor">Número de pallet</label>
                <input [disabled]="block" id="search" type="text" class="form-control" placeholder="Número de pallet">
            </div>
            <button [disabled]="block" mat-mini-fab class="blue" [ngStyle]="{'margin-right': '10px'}" matTooltip="Buscar" matTooltipPosition="above"
                matTooltipClass="tooltip" (click)="search()">
                <mat-icon>search</mat-icon>
            </button>
            <button [disabled]="block == true || printerId==0?true:false" mat-mini-fab [ngClass]="{'ligthBackground':printerId==0,'green':printerId!=0}" matTooltip="Imprimir" matTooltipPosition="above"
                matTooltipClass="tooltip" (click)="print()">
                <mat-icon>print</mat-icon>
            </button>
        </div>
    </ng-container>
    <div *ngIf="block">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
    <table *ngIf="option!=''" mat-table [dataSource]="data" #table>
        <ng-container matColumnDef="Serial">
            <th mat-header-cell *matHeaderCellDef>Serial</th>
            <td mat-cell *matCellDef="let item;let i=index;">{{item.serial}}</td>
        </ng-container>
        <ng-container matColumnDef="Mac">
            <th mat-header-cell *matHeaderCellDef>Mac</th>
            <td mat-cell *matCellDef="let item;let i=index;">{{item.mac}}</td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="headers"></tr>
        <tr mat-row *matRowDef="let row; columns: headers;"></tr>
    </table>
    <div *ngIf="loading" class="label-container-loader">
        <div class="label-loader"></div>
    </div>
</div>