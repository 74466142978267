export class wtspModel{
    odsi:string;
    segmento:string;
    ods:string;
    fecha: string;
    sucursal: string;
    serial: string;
    user: string;
    telephone: string;

    constructor () {}
}