export class RepFlagLogEntity{

    id:number;
    userId:number;
    reportId:number;
    creationDate:string;
    active:boolean;
    validacionReport:string;
    times:string;
    constructor(){}
}