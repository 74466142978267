import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { ResponseModel } from 'src/app/shared/models/response.model';
import { FileGroupEntity } from '../entities/fileGroup.entity';

@Injectable({
    providedIn:'root'
})
export class FileGroupService{
    private url:string;

    constructor(private http:HttpClient){
        this.url=environment.api;
    }
    public create(FileGroupEntity:FileGroupEntity, userId:number):Observable<ResponseModel>{
        var headers = new HttpHeaders().set('content-Type', 'application/json');

        return this.http.post<ResponseModel>(this.url+'FileGroup/create/'+userId,JSON.stringify(FileGroupEntity),{headers:headers});
    }
    public update(FileGroupEntity:FileGroupEntity, userId:number):Observable<ResponseModel>{
        var headers = new HttpHeaders().set('content-Type', 'application/json');

        return this.http.put<ResponseModel>(this.url+'FileGroup/update/'+userId,JSON.stringify(FileGroupEntity),{headers:headers});
    }
    public delete(fileGroupId:number):Observable<ResponseModel>{
        var headers = new HttpHeaders().set('content-Type', 'application/json');

        return this.http.delete<ResponseModel>(this.url+'FileGroup/delete/'+fileGroupId,{headers:headers});
    }
    public findById(fileGroupId:number):Observable<ResponseModel>{
        var headers = new HttpHeaders().set('content-Type', 'application/json');

        return this.http.get<ResponseModel>(this.url+'FileGroup/findById/'+fileGroupId,{headers:headers});
    }
    public list():Observable<ResponseModel>{
        var headers = new HttpHeaders().set('content-Type', 'application/json');

        return this.http.get<ResponseModel>(this.url+'FileGroup/list',{headers:headers});
    }
}