
export class ComCommodityEntryArticlesLogEntity {

    id:number;
    articleId:number;
    quantityPrevious:number;
    quantityNew:number;
    userId:number;
    creationDate:string;

    constructor(){ }
}