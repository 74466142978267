<div mat-dialog-content>
    <div class="label-container-button">
        <button mat-icon-button (click)="close()">
            <mat-icon>clear</mat-icon>
        </button>
    </div>
    <p class="title">Listado de reglas</p>
    <form [formGroup]="form" class="label-form">
        <div class="label-group">
            <label class="primaryColor">Nombre</label>
            <select [(ngModel)]="ruleGenera" formControlName="parametrizationOne" class="form-control" (change)="ruleMaster()">
                <option class="ligthColor" value=0>Seleccione un nombre</option>
                <option *ngFor="let c of ruleGeneralList" value="{{c.id}}">{{c.name}}</option>
                
            </select>
        </div>
        <div class="label-group">
            <label class="primaryColor">Funcion</label>
            <select  formControlName="functionOne" class="form-control">
                <option class="ligthColor" value=0>Seleccione una funcion</option>
                <option *ngFor="let c of ruleMasterList" value="{{c.id}}">{{c.name}}</option>
            </select>
        </div>
        <div class="label-group">
            <label class="primaryColor">Dato 1</label>
            <input formControlName="resultOne" type="text" class="form-control" placeholder="Dato">
        </div>
        <div class="label-group">
            <label class="primaryColor">Dato 2</label>
            <input formControlName="resultTwo" type="text" class="form-control" placeholder="Dato">
        </div>
        <div class="label-group">
            <label class="primaryColor">Campos</label>
            <select formControlName="fieldParametrizationId" class="form-control">
                <option class="ligthColor" value=null>Seleccione un campo</option>
                <option  value=" ">No aplica</option>
                <option *ngFor="let c of FieldsList" value="{{c.id}}">{{c.name}}</option>
            </select>
        </div>

        <div class="label-group">
            <button mat-mini-fab class="green " matTooltip="Guardar" matTooltipPosition="right"
                matTooltipClass="tooltip" (click)="onClick()">
                <mat-icon>add</mat-icon>
            </button>
        </div>
    </form>
    <table class="label-table" mat-table [dataSource]="dataSource" #table>

        <ng-container matColumnDef="metodo">
            <th mat-header-cell *matHeaderCellDef>Nombre</th>
            <td mat-cell *matCellDef="let item">{{item.metodo}}</td>
        </ng-container>
        <ng-container matColumnDef="funcion">
            <th mat-header-cell *matHeaderCellDef>Funcionamiento</th>
            <td mat-cell *matCellDef="let item">{{item.funcion}}</td>
        </ng-container>
        <ng-container matColumnDef="resultOne">
            <th mat-header-cell *matHeaderCellDef>Dato 1</th>
            <td mat-cell *matCellDef="let item">{{item.resultOne}}</td>
        </ng-container>
        <ng-container matColumnDef="resultTwo">
            <th mat-header-cell *matHeaderCellDef>Dato 2</th>
            <td mat-cell *matCellDef="let item">{{item.resultTwo}}</td>
        </ng-container>
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Nombre del campo</th>
            <td mat-cell *matCellDef="let item">{{item.name}}</td>
        </ng-container>
        <ng-container matColumnDef="Acciones">
            <th mat-header-cell *matHeaderCellDef>Acciones</th>
            <td mat-cell *matCellDef="let item">
                <button mat-icon-button matTooltip="Eliminar" matTooltipPosition="above" matTooltipClass="tooltip"
                    (click)="delete(item.id)">
                    <mat-icon class="red-color">delete</mat-icon>
                </button>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns;"></tr>
    </table>
</div>