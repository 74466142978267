import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GenPersonEntity } from 'src/app/appGeneral/entities/genPerson.entity';
import { GenApplicationPersonProfileService } from 'src/app/appGeneral/services/genApplicationPersonProfile.service';
import { AlertService } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-validate-config',
  templateUrl: './validateConfig.component.html',
  styleUrls: ['./validateConfig.component.css']
})
export class ValidateConfigComponent implements OnInit {
  input1:any;
  input2:any;
  selectedRegional:any;
  selectedInterfaz:any;
  genPersonEntity: GenPersonEntity;
  trueChange:boolean;
  admin:boolean;
  constructor(private genApplicationPersonProfileS: GenApplicationPersonProfileService,
    private alertS:AlertService,
    public dialogRef: MatDialogRef<ValidateConfigComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.admin = data.admin;
  }

  ngOnInit(): void {
    this.input1 = '';
    this.input2 = '';
  }

  add(Nombre:string){
    switch (Nombre) {
      case 'OP1':
        if (this.input1 != '') {
          this.data.OP1.push(this.input1.toUpperCase( ));
          this.alertS.open('Para confirmar los cambios seleccione guardar o de lo contrario cancelar', 'info');
          this.input1 = '';
          this.trueChange = true;
        }else{
          this.alertS.open('Por favor escriba un campo','info');
        }
        break;
      case 'OP2':
        if (this.input2 != '') {
          this.data.OP2.push(this.input2.toUpperCase( )); 
          this.alertS.open('Para confirmar los cambios seleccione guardar o de lo contrario cancelar', 'info');
          this.input2 = '';
          this.trueChange = true;
        }else{
          this.alertS.open('Por favor escriba un campo','info');
        }
        break;
    
      default:
        this.alertS.open('ha ocurrido un error, intente de nuevo','error');
        break;
    }
  }

  delete(Nombre:string){
    switch (Nombre) {
      case 'OP1':
        for (let i of this.selectedInterfaz) {
          this.data.OP1.splice(this.data.OP1.indexOf(i),1);
        }
        this.alertS.open('Para confirmar los cambios seleccione guardar o de lo contrario cancelar', 'info');
        this.trueChange = true;
        break;
      case 'OP2':
        for (let i of this.selectedRegional) {
          this.data.OP2.splice(this.data.OP2.indexOf(i),1);
        }
        this.alertS.open('Para confirmar los cambios seleccione guardar o de lo contrario cancelar', 'info');
        this.trueChange = true;
        break;
      default:
        this.alertS.open('ha ocurrido un error, intente de nuevo','error');
        break;
    }
    
  }

  close(): void {
    this.dialogRef.close(this.trueChange);
  }

}
