export class MeeAnswerEntity{
    id:number;
    meetingId:number;
    meeting:string;
    topicId:number;
    topic:string;
    answer:string;
    answerDate:string;
    answerUserId:number;
    answerUser:string;

    constructor(){}
}