export class ConnectionGeneralLogModel {

    id:number;
    creationDate:string;
    userId:number;
    observation:string;
    customer:string;
    url:string;
    validation:string;
    constructor (){}
}