<div mat-dialog-content>
    <div fxLayout fxLayoutAlign="flex-end">
        <button mat-icon-button (click)="close(false)">
            <mat-icon>clear</mat-icon>
        </button>
    </div>
    <p class="title">{{title}}</p>
    <form [formGroup]="form" fxLayout="row wrap" class="form">
        <div fxLayout="column" fxFlex="calc(50% - 20px)" class="group">
            <label class="primaryColor">Usuario</label>
            <input list="menace" name="menace" formControlName="userId" class="form-control" placeholder="Escriba o busque el nombre">
            <datalist id="menace">
                <option class="ligthColor" value="">Seleccione un usuario</option>
                <option *ngFor="let c of personList" value="{{c.id}}">{{c.firstName + ' ' + c.lastName + ' - ' + c.position}} </option>
            </datalist>
        </div>
        
        <button mat-mini-fab class="button green" matTooltip="Guardar" matTooltipPosition="left" matTooltipClass="tooltip"
            [disabled]="!form.valid" (click)="save()">
            <mat-icon>done</mat-icon>
        </button>
    </form>
</div>