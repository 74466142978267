<div mat-dialog-content>
    <div class="close" fxLayout fxLayoutAlign="flex-end">
        <button mat-icon-button (click)="close(false)">
            <mat-icon>clear</mat-icon>
        </button>
    </div>
    <p class="title">Detalle Seguimiento</p>
    <div class="act-detail-container">
        <div *ngFor="let c  of covFormEntity">
            <div class="act-detail-card">
                <div class="act-detail-content-icon">
                    <mat-icon [ngStyle]="{'color':'#E8A41C'}">description</mat-icon>
                </div>
                <div class="act-detail-content">
                    <p class="act-detail-title">Segmento resultado</p>
                    <p class="act-detail-subtitle">{{c.segmentResult}}</p>
                </div>
            </div>
            <div class="act-detail-card">
                <div class="act-detail-content-icon">
                    <mat-icon [ngStyle]="{'color':'#127A35'}">directions_run</mat-icon>
                </div>
           
            <div class="act-detail-content">
                <p class="act-detail-title">Seguimiento</p>
                <p class="act-detail-subtitle">{{c.followUp}}</p>
            </div>
        </div>
        <div class="act-detail-card">
            <div class="act-detail-content-icon">
                <mat-icon [ngStyle]="{'color':'#D32225'}">health_and_safety</mat-icon>
            </div>
            <div class="act-detail-content">
                <p class="act-detail-title">Estado salud</p>
                <p class="act-detail-subtitle">{{c.statusHealth}}</p>
            </div>
        </div>
        <div *ngIf="loading" class="act-detail-container-loader">
            <div class="act-detail-loader"></div>
        </div>
    </div>
</div>
</div>