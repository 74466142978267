<div class="valReportLoad-container">
    <p class="title">MILICOM {{country.name}}</p>
    <ng-container *ngIf="!loadTrue">
        <mat-form-field appearance="fill">
            <mat-label>Archivo</mat-label>
            <mat-select [(ngModel)]="report" (selectionChange)="ngOnInit()">
                <mat-option value="TBL_REVERSE_CPE_AVAILABLE">TBL_REVERSE_CPE_AVAILABLE</mat-option>
                <mat-option value="TBL_REVERSE_COLLECTION">TBL_REVERSE_COLLECTION</mat-option>
                <mat-option value="TBL_REVERSE_LAB_PROCESSED">TBL_REVERSE_LAB_PROCESSED</mat-option>
                <mat-option value="TBL_REVERSE_LAB_PRODUCED">TBL_REVERSE_LAB_PRODUCED</mat-option>
            </mat-select>
        </mat-form-field>
        <div class="valReport-buttns">
            <button *ngIf="admin" [disabled]="!block[0]" mat-mini-fab [ngClass]="!block[0]?'ligthBackground':'green'"
                matTooltip="Crear Archivo Milicom" matTooltipPosition="above" matTooltipClass="tooltip"
                (click)="createArchive()">
                <mat-icon>note_add</mat-icon>
            </button>
            <button [disabled]="block[0]" mat-mini-fab [ngClass]="block[0]?'ligthBackground':'red'"
                matTooltip="Hora de Subida" matTooltipPosition="above" matTooltipClass="tooltip" (click)="hourConf()">
                <mat-icon>schedule</mat-icon>
            </button>
            <button [disabled]="block[0]" mat-mini-fab [ngClass]="block[0]?'ligthBackground':'green'"
                matTooltip="Columnas" matTooltipPosition="above" matTooltipClass="tooltip" (click)="archiveConf()">
                <mat-icon>view_week</mat-icon>
            </button>
            <button [disabled]="block[0]" mat-mini-fab [ngClass]="block[0]?'ligthBackground':'orange'"
                matTooltip="Listas de Validacion" matTooltipPosition="above" matTooltipClass="tooltip"
                (click)="validateConf()">
                <mat-icon>fact_check</mat-icon>
            </button>
        </div>
    </ng-container>
    <div class="valReportLoad-form">
        <div class="valReportLoad-group">
            <label class="primaryColor-c">{{titulo}} {{archive}}</label>
            <div class="validatRe-group">
                <div class="valReport-buttns">
                    <div class="valReportLoad-group">
                        <label class="primaryColor"> Cargar Archivo </label>
                        <input id="idFile" hidden #idFile type="file" accept=".xlsx,.csv" (change)="addFile($event)">
                        <div class="valReportLoad-container-button">
                            <button [disabled]="block[1]" class="valReportLoad-button1" mat-raised-button
                                matTooltipClass="tooltip" matTooltipPosition="right" matTooltip="Cargar archivo"
                                (click)="idFile.click()">
                                <mat-icon *ngIf="check[1]">done_outline</mat-icon>
                                <mat-icon *ngIf="!check[1]">upload_file</mat-icon>
                            </button>
                        </div>
                    </div>
                    <div class="valReportLoad-group">
                        <label class="primaryColor"> Validar Archivo </label>
                        <div class="valReportLoad-container-button">
                            <button [disabled]="block[2]" class="valReportLoad-button2"
                                [style.cursor]="block[1]===false?'auto':'wait'" mat-raised-button matTooltip="Validar"
                                matTooltipPosition="right" matTooltipClass="tooltip">
                                <mat-icon *ngIf="check[2]">done_outline</mat-icon>
                                <mat-icon *ngIf="!check[2]">published_with_changes</mat-icon>
                            </button>
                        </div>
                    </div>
                    <div class="valReportLoad-group">
                        <label class="primaryColor" *ngIf="timeToLoad!=null"><strong>{{timeToLoad}}</strong></label>
                        <label class="primaryColor" *ngIf="timeToLoad==null"> Subir Archivo</label>
                        <div class="valReportLoad-container-button">
                            <button [disabled]="block[3]==true || timeToLoad!=null" class="valReportLoad-button3"
                                mat-raised-button matTooltip="Subir archivo" matTooltipPosition="right"
                                matTooltipClass="tooltip" (click)="saveArchive()">
                                <mat-icon *ngIf="check[3]">done_outline</mat-icon>
                                <mat-icon *ngIf="!check[3]">cloud_upload</mat-icon>
                            </button>
                        </div>
                    </div>
                    <div class="valReportLoad-group">
                        <label class="primaryColor"> Cancelar </label>
                        <div class="valReportLoad-container-button">
                            <button [disabled]="block[4]" class="valReportLoad-button4" mat-raised-button
                                matTooltip="Cancelar" matTooltipPosition="right" matTooltipClass="tooltip"
                                (click)="deleteArchive()">
                                <mat-icon *ngIf="check[4]">done_outline</mat-icon>
                                <mat-icon *ngIf="!check[4]">block</mat-icon>
                            </button>
                        </div>
                    </div>

                </div>
                <div style="text-align: center; width: 100%;">
                    <mat-progress-bar *ngIf="value != 0" color="blue" [mode]="TypeLoading" [value]="value"
                        [bufferValue]="(value+(value/5))">></mat-progress-bar>
                </div>
            </div>

        </div>


    </div>
    <table [dataSource]="dataSource" mat-table>

        <ng-container *ngFor="let col of columns" [matColumnDef]="col">
            <th mat-header-cell *matHeaderCellDef> {{ col }} </th>
            <td mat-cell *matCellDef="let element"> {{ element[col] }} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns;"></tr>

    </table>
    <mat-paginator [pageSizeOptions]="[5, 10, 15, 20, 30, 40, 50, 100]" showFirstLastButtons
        aria-label="Seleccione el numero de filas por pagina"></mat-paginator>
</div>