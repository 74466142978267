export class PqrsClientSerialEntity{

    id:number;
    type:string;
    serial: string;
    phoneMovil: string;
    identification: string;
    imei: string;
    description: string;
    userId: number;
    customerId:number;
    categoryId:number;
    countryId:number;
 
    constructor (){}
}