<div class="segment-container">
    <p class="title">{{title}}</p>
    <form [formGroup]="form" class="segment-form">
        <div class="segment-group">
            <label class="primaryColor">Codigo</label>
            <input formControlName="code" type="text" class="form-control" placeholder="Codigo">
        </div>
        <div class="segment-group">
            <label class="primaryColor">Descripcion</label>
            <input formControlName="description" type="text" class="form-control" placeholder="Descripcion">
        </div>
        <div *ngIf="segmentId != 0" class="segment-group-checkbox">
            <label class="primaryColor">Activo</label>
            <div class="segment-container-checkbox" matRipple [matRippleCentered]="true" [matRippleRadius]="40">
                <div class="segment-checkbox">
                    <input id="active" formControlName="active" type="checkbox">
                    <label class="segment-label" for="active">
                        <mat-icon>done</mat-icon>
                    </label>
                </div>
            </div>
        </div>
        <div class="segment-container-button">
            <button class="segment-button" mat-raised-button matTooltip="Guardar" matTooltipPosition="right" matTooltipClass="tooltip" [disabled]="!form.valid" (click)="onClick()">
                <mat-icon>save</mat-icon>
            </button>
        </div>
    </form>
</div>

