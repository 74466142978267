<div class="pri-container">
    <p class="title">Usuarios Con Permisos</p>
    <button fxFlexAlign="flex-end" mat-mini-fab class="green" matTooltip="Agregar Usuario" matTooltipPosition="left"
        matTooltipClass="tooltip" (click)="create(null)">
        <mat-icon>add</mat-icon>
    </button>
    <div *ngIf="loading" class="pri-container-loader">
        <div class="pri-loader"></div>
    </div>
    <table *ngIf="!loading" class="pri-table" mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="userName">
            <th mat-header-cell *matHeaderCellDef>Nombre</th>
            <td mat-cell *matCellDef="let item">{{item.userName}}</td>
        </ng-container>
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Acciones</th>
            <td mat-cell *matCellDef="let item">
                <button mat-icon-button matTooltip="Eliminar" matTooltipPosition="above" matTooltipClass="tooltip" (click)="delete(item.userId)">
                    <mat-icon class="red-color">delete</mat-icon>
                </button>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns;"></tr>
    </table>
    <mat-paginator #paginator [pageSize]="20" [pageSizeOptions]="[5, 10, 15, 20, 30, 40, 50, 100]" showFirstLastButtons>
    </mat-paginator>
</div>