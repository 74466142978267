<div class="mee-container">
    <p class="title">Gestion de Apoyos</p>
    <div fxLayout fxLayoutAlign="start flex-end" class="mee-container-search">
        <div fxFlex="300px" fxLayout="column" style="margin-right:20px;">
            <label class="mee-title">Estado</label>
            <select [(ngModel)]="states" class="form-control" id="states">
                <option class="mee-title" value="">Seleccione un estado</option>
                <option class="mee-title" value="Pendiente">Pendiente</option>
                <option class="mee-title" value="Terminado">Terminado</option>
                <option class="mee-title" value="Rechazado">Rechazado</option>
            </select>
    </div>
<!--   <button mat-mini-fab class="blue" matTooltip="Buscar" matTooltipPosition="left" matTooltipClass="tooltip"
    (click)="status()">
    <mat-icon>search</mat-icon>
    </button> -->
        <div fxFlex="300px" fxLayout="column" style="margin-right:20px;">
            <span class="mee-title">Fecha de Inicio</span>
            <div fxLayout>
                <input readonly [(ngModel)]="date1" id="startDate" matInput [matDatepicker]="startDate" class="form-control">
                <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
                <mat-datepicker #startDate disabled="false"></mat-datepicker>
            </div>
        </div>
        <div fxFlex="300px" fxLayout="column" style="margin-right:20px;">
            <span class="mee-title">Fecha de Fin</span>
            <div fxLayout>
                <input readonly [(ngModel)]="date2" id="endDate" matInput [matDatepicker]="endDate" class="form-control">
                <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
                <mat-datepicker #endDate disabled="false"></mat-datepicker>
            </div>
        </div>
        <button mat-mini-fab class="blue" *ngIf="search_Es==true" matTooltip="Buscar" matTooltipPosition="left" matTooltipClass="tooltip"
            (click)="search()">
            <mat-icon>search</mat-icon>
        </button>
        <button mat-mini-fab color="clear" *ngIf="clear_Es==true" matTooltip="Limpiar" matTooltipPosition="left" matTooltipClass="tooltip"
            (click)="clear()">
            <mat-icon>youtube_searched_for</mat-icon>
        </button>
     </div>
  
    <div *ngIf="loading" class="mee-container-loader">
        <div class="mee-loader"></div>
    </div>
    <table class="mee-table" mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="support">
            <th mat-header-cell *matHeaderCellDef>Apoyo</th>
            <td mat-cell *matCellDef="let item" (click)="getFiles(item.meetingId,item.id)">{{item.support}}</td>
        </ng-container>
        <ng-container matColumnDef="meeting">
            <th mat-header-cell *matHeaderCellDef>Reunion</th>
            <td mat-cell *matCellDef="let item" (click)="getFiles(item.meetingId,item.id)">{{item.meeting}}</td>
        </ng-container>
        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>Estado</th>
            <td mat-cell *matCellDef="let item"
                [ngStyle]="{'color':item.status==='Pendiente'?'var(--red)':item.status==='Terminado'?'var(--green)':'var(--orange)'}"
                (click)="getFiles(item.meetingId,item.id)">{{item.status}}</td>
        </ng-container>
        <ng-container matColumnDef="startDate">
            <th mat-header-cell *matHeaderCellDef>Fecha Inicio</th>
            <td mat-cell *matCellDef="let item" (click)="getFiles(item.meetingId,item.id)">{{item.startDate}}</td>
        </ng-container>
        <ng-container matColumnDef="endDate">
            <th mat-header-cell *matHeaderCellDef>Fecha Fin</th>
            <td mat-cell *matCellDef="let item" (click)="getFiles(item.meetingId,item.id)">{{item.endDate}}</td>
        </ng-container>
        <ng-container matColumnDef="creationUser">
            <th mat-header-cell *matHeaderCellDef>Usuario Creacion</th>
            <td mat-cell *matCellDef="let item" (click)="getFiles(item.meetingId,item.id)">{{item.creationUser}}</td>
        </ng-container>
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Acciones</th>
            <td mat-cell *matCellDef="let item">
                <button mat-icon-button matTooltip="Gestionar" matTooltipPosition="above" matTooltipClass="tooltip"
                    (click)="management(item)">
                    <mat-icon class="green-color">settings</mat-icon>
                </button>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns;"></tr>
    </table>
    <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 15, 20, 30, 40, 50, 100]" showFirstLastButtons>
    </mat-paginator>
    <ng-container *ngIf="supportId!=0">
        <p class="title">Listado de Evidencias</p>
        <div fxFlex fxLayout fxLayoutAlign="center center">
            <span *ngIf="fileList.length===0" class="mee-title">¡Sin evidencias!</span>
        </div>
        <input #file hidden type="file" (change)="loadFile($event.target.files)">
        <button fxFlexAlign="flex-end" mat-mini-fab class="green" matTooltip="Cargar archivo" matTooltipPosition="left"
            matTooltipClass="tooltip" (click)="file.click()">
            <mat-icon>add</mat-icon>
        </button>
        <div *ngIf="loadingFiles" class="mee-container-loader">
            <div class="mee-loader"></div>
        </div>
        <div *ngIf="!loadingFiles && fileList.length>0" class="mee-container-files">
            <div *ngFor="let f of fileList" class="mee-card-file" matTooltip="{{f.name}}" matTooltipPosition="above"
                matTooltipClass="tooltip">
                <span class="mee-title">{{f.type}}</span>
                <div fxLayout fxLayoutAlign="space-between flex-end" [ngStyle]="{'width':'100%'}">
                    <mat-icon class="blue-color" matTooltip="Descargar" matTooltipPosition="above"
                        matTooltipClass="tooltip" (click)="downloadFile(f)">
                        cloud_download</mat-icon>
                    <mat-icon class="red-color" matTooltip="Eliminar" matTooltipPosition="above"
                        matTooltipClass="tooltip" (click)="deleteFile(f.name)">
                        delete
                    </mat-icon>
                </div>
            </div>
        </div>
    </ng-container>
</div>