export class PriFormulaCreateEntity{

    id:number;
    variableId:number;
    operatorId:number;
    caracterId:number;
    type:string;
    formulaId:number;
    variable:string;
    constructor (){}
}