
export class ComCommodityEntrySapB1Entity {

    id:number;
    customerId:number;
    customerName:string;
    state:string;
    statusCode:number;
    documentCode:string;
    location:string;
    userId:number;
    userName:string;
    creationDate:string;

    constructor(){ }
}