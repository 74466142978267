<div class="user-container">
    <p class="title">Listado Usuarios</p>
    <div class="user-container-button">
        <div class="user-group">
            <input type="text" class="form-control" placeholder="Filtrar" (keyup)="filter($event)">
            <mat-icon class="blue-color">search</mat-icon>
        </div>
        <button routerLink="/principal/user/new" mat-mini-fab class="green" matTooltip="Crear" matTooltipPosition="left" matTooltipClass="tooltip">
            <mat-icon>add</mat-icon>
        </button>
    </div>
    <table #table class="user-table" mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="identification">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Identificacion</th>
            <td mat-cell *matCellDef="let item">{{item.identification}}</td>
        </ng-container>
        <ng-container matColumnDef="firstName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Nombres</th>
            <td mat-cell *matCellDef="let item">{{item.firstName}}</td>
        </ng-container>
        <ng-container matColumnDef="lastName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Apellidos</th>
            <td mat-cell *matCellDef="let item">{{item.lastName}}</td>
        </ng-container>
        <ng-container matColumnDef="userName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Usuario</th>
            <td mat-cell *matCellDef="let item">{{item.userName}}</td>
        </ng-container>
        <ng-container matColumnDef="mail">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Correo</th>
            <td mat-cell *matCellDef="let item">{{item.mail}}</td>
        </ng-container>
        <ng-container matColumnDef="creationDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha Creacion</th>
            <td mat-cell *matCellDef="let item">{{item.creationDate}}</td>
        </ng-container>
        <ng-container matColumnDef="centerCost">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Centro de Costo</th>
            <td mat-cell *matCellDef="let item">{{item.centerCost}}</td>
        </ng-container>
        <ng-container matColumnDef="position">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Cargo</th>
            <td mat-cell *matCellDef="let item">{{item.position}}</td>
        </ng-container>
        <ng-container matColumnDef="city">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Ciudad</th>
            <td mat-cell *matCellDef="let item">{{item.city}}</td>
        </ng-container>
        <ng-container matColumnDef="active">
            <th mat-header-cell *matHeaderCellDef>Activo</th>
            <td mat-cell *matCellDef="let item">
                <mat-icon *ngIf="item.active" class="green-color">done</mat-icon>
                <mat-icon *ngIf="!item.active" class="red-color">clear</mat-icon>
            </td>
        </ng-container>
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Acciones</th>
            <td mat-cell *matCellDef="let item">
                <button *ngIf="!item.active" mat-icon-button matTooltip="Activar" matTooltipPosition="above" matTooltipClass="tooltip" (click)="delete(item.id,true)">
                    <mat-icon class="green-color">done</mat-icon>
                </button>
                <button *ngIf="item.active" mat-icon-button matTooltip="Inactivar" matTooltipPosition="above" matTooltipClass="tooltip" (click)="delete(item.id,false)">
                    <mat-icon class="red-color">delete</mat-icon>
                </button>
                <button mat-icon-button matTooltip="Editar" matTooltipPosition="above" matTooltipClass="tooltip" (click)="edit(item.id)">
                    <mat-icon class="blue-color">create</mat-icon>
                </button>
                <button mat-icon-button matTooltip="Mas" matTooltipPosition="above" matTooltipClass="tooltip" [matMenuTriggerFor]="menuUser">
                    <mat-icon class="orange-color">more_vert</mat-icon>
                </button>
                <mat-menu #menuUser="matMenu" xPosition="before">
                    <button mat-menu-item (click)="getCountry(item.id)">
                        <mat-icon>public</mat-icon>
                        <span>Paises</span>
                    </button>
                    <button mat-menu-item (click)="getApplication(item.id)">
                        <mat-icon>apps</mat-icon>
                        <span>Aplicaciones</span>
                    </button>
                    <button mat-menu-item (click)="getCustomer(item.id)">
                        <mat-icon>hail</mat-icon>
                        <span>Clientes</span>
                    </button>
                    <button mat-menu-item (click)="getProfile(item.id)">
                        <mat-icon>wc</mat-icon>
                        <span>Perfiles</span>
                    </button>
                    <button mat-menu-item (click)="getReport(item.id)">
                        <mat-icon>text_snippet</mat-icon>
                        <span>Reportes</span>
                    </button>
                    <button mat-menu-item (click)="getControlPanel(item.id)">
                        <mat-icon>analytics</mat-icon>
                        <span>Tableros de Control</span>
                    </button>
                    <button mat-menu-item (click)="getPlant(item.id)">
                        <mat-icon>business</mat-icon>
                        <span>Planta</span>
                    </button>
                </mat-menu>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns;"></tr>
    </table>
    <div *ngIf="loading" class="user-container-loader">
        <div class="user-loader"></div>
    </div> 
    <mat-paginator [pageSizeOptions]="[5, 10, 15, 20, 30, 40, 50, 100]" showFirstLastButtons></mat-paginator>
</div>