<div class="report-container-button">
    <button mat-icon-button mat-dialog-close>
        <mat-icon>clear</mat-icon>
    </button>
</div>
<h2 mat-dialog-title>Listas de Validacion</h2>
<mat-dialog-content>
    <mat-accordion>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    OP1
                </mat-panel-title>
                <mat-panel-description>
                    Lista donde los valores diferentes no son admitidos
                </mat-panel-description>
            </mat-expansion-panel-header>
            <ng-container *ngIf="admin">
                <input type="text" maxlength="50" [(ngModel)]="input1" placeholder="Agregar a lista">
                <button mat-mini-fab class="green" tabindex="1" matTooltipPosition="right" matTooltipClass="tooltip"
                    matTooltip="Agregar" (click)="add('OP1')">
                    <mat-icon>add</mat-icon>
                </button>
                <mat-selection-list #sel1 [(ngModel)]="selectedInterfaz">
                    <mat-list-option color="warn" *ngFor="let shoe of this.data.OP1" [value]="shoe">{{shoe}}</mat-list-option>
                </mat-selection-list>
            
                <p>
                    Seleccionados: {{sel1.selectedOptions.selected.length}}
                </p>
                <button [disabled]="sel1.selectedOptions.selected.length==0" mat-raised-button color="warn" matTooltipPosition="right" matTooltipClass="tooltip" (click)="delete('OP1')">Eliminar Seleccionados</button>
            </ng-container>
            <ng-container *ngIf="!admin">
                <mat-list>
                    <mat-list-item *ngFor="let shoe of this.data.OP2;let i = index">{{i+1}}. {{shoe}}</mat-list-item>
                </mat-list>
            </ng-container>
        </mat-expansion-panel>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    OP2
                </mat-panel-title>
                <mat-panel-description>
                    Lista donde los valores diferentes son replazados por 'NULL'
                </mat-panel-description>
            </mat-expansion-panel-header>
            <ng-container *ngIf="admin">
                <input type="text" maxlength="50" [(ngModel)]="input2" placeholder="Agregar a lista">
                <button mat-mini-fab class="green" tabindex="1" matTooltipPosition="right" matTooltipClass="tooltip"
                    matTooltip="Agregar" (click)="add('OP2')">
                    <mat-icon>add</mat-icon>
                </button>
                <mat-selection-list #sel2 [(ngModel)]="selectedRegional">
                    <mat-list-option color="warn" *ngFor="let shoe of this.data.OP2" [value]="shoe">{{shoe}}</mat-list-option>
                </mat-selection-list>
            
                <p>
                    Seleccionados: {{sel2.selectedOptions.selected.length}}
                </p>
                <button [disabled]="sel2.selectedOptions.selected.length==0" mat-raised-button color="warn" matTooltipPosition="right" matTooltipClass="tooltip" (click)="delete('OP2')">Eliminar Seleccionados</button>
            </ng-container>
            <ng-container *ngIf="!admin">
                <mat-list>
                    <mat-list-item *ngFor="let shoe of this.data.OP2;let i = index">{{i+1}}. {{shoe}}</mat-list-item>
                </mat-list>
            </ng-container>
            
        </mat-expansion-panel>
    </mat-accordion>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-stroked-button [(disabled)]="!trueChange" color="primary" (click)="close()">Guardar</button>
    <button mat-stroked-button [(disabled)]="!trueChange" mat-dialog-close color="warn">Cancelar</button>
</mat-dialog-actions>