<div mat-dialog-content>
    <div class="cyclic-container-button">
        <button mat-icon-button (click)="close(false)">
            <mat-icon>clear</mat-icon>
        </button>
    </div>
    <p class="title">Listado de Seriales</p>
    <div class="cyclic-container">
        <table class="cyclic-table" mat-table [dataSource]="dataSource">
            <ng-container matColumnDef="serial">
                <th mat-header-cell *matHeaderCellDef>Serial</th>
                <td mat-cell *matCellDef="let item">{{item.serial}}</td>
            </ng-container>
            <ng-container matColumnDef="mac">
                <th mat-header-cell *matHeaderCellDef>Mac</th>
                <td mat-cell *matCellDef="let item">{{item.mac}}</td>
            </ng-container>
            <ng-container matColumnDef="sapCode">
                <th mat-header-cell *matHeaderCellDef>Codigo Sap</th>
                <td mat-cell *matCellDef="let item">{{item.sapCode}}</td>
            </ng-container>
            <ng-container matColumnDef="sapCodeSap">
                <th mat-header-cell *matHeaderCellDef>Codigo Sap Sap</th>
                <td mat-cell *matCellDef="let item">{{item.sapCodeSap}}</td>
            </ng-container>
            <ng-container matColumnDef="sapCodeWms">
                <th mat-header-cell *matHeaderCellDef>Codigo Sap Wms</th>
                <td mat-cell *matCellDef="let item">{{item.sapCodeWms}}</td>
            </ng-container>
            <ng-container matColumnDef="sapCodeBase">
                <th mat-header-cell *matHeaderCellDef>Codigo Sap Base</th>
                <td mat-cell *matCellDef="let item">{{item.sapCodeBase}}</td>
            </ng-container>
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef>Estado</th>
                <td mat-cell *matCellDef="let item">{{item.status}}</td>
            </ng-container>
            <ng-container matColumnDef="statusSap">
                <th mat-header-cell *matHeaderCellDef>Estado Sap</th>
                <td mat-cell *matCellDef="let item">{{item.statusSap}}</td>
            </ng-container>
            <ng-container matColumnDef="statusWms">
                <th mat-header-cell *matHeaderCellDef>Estado Wms</th>
                <td mat-cell *matCellDef="let item">{{item.statusWms}}</td>
            </ng-container>
            <ng-container matColumnDef="statusBase">
                <th mat-header-cell *matHeaderCellDef>Estado Base</th>
                <td mat-cell *matCellDef="let item">{{item.statusBase}}</td>
            </ng-container>
            <ng-container matColumnDef="pallet">
                <th mat-header-cell *matHeaderCellDef>Pallet</th>
                <td mat-cell *matCellDef="let item">{{item.pallet}}</td>
            </ng-container>
            <ng-container matColumnDef="palletSap">
                <th mat-header-cell *matHeaderCellDef>Pallet Sap</th>
                <td mat-cell *matCellDef="let item">{{item.palletSap}}</td>
            </ng-container>
            <ng-container matColumnDef="palletWms">
                <th mat-header-cell *matHeaderCellDef>Pallet Wms</th>
                <td mat-cell *matCellDef="let item">{{item.palletWms}}</td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="columns"></tr>
            <tr mat-row *matRowDef="let row; columns: columns;"></tr>
        </table>
        <button mat-mini-fab matTooltip="Validar" class="cyclic-float green" matTooltipPosition="left"
            matTooltipClass="tooltip" (click)="validate()">
            <mat-icon>done</mat-icon>
        </button>
    </div>
    <div *ngIf="loading" class="cyclic-container-loader">
        <div class="cyclic-loader"></div>
    </div>
    <mat-paginator [pageSizeOptions]="[5, 10, 15, 20, 30, 40, 50, 100]" showFirstLastButtons></mat-paginator>
    <div>

    </div>
</div>