<div class="loc-container">
    <p class="title">Listado</p>
    <br>
    <br>
    <button mat-mini-fab class="blue" matTooltip="Exportar" matTooltipPosition="above" matTooltipClass="tooltip"
    (click)="download()">
    <mat-icon>cloud_download</mat-icon>
</button>
    <br>
    <table id="tabla" class="loc-table" mat-table [dataSource]="dataSource" matTableExporter
        #exporter="matTableExporter">
        <ng-container matColumnDef="CLIENTE">
            <th mat-header-cell *matHeaderCellDef>CLIENTE</th>
            <td mat-cell *matCellDef="let item">{{item.cliente}}</td>
        </ng-container>
        <ng-container matColumnDef="SERIAL EQUIPO">
            <th mat-header-cell *matHeaderCellDef>SERIAL EQUIPO</th>
            <td mat-cell *matCellDef="let item">{{item.serial_equipo}}</td>
        </ng-container>
        <ng-container matColumnDef="CODIGO SAP">
            <th mat-header-cell *matHeaderCellDef>CODIGO SAP</th>
            <td mat-cell *matCellDef="let item">{{item.codigo_sap}}</td>
        </ng-container>
        <ng-container matColumnDef="ESTADO">
            <th mat-header-cell *matHeaderCellDef>ESTADO</th>
            <td mat-cell *matCellDef="let item">{{item.estado}}</td>
        </ng-container>
        <ng-container matColumnDef="TIPOLOGIA">
            <th mat-header-cell *matHeaderCellDef>TIPOLOGIA</th>
            <td mat-cell *matCellDef="let item">{{item.tipologia}}</td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns;"></tr>
    </table>
    <mat-paginator #paginator [pageSizeOptions]="[5, 10, 15, 20, 30, 40, 50, 100]" showFirstLastButtons>
    </mat-paginator>
    <div *ngIf="loading" class="loc-container-loader">
        <div class="loc-loader"></div>
    </div>
</div>