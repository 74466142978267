<div class="actfix-container">
    <p class="title">Solicitudes de salida</p>
    <div class="actfix-container-button">
        <div class="actfix-group">
            <input type="text" class="form-control" placeholder="Filtrar" (keyup)="filter($event)">
            <mat-icon class="blue-color">search</mat-icon>
        </div>
    </div>
    <table #table class="actfix-table" mat-table [dataSource]="dataSource" matSort>
     
        <ng-container matColumnDef="identification">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>identificacion</th>
            <td mat-cell *matCellDef="let item">{{item.identification}}</td>
        </ng-container>
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Nombre</th>
            <td mat-cell *matCellDef="let item">{{item.name}}</td>
        </ng-container>
        <ng-container matColumnDef="product">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Producto asignado</th>
            <td mat-cell *matCellDef="let item">{{item.product}}</td>
        </ng-container>
        <ng-container matColumnDef="serial">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Serial</th>
            <td mat-cell *matCellDef="let item">{{item.serial}}</td>
        </ng-container>
        <ng-container matColumnDef="nameRes">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Responsable del equipo</th>
            <td mat-cell *matCellDef="let item">{{item.nameRes}}</td>
        </ng-container>
        <ng-container matColumnDef="answer">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Respuesta</th>
            <td mat-cell *matCellDef="let item">{{item.answer}}</td>
        </ng-container>
       
        <ng-container matColumnDef="approvedRejected">
            <th mat-header-cell *matHeaderCellDef>Aprobado / Rechazado</th>
            <td mat-cell *matCellDef="let item">
                <mat-icon *ngIf="item.approvedRejected" class="green-color">done</mat-icon>
                <mat-icon *ngIf="!item.approvedRejected" class="red-color">clear</mat-icon>
            </td>
        </ng-container>
        <ng-container matColumnDef="Acciones">
            <th mat-header-cell *matHeaderCellDef>Acciones</th>
            <td mat-cell *matCellDef="let item">
              <button  mat-icon-button matTooltip="aprobar" matTooltipPosition="above" matTooltipClass="tooltip"
                (click)="save(item.id,item.identification,true,item.answer)">
                <mat-icon  [ngClass]="{'ligthColor':item.answer !=null,'green-color':item.answer===null}">done</mat-icon>
            </button>
           <button  mat-icon-button matTooltip="rechazar" matTooltipPosition="above" matTooltipClass="tooltip"
                (click)="save(item.id,item.identification,false,item.answer)">
                <mat-icon  [ngClass]="{'ligthColor':item.answer !=null,'red-color':item.answer===null}">close</mat-icon>
            </button>
            <div *ngIf="item.approvedRejected == true; else not" >
            <button  mat-icon-button matTooltip="Salida / Ingreso" matTooltipPosition="above" matTooltipClass="tooltip" (click)="dates(item.id)">
            <mat-icon class="blue-color">date_range</mat-icon>
        </button></div>
        <ng-template #not>
            <button disabled mat-icon-button matTooltip="Salida / Ingreso" matTooltipPosition="above" matTooltipClass="tooltip" (click)="dates(item.id)">
                <mat-icon>date_range</mat-icon>
            </button>
        </ng-template>
            <!-- <button [disabled]="item.answer !=null" mat-icon-button matTooltip="aprobar" matTooltipPosition="above" matTooltipClass="tooltip"
                    (click)="save(item.id,item.identification,true,item.answer)">
                    <mat-icon  [ngClass]="{'ligthColor':item.answer !=null,'green-color':item.answer===null}">done</mat-icon>
                </button>
                <button [disabled]="item.answer !=null"  mat-icon-button matTooltip="rechazar" matTooltipPosition="above" matTooltipClass="tooltip"
                    (click)="save(item.id,item.identification,false,item.answer)">
                    <mat-icon  [ngClass]="{'ligthColor':item.answer !=null,'red-color':item.answer===null}">close</mat-icon>
                </button>-->
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns;"></tr>
    </table>
    <div *ngIf="loading" class="report-container-loader">
        <div class="report-loader"></div>
    </div>
    <mat-paginator [pageSizeOptions]="[5, 10, 15, 20, 30, 40, 50, 100]" showFirstLastButtons></mat-paginator>
</div>

