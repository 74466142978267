<div mat-dialog-content>
    <div class="bsc-container-button">
        <button mat-icon-button (click)="close(false)">
            <mat-icon>clear</mat-icon>
        </button>
    </div>
    <p class="title">Nuevo Avance</p>
    <div class="bsc-form">
        <div class="bsc-group">
            <label class="primaryColor">Descripcion</label>
            <textarea [(ngModel)]="description" class="form-control" placeholder="Descripcion"></textarea>
        </div>
        <button mat-mini-fab class="bsc-float-button" matTooltip="Guardar" matTooltipPosition="left" matTooltipClass="tooltip" (click)="save()">
            <mat-icon>save</mat-icon>
        </button>
    </div>
</div>