import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GenSectionEntity } from 'src/app/appGeneral/entities/genSection.entity';
import { GenSectionService } from 'src/app/appGeneral/services/genSection.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { RepReportEntity } from '../../entities/repReport.entity';
import { RepReportService } from '../../services/repReport.service';

@Component({
  selector: 'app-report-edit',
  templateUrl: './reportEdit.component.html',
  styleUrls: ['./reportEdit.component.css']
})
export class ReportEditComponent implements OnInit {
  title:string;
  repReportEntity:RepReportEntity;
  sectionList:GenSectionEntity[];
  newForm = new FormGroup({
    id: new FormControl(''),
    name: new FormControl('', Validators.required),
    typeQuery: new FormControl('', Validators.required),
    storeProcedure: new FormControl('', Validators.required),
    sectionId: new FormControl('', Validators.required),
    active: new FormControl('')
  });
  idReport:number;

  constructor(private repReportS:RepReportService,
    private genSectionS:GenSectionService,
    private alertS:AlertService,
    public dialogRef: MatDialogRef<ReportEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.idReport = data.reportId;
  }

  ngOnInit(): void {
    this.genSectionS.list('REPORTES').subscribe(res=>{
      if(res.message==='OK'){
        this.sectionList=res.object;
        console.log(res.object);
      }else{
        this.alertS.open(res.message,'error');
      }
    },err=>{
      this.alertS.open(err.message,'error');
    });

    if(this.idReport!=null){
      this.title="Editar Reporte";
      this.repReportS.findById(this.idReport).subscribe(res=>{
        if(res.message==='OK'){
          this.repReportEntity=res.object;
          this.newForm.setValue(
            {
              'id':this.repReportEntity.id,
              'name':this.repReportEntity.name,
              'typeQuery':this.repReportEntity.typeQuery,
              'storeProcedure':this.repReportEntity.storeProcedure,
              'sectionId':this.repReportEntity.sectionId,
              'active':this.repReportEntity.active
            }
          );
        }else{
          this.alertS.open(res.message,'error');
        }
      },err=>{
        this.alertS.open(err.message,'error');
      });
    }else{
      this.title="Nuevo Reporte";
    }
  }

  onClick(){
    if (this.idReport != null) {
      this.repReportS.update(this.newForm.value).subscribe((res) => {
          if (res.message === 'OK') {
            if (res.object != 0) {
              this.alertS.open('Reporte actualizado!', 'success');
              this.newForm.reset();
            } else {
              this.alertS.open(res.message, 'error');
            }
          } else {
            this.alertS.open(res.message, 'error');
          }
        },(err) => {
          this.alertS.open(err.message, 'error');
        }
      );
    } else {
      this.repReportS.create(this.newForm.value).subscribe((res) => {
          if (res.message === 'OK') {
            if (res.object != 0) {
              this.alertS.open('Reporte creado!', 'success');
              this.newForm.reset();
            } else {
              this.alertS.open(res.message, 'error');
            }
          } else {
            this.alertS.open(res.message, 'error');
          }
        },(err) => {
          this.alertS.open(err.message, 'error');
        }
      );
    }   
    this.close();
  }

  close(): void {
    this.dialogRef.close();
  }

}
