<div mat-dialog-content>
    <div class="close" fxLayout fxLayoutAlign="flex-end">
        <button mat-icon-button (click)="close(false)">
            <mat-icon>clear</mat-icon>
        </button>
    </div>
    <p class="title">Registro de anexos</p>
    <br>
    <form [formGroup]="form" class="acts-form form" >
        <div class="groupDir">
            <label class="primaryColor">Contrato</label>
            <select class="form-control" formControlName="contract" >
                <option class="ligthColor" value="">Selecciona una opcion</option>
                <option value="Indefinido">Indefinido</option>
                <option value="Fijo">Fijo</option>
                <option value="Obra labor">Obra labor</option>
                <option value="Prestacion de servicios">Prestacion de servicios</option>
                <option value="Temporal">Temporal</option>
            </select>
        </div>
        <div class="groupDir">
            <label class="primaryColor">Segmento</label>
            <select formControlName="segment" class="form-control">
                <option class="ligthColor" value="">Seleccione un equipo</option>
                <option *ngFor="let c of segmentList" value="{{c.description}}">{{c.description}}</option>
            </select>
        </div>
        <div class="groupDir">
            <label class="primaryColor">Cliente</label>
            <select formControlName="customer" class="form-control">
                <option class="ligthColor" value="">Seleccione un equipo</option>
                <option *ngFor="let c of customerList" value="{{c.description}}">{{c.description}}</option>
            </select>
        </div>
        <div class="groupDir">
            <label class="primaryColor">Centro de costo</label>
            <select formControlName="costCenter" class="form-control">
                <option class="ligthColor" value="">Seleccione un equipo</option>
                <option *ngFor="let c of costCenterList" value="{{c.description}}">{{c.description}}</option>
            </select>
        </div>
        <button mat-mini-fab class="button-float green" matTooltip="Guardar" matTooltipPosition="left"
            matTooltipClass="tooltip" (click)="save()">
            <mat-icon>done</mat-icon>
        </button>
    </form>
</div>