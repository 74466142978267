<div class="priVar-container">
    <p class="title">{{title}}</p>
    <form [formGroup]="form" class="priVar-form">
        <div class="priVar-group">
            <label class="primaryColor">Variable</label>
            <input name="name" formControlName="name" class="form-control text-may" placeholder="Variable">
            <div class="invalid-text" *ngIf="form.get('name').invalid && form.get('name').touched">Ingrese un variable valido</div>
        </div>
        <div class="priVar-group">
            <label class="primaryColor">Descripcion</label>
            <input formControlName="description" type="text" class="form-control text-may" placeholder="Descripcion">
            <div class="invalid-text" *ngIf="form.get('description').invalid && form.get('description').touched">Ingrese una descripcion valida</div>
        </div>
        <div class="priVar-group">
            <label class="primaryColor">Valor</label>
            <input name="name" formControlName="value" class="form-control text-may" placeholder="Valor">
            <div class="invalid-text" *ngIf="form.get('value').invalid && form.get('value').touched">Ingrese un variable valido</div>
        </div>
        <div *ngIf="editing !=0" class="priVar-group-checkbox">
            <label class="primaryColor">Activo</label>
            <div class="priVar-container-checkbox" matRipple [matRippleCentered]="true" [matRippleRadius]="40">
                <div class="priVar-checkbox">
                    <input id="check" formControlName="active" type="checkbox">
                    <label class="priVar-label" for="check">
                        <mat-icon>done</mat-icon>
                    </label>
                </div>
            </div>
        </div>
        <div class="priVar-group">
            <label class="primaryColor">Guardar</label>
            <div class="priVar-container-button">
                <button type="reset" class="priVar-button" mat-raised-button matTooltip="Guardar" mat-raised-button [disabled]="!form.valid" matTooltipPosition="right" matTooltipClass="tooltip" (click)="save()">
                    <mat-icon>save</mat-icon>
                </button>
            </div>
        </div>
    </form>
</div>
<div class="priVar-container">
    <div class="priVar-container-button">
        <div class="priVar-group-filter">
            <input type="text" class="form-control" placeholder="Filtrar" (keyup)="filter($event)">
            <mat-icon class="blue-color">search</mat-icon>
        </div>
    </div>
    <table #table class="priVar-table" mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let item">
                <button *ngIf="item.id === editing" mat-icon-button matTooltip="Cerrar Edicion"
                    matTooltipPosition="above" matTooltipClass="tooltip" (click)="closeEditing()">
                    <mat-icon class="ligthColor">close</mat-icon>
                </button>
            </td>
        </ng-container>
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Variable</th>
            <td mat-cell *matCellDef="let item">{{item.name}}</td>
        </ng-container>
        <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Descripcion</th>
            <td mat-cell *matCellDef="let item">{{item.description}}</td>
        </ng-container>
        <ng-container matColumnDef="value">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Valor</th>
            <td mat-cell *matCellDef="let item">{{item.value}}</td>
        </ng-container>
        <ng-container matColumnDef="active">
            <th mat-header-cell *matHeaderCellDef>Activo</th>
            <td mat-cell *matCellDef="let item">
                <mat-icon *ngIf="item.active" class="green-color">done</mat-icon>
                <mat-icon *ngIf="!item.active" class="red-color">clear</mat-icon>
            </td>
        </ng-container>
        <ng-container matColumnDef="Acciones">
            <th mat-header-cell *matHeaderCellDef>Acciones</th>
            <td mat-cell *matCellDef="let item">
                <button mat-icon-button matTooltip="Eliminar" matTooltipPosition="above" matTooltipClass="tooltip"
                    (click)="delete(item.id)">
                    <mat-icon class="red-color">delete</mat-icon>
                </button>
                <button mat-icon-button matTooltip="Editar" matTooltipPosition="above" matTooltipClass="tooltip" (click)="edit(item)">
                    <mat-icon class="blue-color">create</mat-icon>
                </button>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns;"></tr>
    </table>
    <div *ngIf="loading" class="report-container-loader">
        <div class="report-loader"></div>
    </div>
    <mat-paginator [pageSizeOptions]="[5, 10, 15, 20, 30, 40, 50, 100]" showFirstLastButtons></mat-paginator>
</div>