import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResponseModel } from 'src/app/shared/models/response.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MilicomArchivesService {
  
  private url: string;
  constructor(private http: HttpClient) {
    this.url = environment.api;
  }

  public insert(reporte:string,insertMilicom:any):Observable<ResponseModel>{
    var headers=new HttpHeaders().set('content-type','application/json');
    return this.http.post<ResponseModel>(this.url+'MilicomArchives/create/'+reporte,JSON.stringify(insertMilicom),{headers:headers});
  }

  public delete(reporte:string,fechaCargue:string,paisCargue:string,clienteCargue:string):Observable<ResponseModel>{
    var headers = new HttpHeaders().set('content-Type', 'application/x-www-form-urlencoded');
    return this.http.delete<ResponseModel>(this.url+'MilicomArchives/delete/'+reporte+'/'+fechaCargue+'/'+paisCargue+'/'+clienteCargue,{headers:headers});
  }

  public checkingArchive(reporte:string,fechaCargue: string,paisCargue: string,clienteCargue: string):Observable<ResponseModel>{
    var headers = new HttpHeaders().set('content-Type', 'application/json');
    return this.http.get<ResponseModel>(this.url+'MilicomArchives/checking/'+reporte+'/'+fechaCargue+'/'+paisCargue+'/'+clienteCargue,{headers:headers});
  }
}
