<div class="cyclic-container">
    <p class="title">Auditoria Conteos</p>
    <div class="cyclic-form">
        <div class="cyclic-group">
            <label class="primaryColor">Cliente</label>
            <select [(ngModel)]="customer" class="form-control" (change)="getCyclic()">
                <option class="ligthColor" value="0">Seleccione un cliente</option>
                <option *ngFor="let c of customerList" value="{{c.customerId}}_{{c.customer}}">{{c.customer}}</option>
            </select>
        </div>
        <div class="cyclic-group">
            <label class="primaryColor">Ciclico</label>
            <select [(ngModel)]="cyclicId" class="form-control">
                <option class="ligthColor" value="0">Seleccione un ciclico</option>
                <option *ngFor="let c of cyclicList" value="{{c.id}}">{{c.name}}</option>
            </select>
        </div>
        <div class="cyclic-group">
            <label class="primaryColor">Pallet</label>
            <div class="cyclic-control-group">
                <input [(ngModel)]="pallet" type="text" class="form-control" placeholder="Pallet"
                    (keyup)="search($event)">
                <button mat-mini-fab class="blue" matTooltip="Buscar" matTooltipPosition="above"
                    matTooltipClass="tooltip" (click)="search($event)">
                    <mat-icon>search</mat-icon>
                </button>
            </div>
        </div>
    </div>
    <ng-container *ngIf="dataSourceCouting.data.length>0">
        <p class="title">Listado de Conteos</p>
        <table class="cyclic-table" mat-table [dataSource]="dataSourceCouting">
            <ng-container matColumnDef="coutingType">
                <th mat-header-cell *matHeaderCellDef>Tipo Conteo</th>
                <td mat-cell *matCellDef="let item" (click)="getSerials(item.id,item.coutingType,item.pallet,item.type)">{{item.coutingType}}</td>
            </ng-container>
            <ng-container matColumnDef="sampling">
                <th mat-header-cell *matHeaderCellDef>Muestra</th>
                <td mat-cell *matCellDef="let item" (click)="getSerials(item.id,item.coutingType,item.pallet,item.type)">{{item.sampling}}%</td>
            </ng-container>
            <ng-container matColumnDef="total">
                <th mat-header-cell *matHeaderCellDef>Total</th>
                <td mat-cell *matCellDef="let item" (click)="getSerials(item.id,item.coutingType,item.pallet,item.type)">{{item.total}}%</td>
            </ng-container>
            <ng-container matColumnDef="quantity">
                <th mat-header-cell *matHeaderCellDef>Cantidad</th>
                <td mat-cell *matCellDef="let item" (click)="getSerials(item.id,item.coutingType,item.pallet,item.type)">{{item.quantity}}</td>
            </ng-container>
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef>Estado</th>
                <td mat-cell *matCellDef="let item" (click)="getSerials(item.id,item.coutingType,item.pallet,item.type)"
                    [ngClass]="{'red-color':item.status==='Pendiente','orange-color':item.status==='Iniciado' || item.status==='Terminado','green-color':item.status==='Cerrado'}">
                    {{item.status}}</td>
            </ng-container>
            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef>Tipo</th>
                <td mat-cell *matCellDef="let item" (click)="getSerials(item.id,item.coutingType,item.pallet,item.type)">{{item.type}}</td>
            </ng-container>
            <ng-container matColumnDef="creationDate">
                <th mat-header-cell *matHeaderCellDef>Fecha Creacion</th>
                <td mat-cell *matCellDef="let item" (click)="getSerials(item.id,item.coutingType,item.pallet,item.type)">{{item.creationDate}}</td>
            </ng-container>
            <ng-container matColumnDef="startDate">
                <th mat-header-cell *matHeaderCellDef>Fecha Inicio</th>
                <td mat-cell *matCellDef="let item" (click)="getSerials(item.id,item.coutingType,item.pallet,item.type)">{{item.startDate}}</td>
            </ng-container>
            <ng-container matColumnDef="endDate">
                <th mat-header-cell *matHeaderCellDef>Fecha Fin</th>
                <td mat-cell *matCellDef="let item" (click)="getSerials(item.id,item.coutingType,item.pallet,item.type)">{{item.endDate}}</td>
            </ng-container>
            <ng-container matColumnDef="assistant">
                <th mat-header-cell *matHeaderCellDef>Auxiliar</th>
                <td mat-cell *matCellDef="let item" (click)="getSerials(item.id,item.coutingType,item.pallet,item.type)">{{item.assistant}}</td>
            </ng-container>
            <ng-container matColumnDef="card">
                <th mat-header-cell *matHeaderCellDef>Tarjeta</th>
                <td mat-cell *matCellDef="let item" (click)="getSerials(item.id,item.coutingType,item.pallet,item.type)">{{item.card}}</td>
            </ng-container>
            <ng-container matColumnDef="creationUser">
                <th mat-header-cell *matHeaderCellDef>Usuario Creacion</th>
                <td mat-cell *matCellDef="let item" (click)="getSerials(item.id,item.coutingType,item.pallet,item.type)">{{item.creationUser}}</td>
            </ng-container>
            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef>Acciones</th>
                <td mat-cell *matCellDef="let item">
                    <button mat-icon-button matTooltip="Cerrar" matTooltipPosition="above" matTooltipClass="tooltip"
                        (click)="close(item.id,item.sampling,item.total,item.status)">
                        <mat-icon class="green-color">close</mat-icon>
                    </button>
                    <button mat-icon-button matTooltip="Abrir" matTooltipPosition="above" matTooltipClass="tooltip"
                        (click)="open(item.id)">
                        <mat-icon class="orange-color">vpn_key</mat-icon>
                    </button>
                    <button mat-icon-button matTooltip="Imprimir" matTooltipPosition="above" matTooltipClass="tooltip"
                        (click)="print(item)">
                        <mat-icon class="blue-color">print</mat-icon>
                    </button>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="columnsCouting"></tr>
            <tr mat-row *matRowDef="let row; columns: columnsCouting;"></tr>
        </table>
        <mat-paginator #paginatorPallet [pageSizeOptions]="[5, 10, 15, 20, 30, 40, 50, 100]"
            [length]="dataSourceCouting.data.length" showFirstLastButtons></mat-paginator>
    </ng-container>
    <div *ngIf="loadingCouting" class="cyclic-container-loader">
        <div class="cyclic-loader"></div>
    </div>
    <ng-container *ngIf="dataSourceSerials.data.length>0 && type==='Serializar'">
        <div [ngStyle]="{'display':'flex','align-items':'center','justify-content':'space-between'}">
            <p class="title">Listado de Seriales</p>
            <button mat-mini-fab class="blue" matTooltip="Descargar Seriales"
                matTooltipPosition="left" matTooltipClass="tooltip" (click)="download()">
                <mat-icon>cloud_download</mat-icon>
            </button>
        </div>
        <table class="cyclic-table" mat-table [dataSource]="dataSourceSerials">
            <ng-container matColumnDef="serial">
                <th mat-header-cell *matHeaderCellDef>Serial</th>
                <td mat-cell *matCellDef="let item">{{item.serial}}</td>
            </ng-container>
            <ng-container matColumnDef="mac">
                <th mat-header-cell *matHeaderCellDef>Mac</th>
                <td mat-cell *matCellDef="let item">{{item.mac}}</td>
            </ng-container>
            <ng-container matColumnDef="sapCode">
                <th mat-header-cell *matHeaderCellDef>Codigo Sap</th>
                <td mat-cell *matCellDef="let item">{{item.sapCode}}</td>
            </ng-container>
            <ng-container matColumnDef="sapCodeSap">
                <th mat-header-cell *matHeaderCellDef>Codigo Sap Sap</th>
                <td mat-cell *matCellDef="let item">{{item.sapCodeSap}}</td>
            </ng-container>
            <ng-container matColumnDef="sapCodeWms">
                <th mat-header-cell *matHeaderCellDef>Codigo Sap Wms</th>
                <td mat-cell *matCellDef="let item">{{item.sapCodeWms}}</td>
            </ng-container>
            <ng-container matColumnDef="sapCodeBase">
                <th mat-header-cell *matHeaderCellDef>Codigo Sap Base</th>
                <td mat-cell *matCellDef="let item">{{item.sapCodeBase}}</td>
            </ng-container>
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef>Estado</th>
                <td mat-cell *matCellDef="let item">{{item.status}}</td>
            </ng-container>
            <ng-container matColumnDef="statusSap">
                <th mat-header-cell *matHeaderCellDef>Estado Sap</th>
                <td mat-cell *matCellDef="let item">{{item.statusSap}}</td>
            </ng-container>
            <ng-container matColumnDef="statusWms">
                <th mat-header-cell *matHeaderCellDef>Estado Wms</th>
                <td mat-cell *matCellDef="let item">{{item.statusWms}}</td>
            </ng-container>
            <ng-container matColumnDef="statusBase">
                <th mat-header-cell *matHeaderCellDef>Estado Base</th>
                <td mat-cell *matCellDef="let item">{{item.statusBase}}</td>
            </ng-container>
            <ng-container matColumnDef="pallet">
                <th mat-header-cell *matHeaderCellDef>Pallet</th>
                <td mat-cell *matCellDef="let item">{{item.pallet}}</td>
            </ng-container>
            <ng-container matColumnDef="palletSap">
                <th mat-header-cell *matHeaderCellDef>Pallet Sap</th>
                <td mat-cell *matCellDef="let item">{{item.palletSap}}</td>
            </ng-container>
            <ng-container matColumnDef="palletWms">
                <th mat-header-cell *matHeaderCellDef>Pallet Wms</th>
                <td mat-cell *matCellDef="let item">{{item.palletWms}}</td>
            </ng-container>
            <ng-container matColumnDef="creationDate">
                <th mat-header-cell *matHeaderCellDef>Fecha Creacion</th>
                <td mat-cell *matCellDef="let item">{{item.creationDate}}</td>
            </ng-container>
            <ng-container matColumnDef="creationUser">
                <th mat-header-cell *matHeaderCellDef>Usuario Creacion</th>
                <td mat-cell *matCellDef="let item">{{item.creationUser}}</td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="columnsSerials1"></tr>
            <tr mat-row *matRowDef="let row; columns: columnsSerials1;"></tr>
        </table>
        <mat-paginator #paginatorSerials [pageSizeOptions]="[5, 10, 15, 20, 30, 40, 50, 100]"
            [length]="dataSourceSerials.data.length" showFirstLastButtons></mat-paginator>
    </ng-container>
    <ng-container *ngIf="dataSourceSerials.data.length>0 && type==='Contar'">
        <div [ngStyle]="{'display':'flex','align-items':'center','justify-content':'space-between'}">
            <p class="title">Listado de Seriales</p>
            <button mat-mini-fab class="blue" matTooltip="Descargar Seriales"
                matTooltipPosition="left" matTooltipClass="tooltip" (click)="download()">
                <mat-icon>cloud_download</mat-icon>
            </button>
        </div>
        <table class="cyclic-table" mat-table [dataSource]="dataSourceSerials">
            <ng-container matColumnDef="quantity">
                <th mat-header-cell *matHeaderCellDef>Cantidad</th>
                <td mat-cell *matCellDef="let item">{{item.quantity}}</td>
            </ng-container>
            <ng-container matColumnDef="creationDate">
                <th mat-header-cell *matHeaderCellDef>Fecha Creacion</th>
                <td mat-cell *matCellDef="let item">{{item.creationDate}}</td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="columnsSerials2"></tr>
            <tr mat-row *matRowDef="let row; columns: columnsSerials2;"></tr>
        </table>
        <mat-paginator #paginatorSerials [pageSizeOptions]="[5, 10, 15, 20, 30, 40, 50, 100]"
            [length]="dataSourceSerials.data.length" showFirstLastButtons></mat-paginator>
    </ng-container>
    <div *ngIf="loadingSerials" class="cyclic-container-loader">
        <div class="cyclic-loader"></div>
    </div>
</div>