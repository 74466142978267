<div mat-dialog-content>
    <div class="report-container-button">
        <button mat-icon-button mat-dialog-close>
            <mat-icon>clear</mat-icon>
        </button>
    </div>
    <p class="title" #top>Configuracion de dia y hora de subida</p>
    <ng-container>
        <form [formGroup]="form" class="report-form">
            <div class="time">
                <label for="time1"><strong>Desde </strong></label>
                <input matInput type="time" formControlName="time1" (change)="change()">
            </div>
            <hr>
            <div class="time">
                <label for="time2"><strong>Hasta </strong></label>
                <input matInput type="time" formControlName="time2" (change)="change()"/>
            </div>
        </form>
        <section class="check-section">
            <mat-checkbox [disabled]="!data.admin" class="check-margin" color="primary" *ngFor="let day of Days" [(ngModel)]="day.completed" (click)="change()">{{day.name}}</mat-checkbox>
        </section>
    </ng-container>

</div>
<mat-dialog-actions>
    <button mat-stroked-button [(disabled)]="!trueChange" color="primary" (click)="close()">Guardar</button>
    <button mat-stroked-button [(disabled)]="!trueChange" mat-dialog-close color="warn">Cancelar</button>
</mat-dialog-actions>