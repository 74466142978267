<div mat-dialog-content>
    <div class="apps-container-button">
        <button mat-icon-button (click)="close()">
            <mat-icon>clear</mat-icon>
        </button>
    </div>
    <p class="title">Listado de Aplicaciones</p>
    <div class="section-container-option">
        <select class="form-control" [(ngModel)]="applicationId" (change)="search()">
            <option class="ligthColor" value="0">Selecciona una aplicacion</option>
            <option *ngFor="let a of applicationList" value="{{a.id}}">{{a.name}}</option>
        </select>
    </div>
    <table class="apps-table" mat-table [dataSource]="dataSource" #table>
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Nombre</th>
            <td mat-cell *matCellDef="let item">{{item.profile}}</td>
        </ng-container>
        <ng-container matColumnDef="asign">
            <th mat-header-cell *matHeaderCellDef>Asignar</th>
            <td mat-cell *matCellDef="let item">
                <div class="apps-container-checkbox" matRipple [matRippleCentered]="true" [matRippleRadius]="40">
                    <div class="apps-checkbox">
                        <input [id]="item.profileId" type="checkbox" [checked]="item.active" (click)="checked($event.srcElement,item.id,item.profileId,item.active)">
                        <label class="apps-label" [for]="item.profileId">
                            <mat-icon>done</mat-icon>
                        </label>
                    </div>
                </div>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns;"></tr>
    </table>
</div>