<div mat-dialog-content>
    <div fxLayout fxLayoutAlign="flex-end">
        <button mat-icon-button (click)="close(false)">
            <mat-icon>clear</mat-icon>
        </button>
    </div>
    <p class="title">{{title}}</p>
    <div [formGroup]="form" class="form">
        <div fxFlex="100%" fxLayout="column" class="group">
            <label class="primaryColor">Nombre</label>
            <input formControlName="name" type="text" class="form-control" placeholder="Nombre">
        </div>
        <div fxFlex="100%" fxLayout="column" class="group">
            <label class="primaryColor">Periodicidad</label>
            <select formControlName="periodicity" class="form-control">
                <option class="ligthColor" value="">Seleccione una periodicidad</option>
                <option value="Diaria">Diaria</option>
                <option value="Semanal">Semanal</option>
            </select>
        </div>
        <div fxFlex="100%" fxLayout="column" class="group">
            <label class="primaryColor">Grupos</label>     
            <select formControlName="groupId" class="form-control" >
                <option class="ligthColor" value="0">Seleccione un grupo</option>
                <option *ngFor="let d of groupList" value="{{d.id}}">{{d.name}}</option>
            </select>
        </div>
        <div fxFlex=100%>
            <div fxFlex="50%" class="group">
                <label class="primaryColor">Fecha Reunión</label>
                <div fxLayout>
                    <input formControlName="creationDate" id="creationDate" matInput [matDatepicker]="creationDate"
                        class="form-control" disabled>
                    <mat-datepicker-toggle matSuffix [for]="creationDate"></mat-datepicker-toggle>
                    <mat-datepicker #creationDate disabled="false"></mat-datepicker>
                </div>
            </div>
            <div fxFlex="50%">
                <div fxFlex="50%" style="margin-right: 10px;">
                    <div fxFlex="100%" class="group">
                        <span class="primaryColor">Hora Inicio</span>
                        <div fxLayout>
                            <input formControlName="startHour" required matInput type="time" name="tiempo1" class="form-control"/>
                        </div>
                    </div>
                </div>
                <div fxFlex="50%">
                    <div fxFlex="100%" class="group">
                        <label class="primaryColor">Hora Fin</label>
                        <div fxLayout>
                            <input formControlName="endHour" required matInput type="time" name="tiempo2" class="form-control" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <button mat-mini-fab class="button-float green" matTooltip="Guardar" matTooltipPosition="left"
            matTooltipClass="tooltip" [disabled]="!form.valid"
            [ngClass]="{'ligthBackground':!form.valid,'green':form.valid}" (click)="save()">
            <mat-icon>done</mat-icon>
        </button>
    </div>
</div>