export class TraTracingModel{
    fase:string;
    date:string;
    hour:string;
    user:string;
    pallet:string;
    location:string;
    box:string;
    failure:string;
    scrapReason:string;
    technical:string;
    status:string;
    description:string;

    constructor(){}
}