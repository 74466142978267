<div mat-dialog-content>
    <div class="person-container-button">
        <button mat-icon-button (click)="close()">
            <mat-icon>clear</mat-icon>
        </button>
    </div>
    <p class="title">Listado de Usuarios</p>
    <div class="person-container-search">
        <div class="person-group">
            <input type="text" class="form-control" placeholder="Filtrar" (keyup)="filter($event)">
            <mat-icon class="blue-color">search</mat-icon>
        </div>
    </div>
    <table *ngIf="dataSource.data.length>0 && !loading" #table class="person-table" mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="firstName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Nombres</th>
            <td mat-cell *matCellDef="let item">{{item.firstName}}</td>
        </ng-container>
        <ng-container matColumnDef="lastName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Apellidos</th>
            <td mat-cell *matCellDef="let item">{{item.lastName}}</td>
        </ng-container>
        <ng-container matColumnDef="userName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Usuario</th>
            <td mat-cell *matCellDef="let item">{{item.userName}}</td>
        </ng-container>
        <ng-container matColumnDef="mail">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Correo</th>
            <td mat-cell *matCellDef="let item">{{item.mail}}</td>
        </ng-container>
        <ng-container matColumnDef="Asignar">
            <th mat-header-cell *matHeaderCellDef>Asignar</th>
            <td mat-cell *matCellDef="let item">
                <div class="person-container-checkbox" matRipple [matRippleCentered]="true" [matRippleRadius]="40">
                    <div class="person-checkbox">
                        <input [id]="item.id" type="checkbox" [checked]="item.active"
                            (click)="checked($event.srcElement,item.id,item.mail)">
                        <label class="person-label" [for]="item.id">
                            <mat-icon>done</mat-icon>
                        </label>
                    </div>
                </div>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns;"></tr>
    </table>
    <div *ngIf="loading" class="person-container-loader">
        <div class="person-loader"></div>
    </div>
    <mat-paginator [pageSizeOptions]="[5, 10, 15, 20, 30, 40, 50, 100]" showFirstLastButtons></mat-paginator>
</div>