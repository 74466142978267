<div class="scp-container">
    <div class="close" fxLayout fxLayoutAlign="flex-end">
        <button mat-icon-button (click)="close(false)">
            <mat-icon>clear</mat-icon>
        </button>
    </div>
    <p class="title">{{title}}</p>
    <form [formGroup]="form" class="scp-form">
        <div class="scp-group" >
            <label class="primaryColor">Motivo de rechazo</label>
            <select formControlName="description" class="form-control" >
                <option class="ligthColor" value=null>Seleccione una opcion</option>
                <option *ngFor="let c of ScpMotifList" value="{{c.description}}">{{c.description}}</option>
            </select>
            <div class="invalid-text" *ngIf="form.get('description').invalid && form.get('description').touched">Seleccione una opcion valida</div>
        </div>
        <div class="scp-group">
            <label class="primaryColor">Evidencia foto grafica</label>
            <div class="scp-attachment">
                <div class="scp-attachment-content">
                    <ng-container *ngIf="formId ===0">
                        <div *ngFor="let f of fileList;let i=index;" class="scp-container-file" matTooltip="{{f.name}}"
                            matTooltipPosition="above" matTooltipClass="tooltip">
                            <p>{{f.name}}</p>
                            <div class="scp-container-actions">
                                <button mat-icon-button matTooltip="Eliminar" matTooltipPosition="below"
                                    matTooltipClass="tooltip" (click)="removeFile(i)">
                                    <mat-icon class="red-color">delete</mat-icon>
                                </button>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div class="scp-attachment-attach">
                    <input #image hidden type="file" multiple (change)="addFile($event.target.files)">
                    <button mat-icon-button matTooltip="Adjuntar Archivo" matTooltipPosition="left"
                        matTooltipClass="tooltip" (click)="image.click()">
                        <mat-icon class="orange-color">attach_file</mat-icon>
                    </button>
                </div>
            </div>
        </div>
     

        <div class="scp-group">
            <label class="primaryColor">Guardar</label>
            <div class="scp-container-button">
                <button type="reset" class="scp-button" mat-raised-button matTooltip="Guardar" mat-raised-button [disabled]="!form.valid" matTooltipPosition="right" matTooltipClass="tooltip" (click)="close(false)" (click)="save()">
                    <mat-icon>save</mat-icon>
                </button>
            </div>
        </div>
    </form>
</div>