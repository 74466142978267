<div class="reportPqrs-container">
    <p class="title">Reporte PQRS</p>
    <div class="reportPqrs-form">
        <div class="reportPqrs-group">
            <label class="primaryColor">Fecha de Inicio</label>
            <div class="reportPqrs-control">
                <input id="startDate" matInput [matDatepicker]="startDate" class="form-control">
                <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
                <mat-datepicker #startDate disabled="false"></mat-datepicker>
            </div>
        </div>
        <div class="reportPqrs-group">
            <label class="primaryColor">Fecha de Fin</label>
            <div class="reportPqrs-control">
                <input id="endDate" matInput [matDatepicker]="endDate" class="form-control">
                <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
                <mat-datepicker #endDate disabled="false"></mat-datepicker>
            </div>
        </div>
        <button mat-mini-fab class="reportPqrs-button blue" matTooltip="Buscar" matTooltipPosition="above" (click)="search()">
            <mat-icon>search</mat-icon>
        </button>
    </div>
</div>
<table #table class="reportPqrs-table" mat-table [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="Numero">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Número</th>
        <td mat-cell *matCellDef="let item">{{item.number}}</td>
    </ng-container>
    <ng-container matColumnDef="Ticket">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Ticket</th>
        <td mat-cell *matCellDef="let item">{{item.ticket}}</td>
    </ng-container>
    <ng-container matColumnDef="Nombres y apellidos">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Nombres y apellidos</th>
        <td mat-cell *matCellDef="let item">{{item.nameLastname}}</td>
    </ng-container>
    <ng-container matColumnDef="Tipo Cliente">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tipo cliente</th>
        <td mat-cell *matCellDef="let item">{{item.customerType}}</td>
    </ng-container>
    <ng-container matColumnDef="Proyecto">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Proyecto</th>
        <td mat-cell *matCellDef="let item">{{item.proyect}}</td>
    </ng-container>
    <ng-container matColumnDef="Usuario Pqrs">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Usuario Pqrs</th>
        <td mat-cell *matCellDef="let item">{{item.userPqrs}}</td>
    </ng-container>
    <ng-container matColumnDef="Usuario Creacion">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Usuario creacion</th>
        <td mat-cell *matCellDef="let item">{{item.creationPerson}}</td>
    </ng-container>
    <ng-container matColumnDef="Usuario Asignado">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Usuario asignado</th>
        <td mat-cell *matCellDef="let item">{{item.assignedPerson}}</td>
    </ng-container>
    <ng-container matColumnDef="Pais">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Pais</th>
        <td mat-cell *matCellDef="let item">{{item.country}}</td>
    </ng-container>
    <ng-container matColumnDef="Departamento">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Departamento</th>
        <td mat-cell *matCellDef="let item">{{item.department}}</td>
    </ng-container>
    <ng-container matColumnDef="Ciudad">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Ciudad</th>
        <td mat-cell *matCellDef="let item">{{item.city}}</td>
    </ng-container>
    <ng-container matColumnDef="Regional">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Regional</th>
        <td mat-cell *matCellDef="let item">{{item.regional}}</td>
    </ng-container>
    <ng-container matColumnDef="Marca-Modelo">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Marca-Modelo</th>
        <td mat-cell *matCellDef="let item">{{item.tradeMark}}</td>
    </ng-container>
    <ng-container matColumnDef="Metodo Contacto Inicial">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Metodo contacto inicial</th>
        <td mat-cell *matCellDef="let item">{{item.contactMethod}}</td>
    </ng-container>
    <ng-container matColumnDef="Correo Contacto">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Correo contacto</th>
        <td mat-cell *matCellDef="let item">{{item.contactEmail}}</td>
    </ng-container>
    <ng-container matColumnDef="Categoria">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Categoria</th>
        <td mat-cell *matCellDef="let item">{{item.category}}</td>
    </ng-container>
    <ng-container matColumnDef="Area Responsable">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Area Responsable</th>
        <td mat-cell *matCellDef="let item">{{item.responsibleArea}}</td>
    </ng-container>
    <ng-container matColumnDef="Serial">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Serial</th>
        <td mat-cell *matCellDef="let item">{{item.serial}}</td>
    </ng-container>
    <ng-container matColumnDef="Tipo">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tipo</th>
        <td mat-cell *matCellDef="let item">{{item.type}}</td>
    </ng-container>
    <ng-container matColumnDef="Resumen">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Resumen</th>
        <td mat-cell *matCellDef="let item">{{item.summary}}</td>
    </ng-container>
    <ng-container matColumnDef="Fecha Creacion">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha creacion</th>
        <td mat-cell *matCellDef="let item">{{item.creationDate}}</td>
    </ng-container>
    <ng-container matColumnDef="Numero Ingresos">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Numero ingresos</th>
        <td mat-cell *matCellDef="let item">{{item.entryNumber}}</td>
    </ng-container>
    <ng-container matColumnDef="Estado">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Estado</th>
        <td mat-cell *matCellDef="let item">{{item.status}}</td>
    </ng-container>
    <ng-container matColumnDef="Estado Final">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Estado Final</th>
        <td mat-cell *matCellDef="let item">{{item.finalStatus}}</td>
    </ng-container>
    <ng-container matColumnDef="Tecnico Responsable">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tecnico responsable</th>
        <td mat-cell *matCellDef="let item">{{item.technical}}</td>
    </ng-container>
    <ng-container matColumnDef="Registro Fotografico">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Registro fotografico</th>
        <td mat-cell *matCellDef="let item">{{item.photographicRecord}}</td>
    </ng-container>
    <ng-container matColumnDef="Diagnostico Tecnico-Reparacion">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Diagnostico tecnico reparacion</th>
        <td mat-cell *matCellDef="let item">{{item.diagnostic}}</td>
    </ng-container>
    <ng-container matColumnDef="Fecha Escalacion Cliente">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha escalacion cliente</th>
        <td mat-cell *matCellDef="let item">{{item.customerEscalationDate}}</td>
    </ng-container>
    <ng-container matColumnDef="Fecha Respuesta Escalacion Cliente">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha respuesta escalacion cliente</th>
        <td mat-cell *matCellDef="let item">{{item.customerEscalationResponseDate}}</td>
    </ng-container>
    <ng-container matColumnDef="Usuario Escalacion Interna">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Usuario escalacion interna</th>
        <td mat-cell *matCellDef="let item">{{item.internalEscalationAgent}}</td>
    </ng-container>
    <ng-container matColumnDef="Fecha Escalacion Interna">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha escalacion interna</th>
        <td mat-cell *matCellDef="let item">{{item.internalEscalationDate}}</td>
    </ng-container>
    <ng-container matColumnDef="Fecha Respuesta Escalacion Interna">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha respuesta escalacion interna</th>
        <td mat-cell *matCellDef="let item">{{item.internalEscalationResponseDate}}</td>
    </ng-container>
    <ng-container matColumnDef="Fecha Respuesta Pqrs Cliente">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha respuesta Pqrs Cliente</th>
        <td mat-cell *matCellDef="let item">{{item.responseDateCustomerPqrs}}</td>
    </ng-container>
    <ng-container matColumnDef="Estado Gestion">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Estado gestion</th>
        <td mat-cell *matCellDef="let item">{{item.managementStatus}}</td>
    </ng-container>
    <ng-container matColumnDef="Metodo Contacto Final">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Metodo contacto Final</th>
        <td mat-cell *matCellDef="let item">{{item.finalContactMethod}}</td>
    </ng-container>
    <ng-container matColumnDef="Cantidad Equipos">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Cantidad equipos</th>
        <td mat-cell *matCellDef="let item">{{item.equipmentQuantity}}</td>
    </ng-container>
    <ng-container matColumnDef="Pqrs Atribuible">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Pqrs Atribuible</th>
        <td mat-cell *matCellDef="let item">{{item.attributablePqrs}}</td>
    </ng-container>
    <ng-container matColumnDef="Observaciones Woden">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Observaciones Woden</th>
        <td mat-cell *matCellDef="let item">{{item.observations}}</td>
    </ng-container>
    <ng-container matColumnDef="Fecha Envio Unidades">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha envio unidades</th>
        <td mat-cell *matCellDef="let item">{{item.unitShipmentDate}}</td>
    </ng-container>
    <ng-container matColumnDef="Fecha Llegada Unidades">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha llegada unidades</th>
        <td mat-cell *matCellDef="let item">{{item.unitArrivalDate}}</td>
    </ng-container>
    <ng-container matColumnDef="Nivel">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Nivel</th>
        <td mat-cell *matCellDef="let item">{{item.levelNumber}}</td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr mat-row *matRowDef="let row; columns: columns;"></tr>
</table>
<div class="reportPqrs-container-download">
    <button mat-mini-fab class="blue" matTooltip="Descargar" matTooltipPosition="right" matTooltipClass="tooltip" (click)="download(dataSource)">
        <mat-icon>cloud_download</mat-icon>
    </button>
    <div *ngIf="loading" class="reportPqrs-container-loader">
        <div class="reportPqrs-loader"></div>
    </div>
</div>
<mat-paginator [pageSizeOptions]="[5, 10, 15, 20, 30, 40, 50, 100]" showFirstLastButtons></mat-paginator>