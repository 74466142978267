import { Component, OnInit, ViewChild,ElementRef, HostListener  } from '@angular/core';
import { AlertService } from 'src/app/shared/services/alert.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import * as XLSX from 'xlsx';
import * as moment from 'moment';
import { ArchiveConfigComponent } from '../../modals/archiveConfig/archiveConfig.component';
import { MatDialog } from '@angular/material/dialog';
import { GenCountryService } from 'src/app/appGeneral/services/genCountry.service';
import { ValidateConfigComponent } from '../../modals/validateConfig/validateConfig.component';
import { MilicomBaseService } from '../../services/MilicomBase.service';
import { GenApplicationPersonProfileService } from 'src/app/appGeneral/services/genApplicationPersonProfile.service';
import { GenPersonEntity } from 'src/app/appGeneral/entities/genPerson.entity';
import { MilicomLoadBaseEntity } from '../../entities/milicomLoadBase.entity';
import { ConfirmationComponent } from 'src/app/shared/components/confirmation/confirmation.component';
import { HourConfigComponent } from '../../modals/hourConfig/hourConfig.component';
import { MilicomArchivesService } from '../../services/MilicomArchives.service';



@Component({
  selector: 'app-load-validation-milicom',
  templateUrl: './LoadValidationMilicom.component.html',
  styleUrls: ['./LoadValidationMilicom.component.css']
})
export class LoadValidationMilicomComponent implements OnInit {
  Days:any[];
  columns: string[];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild("idFile", {static: false})
  InputVar: ElementRef;
  genPersonEntity: GenPersonEntity;
  ExcelDataNames:any;
  ExcelData:any;
  displayedColumns: string[];
  VColumns:string[][];
  archive: string;
  dataArchive: any;
  types: string[];
  OP1: string[];
  OP2: string[];
  ContOP2:number;
  OP3: string[];
  OP4: string[];
  OP5: string[];
  time1:string;
  time2:string;
  file: FileList;
  files: any;
  value:number;
  report:string;
  TypeLoading: string;
  block:boolean[];
  check:boolean[];
  country: any = '';
  admin: boolean = false;
  loadTrue: boolean;
  titulo:string;
  ColumnsSQL: string[];
  timeToLoad:any;
  constructor(private genApplicationPersonProfileS: GenApplicationPersonProfileService,
    private alertS: AlertService,
    private dialog: MatDialog,
    private genCountryS: GenCountryService, 
    private MilicomB: MilicomBaseService,
    private archiveOption: MilicomArchivesService) {
    this.Days = [];
    this.VColumns = [];
    this.ColumnsSQL = [];
    this.types =["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"];
    this.OP1 = [];
    this.OP2 = [];
    this.ContOP2 = 0;
    this.OP3 = [];
    this.OP4 = [];
    this.OP5 = [];
    this.time1 = '';
    this.time2 = '';
    this.report = 'TBL_REVERSE_LAB_PRODUCED';
  }

  //Preguntar si desea recargar la pagina para evitar perder datos
  @HostListener('window:beforeunload', ['$event'])
  onbeforeunload(event: Event) {
    this.alertS.open('Al recargar la pagina asegurese de que no hay ninguna accion en proceso!','info');
    return false;
  }

  ngOnInit(): void {
    this.timeToLoad = null;
    this.Days = [{name:'Lunes',completed:false,code:1},{name:'Martes',completed:false,code:2},{name:'Miercoles',completed:false,code:3},
    {name:'Jueves',completed:false,code:4},{name:'Viernes',completed:false,code:5},{name:'Sabado',completed:false,code:6},{name:'Domingo',completed:false,code:7}];
    this.titulo = 'Cargar archivo';
    this.loadTrue = false;
    this.TypeLoading = 'indeterminate';
    this.value = 0;
    this.block = [true,true,true,true,true];
    this.check = [false,false,false,false];
    this.dataSource = new MatTableDataSource([]);
    this.columns = [];
    this.ContOP2 = 0;
    //Buscar perfil de usuario y validar si es administrador
    this.genPersonEntity = JSON.parse(localStorage.getItem('user'));
    this.genApplicationPersonProfileS.listProfile(this.genPersonEntity.id, 'REPORTE MILICOM').subscribe((res) => {
      if (res.object.name == 'ADMINISTRADOR') {
        this.admin = true;
      }
    },(err) => {
      this.alertS.open("No se pudo obtener el perfil "+err.message, 'error');
    });
    //Buscar Pais en el que se logueo y si tiene reporte habilitado
    this.genCountryS.findById(Number(localStorage.getItem('countryId'))).subscribe(res=>{
      if (res.message === 'OK') {
        this.country = res.object; //Almacenar pais
        this.MilicomB.findById(this.country.id, this.report).subscribe((res) => {
            if (res.message === 'OK') {
              //Quitar formato 64bits y convertir a array
              this.dataArchive = res.object;
              this.VColumns = JSON.parse(atob(this.dataArchive.columnaR));
              for (let d of this.VColumns) {
                this.columns.push(d[0]);
              }
              this.OP1 = JSON.parse(atob(this.dataArchive.opcion1));
              this.OP2 = JSON.parse(atob(this.dataArchive.opcion2));
              this.OP3 = JSON.parse(atob(this.dataArchive.opcion3));
              this.OP4 = JSON.parse(atob(this.dataArchive.opcion4));
              this.OP5 = JSON.parse(atob(this.dataArchive.opcion5));
              this.time1 = this.dataArchive.horaInicio;
              this.time2 = this.dataArchive.horaFinal;
              this.Days = JSON.parse(atob(this.dataArchive.dias));
              this.block[0] = false;
              this.block[1] = false;
              this.TypeLoading = 'buffer';
              this.validateTime();
            } else {
              this.alertS.open(res.message, 'error');
            }
          },(err) => {
            this.alertS.open(err.message, 'error');
          }
        );
      } else {
        this.alertS.open(res.message, 'error');
      }
    },err =>{
      this.alertS.open(err.message,'error');
    });
    this.archive = '';
  }

  hourConf(){
    this.dialog.open(HourConfigComponent, {
      data: { time1:this.time1,time2:this.time2,Days:this.Days,admin: this.admin },
      disableClose: true,
      width: '30%',
    }).afterClosed().subscribe((res) => {
      if (res.change === true) {
        this.time1 = res.time1;
        this.time2 = res.time2;
        this.Days = res.Days;
        this.executeUpdate();
      }
      this.ngOnInit();
    });
  }

  archiveConf() {
    this.MilicomB.columns(this.report).subscribe((res) => {
        if (res.message === 'OK') {
          this.ColumnsSQL = res.object;
          for (let d of JSON.parse(atob(this.dataArchive.columnaR))) {
            this.ColumnsSQL.splice(this.ColumnsSQL.indexOf(d[0]), 1);
          }
          this.dialog.open(ArchiveConfigComponent, {
            data: {
              columns: this.VColumns,
              ColumnsSQL: this.ColumnsSQL,
              admin: this.admin,
            },
            disableClose: true,
            width: '60%',
          }).afterClosed().subscribe((res) => {
            if (res === true) {
              this.executeUpdate();
            }
            this.ngOnInit();
          });
        }
      },(err) => {
        this.alertS.open(err.message, 'error');
      }
    );
  }
  validateConf() {
    this.dialog.open(ValidateConfigComponent, {
        data: { OP1: this.OP1,
          OP2: this.OP2,
          OP3: this.OP3,
          OP4: this.OP4,
          OP5: this.OP5,
          admin: this.admin },
        disableClose: true,
        width: '40%',
      }).afterClosed().subscribe((res) => {
        if (res === true) {
          this.executeUpdate();
        }
        this.ngOnInit();
      });
  }

  executeUpdate(){
    var archive = new MilicomLoadBaseEntity();
    archive.id = this.dataArchive.id;
    archive.idPais = this.dataArchive.idPais;
    archive.reporte = this.report;
    archive.columnaR = btoa(JSON.stringify(this.VColumns));
    archive.extension = this.dataArchive.extension;
    archive.opcion1 = btoa(JSON.stringify(this.OP1));
    archive.opcion2 = btoa(JSON.stringify(this.OP2));
    archive.opcion3 = btoa(JSON.stringify(this.OP3));
    archive.opcion4 = btoa(JSON.stringify(this.OP4));
    archive.opcion5 = btoa(JSON.stringify(this.OP5));
    archive.horaInicio = this.time1;
    archive.horaFinal = this.time2;
    archive.dias = btoa(JSON.stringify(this.Days));
    archive.active = this.dataArchive.active;
    this.MilicomB.update(archive).subscribe(
      (res) => {
        if (res.message === 'OK') {
          this.alertS.open('Se han actualizado las columnas', 'success');
        } else {
          this.alertS.open('Error al actualizar la columnas Error:' + res.message,'error');
        }
      },(err) => {
        this.alertS.open(err.message, 'error');
      }
    );
  }

  createArchive(){
    var archive = new MilicomLoadBaseEntity();
    archive.idPais = this.country.id;
    archive.reporte = this.report;
    archive.columnaR = btoa(JSON.stringify([]));
    archive.extension = btoa(JSON.stringify(this.types));
    archive.opcion1 = btoa(JSON.stringify([]));
    archive.opcion2 = btoa(JSON.stringify([]));
    archive.opcion3 = btoa(JSON.stringify([]));
    archive.opcion4 = btoa(JSON.stringify([]));
    archive.opcion5 = btoa(JSON.stringify([]));
    archive.horaInicio = '00:00';
    archive.horaFinal = '00:00';
    archive.dias = btoa(JSON.stringify(this.Days));
    archive.countryName = this.country.name;
    if (this.admin) {
      this.dialog.open(ConfirmationComponent, {
        data: { message: '¿Desea crear el archivo '+this.report+' milicom para '+this.country.name+'?' },
        height: '250px',
        width: '400px',
      }).afterClosed().subscribe((resA) => {
        if (resA) {
          this.MilicomB.create(archive).subscribe(
            (res) => {
              if (res.message === 'OK') {
                this.alertS.open('El archivo '+this.report+' milicom para ' +this.country.name +' fue creado correctamente','success');
                this.ngOnInit();
              } else {
                this.alertS.open(res.message, 'error');
              }
            },(err) => {
              this.alertS.open(err.message, 'error');
            }
          );
        }
      });
    } else {
      this.alertS.open('No tiene permisos para crear este archivo','error');
    }
  }

  addFile(event: any) {
    this.file = event.target.files;
    this.archive = this.file[0].name;
    if (this.file.length != 0) {
      if (this.types.includes(this.file[0].type)) {
        this.value = 40;
        this.block[1] = true;
        this.block[2] = false;
        this.check[1] = true;
        this.LoadArchive(event);
      } else {
        this.alertS.open('Seleccione un archivo con extension valida','warning');
        this.ngOnInit();
        this.InputVar.nativeElement.value = '';
      }
    } else {
      this.alertS.open('No selecciono ningun archivo', 'info');
      this.ngOnInit();
      this.InputVar.nativeElement.value = '';
    }
  }

  LoadArchive(event:any){
    //Cargar columnas del archivo a la libreria
    let file = event.target.files[0];
    let fileReader = new FileReader();
    fileReader.readAsBinaryString(file);

    fileReader.onload = (e)=>{
      //Lectura del archivo y conversion a formato JSON
      var workBook = XLSX.read(fileReader.result,{type:'binary'});
      var sheetNames = workBook.SheetNames;
      this.ExcelData = XLSX.utils.sheet_to_json(workBook.Sheets[sheetNames[0]],{defval: ""});
      this.ExcelDataNames = Object.keys(this.ExcelData[0]);
      //cargue datos a la tabla de angular
      this.dataSource = new MatTableDataSource<any>(this.ExcelData);
      this.table.renderRows();
      this.dataSource.paginator = this.paginator;
      //Texto mostrado a español
      this.paginator._intl.itemsPerPageLabel = 'Elementos por pagina';
      //Ejecutar proceso de validacion
      this.ValidateArchive(this.ExcelDataNames,this.ExcelData);
    }
    this.value = 30;
  }

  ValidateArchive(Cnames:any,data:any){
    if (Cnames.length == this.VColumns.length) {
      for (let cont = 0; cont < this.VColumns.length; cont++) {
        if (Cnames[cont] != this.VColumns[cont][0]) {
          this.alertS.open("El nombre de la columna "+Cnames[cont]+" no coincide con la estructura asignada", 'warning');
          Cnames = [];
          break;
        }
      }
      this.value = 40;
      if (Cnames.length != 0) {
        this.value = 60;
        for (let contd = 0; contd < data.length; contd++) {
          for (let contn = 0; contn < this.VColumns.length; contn++) {
            //console.log('la palabra '+data[contd][this.VColumns[contn][0]]+' tiene '+data[contd][this.VColumns[contn][0]].toString().length);
            //console.log('Columna '+ data[contd][this.VColumns[contn][0]]+' tipo '+typeof  data[contd][this.VColumns[contn][0]]+' CSegunda '+this.VColumns[contn][1]+' tipo '+typeof this.VColumns[contn][1]);
            //Validacion tipo de dato
            if (typeof data[contd][this.VColumns[contn][0]] != this.VColumns[contn][1]) {
              this.alertS.open('Error en la columna '+this.VColumns[contn][0]+' en la fila '+(contd+1)+' se espera que sean tipo '+this.VColumns[contn][1],'error');
              data = [];
              break;
            }
            //Validacion cantidad de caracteres de dato
            if (data[contd][this.VColumns[contn][0]].toString().length > this.VColumns[contn][2]) {
              //console.log(data[contd][this.VColumns[contn][0]]+""+data[contd][this.VColumns[contn][0]].toString().length+" Segundo "+this.VColumns[contn][2]);
              this.alertS.open('Error en la columna '+this.VColumns[contn][0]+' en la fila '+(contd+1)+' se espera que sea que tenga un maximo de '+this.VColumns[contn][2]+" caracteres",'error');
              data = [];
              break;
            }
            //Validaciones opcionales
            if (this.VColumns[contn][3]!='') {
              switch (this.VColumns[contn][3]) {
                case 'NOTNULL':
                  if (data[contd][this.VColumns[contn][0]] == 'Null' || data[contd][this.VColumns[contn][0]] == '') {
                    this.alertS.open('Error en la columna '+this.VColumns[contn][0]+' en la fila '+(contd+1)+' el valor no puede ser vacio','error');
                    data = [];
                  }
                  break;
                case 'DATE':
                   var date = moment(data[contd][this.VColumns[contn][0]],"DD/MM/YYYY",true);
                   if (date.isValid()===false) {
                    this.alertS.open('Error en la columna '+this.VColumns[contn][0]+' en la fila '+(contd+1)+' el formato de fecha es invalido se espera que sea DD/MM/YYYY','error');
                    data = [];
                   }
                  break;
                case 'OP1':
                  if (this.OP1.includes(data[contd][this.VColumns[contn][0]]) === false) {
                    this.alertS.open('Error en la columna '+this.VColumns[contn][0]+' en la fila '+(contd+1)+' se espera que corresponda a alguno de estos tipos: '+this.OP1,'error');
                    data = [];
                  }
                  break;
                case 'OP2':
                  if (this.OP2.includes(data[contd][this.VColumns[contn][0]]) === false) {
                    data[contd][this.VColumns[contn][0]] = "NULL";
                    this.ContOP2 = this.ContOP2 + 1;
                  }
                  break;
                default:
                  this.alertS.open('No se encontro el metodo de validacion '+this.VColumns[contn][3]+' para la columna '+this.VColumns[contn][0], 'warning');
                  break;
              }
              if (data.length == 0) {
                //Romper Ciclo
                break;
              }

            }
          }
        }
        if (this.ContOP2!=0) {
          this.alertS.open(this.ContOP2+' registros fueron replazados por NULL al no ser encontrados en la lista OP2','info');
        }
        if (data.length != 0) {
          this.value = 80;
          this.block[2] = true;
          this.block[3] = false;
          this.block[4] = false;
          this.check[2] = true; 
          this.ExcelData = data;
        } else {
          this.deleteArchive();
        }
      } else {
        this.deleteArchive();
      }
    } else {
      this.alertS.open("El numero de columnas asignado para este informe son "+this.VColumns.length, 'warning');
      this.deleteArchive();
    }
  }

  saveArchive(){
    this.value = 100;
    this.titulo = 'Cargando archivo';
    this.block[3] = true;
    this.block[4] = true;
    this.check[3] = true;
    this.check[4] = true;
    this.TypeLoading = 'indeterminate';
    this.loadTrue = true;
    var monthAgo = new Date();
    this.archiveOption.checkingArchive(this.report,moment(new Date(monthAgo.setMonth(monthAgo.getMonth()-1))).format('YYYY-MM-DD'),this.country.name,'TIGO').subscribe((res)=>{
      if (res.message === 'OK') {
        if (res.object.length != 0) {
          this.dialog.open(ConfirmationComponent, {
              data: {message: '<p>El archivo ya fue cargado el dia de hoy, ¿Desea remplazar los datos existentes?</p><ol><li><Strong>NOMBRE:</Strong>'+res.object[2]+'</li><li><Strong>REGION:</Strong>'+res.object[1]+' '+res.object[0]+'</li><li><Strong>ULTIMA MODIFICACION:</Strong>'+new Date(+res.object[3])+'</li><li><Strong>FECHA CARGUE:</Strong>'+res.object[4]+'</li><li><Strong>REGISTROS:</Strong>'+res.object[5]+'</li></ol>'},
              height: '310px',
              width: '550px',
            })
            .afterClosed().subscribe((resA) => {
              if (resA) {
                this.archiveOption.delete(this.report,moment(new Date(monthAgo.setMonth(monthAgo.getMonth()))).format('YYYY-MM-DD'),this.country.name,'TIGO').subscribe((res)=>{
                  if (res.message === 'OK') {
                    this.insert();
                  } else {
                    this.alertS.open('Error al eliminar los archivos, ' + res.message, 'error');
                    this.deleteArchive();
                  }
                });
              } else {
                this.deleteArchive();
              }
            });
        } else {
          this.insert();
        }
      } else {
        this.alertS.open('Error al cargar el archivo, ' + res.message, 'error');
        this.deleteArchive();
      }
    });
  }

  insert(){
    if (this.validateTime()) {
      for (let contd = 0; contd < this.ExcelData.length; contd++) {
        //Cargar 4 campos adicionales a la tabla
        this.ExcelData[contd].clienteCargue = 'TIGO';
        this.ExcelData[contd].nombreCargue = this.archive;
        this.ExcelData[contd].paisCargue = this.country.name;
        this.ExcelData[contd].ultimaModificacion = this.file[0].lastModified.toString();
        //cargar columnas no seleccionadas
        if (this.ColumnsSQL.length != 0) {
          for (let add of this.ColumnsSQL) {
            this.ExcelData[contd][add] = '#N/A COLUMNA';
          }
        }
        //convertir todos los datos a string
        for (let contn = 0; contn < this.VColumns.length; contn++) {
          if (typeof this.ExcelData[contd][this.VColumns[contn][0]] != 'string') {
            this.ExcelData[contd][this.VColumns[contn][0]] = this.ExcelData[contd][this.VColumns[contn][0]].toString();
          }
        }
      }
      this.archiveOption.insert(this.report,this.ExcelData).subscribe((res): void => {
          if (res.message === 'OK') {
            this.alertS.open('Se cargo el archivo correctamente, con ' +res.object +' registros','success');
            this.TypeLoading = 'buffer';
            this.value = 0;
            this.ngOnInit();
            this.InputVar.nativeElement.value = '';
          } else {
            this.alertS.open('Error al cargar el archivo, ' + res.message,'error');
            this.deleteArchive();
          }
        },(err): void => {
          this.alertS.open(err.message, 'error');
          this.deleteArchive();
        }
      );
    } else {
      this.alertS.open('El reporte solo podra ser cargado en dias y horas establecidas','warning');
      this.deleteArchive();
    }
    
  }

  deleteArchive(){
    this.alertS.open("El cargue de archivo ha sido cancelado", 'success');
    this.ngOnInit();
    this.InputVar.nativeElement.value = '';
  }

  validateTime(){
    var date = new Date();
    var timeNow = moment(date).format('HH:mm');
    var initialtime = moment(new Date(date.toDateString() + ' ' + this.time1)).format('HH:mm');
    var finaltime = moment(new Date(date.toDateString() + ' ' + this.time2)).format('HH:mm');
    var dayInfo = this.Days.filter(dias => dias.completed === true);
    var status;
    if (dayInfo.length!=0) {
        var day = dayInfo.filter(dia => dia.code == date.getDay());
        if (day.length!=0) {
          if (timeNow >= initialtime && timeNow <= finaltime) {
            status=true;
            this.timeToLoad = null;
          } else {
            this.timeToLoad = 'Habilitado a las '+this.time1; 
            status=false;
          } 
        } else {
          var names = [];
          for (let i = 0; i < dayInfo.length; i++) {
            names[i] = dayInfo[i].name; 
          }
          this.timeToLoad = 'habilitado '+names;
          status=false;
        }
    } else {
      this.timeToLoad = 'No hay dias habilitados'; 
      status=false;
    }
    return status;
  }


}
