<div class="pqr-content">
    <p class="pqr-subtitle">Bienvenido a Woden S.A.S</p>
    <p class="pqr-text">A través de nuestra plataforma de PQR´S,
        usted podrá tramitar y/o solicitar información referente a sus peticiones,
        quejas/reclamaciones, sugerencias o felicitaciones. Woden® responderá
        sus requerimientos dentro del término establecido contractualmente.</p>
    <p class="pqr-text">Para registrar su petición, queja, reclamación, sugerencia y/o felicitación tenga en cuenta lo
        siguiente:</p>
    <p class="pqr-text"><strong>Petición: </strong>Es aquel derecho que tiene toda persona para solicitar o reclamar
        ante las autoridades competentes por razones de interés general o interés particular para elevar solicitudes
        respetuosas de información y/o consulta y para obtener pronta resolución de estas.</p>
    <p class="pqr-text"><strong>Queja: </strong>Cualquier expresión verbal, escrita o en medio electrónica de
        insatisfacción con la conducta o la acción de los servidores públicos o de los particulares que llevan a cabo
        una función estatal y que requiere una respuesta. Las quejas deben ser resueltas, atendidas o contestadas dentro
        de los quince días siguientes a la fecha de su presentación.</p>
    <p class="pqr-text"><strong>Reclamo: </strong>Cualquier expresión verbal, escrita o en medio electrónico, de
        insatisfacción referida a la prestación de un servicio o la deficiente atención de una autoridad pública, es
        decir, es una declaración formal por el incumplimiento de un derecho que ha sido perjudicado o amenazado,
        ocasionado por la deficiente prestación o suspensión injustificada del servicio. Los reclamos deben ser
        resueltos, atendidos o contestados dentro de los quince días siguientes a la fecha de su presentación.</p>
    <p class="pqr-text"><strong>Sugerencia: </strong>Cualquier expresión verbal, escrita o en medio electrónico de
        recomendación entregada por el ciudadano, que tiene por objeto mejorar el servicio que se presta en cada una de
        las dependencias del Ministerio de Tecnologías de la Información y las Comunicaciones, racionalizar el empleo de
        los recursos o hacer más participativa la gestión pública. En un término de diez días se informará sobre la
        viabilidad de su aplicación.</p>
    <i class="pqr-text">{{this.pqrFormLawEntity.descriptionLaw}}</i>
    <br>
    <button mat-raised-button routerLink="/wdcs/pqr/newClient">Continuar</button>
</div>