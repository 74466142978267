<div mat-dialog-content>
    <p class="title">Contraseña</p>
    <div class="pass-group">
            <input [(ngModel)]="passwords" name="password" id="password" type="password" class="form-control"
                placeholder="Contraseña">
            <mat-icon (click)="mostrarContrasena()">visibility</mat-icon>
        </div>
    <br>
    <div class="pass-text">
        <input class="boton5" type="button" value="Ingresar" (click)="search()">
        <mat-icon matTooltip="Ir a inicio" class="pass-text-pass" routerLink="/principal/" (click)="closehome()">home</mat-icon>
    </div>
</div>