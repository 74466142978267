import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { GenApplicationPersonProfileService } from 'src/app/appGeneral/services/genApplicationPersonProfile.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-hour-config',
  templateUrl: './hourConfig.component.html',
  styleUrls: ['./hourConfig.component.css'],
})
export class HourConfigComponent implements OnInit {
  form = new FormGroup({
    time1: new FormControl(''),
    time2: new FormControl('')    
  });
  trueChange: boolean;
  time1: any;
  time2: any;
  Days: any;

  constructor(
    private genApplicationPersonProfileS: GenApplicationPersonProfileService,
    private alertS: AlertService,
    public dialogRef: MatDialogRef<HourConfigComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.form.controls['time1'].disable();
    this.form.controls['time2'].disable();
    this.Days = data.Days;
  }

  ngOnInit(): void {
    this.form.setValue({
      'time1': this.data.time1,
      'time2': this.data.time2,
    });
    if(this.data.admin){
      this.form.controls['time1'].enable();
      this.form.controls['time2'].enable();
    }
  }

  change(){
    if (this.data.admin) {
      this.trueChange = true;
      this.time1 = this.form.value.time1;
      this.time2 = this.form.value.time2;
      this.alertS.open('Para confirmar los cambios seleccione guardar o de lo contrario cancelar', 'info'); 
    }
  }

  close(): void {
    this.dialogRef.close({change:this.trueChange,Days:this.Days,time1:this.time1,time2:this.time2});
  }
}
