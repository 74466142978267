<div class="mee-container">
    <p class="title">Solicitar Apoyo</p>
    <div fxLayout fxLayoutAlign="start flex-end" class="mee-container-search">
        <div fxFlex="300px" fxLayout="column" style="margin-right:20px;">
            <span class="mee-title">Fecha de Inicio</span>
            <div fxLayout>
                <input [(ngModel)]="date1" id="startDate" matInput [matDatepicker]="startDate" class="form-control">
                <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
                <mat-datepicker #startDate disabled="false"></mat-datepicker>
            </div>
        </div>
        <div fxFlex="300px" fxLayout="column" style="margin-right:20px;">
            <span class="mee-title">Fecha de Fin</span>
            <div fxLayout>
                <input [(ngModel)]="date2" id="endDate" matInput [matDatepicker]="endDate" class="form-control">
                <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
                <mat-datepicker #endDate disabled="false"></mat-datepicker>
            </div>
        </div>
        <button mat-mini-fab class="blue" matTooltip="Buscar" matTooltipPosition="above" matTooltipClass="tooltip"
            (click)="search()">
            <mat-icon>search</mat-icon>
        </button>
    </div>
    <p class="title">Listado de Reuniones</p>
    <div *ngIf="loadingMeeting" class="mee-container-loader">
        <div class="mee-loader"></div>
    </div>
    <table class="mee-table" mat-table [dataSource]="dataSourceMeeting">
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Nombre</th>
            <td mat-cell *matCellDef="let item" (click)="getSupport(item.id)">{{item.name}}</td>
        </ng-container>
        <ng-container matColumnDef="periodicity">
            <th mat-header-cell *matHeaderCellDef>Periodicidad</th>
            <td mat-cell *matCellDef="let item" (click)="getSupport(item.id)">{{item.peroidicity}}</td>
        </ng-container>
        <ng-container matColumnDef="creationDate">
            <th mat-header-cell *matHeaderCellDef>Fecha Creacion</th>
            <td mat-cell *matCellDef="let item" (click)="getSupport(item.id)">{{item.creationDate}}</td>
        </ng-container>
        <ng-container matColumnDef="creationUser">
            <th mat-header-cell *matHeaderCellDef>Usuario Creacion</th>
            <td mat-cell *matCellDef="let item" (click)="getSupport(item.id)">{{item.creationUser}}</td>
        </ng-container>
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Acciones</th>
            <td mat-cell *matCellDef="let item">
                <button mat-icon-button matTooltip="Crear Apoyo" matTooltipPosition="above" matTooltipClass="tooltip"
                    (click)="create()">
                    <mat-icon class="green-color">add</mat-icon>
                </button>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columnsMeeting"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsMeeting;"></tr>
    </table>
    <mat-paginator #paginatorMeeting [pageSizeOptions]="[5, 10, 15, 20, 30, 40, 50, 100]" showFirstLastButtons>
    </mat-paginator>
    <ng-container *ngIf="meetingId!=0">
        <p class="title">Listado de Apoyos</p>
        <div fxFlex fxLayout fxLayoutAlign="center center">
            <span *ngIf="dataSourceSupport.data.length===0" class="mee-title">¡Sin apoyos!</span>
        </div>
        <div *ngIf="loadingSupport" class="mee-container-loader">
            <div class="mee-loader"></div>
        </div>
        <table *ngIf="dataSourceSupport.data.length>0" class="mee-table" mat-table [dataSource]="dataSourceSupport">
            <ng-container matColumnDef="support">
                <th mat-header-cell *matHeaderCellDef>Apoyo</th>
                <td mat-cell *matCellDef="let item">{{item.support}}</td>
            </ng-container>
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef>Estado</th>
                <td mat-cell *matCellDef="let item"
                    [ngStyle]="{'color':item.status==='Pendiente'?'var(--red)':'var(--green)'}">{{item.status}}</td>
            </ng-container>
            <ng-container matColumnDef="creationDate">
                <th mat-header-cell *matHeaderCellDef>Fecha Creacion</th>
                <td mat-cell *matCellDef="let item">{{item.creationDate}}</td>
            </ng-container>
            <ng-container matColumnDef="startDate">
                <th mat-header-cell *matHeaderCellDef>Fecha Inicio</th>
                <td mat-cell *matCellDef="let item">{{item.startDate}}</td>
            </ng-container>
            <ng-container matColumnDef="endDate">
                <th mat-header-cell *matHeaderCellDef>Fecha Fin</th>
                <td mat-cell *matCellDef="let item">{{item.endDate}}</td>
            </ng-container>
            <ng-container matColumnDef="responsibleUser">
                <th mat-header-cell *matHeaderCellDef>Usuario Responsable</th>
                <td mat-cell *matCellDef="let item">{{item.responsibleUser}}</td>
            </ng-container>
            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef>Acciones</th>
                <td mat-cell *matCellDef="let item">
                    <button mat-icon-button matTooltip="Eliminar" matTooltipPosition="above" matTooltipClass="tooltip"
                        (click)="delete(item.id,item.status)">
                        <mat-icon class="red-color">delete</mat-icon>
                    </button>
                    <button mat-icon-button matTooltip="Archivos" matTooltipPosition="above" matTooltipClass="tooltip"
                        (click)="getFiles(item.meetingId,item.id)">
                        <mat-icon class="orange-color">assignment</mat-icon>
                    </button>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="columnsSupport"></tr>
            <tr mat-row *matRowDef="let row; columns: columnsSupport;"></tr>
        </table>
        <mat-paginator *ngIf="dataSourceSupport.data.length>0" #paginatorSupport
            [pageSizeOptions]="[5, 10, 15, 20, 30, 40, 50, 100]" showFirstLastButtons>
        </mat-paginator>
    </ng-container>
</div>