export class RepReportEntity{
    id:number;
    name:string;
    typeQuery:string;
    storeProcedure:string;
    creationDate:string;
    sectionId:number;
    section:string;
    active:boolean;

    constructor(){}
}