<div mat-dialog-content>
    <div class="bsc-container-button">
        <button mat-icon-button (click)="close(0,'')">
            <mat-icon>clear</mat-icon>
        </button>
    </div>
    <p class="title">Listado de Variables</p>
    <table #table class="bsc-table" mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef>Id</th>
            <td mat-cell *matCellDef="let item" (click)="close(item.id,item.name)">{{item.id}}</td>
        </ng-container>
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Nombre</th>
            <td mat-cell *matCellDef="let item" (click)="close(item.id,item.name)">{{item.name}}</td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns;"></tr>
    </table>
    <div *ngIf="loading" class="bsc-container-loader">
        <div class="bsc-loader"></div>
    </div>
    <mat-paginator [pageSizeOptions]="[5, 10, 15, 20, 30, 40, 50, 100]" showFirstLastButtons></mat-paginator>
</div>