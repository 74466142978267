<div class="task-container">
    <p class="title">Listado de Tareas</p>
    <div class="task-container-button">
        <div class="task-group">
            <input type="text" class="form-control" placeholder="Filtrar" (keyup)="filter($event)">
            <mat-icon class="blue-color">search</mat-icon>
        </div>
        <button routerLink="/tasPrincipal/task/new" mat-mini-fab class="green" matTooltip="Crear" matTooltipPosition="left"
            matTooltipClass="tooltip">
            <mat-icon>add</mat-icon>
        </button>
    </div>
    <table #table class="task-table" mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="title">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Titulo</th>
            <td mat-cell *matCellDef="let item" routerLink="/tasPrincipal/activity/taskId/{{item.id}}">{{item.title}}</td>
        </ng-container>
        <ng-container matColumnDef="priority">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Prioridad</th>
            <td mat-cell *matCellDef="let item" [ngClass]="{'green-color':item.priority==='Baja','orange-color':item.priority==='Media','red-color':item.priority==='Alta'}" routerLink="/tasPrincipal/activity/taskId/{{item.id}}">{{item.priority}}</td>
        </ng-container>
        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Estado</th>
            <td mat-cell *matCellDef="let item" [ngClass]="{'red-color':item.status==='Pendiente','green-color':item.status==='Terminada'}" routerLink="/tasPrincipal/activity/taskId/{{item.id}}">{{item.status}}</td>
        </ng-container>
        <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Tipo</th>
            <td mat-cell *matCellDef="let item" routerLink="/tasPrincipal/activity/taskId/{{item.id}}">{{item.type}}</td>
        </ng-container>
        <ng-container matColumnDef="startDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha de inicio</th>
            <td mat-cell *matCellDef="let item" routerLink="/tasPrincipal/activity/taskId/{{item.id}}">{{item.startDate}}</td>
        </ng-container>
        <ng-container matColumnDef="endDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha de finalizacion</th>
            <td mat-cell *matCellDef="let item" routerLink="/tasPrincipal/activity/taskId/{{item.id}}">{{item.endDate}}</td>
        </ng-container>
        <ng-container matColumnDef="requestPerson">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Usuario solicitante</th>
            <td mat-cell *matCellDef="let item" routerLink="/tasPrincipal/activity/taskId/{{item.id}}">{{item.requestPerson}}</td>
        </ng-container>
        <ng-container matColumnDef="assignedPerson">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Usuario asignado</th>
            <td mat-cell *matCellDef="let item" routerLink="/tasPrincipal/activity/taskId/{{item.id}}">{{item.assignedPerson}}</td>
        </ng-container>
        <ng-container matColumnDef="Acciones">
            <th mat-header-cell *matHeaderCellDef>Acciones</th>
            <td mat-cell *matCellDef="let item">
                <button mat-icon-button matTooltip="Eliminar" matTooltipPosition="above"
                    matTooltipClass="tooltip" (click)="delete(item.id)">
                    <mat-icon class="red-color">delete</mat-icon>
                </button>
                <button mat-icon-button matTooltip="Editar" matTooltipPosition="above"
                    matTooltipClass="tooltip" (click)="edit(item.id)">
                    <mat-icon class="blue-color">create</mat-icon>
                </button>
                <button mat-icon-button matTooltip="Agregar actividad" matTooltipPosition="above"
                    matTooltipClass="tooltip" (click)="addActivity(item.id)">
                    <mat-icon class="orange-color">add</mat-icon>
                </button>
                <button mat-icon-button matTooltip="Cerrar" matTooltipPosition="above"
                    matTooltipClass="tooltip" (click)="close(item.id,item.status)">
                    <mat-icon class="green-color">close</mat-icon>
                </button>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns;"></tr>
    </table>
    <div *ngIf="loading" class="task-container-loader">
        <div class="task-loader"></div>
    </div>
    <mat-paginator [pageSizeOptions]="[5, 10, 15, 20, 30, 40, 50, 100]" showFirstLastButtons></mat-paginator>
</div>