import { NgModule,CUSTOM_ELEMENTS_SCHEMA   } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { MaterialModule } from '../material.module';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { AppFileGroupRoutingModule } from './appFileGroupRouting.module';
import { FileGroupPrincipalComponent } from './components/principal/FlieGroupPrincipal.component';
import { FileGroupNewComponent } from './components/fileGroup/new/fileGroupNew.component';
import { FileGroupListComponent } from './components/fileGroup/list/fileGroupList.component';
import { FileGroupEditComponent } from './components/fileGroup/edit/fileGroupEdit.component';
import { GroupUserListComponent } from './components/user/list/groupUserList.component';
import { FileGroupCustomerModal } from './modals/fileGroupCustomer/fileGroupCustomer.modal';
import { FileGroupModal } from './modals/fileGroup/fileGroup.modal';
import { FileGroupAssignmentListComponent } from './components/fileGroupList/list/fileGroupAssignment.component';


@NgModule({
  declarations: [
    FileGroupPrincipalComponent,
    FileGroupNewComponent,
    FileGroupListComponent,
    FileGroupEditComponent,
    GroupUserListComponent,
    FileGroupCustomerModal,
    FileGroupModal,
    FileGroupAssignmentListComponent,
    
    
  ],
  imports: [
    CommonModule,
    AppFileGroupRoutingModule,
    ReactiveFormsModule,
    MaterialModule,
    FormsModule,
    MatIconModule,      
    MatButtonModule,
  
  ],schemas: [ CUSTOM_ELEMENTS_SCHEMA ]

})
export class AppFileGruopModule { }