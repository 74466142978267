<div class="actfix-container">
    <p class="title">Registros</p>
    <div class="actfix-container-button">
        <div class="actfix-group">
            <input type="text" class="form-control" placeholder="Filtrar" (keyup)="filter($event)">
            <mat-icon class="blue-color">search</mat-icon>
        </div>
    </div>
    <table #table class="actfix-table" mat-table [dataSource]="dataSource" matSort>
     
        <ng-container matColumnDef="typeDocument">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Tipo de documento</th>
            <td mat-cell *matCellDef="let item">{{item.typeDocument}}</td>
        </ng-container>
        <ng-container matColumnDef="identification">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>N° identificacion</th>
            <td mat-cell *matCellDef="let item">{{item.identification}}</td>
        </ng-container>
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Nombres</th>
            <td mat-cell *matCellDef="let item">{{item.name}}</td>
        </ng-container>
        <ng-container matColumnDef="lastName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Apellidos</th>
            <td mat-cell *matCellDef="let item">{{item.lastName}}</td>
        </ng-container>
        <ng-container matColumnDef="phone">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Telefono</th>
            <td mat-cell *matCellDef="let item">{{item.phone}}</td>
        </ng-container>
        <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Correo</th>
            <td mat-cell *matCellDef="let item">{{item.email}}</td>
        </ng-container>
        <ng-container matColumnDef="entity">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Entidad</th>
            <td mat-cell *matCellDef="let item">{{item.entity}}</td>
        </ng-container>
        <ng-container matColumnDef="eps">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>EPS</th>
            <td mat-cell *matCellDef="let item">{{item.eps}}</td>
        </ng-container>
        <ng-container matColumnDef="arl">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>ARL</th>
            <td mat-cell *matCellDef="let item">{{item.arl}}</td>
        </ng-container>
        <ng-container matColumnDef="visit">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Visita</th>
            <td mat-cell *matCellDef="let item">{{item.visit}}</td>
        </ng-container>
        <ng-container matColumnDef="license">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Carnet</th>
            <td mat-cell *matCellDef="let item">{{item.license}}</td>
        </ng-container>
        <ng-container matColumnDef="typeVisit">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Tipo de visita</th>
            <td mat-cell *matCellDef="let item">{{item.typeVisit}}</td>
        </ng-container>
        <ng-container matColumnDef="team">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Trae equipo</th>
            <td mat-cell *matCellDef="let item">{{item.team}}</td>
        </ng-container>
        <ng-container matColumnDef="brand">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Marca</th>
            <td mat-cell *matCellDef="let item">{{item.brand}}</td>
        </ng-container>
        <ng-container matColumnDef="serial">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Serial</th>
            <td mat-cell *matCellDef="let item">{{item.serial}}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns;"></tr>
    </table>
    <div *ngIf="loading" class="report-container-loader">
        <div class="report-loader"></div>
    </div>
    <mat-paginator [pageSizeOptions]="[5, 10, 15, 20, 30, 40, 50, 100]" showFirstLastButtons></mat-paginator>
</div>

