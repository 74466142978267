import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { ResponseModel } from 'src/app/shared/models/response.model';
import { FileGroupUserEntity} from '../entities/fileGroupUser.entity';



@Injectable({
    providedIn:'root'
})
export class FileGroupUserService{
    
    private url:string;

    constructor(private http:HttpClient){
    this.url=environment.api;
        //this.url=environment.apiIq09;
    }
    public create(userId:number,groupId:number):Observable<ResponseModel>{
        var headers = new HttpHeaders().set('content-Type', 'application/json');
        var body=new FileGroupUserEntity;
        body.userId=userId;
        body.groupId=groupId;

        return this.http.post<ResponseModel>(this.url+'FileGroupUser/create',JSON.stringify(body),{headers:headers});
    }

    public delete(userId:number, groupId:number):Observable<ResponseModel>{
        var headers = new HttpHeaders().set('content-Type', 'application/x-www-form-urlencoded');

        return this.http.delete<ResponseModel>(this.url+'FileGroupUser/delete/'+userId+'/'+groupId,{ headers: headers });
    }

    public list(userId:number):Observable<ResponseModel>{
        var headers = new HttpHeaders().set('content-Type', 'application/x-www-form-urlencoded');
        return this.http.get<ResponseModel>(this.url+'FileGroupUser/list/'+userId,{headers:headers});
    }
}