<div class="report-container">
    <p class="title">Listado de Pallet Pendientes por Auditar</p>

    <div class="report-container-button">
        <div class="report-group">
            <input type="text" class="form-control" placeholder="Filtrar" (keyup)="filter($event)">
            <mat-icon class="blue-color">search</mat-icon>
        </div>
    </div>

    <table #table class="report-table" mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="Pallet">
            <th mat-header-cell *matHeaderCellDef>Pallet</th>
            <td mat-cell *matCellDef="let item">{{item.pallet}}</td>
        </ng-container>
        <ng-container matColumnDef="CodigoSap">
            <th mat-header-cell *matHeaderCellDef>Codigo SAP</th>
            <td mat-cell *matCellDef="let item">{{item.codigoSap}}</td>
        </ng-container>
        <ng-container matColumnDef="Location">
            <th mat-header-cell *matHeaderCellDef>Usuario de creacion</th>
            <td mat-cell *matCellDef="let item">{{item.userCreation}}</td>
        </ng-container>
        <ng-container matColumnDef="Typology">
            <th mat-header-cell *matHeaderCellDef>Tipologia</th>
            <td mat-cell *matCellDef="let item">{{item.typology}}</td>
        </ng-container>
        <ng-container matColumnDef="State">
            <th mat-header-cell *matHeaderCellDef>Estado</th>
            <td mat-cell *matCellDef="let item">{{item.status}}</td>
        </ng-container>
        <ng-container matColumnDef="Actions">
            <th mat-header-cell *matHeaderCellDef>Acciones</th>
            <td mat-cell *matCellDef="let item">
                <button mat-icon-button matTooltip="aprobar" matTooltipPosition="above"
                    matTooltipClass="tooltip" (click)="changeState(item.id,'Aprobado')">
                    <mat-icon class="blue-color">done</mat-icon>
                </button>
                <button mat-icon-button matTooltip="Rechazar" matTooltipPosition="above"
                    matTooltipClass="tooltip" (click)="changeState(item.id,'Rechazado')">
                    <mat-icon class="red-color">close</mat-icon>
                </button>
                <button mat-icon-button matTooltip="Consultar Series" matTooltipPosition="above"
                    matTooltipClass="tooltip" (click)="searchDetail(item.id)">
                    <mat-icon class="green-color">verified</mat-icon>
                </button>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns;"></tr>
    </table>
    <div *ngIf="loading" class="report-container-loader">
        <div class="report-loader"></div>
    </div>
    <mat-paginator [pageSizeOptions]="[5, 10, 15, 20, 30, 40, 50, 100]" showFirstLastButtons></mat-paginator>
</div>