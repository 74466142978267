export class ProAnalysisEntity{
    id:number;
    measurementDetailId:number;
    analysis:string;
    perspective:string;
    strategicObjetive:string;
    indicator:string;
    month:string;
    actionPlanId:number;
    actionPlan:string;

    constructor(){}
}