import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { AlertService } from 'src/app/shared/services/alert.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationComponent } from 'src/app/shared/components/confirmation/confirmation.component';
import { ActFeaturesModal } from "src/app/appActiveFixed/modals/features/actFeatures.modal";
import { MeeGroupService } from "src/app/appMeeting/services/meeGroup.service";
import { MeegroupProg } from "src/app/appMeeting/modals/meegroupProg/meegroupProg.modal";
import { MeeGroupEntity } from "src/app/appMeeting/entities/meeGroup.entity";
import { MeegroupnewModal } from "src/app/appMeeting/modals/meegroupnew/meegroupnew.modal";

@Component({
  selector: 'app-meeGroupList',
  templateUrl: './meeGroupList.component.html',
  styleUrls: ['./meeGroupList.component.css'],
})
export class MeeGroupListComponent implements OnInit {
  //Variables
  loading: boolean;
  columns: string[];
  id: number;
  dataSource = new MatTableDataSource<any>();
  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  unibytes: Uint8Array = null;
  form: any;
  //meGroupformId:number;
  //status:boolean;
  constructor(private meeGroupS: MeeGroupService,private dialog: MatDialog,private alertS: AlertService) 
  {
    this.loading = false;
    this.columns = ['name', 'description', 'active', 'Acciones'];
    this.dataSource = new MatTableDataSource([]);
  }

  ngOnInit(): void {
    (this.loading = true), this.search();
  }

  search() {
    this.meeGroupS.list().subscribe(
      (res) => {
        if (res.message === 'OK') {
          this.loading = false;
          this.dataSource = new MatTableDataSource<any>(res.object);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        } else {
          this.alertS.open(res.message, 'error');
        }
      },
      (err) => {
        this.alertS.open(err.message, 'error');
      }
    );
  }

  program(meetingGroup: MeeGroupEntity) {
    this.dialog.open(MeegroupProg, {
      data: { meetingGroup: meetingGroup },
        width: '40%',
    }).afterClosed().subscribe((resA) => {
      if (resA) {
        this.search();
      }
    });
  }

  edit(meetingGroup: MeeGroupEntity) {
    this.dialog.open(MeegroupnewModal, {
        data: { meetingGroup: meetingGroup },
        width: '40%',
      })
      .afterClosed().subscribe((resA) => {
        if (resA) {
          this.search();
        }
      });
  }

  filter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  delete(value: number) {
    this.dialog
      .open(ConfirmationComponent, {
        data: { message: '¿ Desea eliminar el registro ?' },
        height: '250px',
        width: '400px',
      })
      .afterClosed()
      .subscribe(
        (res) => {
          if (res) {
            this.meeGroupS.delete(value).subscribe(
              (res) => {
                if (res.message === 'OK') {
                  if (res.object != 0) {
                    this.alertS.open('Registro eliminado!', 'success');
                    this.meeGroupS.list().subscribe(
                      (res) => {
                        this.loading = false;
                        this.dataSource = new MatTableDataSource<any>(
                          res.object
                        );
                        this.dataSource.paginator = this.paginator;
                        this.dataSource.sort = this.sort;
                      },
                      (err) => {
                        this.alertS.open(err, 'error');
                      }
                    );
                  } else {
                    this.alertS.open(res.message, 'error');
                  }
                } else {
                  this.alertS.open(res.message, 'error');
                }
              },
              (err) => {
                this.alertS.open(err.message, 'error');
              }
            );
          }
        },
        (err) => {
          this.alertS.open(err.message, 'error');
        }
      );
  }
  getfeatures(value: number) {
    this.dialog.open(ActFeaturesModal, {
      data: { id: value },
      width: '600px',
    });
  }
}