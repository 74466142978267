import { Component, OnInit, ViewChild } from "@angular/core";
import { DownloadFilesClaroFontModel } from "src/app/appDownloadFiles/models/downloadFilesClaroFont.model";
import { DownloadFilesClaroSmartModel } from "src/app/appDownloadFiles/models/downloadFilesClaroSmart.model";
import { DownloadFilesDirectvModel } from "src/app/appDownloadFiles/models/downloadFilesDirectv.model";
import { DownloadFilesDirectvSmartModel } from "src/app/appDownloadFiles/models/downloadFilesDirectvSmart.model";
import { DownloadFilesEtbModel } from "src/app/appDownloadFiles/models/downloadFilesEtb.model";
import { DownloadFilesHughesModel } from "src/app/appDownloadFiles/models/downloadFilesHughes.model";
import { DownloadFilesPlataformaModel } from "src/app/appDownloadFiles/models/downloadFilesPlataforma.model";
import { DownloadFilesRadioTechModel } from "src/app/appDownloadFiles/models/downloadFilesRadioTech.model";
import { DownloadFilesRedModel } from "src/app/appDownloadFiles/models/downloadFilesRed.model";
import { DownloadFilesTigoModel } from "src/app/appDownloadFiles/models/downloadFilesTigo.model";
import { DownloadFilesService } from "src/app/appDownloadFiles/services/downloadFiles.service";
import { FileGroupAssignmentModel } from "src/app/appFileGroup/models/fileGroupAssignment.model";
import { FileListModel } from "src/app/appFileGroup/models/FileList.model";
import { FileGroupAssignmentService } from "src/app/appFileGroup/services/fileGroupAssignment.service";
import { FileGroupValidationService } from "src/app/appFileGroup/services/fileGroupValidation.service";
import { GenCustomerEntity } from "src/app/appGeneral/entities/genCustomer.entity";
import { GenPersonEntity } from "src/app/appGeneral/entities/genPerson.entity";
import { AlertService } from "src/app/shared/services/alert.service";


@Component({
    selector: 'app-fileGroupAssignment',
    templateUrl: './fileGroupAssignment.component.html',
    styleUrls: ['./fileGroupAssignment.component.css']
})
export class FileGroupAssignmentListComponent implements OnInit {
    loading: boolean;
    genPersonEntity: GenPersonEntity;
    fileGroupList: FileGroupAssignmentModel[];
    customers: string;
    loadingF: boolean;
    fileList: FileListModel[];
    statusW:string;
    fileListCLaroSmart: DownloadFilesClaroSmartModel[];
    fileListClaroFont: DownloadFilesClaroFontModel[];
    fileListDirectv: DownloadFilesDirectvModel[];
    fileListDirectvSmart: DownloadFilesDirectvSmartModel[];
    fileListTigo: DownloadFilesTigoModel[];
    fileListEtb: DownloadFilesEtbModel[];
    fileListHughes: DownloadFilesHughesModel[];
    fileListTigoM: DownloadFilesTigoModel[];
    fileListRed: DownloadFilesRedModel[];
    fileListPlataforma: DownloadFilesPlataformaModel[];
    fileListRadioTech: FileListModel[];
    fileListTotalPlay: FileListModel[];
    fileListSkyNet: FileListModel[];
    statusCl:string;
    statusClS:string;
    statusClF:string;
    statusDr:string;
    statusDrS:string;
    statusEt:string;
    statusHg:string;
    statusPm:string;
    statusRe:string;
    statusTib:string;
    statusTiM:string;
    statusRT:string;
    statusTP:string;
    statusSN:string;
    customer: GenCustomerEntity;
    loadingCl: boolean;
    loadingClS: boolean;
    loadingClF: boolean;
    loadingDr: boolean;
    loadingDrs: boolean;
    loadingEt: boolean;
    loadingHg: boolean;
    loadingPm: boolean;
    loadingRe: boolean;
    loadingTiM: boolean;
    loadingTib: boolean;
    loadingRT: boolean;
    loadingTP: boolean;
    loadingSN: boolean;

    constructor(private downloadFilesS: DownloadFilesService, private fileGroupAssignmentS: FileGroupAssignmentService, private fileGroupValidationService: FileGroupValidationService, private alertS: AlertService) {
        this.loading = false;
        this.customers = '';
        this.fileList = [];
        this.fileListCLaroSmart = [];
        this.fileListClaroFont = [];
        this.fileListDirectv = [];
        this.fileListDirectvSmart = [];
        this.fileListTigo = [];
        this.fileListEtb = [];
        this.fileListHughes = [];
        this.fileListTigoM = [];
        this.fileListRed = [];
        this.fileListPlataforma = [];
        this.fileListRadioTech = [];
        this.customer = new GenCustomerEntity();
        this.statusCl = '';
        this.statusClS = '';
        this.statusClF = '';
        this.statusDr = '';
        this.statusDrS = '';
        this.statusEt = '';
        this.statusHg = '';
        this.statusPm = '';
        this.statusRe = '';
        this.statusTib = '';
        this.statusTiM = '';
        this.statusRT = '';
        this.statusTP = '';
        this.statusSN = '';
        this.loadingCl = true;
        this.loadingClS = true;
        this.loadingClF = true;
        this.loadingDr = true;
        this.loadingDrs = true;
        this.loadingEt = true;
        this.loadingHg = true;
        this.loadingPm = true;
        this.loadingRe = true;
        this.loadingTiM = true;
        this.loadingTib = true;
        this.loadingRT = true;
        this.loadingTP = true;
        this.loadingSN = true;

    }

    ngOnInit(): void {
        this.genPersonEntity = JSON.parse(localStorage.getItem('user'));
        this.loading = true;
        this.fileGroupAssignmentS.assignmnet(this.genPersonEntity.id).subscribe(res => {
            if (res.message === 'OK') {
                this.fileGroupList = res.object

                this.fileGroupList.forEach(element => {
                    element.description;
                    this.customers = element.description;   
                    this.filesAll(element.description);
                });
                
              
            } else {
                this.alertS.open(res.message, 'error');
            }
        }, err => {
            this.alertS.open(err.message, 'error');
        });
    }

    download(file: FileListModel) {
        var downloadLink = document.createElement("a");
        if (file.type === 'imagen') {
          downloadLink.setAttribute("href", "data:image/png;base64," + file.file);
        } else {
          var binary = window.atob(file.file);
          var binaryLength = binary.length;
          var bytes = new Uint8Array(binaryLength);
          for (var i = 0; i < binaryLength; i++) {
            var ascii = binary.charCodeAt(i);
            bytes[i] = ascii;
          }
          var blob = new Blob([bytes], { type: "application/" + file.type });
          downloadLink.href = window.URL.createObjectURL(blob);
        }
        downloadLink.setAttribute("download", file.name);
        downloadLink.style.visibility = "hidden";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      }

      filesAll(customerAll: string){

        switch(customerAll){
          case 'CLARO':
            this.filesClaro();
            this.filesClaroSmart();
            this.filesClaroFont();
            break;
          
          case 'DIRECTV':
            this.filesDirectv();
            this.filesDirectvSmart();
            break;

          case 'TIGO':
            this.filesTigo();
            this.filesTigoMedellin();
            break;

          case 'ETB':
            this.filesEtb();
            break;

          case 'HUGHES':
            this.filesHughes();
            break;
          
          case 'RED EXTERNA':
            this.filesRedExterna();
            break;
          
          case 'PLATAFORMA MOVIL':
            this.filesPlataforma();
            break;

          case 'RADIO-TECH':
            this.filesRadioTech();
            break;

          case 'TOTAL-PLAY':
            this.filesTotalPlay();
            break;
          
          case 'SKYNET':
            this.filesSkyNet();
            break;
        }        

        /*if(customerAll == 'CLARO'){
          this.filesClaro();
          this.filesClaroSmart();
          this.filesClaroFont();
        }else if(customerAll == 'DIRECTV'){
          this.filesDirectv();
          this.filesDirectvSmart();
        }else if(customerAll == 'ETB'){
            this.filesEtb();
        }else if(customerAll == 'TIGO'){
          this.filesTigo();
        }else if(customerAll == 'TIGO MEDELLIN'){
          this.filesTigoMedellin();
        }else if(customerAll == 'HUGHES'){
          this.filesHughes();
        }else if(customerAll == 'RED EXTERNA'){
          this.filesRedExterna();
        }else if(customerAll == 'PLATAFORMA MOVIL'){
          this.filesPlataforma();
        }else if(customerAll == 'RADIO-TECH'){
          this.filesRadioTech();
        }else if(customerAll == 'TOTAL-PLAY'){
          this.filesTotalPlay();
        }else if(customerAll == 'SKYNET'){
          this.filesSkyNet();
        }*/

      }

      filesClaro() {
        this.loadingCl=false;
        this.downloadFilesS.listFileClaro().subscribe(resL => {
          if (resL.message === 'OK') {
            this.fileList = resL.object;
            if(this.fileList.length != 0){
              this.statusCl = 'CLARO';
              this.loadingCl=true;
            }else{
              this.statusCl = 'CLARO EN ACTUALIZACION...';
              this.loadingCl=false;
            }
            
          } else {
            this.alertS.open(resL.message, 'error');
          }
        }, err => {
          this.alertS.open(err.message, 'error');
        });
      }

      filesClaroSmart() {
        this.loadingClS = false;
        this.downloadFilesS.listFileClaroSmart().subscribe(resL => {
          if (resL.message === 'OK') {
            this.fileListCLaroSmart = resL.object;
            if(this.fileListCLaroSmart.length != 0){
              this.statusClS = 'CLARO SMARTCARD';
              this.loadingClS = true;
            }else{
              this.statusClS = 'CLARO SMARTCARD EN ACTUALIZACION...';
              this.loadingClS = false;
            }
          } else {
            this.alertS.open(resL.message, 'error');
          }
        }, err => {
          this.alertS.open(err.message, 'error');
        });
      }
      
      filesClaroFont() {
        this.loadingClF = false;
        this.downloadFilesS.listFileClaroFont().subscribe(resL => {
          if (resL.message === 'OK') {
            this.fileListClaroFont = resL.object;
            if(this.fileListClaroFont.length != 0){
              this.statusClF = 'CLARO FONTIBON';
              this.loadingClF = true;
            }else{
              this.statusClF = 'CLARO FONTIBON EN ACTUALIZACION...';
              this.loadingClF = false;
            }
          } else {
            this.alertS.open(resL.message, 'error');
          }
        }, err => {
          this.alertS.open(err.message, 'error');
        });
      }
      
      filesDirectv() {
        this.loadingDr = false;
        this.downloadFilesS.listFileDirectv().subscribe(resL => {
          if (resL.message === 'OK') {
            this.fileListDirectv = resL.object;
            if(this.fileListDirectv.length != 0){
              this.statusDr = 'DIRECTV';
              this.loadingDr = true;
            }else{
              this.statusDr = 'DIRECTV EN ACTUALIZACION...';
              this.loadingDr = false;
            }
          } else {
            this.alertS.open(resL.message, 'error');
          }
        }, err => {
          this.alertS.open(err.message, 'error');
        });
      }
      
      filesDirectvSmart() {
        this.loadingDrs = false;
        this.downloadFilesS.listFileDirectvSmart().subscribe(resL => {
          if (resL.message === 'OK') {
            this.fileListDirectvSmart = resL.object;
            if(this.fileListDirectvSmart.length != 0){
              this.statusDrS = 'DIRECTV SMARTCARD';
              this.loadingDrs = true;
            }else{
              this.statusDrS = 'DIRECTV SMARTCARD EN ACTUALIZACION...';
              this.loadingDrs = false;
            }
          } else {
            this.alertS.open(resL.message, 'error');
          }
        }, err => {
          this.alertS.open(err.message, 'error');
        });
      }
      
      filesEtb() {
        this.loadingEt = false;
        this.downloadFilesS.listFileEtb().subscribe(resL => {
          if (resL.message === 'OK') {
            this.fileListEtb = resL.object;
            if(this.fileListEtb.length != 0){
              this.statusEt = 'ETB';
              this.loadingEt = true;
            }else{
              this.statusEt = 'ETB EN ACTUALIZACION...';
              this.loadingEt = false;
            }
          } else {
            this.alertS.open(resL.message, 'error');
          }
        }, err => {
          this.alertS.open(err.message, 'error');
        });
      }
      
      filesHughes() {
        this.loadingHg = false;
        this.downloadFilesS.listFileHughes().subscribe(resL => {
          if (resL.message === 'OK') {
            this.fileListHughes = resL.object;
            if(this.fileListHughes.length != 0){
              this.statusHg = 'HUGHES';
              this.loadingHg = true;
            }else{
              this.statusHg = 'HUGHES EN ACTUALIZACION...';
              this.loadingHg = false;
            }
          } else {
            this.alertS.open(resL.message, 'error');
          }
        }, err => {
          this.alertS.open(err.message, 'error');
        });
      }
      
      filesTigo() {
        this.loadingTib=false;
        this.downloadFilesS.listFileTigo().subscribe(resL => {
          if (resL.message === 'OK') {
            this.fileListTigo = resL.object;
            if(this.fileListTigo.length != 0){
              this.statusTib = 'TIGO BOGOTA';
              this.loadingTib = true;
            }else{
              this.statusTib = 'TIGO BOGOTA EN ACTUALIZACION...';
              this.loadingTib = false;
            }
          } else {
            this.alertS.open(resL.message, 'error');
          }
        }, err => {
          this.alertS.open(err.message, 'error');
        });
      }
      
      filesTigoMedellin() {
        this.loadingTiM = false
        this.downloadFilesS.listFileTigoMedellin().subscribe(resL => {
          if (resL.message === 'OK') {
            this.fileListTigoM = resL.object;
            if(this.fileListTigoM.length != 0){
              this.statusTiM = 'TIGO MEDELLIN';
              this.loadingTiM = true;
            }else{
              this.statusTiM = 'TIGO MEDELLIN EN ACTUALIZACION...';
              this.loadingTiM = false;
            }
          } else {
            this.alertS.open(resL.message, 'error');
          }
        }, err => {
          this.alertS.open(err.message, 'error');
        });
      }
      
      filesRedExterna() {
        this.loadingRe = false
        this.downloadFilesS.listFileRedExterna().subscribe(resL => {
          if (resL.message === 'OK') {
            this.fileListRed = resL.object;
            if(this.fileListRed.length != 0){
              this.statusRe = 'RED EXTERNA';
              this.loadingRe=true;
            }else{
              this.statusRe = 'RED EXTERNA EN ACTUALIZACION...';
              this.loadingRe=false;
            }
          } else {
            this.alertS.open(resL.message, 'error');
          }
        }, err => {
          this.alertS.open(err.message, 'error');
        });
      }
      
      filesPlataforma() {
        this.loadingPm = false
        this.downloadFilesS.listPlatafoma().subscribe(resL => {
          if (resL.message === 'OK') {
            this.fileListPlataforma = resL.object;
            if(this.fileListPlataforma.length != 0){
              this.statusPm = 'PLATAFORMA MOVIL';
              this.loadingPm = true;
            }else{
              this.statusPm = 'PLATAFORMA MOVIL EN ACTUALIZACION...';
              this.loadingPm = false;
            }
          } else {
            this.alertS.open(resL.message, 'error');
          }
        }, err => {
          this.alertS.open(err.message, 'error');
        });
      }

      filesRadioTech() {
        this.loadingRT = false
        this.downloadFilesS.listRadioTech().subscribe(resL => {
          if (resL.message === 'OK') {
            this.fileListRadioTech = resL.object;
            if(this.fileListRadioTech.length != 0){
              this.statusRT = 'RADIO-TECH';
              this.loadingRT = true;
            }else{
              this.statusRT = 'RADIO-TECH EN ACTUALIZACION...';
              this.loadingRT = false;
            }
          } else {
            this.alertS.open(resL.message, 'error');
          }
        }, err => {
          this.alertS.open(err.message, 'error');
        });
      }

      filesTotalPlay() {
        this.loadingTP = false
        this.downloadFilesS.listTotalPlay().subscribe(resL => {
          if (resL.message === 'OK') {
            this.fileListTotalPlay = resL.object;
            if(this.fileListTotalPlay.length != 0){
              this.statusTP = 'TOTAL-PLAY';
              this.loadingTP = true;
            }else{
              this.statusTP = 'TOTAL-PLAY EN ACTUALIZACION...';
              this.loadingTP = false;
            }
          } else {
            this.alertS.open(resL.message, 'error');
          }
        }, err => {
          this.alertS.open(err.message, 'error');
        });
      }
      
      filesSkyNet() {
        this.loadingSN = false
        this.downloadFilesS.listSkyNet().subscribe(resL => {
          if (resL.message === 'OK') {
            this.fileListSkyNet = resL.object;
            if(this.fileListSkyNet.length != 0){
              this.statusSN = 'SKYNET';
              this.loadingSN = true;
            }else{
              this.statusSN = 'SKYNET EN ACTUALIZACION...';
              this.loadingSN = false;
            }
          } else {
            this.alertS.open(resL.message, 'error');
          }
        }, err => {
          this.alertS.open(err.message, 'error');
        });
      }
}