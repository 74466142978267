<div class="pqr-container">
    <p class="title">Formulario N°1 Politica y Ley (Home)</p>
    <div class="pqr-form">
        <div class="pqr-group">
            <button class="pqr-button" mat-raised-button matTooltip="Generar registro"
            matTooltipPosition="right" matTooltipClass="tooltip" (click)="registerOne() ">
            <mat-icon>create</mat-icon>
        </button>
        </div>
        <div class="pqr-group">
            <button class="pqr-button-button" mat-raised-button matTooltip="Listar registros"
            matTooltipPosition="right" matTooltipClass="tooltip" (click)="registerOneList()">
            <mat-icon>view_list</mat-icon>
        </button>
        </div>
    </div>
    <p class="title">Formulario N°2 Titulo, parrafo de introduccion y entradas de texto (Pagina cliente inicio)</p>
    <div class="pqr-form">
        <div class="pqr-group">
            <button class="pqr-button" mat-raised-button matTooltip="Generar registro"
            matTooltipPosition="right" matTooltipClass="tooltip" (click)="registerTwo()">
            <mat-icon>create</mat-icon>
        </button>
        </div>
        <div class="pqr-group">
            <button class="pqr-button-button" mat-raised-button matTooltip="Listar registros"
            matTooltipPosition="right" matTooltipClass="tooltip" (click)="registerTwoList()">
            <mat-icon>view_list</mat-icon>
        </button>
        </div>
    </div>
    <p class="title">Formulario N°3 Titulo y encabezados de tabla (Pagina cliente final)</p>
    <div class="pqr-form">
        <div class="pqr-group">
            <button class="pqr-button" mat-raised-button matTooltip="Generar registro"
            matTooltipPosition="right" matTooltipClass="tooltip" (click)="registerTheer()">
            <mat-icon>create</mat-icon>
        </button>
        </div>
        <div class="pqr-group">
            <button class="pqr-button-button" mat-raised-button matTooltip="Listar registros"
            matTooltipPosition="right" matTooltipClass="tooltip" (click)="registerTheerList()">
            <mat-icon>view_list</mat-icon>
        </button>
        </div>
    </div>
    <p class="title">Formulario N°4 Titulo, parrafo de introduccion y entradas de texto (Modal)</p>
    <div class="pqr-form">
        <div class="pqr-group">
            <button class="pqr-button" mat-raised-button matTooltip="Generar registro"
            matTooltipPosition="right" matTooltipClass="tooltip" (click)="registerFour()">
            <mat-icon>create</mat-icon>
        </button>
        </div>
        <div class="pqr-group">
            <button class="pqr-button-button" mat-raised-button matTooltip="Listar registros"
            matTooltipPosition="right" matTooltipClass="tooltip" (click)="registerFourList()">
            <mat-icon>view_list</mat-icon>
        </button>
        </div>
    </div>
</div>