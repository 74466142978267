export class PqrFormLawEntity{
    
    id:number;
    descriptionLaw:string;
    languageId:number;
    creationDate:string;
    updateDate:string;
    active:boolean;
    
    constructor (){}
}