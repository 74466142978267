export class ScpAuditPreviousEntity{

    id:number;
    name:string;
    customerId:number;
    customerName:string;
    state:string;
    userId:number;
    userName:string;
    creationDate:string;
    active:boolean;

    constructor(){}
}