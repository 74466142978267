import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { AlertService } from 'src/app/shared/services/alert.service';
import { GenPersonService } from 'src/app/appGeneral/services/genPerson.service';
import { GenPersonEntity } from 'src/app/appGeneral/entities/genPerson.entity';
import { Observable } from 'rxjs';
import { FileGroupEntity } from 'src/app/appFileGroup/entities/fileGroup.entity';
import { FileGroupService } from 'src/app/appFileGroup/services/fileGroup.service';


@Component({
    selector: 'app-fileGroupNew',
    templateUrl: './fileGroupNew.component.html',
    styleUrls: ['./fileGroupNew.component.css']
})
export class FileGroupNewComponent implements OnInit{
    @Input() fileGroupId: number;
    @Output() closeDialog = new EventEmitter<any>();
    title:string;
    personList: GenPersonEntity[];
    FileGroupEntity: FileGroupEntity;
    genPersonE: GenPersonEntity;

    form = new FormGroup({
        id: new FormControl(''),
        name: new FormControl('', Validators.required),
        description: new FormControl('', Validators.required),
        active: new FormControl('')
    })
    filteredOptions: Observable<string[]>;

    constructor(private fileGroupS: FileGroupService, private genPersonS: GenPersonService, private alertS: AlertService) {
        this.fileGroupId = 0;
        this.genPersonE = new GenPersonEntity();
    }

    ngOnInit() {
      this.genPersonE = JSON.parse(localStorage.getItem('user'));
        if (this.fileGroupId != 0) {
            this.title = "Editar Grupo";
            this.fileGroupS.findById(this.fileGroupId).subscribe(res => {
                if (res.message === 'OK') {
                    this.FileGroupEntity = res.object;
                    this.form.setValue(
                        {
                            'id': this.FileGroupEntity.id,
                            'name': this.FileGroupEntity.name,
                            'description': this.FileGroupEntity.description,
                            'active': this.FileGroupEntity.active
                        }
                    );
                } else{
                    this.alertS.open(res.message,'error'); 
                }
            }, err => {
                this.alertS.open(err.message, 'error');
              });
        } else {
            this.title = "Nuevo Grupo";
        }
    }

    onClick() {
        console.log(this.fileGroupId)
        if (this.fileGroupId == 0) {
          //this.form.controls.startDate.setValue(moment(this.form.controls.startDate.value).format('YYYY-MM-DD'));
          this.fileGroupS.create(this.form.value, this.genPersonE.id ).subscribe(res => {
            if (res.message === 'OK') {
              if (res.object != 0) {
                this.closeDialog.emit();
                this.alertS.open('Grupo creado!', 'success');
                this.form.reset();
              } else {
                this.alertS.open(res.message, 'error');
              }
            } else {
              this.alertS.open(res.message, 'error');
            }
          }, err => {
            this.alertS.open(err.message, 'error');
          });
        } else {
          //this.form.controls.startDate.setValue(moment(this.form.controls.startDate.value).format('YYYY-MM-DD'));
          //this.form.controls.endDate.setValue(moment(this.form.controls.endDate.value).format('YYYY-MM-DD'));
          this.fileGroupS.update(this.form.value, this.genPersonE.id).subscribe(res => {
            console.log(this.form.value);
            if (res.message === 'OK') {
              if (res.object != 0) {
                this.closeDialog.emit();
                this.alertS.open('Grupo actualizado!', 'success');
                this.form.setValue({
                  'id': 0,
                  'name': '',
                  'description': '',
                  'active': ''
                });
                this.form.reset();
              } else {
                this.alertS.open(res.message, 'error');
              }
            } else {
              this.alertS.open(res.message, 'error');
            }
          }, err => {
            this.alertS.open(err.message, 'error');
          });
        }
      }
}