import { Component, OnInit } from "@angular/core";

@Component({
    selector: 'app-covFormHomeSeg',
    templateUrl: './covFormHomeSeg.component.html',
    styleUrls: ['./covFormHomeSeg.component.css']

})

export class CovFormSegHomeComponent implements OnInit{
    constructor(){}
    ngOnInit(): void {
    
       
    }

}
