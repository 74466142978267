import {Component,OnInit,Inject} from '@angular/core';
import { AlertService } from 'src/app/shared/services/alert.service';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { MeeGroupEntity } from 'src/app/appMeeting/entities/meeGroup.entity';
import { MeeGroupService } from 'src/app/appMeeting/services/meeGroup.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-meegroupnewmodal',
  templateUrl: './meegroupnew.modal.html',
  styleUrls: ['./meegroupnew.modal.css'],
})
export class MeegroupnewModal implements OnInit {
  title: string;

  formN = new FormGroup({
    id: new FormControl(''),
    name: new FormControl(''),
    description: new FormControl(''),
    Pro_Days: new FormControl(''),
    Pro_Hours: new FormControl(''),
    Pro_DateMax: new FormControl(''),
    active: new FormControl(''),
  });

  meeGroupEntity: MeeGroupEntity;

  constructor(
    private fb: FormBuilder,
    private alertS: AlertService,
    private meeGroupS: MeeGroupService,
    public dialogRef: MatDialogRef<MeegroupnewModal>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.title = '';
    dialogRef.disableClose = true;
    this.meeGroupEntity = new MeeGroupEntity();
  }

  ngOnInit(): void {
    if (this.data.meetingGroup != null) {
      this.formN.setValue({
        'id': this.data.meetingGroup.id,
        'name': this.data.meetingGroup.name,
        'description': this.data.meetingGroup.description,
        'Pro_Days': 'Monday;',
        'Pro_Hours': null,
        'Pro_DateMax': null,
        'active': this.data.meetingGroup.active,
      });
      this.title = 'Editar Reunión';
    } else {
      this.title = 'Nueva Reunión';
    }
  }

  save() {
    if (this.data.meetingGroup === null) {
      this.meeGroupS.create(this.formN.value).subscribe(
        (res) => {
          if (res.message === 'OK') {
            if (res.object != 0) {
              this.alertS.open('Grupo creado', 'success');
              this.close(true);
            } else {
              this.alertS.open('Error al crear el grupo', 'error');
            }
          } else {
            this.alertS.open(res.message, 'error');
          }
        },
        (err) => {
          this.alertS.open(err.message, 'error');
        }
      );
    } else {
      this.meeGroupS.update(this.formN.value).subscribe(
        (res) => {
          if (res.message === 'OK') {
            if (res.object != 0) {
              this.alertS.open('Grupo actualizado', 'success');
              this.close(true);
            } else {
              this.alertS.open('Error al actualizar Grupo', 'error');
            }
          } else {
            this.alertS.open(res.message, 'error');
          }
        },
        (err) => {
          this.alertS.open(err.message, 'error');
        }
      );
    }
  }

  close(status: boolean): void {
    this.dialogRef.close(status);
  }
}
