import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTable } from '@angular/material/table';
import { GenPersonEntity } from 'src/app/appGeneral/entities/genPerson.entity';
import { GenApplicationPersonProfileService } from 'src/app/appGeneral/services/genApplicationPersonProfile.service';
import { ConfirmationComponent } from 'src/app/shared/components/confirmation/confirmation.component';
import { AlertService } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-archive-config',
  templateUrl: './archiveConfig.component.html',
  styleUrls: ['./archiveConfig.component.css']
})
export class ArchiveConfigComponent implements OnInit {
  columns: string[];
  dataSource: any[];
  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild("top") topPage: ElementRef;
  form = new FormGroup({
    name: new FormControl(''),
    tipo: new FormControl('', Validators.required),
    caracteres: new FormControl(''),
    validacion: new FormControl('')
  });
  genPersonEntity: GenPersonEntity;
  opEdit:boolean;
  IdEdit:number;
  trueChange:boolean;
  admin:boolean;
  checkStatus:boolean;
  ColumnsSQL: string[];
  nameDisabled: string;
  
  constructor(private genApplicationPersonProfileS: GenApplicationPersonProfileService,
    private dialog: MatDialog,
    private alertS:AlertService,
    public dialogRef: MatDialogRef<ArchiveConfigComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { 
    //!!Pendiente renombrar indices en vez de numeros!!
    this.columns = ['0','1','2','3','4'];
    this.dataSource = data.columns;
    this.trueChange = false;
    this.admin = data.admin;
    this.ColumnsSQL = data.ColumnsSQL;
  }

  ngOnInit(): void {
    this.nameDisabled = '';
    this.form.controls['name'].enable();
    this.form.setValue({
      'name': '',
      'tipo': 'string',
      'caracteres': 32,
      'validacion': '',
    });
    this.opEdit = false;
    this.IdEdit = null;
    this.checkStatus = false;
  }

  check(status:any){
    this.checkStatus = status;
  }
  
  save(){
    if (this.opEdit === true) {
      if (this.form.value.name != '' && this.form.value.caracteres > 0 && this.form.value.caracteres <= 250) {
        var name = this.dataSource[this.IdEdit][0];
        this.dataSource[this.IdEdit] = [
          name,
          this.form.value.tipo,
          this.form.value.caracteres,
          this.form.value.validacion,
        ];
        if (this.checkStatus===true) {
          const element = this.dataSource.splice(this.IdEdit,1)[0];
          this.dataSource.splice(0,0,element);
        }
        this.alertS.open('Para confirmar los cambios seleccione guardar o de lo contrario cancelar', 'info');
        this.ngOnInit();
        this.table.renderRows();
        this.trueChange = true;
      } else {
        this.alertS.open('Error, el N° de caracteres es entre 0 y 205','error');
      }
    } else {
      if (this.form.value.name != '' && this.form.value.caracteres > 0 && this.form.value.caracteres <= 250) {
        if (this.checkStatus===true) {
          this.dataSource.unshift([
            this.form.value.name,
            this.form.value.tipo,
            this.form.value.caracteres,
            this.form.value.validacion,
          ]);
        }else{
          this.dataSource.push([
          this.form.value.name,
          this.form.value.tipo,
          this.form.value.caracteres,
          this.form.value.validacion,
          ]);
        }
        this.ColumnsSQL.splice(this.ColumnsSQL.indexOf(this.form.value.name),1);
        this.alertS.open('Para confirmar los cambios seleccione guardar o de lo contrario cancelar', 'info');
        this.ngOnInit();
        this.table.renderRows();
        this.trueChange = true;
      } else {
        this.alertS.open('Error, el N° de caracteres es entre 0 y 205','error');
      }
    }
  }

  edit(nombre:string,tipo:string,max:number,validacion:string,id:number){
    this.nameDisabled = nombre;
    this.form.setValue({
      'name': nombre,
      'tipo': tipo,
      'caracteres': max,
      'validacion': validacion,
    });
    this.form.controls['name'].disable();
    this.opEdit = true;
    this.IdEdit = id;
    //Llevar scroll a top
    this.topPage.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" });
  }

  delete(id:number){
    this.dialog.open(ConfirmationComponent, {
        data: { message: '¿Desea eliminar la columna?' },
        height: '250px',
        width: '500px',
    }).afterClosed().subscribe((resA) => {
      if (resA) {
        const element = this.dataSource.splice(id, 1);
        this.ColumnsSQL.push(element[0][0]);
        this.alertS.open('Para confirmar los cambios seleccione guardar o de lo contrario cancelar', 'info');
        this.ngOnInit();
        this.table.renderRows();
        this.trueChange = true;
      }
    });
  }

  close(): void {
    this.dialogRef.close(this.trueChange);
  }

}
