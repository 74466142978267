
export class ComCommodityEntryArticlesEntity {

    id:number;
    comCommodityEntryId:number;
    comCommodityEntryNumber:string;
    sapCode:string;
    descripcion:string;
    quantity:number;t
    userId:number;
    userName:string;
    creationDate:string;

    constructor(){ }
}