<div class="scp-container">
    <p class="title">{{title}}</p>
    <form [formGroup]="form" class="scp-form">

        <div class="scp-group">
            <label class="primaryColor">Tipo</label>
            <input name="type" formControlName="type" class="form-control" placeholder="Tipo">
            <div class="invalid-text" *ngIf="form.get('type').invalid && form.get('type').touched">Ingrese un valor valido</div>
        </div>

        <div class="scp-group">
            <label class="primaryColor">Codigo</label>
            <input name="code" formControlName="code" class="form-control" placeholder="Codigo">
            <div class="invalid-text" *ngIf="form.get('code').invalid && form.get('code').touched">Ingrese un valor valido</div>
        </div>

        <div class="scp-group">
            <label class="primaryColor">Descripcion</label>
            <input name="description" formControlName="description" class="form-control" placeholder="Descripcion">
            <div class="invalid-text" *ngIf="form.get('description').invalid && form.get('description').touched">Ingrese un valor valido</div>
        </div>
     
        <div *ngIf="motifId != 0" class="scp-group-checkbox">
            <label class="primaryColor">Activo</label>
            <div class="scp-container-checkbox" matRipple [matRippleCentered]="true" [matRippleRadius]="40">
                <div class="scp-checkbox">
                    <input id="check" formControlName="active" type="checkbox">
                    <label class="scp-label" for="check">
                        <mat-icon>done</mat-icon>
                    </label>
                </div>
            </div>
        </div>

        <div class="scp-group">
            <label class="primaryColor">Guardar</label>
            <div class="scp-container-button">
                <button type="reset" class="scp-button" mat-raised-button matTooltip="Guardar" mat-raised-button [disabled]="!form.valid" matTooltipPosition="right" matTooltipClass="tooltip" (click)="save()">
                    <mat-icon>save</mat-icon>
                </button>
            </div>
        </div>
    </form>
</div>