import { Component, OnInit } from "@angular/core";
import { AlertService } from "src/app/shared/services/alert.service";
import { consumoFsmService } from "../../services/consumoFsm.Service";

@Component({
    selector: 'app-consumoFsm',
    templateUrl: './consumoFsm.component.html',
    styleUrls: ['./consumoFsm.component.css']
})
export class ConsumoFsmComponent implements OnInit{
ngOnInit(): void {
    
}
}