<div mat-dialog-content>
    <div class="cyclic-container-button">
        <button mat-icon-button (click)="close()">
            <mat-icon>clear</mat-icon>
        </button>
    </div>
    <p class="title">Listado de Usuarios</p>
    <div class="cyclic-container-search">
        <div class="cyclic-group">
            <input type="text" class="form-control" placeholder="Filtrar" (keyup)="filter($event)">
            <mat-icon class="blue-color">search</mat-icon>
        </div>
    </div>
    <table #table class="cyclic-table" mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="person">
            <th mat-header-cell *matHeaderCellDef>Nombres</th>
            <td mat-cell *matCellDef="let item">{{item.person}}</td>
        </ng-container>
        <ng-container matColumnDef="Asignar">
            <th mat-header-cell *matHeaderCellDef>Asignar</th>
            <td mat-cell *matCellDef="let item;let i=index">
                <div class="cyclic-container-checkbox" matRipple [matRippleCentered]="true" [matRippleRadius]="40">
                    <div class="cyclic-checkbox">
                        <input [id]="i" type="checkbox" [checked]="item.active" (click)="checked($event.srcElement,item.personId)">
                        <label class="cyclic-label" [for]="i">
                            <mat-icon>done</mat-icon>
                        </label>
                    </div>
                </div>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns;"></tr>
    </table>
    <div *ngIf="loading" class="cyclic-container-loader">
        <div class="cyclic-loader"></div>
    </div>
    <mat-paginator [pageSizeOptions]="[5, 10, 15, 20, 30, 40, 50, 100]" showFirstLastButtons></mat-paginator>
</div>