<div mat-dialog-content>
    <div class="cyclic-container-button">
        <button mat-icon-button (click)="close()">
            <mat-icon>clear</mat-icon>
        </button>
    </div>
    <p class="title">Detalle de Conteos</p>
    <table #table class="cyclic-table" mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="coutingType">
            <th mat-header-cell *matHeaderCellDef>Tipo Conteo</th>
            <td mat-cell *matCellDef="let item">{{item.coutingType}}</td>
        </ng-container>
        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>Estado</th>
            <td mat-cell *matCellDef="let item">{{item.status}}</td>
        </ng-container>
        <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef>Tipo</th>
            <td mat-cell *matCellDef="let item">{{item.type}}</td>
        </ng-container>
        <ng-container matColumnDef="quantity">
            <th mat-header-cell *matHeaderCellDef>Cantidad</th>
            <td mat-cell *matCellDef="let item">{{item.quantity}}</td>
        </ng-container>
        <ng-container matColumnDef="startDate">
            <th mat-header-cell *matHeaderCellDef>Fecha Inicio</th>
            <td mat-cell *matCellDef="let item">{{item.startDate}}</td>
        </ng-container>
        <ng-container matColumnDef="endDate">
            <th mat-header-cell *matHeaderCellDef>Fecha Fin</th>
            <td mat-cell *matCellDef="let item">{{item.endDate}}</td>
        </ng-container>
        <ng-container matColumnDef="assistant">
            <th mat-header-cell *matHeaderCellDef>Auxiliar</th>
            <td mat-cell *matCellDef="let item">{{item.assistant}}</td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns;"></tr>
    </table>
    <div *ngIf="loading" class="cyclic-container-loader">
        <div class="cyclic-loader"></div>
    </div>
</div>