import { GenCustomerService } from 'src/app/appGeneral/services/genCustomer.service';
import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { ConfirmationComponent } from "src/app/shared/components/confirmation/confirmation.component";
import { AlertService } from "src/app/shared/services/alert.service";
import { GenPersonEntity } from "src/app/appGeneral/entities/genPerson.entity";
import { MatPaginator } from "@angular/material/paginator";
import { LoadClientService } from "../../services/loadClient.service";
import { GenCustomerEntity } from 'src/app/appGeneral/entities/genCustomer.entity';
import { GenPersonCountryService } from 'src/app/appGeneral/services/genPersonCountry.service';
import { GenCountryEntity } from 'src/app/appGeneral/entities/genCountry.entity';
import { LoadPersonCustomerService } from '../../services/loadPersonCustomer.service';
import { LoadClientPersonHistoryService } from '../../services/loadClientPersonHistory.service';

@Component({
  selector: 'app-loadClient',
  templateUrl: './loadClient.component.html',
  styleUrls: ['./loadClient.component.css']
})

export class loadClientPComponent implements OnInit {
  uploading: boolean;
  loading: boolean;
  columns: string[];
  fileList: File[];
  header: string[];
  dataSource: MatTableDataSource<any>;
  public listCustomers: GenCustomerEntity[];
  private _alertService: AlertService
  @ViewChild('paginator') paginator: MatPaginator;
  person: GenPersonEntity;
  loadId: number;
  countryId:number;
  customer: GenCustomerEntity;
  customerList: GenCustomerEntity[];
  countryList:GenCountryEntity[];
  @Inject(MAT_DIALOG_DATA) public data: any

  constructor(private loadClientS: LoadClientService, private alertS: AlertService, private dialog: MatDialog,private loadCustomerPersoS:LoadPersonCustomerService, private loadHistoryS:LoadClientPersonHistoryService) {
    this.loading = false;
    this.columns = ['CLIENTE','SERIAL EQUIPO', 'CODIGO SAP', 'ESTADO', 'TIPOLOGIA'];
    this.header = ['cliente','serial_EQUIPO','codigo_SAP','estado','tipologia'];
    this.dataSource = new MatTableDataSource([]);
    this.loadId = 0;
    this.uploading = false;
    this.fileList = [];
    this.customer = new GenCustomerEntity();
    this.countryId = 0;
    this.person = new GenPersonEntity();
  }
  ngOnInit(): void {
    this.loading = true;
    this.person = JSON.parse(localStorage.getItem('user'));
    this.loadCustomerPersoS.findCustomerByPersonId(Number(JSON.parse(localStorage.getItem("user"))["id"])).subscribe(res =>{
      if (res.message === 'OK'){
        this.customer = res.object;
        this.loadClientS.list(this.customer.id).subscribe(resL => {
              if (resL.message === 'OK') {
                this.dataSource = new MatTableDataSource(resL.object);
                this.dataSource.paginator = this.paginator;
                this.loading = false;
              } else {
                this.alertS.open(resL.message, 'error');
                this.uploading = false;
              }
            }, err => {
              this.alertS.open(err.message, 'error');
              this.uploading = false;
            });
      } else {this.alertS.open(res.message, 'error');}
    },err =>{
      this.alertS.open(err.message, 'error');
    })
  }
  load(file: FileList) {
    this.loadId = Math.round(Math.random()*20);
    if (file.length > 0) {
      this.uploading = true;
      this.loadClientS.create(this.customer.description,this.customer.id, this.loadId, file[0]).subscribe(resC => {
        if (resC.message === 'OK') {
          if (resC.object != 0) {
            this.alertS.open('Archivo cargado!', 'success');
            this.create();
            this.loading = true;
            this.uploading = false;
            this.loadClientS.list(this.customer.id).subscribe(resL => {
              if (resL.message === 'OK') {
                this.dataSource = new MatTableDataSource(resL.object);
                this.dataSource.paginator = this.paginator;
                this.loading = false;
                this.uploading = false;
              } else {
                this.alertS.open(resL.message, 'error');
                this.uploading = false;
              }
            }, err => {
              this.alertS.open(err.message, 'error');
              this.uploading = false;
            });
          } else {
            this.alertS.open('Error al cargar el archivo!', 'error');
            this.uploading = false;
          }
        } else {
          this.alertS.open(resC.message, 'error');
          this.uploading = false;
        }
      }, err => {
        this.alertS.open('El archivo no es valido, por favor verificar espacios en blanco', 'error');
        this.uploading = false;
      
      });
      (document.getElementById('serials')as HTMLInputElement).value=null;
    }
  }
  delete() {
    this.dialog.open(ConfirmationComponent, {
      data: { message: '¿Desea eliminar la carga?' },
      width: '400px',
      height: '250px'
    }).afterClosed().subscribe(resA => {
      if (resA) {
        this.loadClientS.delete(this.customer.id).subscribe(resD => {
          if (resD.message === 'OK') {
            if (resD.object != 0) {
              this.alertS.open('Carga eliminada!', 'success');
              this.getData();
            } else {
              this.alertS.open('Error al eliminar la carga!', 'error');
            }
          } else {
            this.alertS.open(resD.message, 'error');
          }
        }, err => {
          this.alertS.open(err.message, 'error');
        });
      }
    });
  }

  loadFile() {
    this.loading = true;
    this.loadClientS.loadFile(this.fileList).subscribe(res => {
      if (res.message === 'OK') {

        if (res.object != 0) {
          this.fileList = [];
        } else {
          this.alertS.open('Error al cargar el archivo!', 'error');
        }
      } else {
        this.alertS.open(res.message, 'error');
      }
    }, err => {
      this.alertS.open(err.message, 'error');
    });
  }
  getToday(): string {
    return new Date().toISOString().split('T')[0]
  }



  getData() {
    this.loading = true;
    this.loadClientS.list(this.customer.id).subscribe(resL => {
      if (resL.message === 'OK') {
        this.dataSource = new MatTableDataSource(resL.object);
        this.dataSource.paginator = this.paginator;
        this.loading = false;
      } else {
        this.alertS.open(resL.message, 'error');
      }
    }, err => {
      this.alertS.open(err.message, 'error');
    });
  }

  download() {
    let delimiter = "\t";
    let headers = '';
    let file = '';

    for (let i = 0; i < this.columns.length; i++) {
      headers = headers + this.columns[i];
      if (i < this.columns.length - 1) {
        headers = headers + delimiter;
      }
    }
    file = headers;
    for (let i = 0; i < this.dataSource.data.length; i++) {
      file = file + "\n";
      for (let j = 0; j < this.header.length; j++) {
        file = file + this.dataSource.data[i][this.header[j]];
        file = file + delimiter;
      }
    }
    let blob = new Blob(['\ufeff' + file], { type: 'text/csv;charset=utf-8;' });
    let url = URL.createObjectURL(blob);
    let downloadLink = document.createElement("a");
    downloadLink.setAttribute("href", url);
    downloadLink.setAttribute("download", "Listado.csv");
    downloadLink.style.visibility = "hidden";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }
  
  create(){
    this.loadHistoryS.create(this.person.id, this.customer.id).subscribe(res=>{
      if(res.message==='OK'){
        if(res.object !=0){
          this.alertS.open('Registro creado','success');
        }else{
          this.alertS.open(res.message,'error');
        }
      }else{
        this.alertS.open(res.message,'error');
      }        
    },err=>{
      this.alertS.open(err.message,'error');
    });

  }
  
}

