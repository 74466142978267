export class ProAdvanceEntity{
    id:number;
    description:string;
    activityId:number;
    activity:string;
    creationDate:string;
    creationUserId:number;
    creationUser:string;

    constructor(){}
}