<div class="cyclic-container">
    <p class="title">Cargar Bases</p>
    <div class="cyclic-form">
        <div class="cyclic-group">
            <label class="primaryColor">Cliente</label>
            <select [(ngModel)]="customer" class="form-control" (change)="getRecords()">
                <option class="ligthColor" value="0">Seleccione un cliente</option>
                <option *ngFor="let c of customerList" value="{{c.customer}}">{{c.customer}}</option>
            </select>
        </div>
        <input #file hidden type="file" (change)="loadFile($event.target.files)">
        <button class="blue" mat-mini-fab matTooltip="Cargar Archivo" matTooltipPosition="right"
            matTooltipClass="tooltip" (click)="file.click()">
            <mat-icon>backup</mat-icon>
        </button>
    </div>
    <button fxFlexAlign="flex-end" class="red" mat-mini-fab matTooltip="Eliminar Registros" matTooltipPosition="left" matTooltipClass="tooltip"
        (click)="deleteRecords()">
        <mat-icon>delete</mat-icon>
    </button>
    <table #table class="cyclic-table" mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="serial">
            <th mat-header-cell *matHeaderCellDef>Serial</th>
            <td mat-cell *matCellDef="let item">{{item.serial}}</td>
        </ng-container>
        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>Estado</th>
            <td mat-cell *matCellDef="let item">{{item.status}}</td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns;"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="[15, 20, 30, 40, 50, 100]" showFirstLastButtons></mat-paginator>
    <div *ngIf="loading" class="cyclic-container-loader">
        <div class="cyclic-loader"></div>
    </div>
</div>