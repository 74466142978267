<div class="fileGroup-container">
    <p class="title">{{title}}</p>
    <form [formGroup]="form" class="fileGroup-form">
        <div class="fileGroup-group">
            <label class="primaryColor">Nombre</label>
            <input formControlName="name" type="text" class="form-control" placeholder="Nombre">
            <div class="invalid-text" *ngIf="form.get('name').invalid && form.get('name').touched">Ingrese un nombre valido</div>
        </div>
        <div class="fileGroup-group">
            <label class="primaryColor">Descripción</label>
            <!--<textarea formControlName="description" class="form-control" placeholder="Descripción"></textarea>-->
            <input formControlName="description" type="text" class="form-control" placeholder="Descripción">
            <div class="invalid-text" *ngIf="form.get('description').invalid && form.get('description').touched">Ingrese una descripcion valia</div>
        </div>
        <div *ngIf="fileGroupId != 0" class="fileGroup-group-checkbox">
            <label class="primaryColor">Activo</label>
            <div class="fileGroup-container-checkbox" matRipple [matRippleCentered]="true" [matRippleRadius]="40">
                <div class="fileGroup-checkbox">
                    <input id="check" formControlName="active" type="checkbox">
                    <label class="fileGroup-label" for="check">
                        <mat-icon>done</mat-icon>
                    </label>
                </div>
            </div>
        </div>
        <div class="fileGroup-container-button">
            <button class="fileGroup-button" mat-raised-button matTooltip="Guardar" matTooltipPosition="right" matTooltipClass="tooltip" [disabled]="!form.valid" (click)="onClick()">
                <mat-icon>save</mat-icon>
            </button>
        </div>
    </form>
</div>