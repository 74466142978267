<div mat-dialog-content>
    <div class="bsc-container-button">
        <button mat-icon-button (click)="close(false)">
            <mat-icon>clear</mat-icon>
        </button>
    </div>
    <p class="title">{{title}}</p>
    <form [formGroup]="form" class="bsc-form">
        <div class="bsc-group">
            <label class="primaryColor">Nombre</label>
            <input formControlName="name" type="text" class="form-control" placeholder="Nombre">
        </div>
        <div class="bsc-group">
            <label class="primaryColor">Entregables</label>
            <input formControlName="deliverables" type="text" class="form-control" placeholder="Entregables">
        </div>
        <div class="bsc-group">
            <label class="primaryColor">Fecha de Finalizacion</label>
            <div class="bsc-form-control">
                <input formControlName="endDate" matInput [matDatepicker]="endDate" disabled class="form-control">
                <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
                <mat-datepicker #endDate disabled="false"></mat-datepicker>
            </div>
        </div>
        <div class="bsc-group">
            <label class="primaryColor">Usuario Responsable</label>
            <select formControlName="responsibleUserId" class="form-control">
                <option class="ligthColor" value="">Seleccione un usuario responsable</option>
                <option *ngFor="let r of responsibleUserList" value="{{r.id}}">{{r.firstName}} {{r.lastName}}</option>
            </select>
        </div>
        <button mat-mini-fab class="bsc-float-button" matTooltip="Guardar" matTooltipPosition="left" matTooltipClass="tooltip" [disabled]="!form.valid" (click)="save()">
            <mat-icon>save</mat-icon>
        </button>
    </form>
</div>