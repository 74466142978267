export class LoadClientFieldsEntity{

    id:number;
    name:string;
    codigo:string;
    parametrizationId:number;
    active:boolean;
    userId:number;
    updateUserId:number;
    creationDate:string;
    updateDate:string;
    constructor (){}
}
