<div mat-dialog-content>
    <div class="close" fxLayout fxLayoutAlign="flex-end">
        <button mat-icon-button (click)="close(false)">
            <mat-icon>clear</mat-icon>
        </button>
    </div>
    <p class="title">Detalle Prueba N°3</p>
    <div class="act-detail-container">
        <div *ngFor="let c  of covFormEntity">
            <div class="act-detail-card">
                <div class="act-detail-content-icon">
                    <mat-icon [ngStyle]="{'color':'#DADA22'}">event</mat-icon>
                </div>
                <div class="act-detail-content">
                    <p class="act-detail-title">Fecha de Prueba</p>
                    <p class="act-detail-subtitle">{{c.dateThirdProof}}</p>
                </div>
                <div class="act-detail-content-icon">
                    <mat-icon [ngStyle]="{'color':'#DF9D1B'}">calendar_today</mat-icon>
                </div>
                <div class="act-detail-content">
                    <p class="act-detail-title">Fecha de ejecucion</p>
                    <p class="act-detail-subtitle">{{c.dateExecutionThirdProof}}</p>
                </div>
            </div>
             <div class="act-detail-card">
                <div class="act-detail-content-icon">
                    <mat-icon [ngStyle]="{'color':'#1BD6DF'}">fact_check</mat-icon>
                </div>
                <div class="act-detail-content">
                    <p class="act-detail-title">Resultado de prueba</p>
                    <p class="act-detail-subtitle">{{c.resultThirdProof}}</p>
                </div>
                <div class="act-detail-content-icon">
                    <mat-icon [ngStyle]="{'color':'#550958'}">date_range</mat-icon>
                </div>
                <div class="act-detail-content">
                    <p class="act-detail-title">Fecha de notificacion</p>
                    <p class="act-detail-subtitle">{{c.dateNotificationResultThird}}</p>
                </div>
            </div>
            <div *ngIf="loading" class="act-detail-container-loader">
                <div class="act-detail-loader"></div>
            </div>
        </div>
    </div>
</div>