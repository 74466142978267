import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { RepReportListComponent } from "../appReport/components/administration/report/repReportList.component";
import { RepReportComponent } from "../appReport/components/report/repReport.component";
import { AuthGuardService } from "../shared/services/authGuard.service";
import { LoadValidationMilicomComponent } from "./components/LoadValidationMilicom/LoadValidationMilicom.component";
import { miliPrincipalComponent } from "./components/principal/miliprincipal.component";


const routes: Routes = [
    {
      path: 'milicom', component: miliPrincipalComponent,canActivate:[AuthGuardService],
      children:[
        {path:'validation/load',component:LoadValidationMilicomComponent},
        {path:'report/reportId/:reportId',component:RepReportComponent},
        //{path:'NombrePath',component:NombreComponente},
      ]
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class AppMilicomLoadValidationRoutingModule { }
  