export class InvGeneraSerialEntity{

    id:number;
    serial:string;
    typeId:number;
    creationDate:string;
    userId:number;
    active:boolean;

    constructor (){}
}