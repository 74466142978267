<div class="pqr-container">
    <p class="title">Consultar PQRS</p>
    <div class="pqr-form">
        <div class="pqr-group">
            <label class="primaryColor">Consultar por:</label>
            <select [(ngModel)]="searchType" class="form-control">
                <option class="ligthColor" value="">Seleccione una opción</option>
                <option value="Numero de identificacion">Número de cédula</option>
                <option value="Numero de ticket">Número de ticket</option>
                <option value="Numero de PQRS">Número de PQRS</option>
                <option value="Serial/Imei">Serial/Imei</option>
                <option value="Nombre de cliente / solicitante">Nombre de cliente / solicitante</option>
            </select>
        </div>
        <div *ngIf="searchType != ''" class="pqr-group">
            <label class="primaryColor">{{searchType}}</label>
            <input [(ngModel)]="searchData" type="text" class="form-control" placeholder="{{searchType}}">
        </div>
        <div class="pqr-container-button">
            <button class="pqr-button" [disabled]="searchType === ''" mat-raised-button matTooltip="Buscar"
                matTooltipPosition="right" matTooltipClass="tooltip" (click)="search()">
                <mat-icon>search</mat-icon>
            </button>
        </div>
    </div>
    <div *ngIf="loadingPqrs" class="pqr-container-loader">
        <div class="pqr-loader"></div>
    </div>
    <div *ngIf="pqrsList.length > 0 && !loadingPqrs" class="pqr-content">
        <div *ngFor="let p of pqrsList" class="pqr-card" (click)="detail(p.number,p.ticket,p.creationDate)">
            <div (click)="tracing(p.number)"></div>
            <p class="pqr-card-title">Trámite</p>
            <p class="pqr-card-content">{{p.type}}</p>
            <p class="pqr-card-title">Ticket</p>
            <p class="pqr-card-content">{{p.ticket}}</p>
            <p class="pqr-card-title">Número PQRS</p>
            <p class="pqr-card-content">{{p.number}}</p>
            <p class="pqr-card-title">Estado</p>
            <p class="pqr-card-content">{{p.status}}</p>
        </div>
    </div>
    <p *ngIf="tracingList.length > 0" class="title" [ngStyle]="{'text-align':'center'}">Seguimiento PQRS</p>
    <div *ngIf="loadingTracing" class="pqr-container-loader">
        <div class="pqr-loader"></div>
    </div>
    <div *ngIf="tracingList.length > 0 && !loadingTracing" class="pqr-container-tracing">
        <ng-container *ngFor="let t of tracingList">
            <div class="pqr-tracking-content">
                <div class="pqr-tracking-circle" matTooltip="{{t.observations}}" matTooltipPosition="above"
                    matTooltipClass="tooltip">
                    <mat-icon>done</mat-icon>
                </div>
                <div class="pqr-tracking-information">
                    <span class="pqr-tracking-title">{{t.eventStatus}}</span>
                    <span class="pqr-tracking-subtitle">{{t.user}}</span>
                    <span class="pqr-tracking-subtitle">{{t.date}}</span>
                </div>
                <div class="pqr-tracking-line"></div>
            </div>
        </ng-container>
    </div>
    <p *ngIf="pqrPqrsEntity!=null" class="title" [ngStyle]="{'text-align':'center'}">Detalle PQRS</p>
    <div *ngIf="pqrPqrsEntity!=null" class="pqr-detail-container">
        <div class="detail-card">
            <div class="detail-content-icon">
                <mat-icon [ngStyle]="{'color':'#f44336'}">local_offer</mat-icon>
            </div>
            <div class="detail-content">
                <p class="detail-title">Ticket</p>
                <p class="detail-subtitle">{{pqrPqrsEntity.ticket}}</p>
            </div>
            <div class="detail-content-icon">
                <mat-icon [ngStyle]="{'color':'#2196f3'}">format_list_numbered</mat-icon>
            </div>
            <div class="detail-content">
                <p class="detail-title">Número</p>
                <p class="detail-subtitle">{{pqrPqrsEntity.number}}</p>
            </div>
        </div>
        <div class="detail-card">
            <div class="detail-content-icon">
                <mat-icon [ngStyle]="{'color':'#ff9800'}">account_box</mat-icon>
            </div>
            <div class="detail-content">
                <p class="detail-title">Usuario de creación</p>
                <p class="detail-subtitle">{{pqrPqrsEntity.creationPerson}}</p>
            </div>
            <div class="detail-content-icon">
                <mat-icon [ngStyle]="{'color':'#00e5ff'}">date_range</mat-icon>
            </div>
            <div class="detail-content">
                <p class="detail-title">Fecha de Reporte</p>
                <p class="detail-subtitle">{{pqrPqrsEntity.creationDate}}</p>
            </div>
        </div>
        <div class="detail-card">
            <div class="detail-content-icon">
                <mat-icon [ngStyle]="{'color':'#673ab7'}">airplanemode_active</mat-icon>
            </div>
            <div class="detail-content">
                <p class="detail-title">País</p>
                <p class="detail-subtitle">{{pqrPqrsEntity.country}}</p>
            </div>
            <div class="detail-content-icon">
                <mat-icon [ngStyle]="{'color':'#ff80ab'}">flight_takeoff</mat-icon>
            </div>
            <div class="detail-content">
                <p class="detail-title">Departamento</p>
                <p class="detail-subtitle">{{pqrPqrsEntity.department}}</p>
            </div>
        </div>
        <div class="detail-card">
            <div class="detail-content-icon">
                <mat-icon [ngStyle]="{'color':'#00e676'}">flight_land</mat-icon>
            </div>
            <div class="detail-content">
                <p class="detail-title">Ciudad</p>
                <p class="detail-subtitle">{{pqrPqrsEntity.city}}</p>
            </div>
            <div class="detail-content-icon">
                <mat-icon [ngStyle]="{'color':'#009688'}">grading</mat-icon>
            </div>
            <div class="detail-content">
                <p class="detail-title">Seriales</p>
                <p class="detail-subtitle">{{pqrPqrsEntity.serial}}</p>
            </div>
        </div>
        <div class="detail-card">

            <div class="detail-content-icon">
                <mat-icon [ngStyle]="{'color':'#607d8b'}">assignment</mat-icon>
            </div>
            <div *ngIf="pqrPqrsEntity.summary == null; else notequal " class="detail-content">
                <p class="detail-title">Resumen de la Solicitud</p>
                <p class="detail-subtitle">{{pqrPqrsEntity.observation}}</p>
            </div>
            <ng-template #notequal>
                <div class="detail-content">
                    <p class="detail-title">Resumen de la Solicitud</p>
                    <p class="detail-subtitle">{{pqrPqrsEntity.summary}}</p>
                </div>
            </ng-template>
            <div class="detail-content-icon">
                <mat-icon [ngStyle]="{'color':'#2196f3'}">assignment</mat-icon>
            </div>
            <div class="detail-content">
                <p class="detail-title">Observaciones Woden</p>
                <p class="detail-subtitle">{{pqrPqrsEntity.observations}}</p>
            </div>
        </div>
        <div class="detail-card">

            <div class="detail-content-icon">
                <mat-icon [ngStyle]="{'color':'#0B2387'}">text_snippet</mat-icon>
            </div>
            <div class="detail-content">
                <p class="detail-title">Archivos apertura</p>
                <div class="detail-container-file">
                    <p *ngIf="fileList.length===0" class="detail-subtitle"></p>
                    <div *ngFor="let f of fileList;" class="detail-content-file" matTooltip="{{f.name}}"
                        matTooltipPosition="above" matTooltipClass="tooltip">
                        <p>{{f.type}}</p>
                        <button mat-icon-button matTooltip="Descargar" matTooltipPosition="right"
                            matTooltipClass="tooltip" (click)="downloadFile(f)">
                            <mat-icon class="blue-color">cloud_download</mat-icon>
                        </button>
                    </div>
                    <p *ngIf="fileStartList.length ===0" class="detail-subtitle"></p>
                    <div *ngFor="let f of fileStartList;" class="detail-content-file" matTooltip="{{f.name}}"
                        matTooltipPosition="above" matTooltipClass="tooltip">
                        <p>{{f.type}}</p>
                        <button mat-icon-button matTooltip="Descargar" matTooltipPosition="right"
                            matTooltipClass="tooltip" (click)="downloadFile(f)">
                            <mat-icon class="blue-color">cloud_download</mat-icon>
                        </button>
                    </div>
                </div>
            </div>

            <div class="detail-content-icon">
                <mat-icon [ngStyle]="{'color':'#2196f3'}">date_range</mat-icon>
            </div>
            <div class="detail-content">
                <p class="detail-title">Archivos cierre</p>
                <div class="detail-container-file">
                    <p *ngIf="fileEndList.length===0" class="detail-subtitle">Sin Archivos</p>
                    <div *ngFor="let f of fileEndList;" class="detail-content-file" matTooltip="{{f.name}}"
                        matTooltipPosition="above" matTooltipClass="tooltip">
                        <p>{{f.type}}</p>
                        <button mat-icon-button matTooltip="Descargar" matTooltipPosition="right"
                            matTooltipClass="tooltip" (click)="downloadFile(f)">
                            <mat-icon class="blue-color">cloud_download</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>