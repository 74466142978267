<div class="actfix-container">
    <p class="title">{{title}}</p>
    <form [formGroup]="form" class="actfix-form">
        <div class="actfix-group">
            <label class="primaryColor">Nombre</label>
            <input name="name" formControlName="name" class="form-control text-may" placeholder="Nombre">
            <div class="invalid-text" *ngIf="form.get('name').invalid && form.get('name').touched">Ingrese un nombre valido</div>
        </div>
     
        <div class="actfix-group">
            <label class="primaryColor">Descripcion</label>
            <input formControlName="description" type="text" class="form-control text-may" placeholder="Descripcion">
            <div class="invalid-text" *ngIf="form.get('description').invalid && form.get('description').touched">Ingrese una descripcion valida</div>
        </div>
        <div class="actfix-group">
            <label class="primaryColor">Tipo de serial</label>
            <select class="form-control"  formControlName="typeEntry" name="typeEntry" required>
                <option class="ligthColor" value="">Selecciona una opcion</option>
                <option value="SERIAL">SERIAL</option>
                <option value="TEXTO">TEXTO</option>
            </select>
            <div class="invalid-text" *ngIf="form.get('typeEntry').invalid && form.get('typeEntry').touched">Seleccione una opcion valida</div>
        </div>  
        <div *ngIf="ActFixformId != 0" class="actfix-group-checkbox">
            <label class="primaryColor">Activo</label>
            <div class="actfix-container-checkbox" matRipple [matRippleCentered]="true" [matRippleRadius]="40">
                <div class="actfix-checkbox">
                    <input id="check" formControlName="active" type="checkbox">
                    <label class="actfix-label" for="check">
                        <mat-icon>done</mat-icon>
                    </label>
                </div>
            </div>
        </div>
        <!--<div>
            <webcam [height]="400" 
                    [width]="1000" 
                    [trigger]="triggerObservable"
                        (imageCapture)="handleImage($event)">
             </webcam>
              
            <button class="actionBtn" (click)="triggerSnapshot();">
                Click Here and take the Shot</button>
              
            <div class="snapshot" *ngIf="webcamImage">
              <h2>Here is your image!</h2>
              <img [src]="webcamImage.imageAsDataUrl"/>
            </div>
            </div>-->
        <div class="actfix-group">
            <label class="primaryColor">Guardar</label>
            <div class="actfix-container-button">
                <button type="reset" class="actfix-button" mat-raised-button matTooltip="Guardar" mat-raised-button [disabled]="!form.valid" matTooltipPosition="right" matTooltipClass="tooltip" (click)="save()">
                    <mat-icon>save</mat-icon>
                </button>
            </div>
        </div>
    </form>
</div>