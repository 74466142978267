<div class="report-container">
    <p class="title">Conteo General</p>
    <form [formGroup]="form">
        <div class="report-form">
            <div class="report-control-group">
                <label class="primaryColor">Conteo</label>
                <select class="form-control" [(ngModel)]="countingType" name="countingType" formControlName="countingType">
                    <option class="ligthColor" value=null>Seleccione un conteo</option>
                    <option>Primer conteo</option>
                    <option>Segundo conteo</option>
                    <option>Tercer conteo</option>
                </select>
            </div>
    
            <div class="report-control-group">
                <label class="primaryColor"></label>
                <br>
                <button class="inv-button" mat-raised-button matTooltip="Iniciar conteo" [disabled]="countingType === null"(click)="init()">
                    <mat-icon>forward</mat-icon>
                </button>
            </div>
        </div>
        <div *ngIf="this.inventory">
        <div class="report-form">
            <div class="report-control-group">
                <label class="primaryColor">Almacen</label>
                <select [(ngModel)]="goodDeft" class="form-control" name="goodDeft" formControlName="goodDeft" (change)="location()">
                    <option class="ligthColor" value=''>Seleccione un almacen</option>
                    <option *ngFor="let c of storeList"  value="{{c.warehouse}}">{{c.warehouse}}</option>
                </select>
            </div>
            <div class="report-control-group">
                <label class="primaryColor">Ubicacion</label>
                <select [(ngModel)]="locationNumber" class="form-control" name="parameterizationId" formControlName="parameterizationId">
                    <option class="ligthColor" value=null>Seleccione una ubicacion</option>
                    <option *ngFor="let c of locationList"  value="{{c.location}}">
                        {{c.location}}</option>
                </select>
            </div>
            <div class="report-control-group">
                <input [(ngModel)]="partNumbers" name="partNumbers" formControlName="store" type="text" class="form-control" disabled placeholder="Seleccionar part number">
                <button mat-mini-fab class="green" matTooltip="Part Number" matTooltipPosition="above"
                    matTooltipClass="tooltip" (click)="getPartNumber()">
                    <mat-icon>add</mat-icon>
                </button>
            </div>
            <!--
            <div class="report-control-group">
                <label class="primaryColor">PN</label>
                <select class="form-control" name="store" formControlName="store">
                    <option class="ligthColor" value=null>Seleccione un PN</option>
                    <option *ngFor="let c of partNumberList"  value="{{c.partNumber}}">{{c.partNumber}}</option>
                </select>
            </div>
            -->
            <div *ngIf="this.loadingSave == false ; else notCro" class="report-control-group">
                <label class="primaryColor"></label>
                <br>
                <button class="inv-button" disabled mat-raised-button matTooltip="Iniciar conteo" (click)="save()">
                    <mat-icon>save</mat-icon>
                </button>
            </div>
            <ng-template class="report-control-group" #notCro>
                <label class="primaryColor"></label>
                <br>
                <button class="inv-button" mat-raised-button matTooltip="Iniciar conteo" (click)="save()">
                    <mat-icon>save</mat-icon>
                </button>
            </ng-template>
        </div>
        </div>
    </form>
    <div *ngIf="this.inventory">
    <div class="inv-container-button">
        <div class="inv-group">
            <input type="text" class="form-control" placeholder="Filtrar" (keyup)="filter($event)">
            <mat-icon class="blue-color">search</mat-icon>
        </div>
    </div>
    <table #table class="inv-table" mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="countingType">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Tipo de conteo</th>
            <td mat-cell *matCellDef="let item">{{item.countingType}}</td>
        </ng-container>
        <ng-container matColumnDef="goodDeft">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Almacen</th>
            <td mat-cell *matCellDef="let item">{{item.goodDeft}}</td>
        </ng-container>
        <ng-container matColumnDef="tipo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Part Number</th>
            <td mat-cell *matCellDef="let item">{{item.tipo}}</td>
        </ng-container>
        <ng-container matColumnDef="parametrizacion">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Ubicacion</th>
            <td mat-cell *matCellDef="let item">{{item.parametrizacion}}</td>
        </ng-container>
        <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Tipo de inventario</th>
            <td mat-cell *matCellDef="let item">{{item.type}}</td>
        </ng-container>
        <ng-container matColumnDef="userName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Usuario de creacion</th>
            <td mat-cell *matCellDef="let item">{{item.userName}}</td>
        </ng-container>
        <ng-container matColumnDef="creationDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha de registro</th>
            <td mat-cell *matCellDef="let item">{{item.creationDate}}</td>
        </ng-container>
        <ng-container matColumnDef="active">
            <th mat-header-cell *matHeaderCellDef>Activo</th>
            <td mat-cell *matCellDef="let item">
                <mat-icon *ngIf="item.active" class="green-color">done</mat-icon>
                <mat-icon *ngIf="!item.active" class="red-color">clear</mat-icon>
            </td>
        </ng-container>
        <ng-container matColumnDef="Acciones">
            <th mat-header-cell *matHeaderCellDef>Acciones</th>
            <td mat-cell *matCellDef="let item">
                <div *ngIf="item.type == 'Serializado'; else notEqual">
                    <button mat-icon-button matTooltip="Serializar" [disabled]="item.closeOpen == false" matTooltipPosition="above" matTooltipClass="tooltip"
                        (click)="serial(item.id)">
                        <mat-icon class="green-color">view_week</mat-icon>
                    </button>
                </div>
                <ng-template #notEqual>
                    <button mat-icon-button matTooltip="Conteo" [disabled]="item.closeOpen == false" matTooltipPosition="above" matTooltipClass="tooltip"
                        (click)="counting(item.id)">
                        <mat-icon class="blue-color">calculate</mat-icon>
                    </button>
                </ng-template>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns;"></tr>
    </table>
    <div *ngIf="loading" class="report-container-loader">
        <div class="report-loader"></div>
    </div>
    <mat-paginator [pageSizeOptions]="[5, 10, 15, 20, 30, 40, 50, 100]" showFirstLastButtons></mat-paginator>
</div>

</div>