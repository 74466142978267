import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadValidationMilicomComponent } from './components/LoadValidationMilicom/LoadValidationMilicom.component';
import { miliPrincipalComponent } from './components/principal/miliprincipal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../material.module';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTableModule } from '@angular/material/table';
import { AppMilicomLoadValidationRoutingModule } from './appMilicomLoadValidationRouting.module';
import { ArchiveConfigComponent } from './modals/archiveConfig/archiveConfig.component';
import { ValidateConfigComponent } from './modals/validateConfig/validateConfig.component';
import { HourConfigComponent } from './modals/hourConfig/hourConfig.component';

@NgModule({
  declarations: [
    miliPrincipalComponent,
    LoadValidationMilicomComponent,
    ArchiveConfigComponent,
    ValidateConfigComponent,
    HourConfigComponent,
  ],
  imports: [
    CommonModule,
    AppMilicomLoadValidationRoutingModule,
    ReactiveFormsModule,
    MaterialModule,
    FormsModule,
    MatIconModule,
    MatButtonModule,
    MatProgressBarModule,
    MatTableModule,
  ],schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class AppMilicomLoadValidationModule { }
