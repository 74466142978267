<div mat-dialog-content>
    <div class="label-container-button">
        <button mat-icon-button (click)="close()">
            <mat-icon>clear</mat-icon>
        </button>
    </div>
    <p class="title">Listado de Codigos Prn</p>
    <form [formGroup]="form" class="label-form">
        <div class="label-group">
            <label class="primaryColor">Nombre</label>
            <input formControlName="name" type="number" class="form-control" placeholder="Nombre">
        </div>
        <div class="label-group">
            <label class="primaryColor">Codigo Prn</label>
            <textarea formControlName="codePrn" rows="1" cols="1" class="form-control" placeholder="Codigo Prn"></textarea>
        </div>
        <button mat-mini-fab class="green" matTooltip="Guardar" matTooltipPosition="right" matTooltipClass="tooltip"
            (click)="onClick()">
            <mat-icon>add</mat-icon>
        </button>
    </form>
    <table class="label-table" mat-table [dataSource]="dataSource" #table>
        <ng-container matColumnDef="Id">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let item">
                <button *ngIf="item.id === editing" mat-icon-button matTooltip="Cerrar Edicion"
                    matTooltipPosition="above" matTooltipClass="tooltip" (click)="closeEditing()">
                    <mat-icon class="ligthColor">close</mat-icon>
                </button>
            </td>
        </ng-container>
        <ng-container matColumnDef="Nombre">
            <th mat-header-cell *matHeaderCellDef>Nombre</th>
            <td mat-cell *matCellDef="let item">{{item.name}}</td>
        </ng-container>
        <ng-container matColumnDef="Acciones">
            <th mat-header-cell *matHeaderCellDef>Acciones</th>
            <td mat-cell *matCellDef="let item">
                <button mat-icon-button matTooltip="Eliminar" matTooltipPosition="above" matTooltipClass="tooltip"
                    (click)="delete(item.id)">
                    <mat-icon class="red-color">delete</mat-icon>
                </button>
                <button mat-icon-button matTooltip="Editar" matTooltipPosition="above" matTooltipClass="tooltip"
                    (click)="edit(item.id,item.name,item.codePrn)">
                    <mat-icon class="blue-color">create</mat-icon>
                </button>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns;"></tr>
    </table>
</div>