<div class="fileL-container">
    <p class="title">Archivos</p>
    <br>
    <p class="title">{{this.statusCl}}</p>
    <div *ngIf="!loadingCl" class="fileL-container-loader">
        <div class="fileL-loader" [ngStyle]="{'border-top-color': '#0A2283','border-bottom-color':'#0A2283'}">
        </div>
    </div>
    <ng-container *ngIf="fileList.length>0 && loadingCl">
        <div class="fileL-container-files">
            <div *ngFor="let f of fileList" class="fileL-card-files" matTooltip="{{f.name}}" matTooltipPosition="above"
                matTooltipClass="tooltip">
                <p>{{f.type}}</p>
                <div class="fileL-card-actions">
                    <button mat-icon-button matTooltip="Descargar" matTooltipPosition="below" matTooltipClass="tooltip"
                        (click)="download(f)">
                        <mat-icon class="blue-color">cloud_download</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </ng-container>
    <p class="title">{{this.statusClS}}</p>
    <div *ngIf="!loadingClS" class="fileL-container-loader">
        <div class="fileL-loader" [ngStyle]="{'border-top-color': '#0A2283','border-bottom-color':'#0A2283'}">
        </div>
    </div>
    <ng-container *ngIf="fileListCLaroSmart.length>0 && loadingClS">
        <div class="fileL-container-files">
            <div *ngFor="let f of fileListCLaroSmart" class="fileL-card-files" matTooltip="{{f.name}}"
                matTooltipPosition="above" matTooltipClass="tooltip">
                <p>{{f.type}}</p>
                <div class="fileL-card-actions">
                    <button mat-icon-button matTooltip="Descargar" matTooltipPosition="below" matTooltipClass="tooltip"
                        (click)="download(f)">
                        <mat-icon class="blue-color">cloud_download</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </ng-container>
    <p class="title">{{this.statusClF}}</p>
    <div *ngIf="!loadingClF" class="fileL-container-loader">
        <div class="fileL-loader" [ngStyle]="{'border-top-color': '#0A2283','border-bottom-color':'#0A2283'}">
        </div>
    </div>
    <ng-container *ngIf="fileListClaroFont.length>0 && loadingClF">
        <div class="fileL-container-files">
            <div *ngFor="let f of fileListClaroFont" class="fileL-card-files" matTooltip="{{f.name}}"
                matTooltipPosition="above" matTooltipClass="tooltip">
                <p>{{f.type}}</p>
                <div class="fileL-card-actions">
                    <button mat-icon-button matTooltip="Descargar" matTooltipPosition="below" matTooltipClass="tooltip"
                        (click)="download(f)">
                        <mat-icon class="blue-color">cloud_download</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </ng-container>
    <p class="title">{{this.statusRe}}</p>
    <div *ngIf="!loadingRe" class="fileL-container-loader">
        <div class="fileL-loader" [ngStyle]="{'border-top-color': '#0A2283','border-bottom-color':'#0A2283'}">
        </div>
    </div>
    <ng-container *ngIf="fileListRed.length>0 && loadingRe">
        <div class="fileL-container-files">
            <div *ngFor="let f of fileListRed" class="fileL-card-files" matTooltip="{{f.name}}"
                matTooltipPosition="above" matTooltipClass="tooltip">
                <p>{{f.type}}</p>
                <div class="fileL-card-actions">
                    <button mat-icon-button matTooltip="Descargar" matTooltipPosition="below" matTooltipClass="tooltip"
                        (click)="download(f)">
                        <mat-icon class="blue-color">cloud_download</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </ng-container>
    <p class="title">{{this.statusPm}}</p>
    <div *ngIf="!loadingPm" class="fileL-container-loader">
        <div class="fileL-loader" [ngStyle]="{'border-top-color': '#0A2283','border-bottom-color':'#0A2283'}">
        </div>
    </div>
    <ng-container *ngIf="fileListPlataforma.length>0 && loadingPm">
        <div class="fileL-container-files">
            <div *ngFor="let f of fileListPlataforma" class="fileL-card-files" matTooltip="{{f.name}}"
                matTooltipPosition="above" matTooltipClass="tooltip">
                <p>{{f.type}}</p>
                <div class="fileL-card-actions">
                    <button mat-icon-button matTooltip="Descargar" matTooltipPosition="below" matTooltipClass="tooltip"
                        (click)="download(f)">
                        <mat-icon class="blue-color">cloud_download</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </ng-container>
    <p class="title">{{this.statusDr}}</p>
    <div *ngIf="!loadingDr" class="fileL-container-loader">
        <div class="fileL-loader" [ngStyle]="{'border-top-color': '#0A2283','border-bottom-color':'#0A2283'}">
        </div>
    </div>
    <ng-container *ngIf="fileListDirectv.length>0 && loadingDr">
        <div class="fileL-container-files">
            <div *ngFor="let f of fileListDirectv" class="fileL-card-files" matTooltip="{{f.name}}"
                matTooltipPosition="above" matTooltipClass="tooltip">
                <p>{{f.type}}</p>
                <div class="fileL-card-actions">
                    <button mat-icon-button matTooltip="Descargar" matTooltipPosition="below" matTooltipClass="tooltip"
                        (click)="download(f)">
                        <mat-icon class="blue-color">cloud_download</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </ng-container>
    <p class="title">{{this.statusDrS}}</p>
    <div *ngIf="!loadingDrs" class="fileL-container-loader">
        <div class="fileL-loader" [ngStyle]="{'border-top-color': '#0A2283','border-bottom-color':'#0A2283'}">
        </div>
    </div>
    <ng-container *ngIf="fileListDirectvSmart.length>0 && loadingDrs">
        <div class="fileL-container-files">
            <div *ngFor="let f of fileListDirectvSmart" class="fileL-card-files" matTooltip="{{f.name}}"
                matTooltipPosition="above" matTooltipClass="tooltip">
                <p>{{f.type}}</p>
                <div class="fileL-card-actions">
                    <button mat-icon-button matTooltip="Descargar" matTooltipPosition="below" matTooltipClass="tooltip"
                        (click)="download(f)">
                        <mat-icon class="blue-color">cloud_download</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </ng-container>
    <p class="title">{{this.statusTib}}</p>
    <div *ngIf="!loadingTib" class="fileL-container-loader">
        <div class="fileL-loader" [ngStyle]="{'border-top-color': '#0A2283','border-bottom-color':'#0A2283'}">
        </div>
    </div>
    <ng-container *ngIf="fileListTigo.length>0 && loadingTib">
        <div class="fileL-container-files">
            <div *ngFor="let f of fileListTigo" class="fileL-card-files" matTooltip="{{f.name}}"
                matTooltipPosition="above" matTooltipClass="tooltip">
                <p>{{f.type}}</p>
                <div class="fileL-card-actions">
                    <button mat-icon-button matTooltip="Descargar" matTooltipPosition="below" matTooltipClass="tooltip"
                        (click)="download(f)">
                        <mat-icon class="blue-color">cloud_download</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </ng-container>
    <p class="title">{{this.statusTiM}}</p>
    <div *ngIf="!loadingTiM" class="fileL-container-loader">
        <div class="fileL-loader" [ngStyle]="{'border-top-color': '#0A2283','border-bottom-color':'#0A2283'}">
        </div>
    </div>
    <ng-container *ngIf="fileListTigoM.length>0 && loadingTiM">
        <div class="fileL-container-files">
            <div *ngFor="let f of fileListTigoM" class="fileL-card-files" matTooltip="{{f.name}}"
                matTooltipPosition="above" matTooltipClass="tooltip">
                <p>{{f.type}}</p>
                <div class="fileL-card-actions">
                    <button mat-icon-button matTooltip="Descargar" matTooltipPosition="below" matTooltipClass="tooltip"
                        (click)="download(f)">
                        <mat-icon class="blue-color">cloud_download</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </ng-container>
    <p class="title">{{this.statusEt}}</p>
    <div *ngIf="!loadingEt" class="fileL-container-loader">
        <div class="fileL-loader" [ngStyle]="{'border-top-color': '#0A2283','border-bottom-color':'#0A2283'}">
        </div>
    </div>
    <ng-container *ngIf="fileListEtb.length>0 && loadingEt">
        <div class="fileL-container-files">
            <div *ngFor="let f of fileListEtb" class="fileL-card-files" matTooltip="{{f.name}}"
                matTooltipPosition="above" matTooltipClass="tooltip">
                <p>{{f.type}}</p>
                <div class="fileL-card-actions">
                    <button mat-icon-button matTooltip="Descargar" matTooltipPosition="below" matTooltipClass="tooltip"
                        (click)="download(f)">
                        <mat-icon class="blue-color">cloud_download</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </ng-container>
    <p class="title">{{this.statusHg}}</p>
    <div *ngIf="!loadingHg" class="fileL-container-loader">
        <div class="fileL-loader" [ngStyle]="{'border-top-color': '#0A2283','border-bottom-color':'#0A2283'}">
        </div>
    </div>
    <ng-container *ngIf="fileListHughes.length>0 && loadingHg">
        <div class="fileL-container-files">
            <div *ngFor="let f of fileListHughes" class="fileL-card-files" matTooltip="{{f.name}}"
                matTooltipPosition="above" matTooltipClass="tooltip">
                <p>{{f.type}}</p>
                <div class="fileL-card-actions">
                    <button mat-icon-button matTooltip="Descargar" matTooltipPosition="below" matTooltipClass="tooltip"
                        (click)="download(f)">
                        <mat-icon class="blue-color">cloud_download</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </ng-container>
    <p class="title">{{this.statusRT}}</p>
    <div *ngIf="!loadingRT" class="fileL-container-loader">
        <div class="fileL-loader" [ngStyle]="{'border-top-color': '#0A2283','border-bottom-color':'#0A2283'}">
        </div>
    </div>
    <ng-container *ngIf="fileListRadioTech.length>0 && loadingRT">
        <div class="fileL-container-files">
            <div *ngFor="let f of fileListRadioTech" class="fileL-card-files" matTooltip="{{f.name}}"
                matTooltipPosition="above" matTooltipClass="tooltip">
                <p>{{f.type}}</p>
                <div class="fileL-card-actions">
                    <button mat-icon-button matTooltip="Descargar" matTooltipPosition="below" matTooltipClass="tooltip"
                        (click)="download(f)">
                        <mat-icon class="blue-color">cloud_download</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </ng-container>
    <p class="title">{{this.statusTP}}</p>
    <div *ngIf="!loadingTP" class="fileL-container-loader">
        <div class="fileL-loader" [ngStyle]="{'border-top-color': '#0A2283','border-bottom-color':'#0A2283'}">
        </div>
    </div>
    <ng-container *ngIf="fileListTotalPlay.length>0 && loadingTP">
        <div class="fileL-container-files">
            <div *ngFor="let f of fileListTotalPlay" class="fileL-card-files" matTooltip="{{f.name}}"
                matTooltipPosition="above" matTooltipClass="tooltip">
                <p>{{f.type}}</p>
                <div class="fileL-card-actions">
                    <button mat-icon-button matTooltip="Descargar" matTooltipPosition="below" matTooltipClass="tooltip"
                        (click)="download(f)">
                        <mat-icon class="blue-color">cloud_download</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </ng-container>
    <p class="title">{{this.statusSN}}</p>
    <div *ngIf="!loadingSN" class="fileL-container-loader">
        <div class="fileL-loader" [ngStyle]="{'border-top-color': '#0A2283','border-bottom-color':'#0A2283'}">
        </div>
    </div>
    <ng-container *ngIf="fileListSkyNet.length>0 && loadingSN">
        <div class="fileL-container-files">
            <div *ngFor="let f of fileListSkyNet" class="fileL-card-files" matTooltip="{{f.name}}"
                matTooltipPosition="above" matTooltipClass="tooltip">
                <p>{{f.type}}</p>
                <div class="fileL-card-actions">
                    <button mat-icon-button matTooltip="Descargar" matTooltipPosition="below" matTooltipClass="tooltip"
                        (click)="download(f)">
                        <mat-icon class="blue-color">cloud_download</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </ng-container>
</div>