<div class="coms-container">
    <p class="title">{{title}}</p>
    <form [formGroup]="form" class="coms-form">

        <div class="coms-group">
            <label class="primaryColor">Cliente</label>
            <select [(ngModel)]="customerId" formControlName="customerId" class="form-control" (change)="getOriginList()">
                <option class="ligthColor" value="0" selected>Seleccione una Ubicacion</option>
                <option *ngFor="let c of customerList" value="{{c.customerId}}">{{c.customer}}</option>
            </select>
            <div class="invalid-text" *ngIf="form.get('customerId').invalid && form.get('customerId').touched">Seleccione una opcion valida</div>
        </div>
        
        <div class="coms-group">
            <label class="primaryColor">Ciudad</label>
            <select [(ngModel)]="city" formControlName="city" class="form-control">
                <option class="ligthColor" value="0">Seleccione una Ubicacion</option>
                <option *ngFor="let c of cityList" value="{{c.name}}">{{c.name}}</option>
            </select>
            <div class="invalid-text" *ngIf="form.get('city').invalid && form.get('city').touched">Seleccione una opcion valida</div>
        </div>

        <div class="coms-group">
            <label class="primaryColor">Origen</label>
            <select [(ngModel)]="origin" formControlName="origin" class="form-control" (change)="getOriginType(origin)">
                <option class="ligthColor" value="0">Seleccione un Origen</option>
                <option *ngFor="let c of originList" value="{{c.origin}}">{{c.origin}}</option>
            </select>
            <div class="invalid-text" *ngIf="form.get('origin').invalid && form.get('origin').touched">Seleccione una opcion valida</div>
        </div>

        <div class="coms-group">
            <label class="primaryColor">Tipo Origen</label>
            <select [(ngModel)]="originType" formControlName="originType" class="form-control">
                <option class="ligthColor" value="0">Seleccione un Tipo de Origen</option>
                <option *ngFor="let c of originTypeList" value="{{c.originType}}">{{c.originType}}</option>
            </select>
            <div class="invalid-text" *ngIf="form.get('originType').invalid && form.get('originType').touched">Seleccione una opcion valida</div>
        </div>

        <div class="coms-group">
            <label class="primaryColor">Numero de Identificacion Agente</label>
            <input name="agentIdentification" formControlName="agentIdentification" class="form-control text-may" placeholder="Pedido">
            <div class="invalid-text" *ngIf="form.get('agentIdentification').invalid && form.get('agentIdentification').touched">Ingrese un valor valido</div>
        </div>
     
        <div *ngIf="editing != 0" class="coms-group-checkbox">
            <label class="primaryColor">Activo</label>
            <div class="coms-container-checkbox" matRipple [matRippleCentered]="true" [matRippleRadius]="40">
                <div class="coms-checkbox">
                    <input id="check" formControlName="active" type="checkbox">
                    <label class="coms-label" for="check">
                        <mat-icon>done</mat-icon>
                    </label>
                </div>
            </div>
        </div>

        <div class="coms-group">
            <label class="primaryColor">Guardar</label>
            <div class="coms-container-button">
                <button type="reset" class="coms-button" mat-raised-button matTooltip="Guardar" mat-raised-button [disabled]="!form.valid" matTooltipPosition="right" matTooltipClass="tooltip" (click)="save()">
                    <mat-icon>save</mat-icon>
                </button>
            </div>
        </div>
    </form>

    <div class="coms-container-button">
        <div class="coms-group">
            <input type="text" class="form-control" placeholder="Filtrar" (keyup)="filter($event)">
            <mat-icon class="blue-color">search</mat-icon>
        </div>
    </div>
    <table #table class="coms-table" mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let item">
                <button *ngIf="item.id === editing" mat-icon-button matTooltip="Cerrar Edicion"
                    matTooltipPosition="above" matTooltipClass="tooltip" (click)="closeEditing()">
                    <mat-icon class="ligthColor">close</mat-icon>
                </button>
            </td>
        </ng-container>
        <ng-container matColumnDef="number">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Numero</th>
            <td mat-cell *matCellDef="let item">{{item.number}}</td>
        </ng-container>
        <ng-container matColumnDef="customerName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Cliente</th>
            <td mat-cell *matCellDef="let item">{{item.customerName}}</td>
        </ng-container>
        <ng-container matColumnDef="city">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Ciudad</th>
            <td mat-cell *matCellDef="let item">{{item.city}}</td>
        </ng-container>
        <ng-container matColumnDef="origin">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Origen</th>
            <td mat-cell *matCellDef="let item">{{item.origin}}</td>
        </ng-container>
        <ng-container matColumnDef="originType">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Tipo Origen</th>
            <td mat-cell *matCellDef="let item">{{item.originType}}</td>
        </ng-container>
        <ng-container matColumnDef="userName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Usuario</th>
            <td mat-cell *matCellDef="let item">{{item.userName}}</td>
        </ng-container>
        <ng-container matColumnDef="creationDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha de Creacion</th>
            <td mat-cell *matCellDef="let item">{{item.creationDate}}</td>
        </ng-container>
        <ng-container matColumnDef="state">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Estado</th>
            <td mat-cell *matCellDef="let item">{{item.state}}</td>
        </ng-container>
        <ng-container matColumnDef="active">
            <th mat-header-cell *matHeaderCellDef>Activo</th>
            <td mat-cell *matCellDef="let item">
                <mat-icon *ngIf="item.active" class="green-color">done</mat-icon>
                <mat-icon *ngIf="!item.active" class="red-color">clear</mat-icon>
            </td>
        </ng-container>
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Acciones</th>
            <td mat-cell *matCellDef="let item">
                <button mat-icon-button matTooltip="Eliminar" matTooltipPosition="above" matTooltipClass="tooltip"
                (click)="delete(item.id)">
                    <mat-icon class="red-color">delete</mat-icon>
                </button>
                <button mat-icon-button matTooltip="Editar" matTooltipPosition="above"
                    matTooltipClass="tooltip" (click)="edit(item)">
                    <mat-icon class="blue-color">create</mat-icon>
                </button>
                <button mat-icon-button matTooltip="Mas" matTooltipPosition="above" matTooltipClass="tooltip" [matMenuTriggerFor]="menuUser">
                    <mat-icon class="orange-color">more_vert</mat-icon>
                </button>
                <mat-menu #menuUser="matMenu" xPosition="before">
                    <button mat-menu-item (click)="newArticle(item,item.id)">
                        <mat-icon>history_edu</mat-icon>
                        <span>Articulos</span>
                    </button>
                </mat-menu>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns;"></tr>
    </table>
    <div *ngIf="loading" class="report-container-loader">
        <div class="report-loader"></div>
    </div>
    <mat-paginator [pageSizeOptions]="[5, 10, 15, 20, 30, 40, 50, 100]" showFirstLastButtons></mat-paginator>

</div>