<div mat-dialog-content>
    <div class="password-container-button">
        <button mat-icon-button (click)="close()">
            <mat-icon>clear</mat-icon>
        </button>
    </div>
    <p class="title">Cambiar Contraseña</p>
    <div [formGroup]="form" class="password-form">
        <div class="password-group">
            <label class="primaryColor">Contraseña Actual</label>
            <input formControlName="currentPassword" type="password" class="form-control"
                placeholder="Contraseña Actual">
            <span
                *ngIf="form.controls['currentPassword'].invalid && (form.controls['currentPassword'].dirty || form.controls['currentPassword'].touched)"
                class="password-alert red-color">Este campo es Obligatorio</span>
        </div>

        <div class="password-group">
            <label class="primaryColor">Nueva Contraseña</label>
            <input formControlName="newPassword" type="password" class="form-control" placeholder="Nueva Contraseña" required pattern="^(?=.*\d)(?=.*[\u0021-\u002b\u003c-\u0040])(?=.*[A-Z])(?=.*[a-z])\S{8,16}$" >
            <span
                *ngIf="form.controls['newPassword'].invalid && (form.controls['newPassword'].dirty || form.controls['newPassword'].touched)"
                class="password-alert red-color">La contraseña debe contener 8 caracteres y al menos una mayuscula, una minuscula y un número</span>
        </div>
        <div class="password-group">
            <label class="primaryColor">Confirmar Contraseña</label>
            <input formControlName="confirmPassword" type="password" class="form-control"
                placeholder="Confirmar Contraseña">
            <span
                *ngIf="form.controls['confirmPassword'].invalid && (form.controls['confirmPassword'].dirty || form.controls['confirmPassword'].touched)"
                class="password-alert red-color">La contraseña debe contener 8 caracteres y al menos una mayuscula, una minuscula y un número</span>
        </div>
        <button mat-mini-fab class="password-float-button green" matTooltip="Guardar" matTooltipPosition="left"
            matTooltipClass="tooltip" [disabled]="!form.valid"
            [ngClass]="{'ligthBackground':!form.valid,'green':form.valid}" (click)="save()">
            <mat-icon>done</mat-icon>
        </button>
    </div>
</div>