export class MeeGroupEntity {

    id:number;
    name: string;
    description: string;
    daysPro: string;
    hoursPro: string;
    dateMaxPro: string;
    active: boolean;

    constructor (){}
}