export class ComCommoditySAPReturnModel{
    
    CantidadAnterior: number;
    CantidadEnviar: number;
    CantidadNueva: number;
    CodigoSap: string;
    DocEntry:string;
    DocNum:string;
    Pallet:string;
    constructor () {}
}