<div class="coms-container">
    <p class="title">{{title}}</p>
    <div class="coms-container-search">
        <div class="coms-group">
            <input type="text" class="form-control" placeholder="Filtrar" (keyup)="filter($event)">
            <mat-icon class="blue-color">search</mat-icon>
        </div>
    </div>
    <table #table class="coms-table" mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="codigoSap">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Codigo Sap</th>
            <td mat-cell *matCellDef="let item" (click)="close(item)">{{item.codigoSap}}</td>
        </ng-container>
        <ng-container matColumnDef="descripcion">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Descripcion</th>
            <td mat-cell *matCellDef="let item" (click)="close(item)">{{item.descripcion}}</td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns;"></tr>
    </table>
    <div *ngIf="loading" class="coms-container-loader">
        <div class="coms-loader"></div>
    </div>
    <mat-paginator [pageSizeOptions]="[5, 10, 15, 20, 30, 40, 50, 100]" showFirstLastButtons></mat-paginator>
</div>