<div mat-dialog-content>
    <div fxLayout fxLayoutAlign="flex-end">
        <button mat-icon-button (click)="close(false)">
            <mat-icon>clear</mat-icon>
        </button>
    </div>
    <p class="title">{{title}}</p>
    <div [formGroup]="formN" class="form">
        <div fxFlex="100%" fxLayout="column" class="group">
            <label class="primaryColor">Nombre</label>
            <input name="name" formControlName="name" class="form-control text-may" placeholder="Nombre">
            <div class="invalid-text" *ngIf="formN.get('name').invalid && formN.get('name').touched">Ingrese un nombre valido</div>
        </div>
        <div fxFlex="100%" fxLayout="column" class="group">
            <label class="primaryColor">Descripcion</label>
            <input formControlName="description" type="text" class="form-control text-may" placeholder="Descripcion">
            <div class="invalid-text" *ngIf="formN.get('description').invalid && formN.get('description').touched">Ingrese una descripcion valida</div>
        </div>
        <div fxFlex="100%" fxLayout="column" class="group">
            <div class="meeg-group-checkbox">
                <label class="primaryColor">Activo</label>
                <div class="meeg-container-checkbox" matRipple [matRippleCentered]="true" [matRippleRadius]="40">
                    <div class="meeg-checkbox">
                        <input id="check" formControlName="active" type="checkbox">
                        <label class="meeg-label" for="check">
                            <mat-icon>done</mat-icon>
                        </label>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
    <button  mat-mini-fab class="button-float green" matTooltip="Guardar" matTooltipPosition="left" matTooltipClass="tooltip" [disabled]="!formN.valid" [ngClass]="{'ligthBackground':!formN.valid,'green':formN.valid}" (click)="save()">
            <mat-icon>done</mat-icon>
    </button>
</div>