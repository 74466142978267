import { Component, Inject, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { numbers } from "@material/snackbar";
import { AlertService } from 'src/app/shared/services/alert.service';
import { FileGroupService } from "../../services/fileGroup.service";
import { FileGroupUserService } from "../../services/fileGroupUser.service";


@Component({
    selector: 'modal-fileGroup',
    templateUrl: './fileGroup.modal.html',
    styleUrls: ['./fileGroup.modal.css']
})
export class FileGroupModal {
    columns: string[];
    loading: boolean;
    @ViewChild(MatTable) table: MatTable<any>;
    dataSource = new MatTableDataSource<any>();
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    rest: number;
    prueba: number;

    constructor(private alertS: AlertService, private fileGroupUserS: FileGroupUserService, private groupS: FileGroupService,
        public dialogRef: MatDialogRef<FileGroupModal>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        dialogRef.disableClose = true;
        this.columns = ['id', 'name', 'asignar'];

        this.dataSource = new MatTableDataSource([]);
    }
    ngOnInit(): void {
        this.rest = 0;
        this.prueba = 0;
        this.fileGroupUserS.list(this.data.userId).subscribe(res => {
            this.dataSource = res.object;
        }, err => {
            this.alertS.open(err.message, 'error');
        });
        this.search();
    }
    close(): void {
        this.dialogRef.close();
    }
    checked(input: HTMLInputElement,  groupId: number, active: boolean) {
        if (input.checked) {
            if(!active){
                this.fileGroupUserS.create( this.data.userId, groupId).subscribe(resC => {
                    if (resC.message === 'OK') {
                        if (resC.object != 0) {
                            this.alertS.open('Grupo agregado!', 'success');
                            this.search();
                        } else {
                            this.alertS.open('Error al agregar el Grupo!', 'error');
                        }
                    } else {
                        this.alertS.open(resC.message, 'error');
                    }
                }, err => {
                    this.alertS.open(err.message, 'error');
                });
            }
        } else {
            if (active) {
                this.fileGroupUserS.delete(this.data.userId, groupId).subscribe(resD => {
                    if (resD.message === 'OK') {
                        if (resD.object != 0) {
                            this.alertS.open('Grupo eliminado!', 'success');
                            this.search();
                        } else {
                            this.alertS.open('Error al eliminar el Grupo!', 'error');
                        }
                    } else {
                        this.alertS.open(resD.message, 'error');
                    }
                }, err => {
                    this.alertS.open(err.message, 'error');
                });
            } else {
                this.fileGroupUserS.delete(this.data.userId, groupId).subscribe(resD => {
                    if (resD.message === 'OK') {
                        if (resD.object != 0) {
                            this.alertS.open('Grupo eliminado!', 'success');
                            this.search();
                        } else {
                            this.alertS.open('Error al eliminar el Grupo!', 'error');
                        }
                    } else {
                        this.alertS.open(resD.message, 'error');
                    }
                }, err => {
                    this.alertS.open(err.message, 'error');
                });
            }
        }
    }
    search() {
        this.fileGroupUserS.list(this.data.userId).subscribe(res => {
            if (res.message === 'OK') {
                this.loading = false;
                this.dataSource = new MatTableDataSource<any>(res.object);
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
            } else {
                this.alertS.open(res.message, 'error');
            }
        }, err => {
            this.alertS.open(err.message, 'error');
        });
    }

    filter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }
}