<div class="coms-container">
    <p class="title">{{title}}</p>
    <form [formGroup]="form" class="coms-form">
        
        <div class="coms-group">
            <label class="primaryColor">Pallet</label>
            <input [(ngModel)]="codeEntry" name="codeEntry" formControlName="codeEntry" class="form-control text-may" placeholder="Pallet">
            <div class="invalid-text" *ngIf="form.get('codeEntry').invalid && form.get('codeEntry').touched">Ingrese un valor valido</div>
        </div>

        <div class="coms-group">
            <label class="primaryColor">Generar</label>
            <div class="coms-container-button">
                <button type="reset" class="coms-button" mat-raised-button matTooltip="Generar" mat-raised-button [disabled]="!form.valid" matTooltipPosition="right" matTooltipClass="tooltip" (click)="getDocument()">
                    <mat-icon>save</mat-icon>
                </button>
            </div>
        </div>
    </form>
</div>