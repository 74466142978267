export class SerialSapModel{
    serial:string;
    mac:string;
    codigoSap:string;
    descripcion:string;
    fecha:Date;
    pallet:string;
    ncajaEmpaque:string;
    nombreusuario:string;
    campo1:string;
    campo2:string;
    campo3:string;
    campo4:string;
    campo5:string;
    campo6:string;
    campo7:string;
    campo8:string;
    campo9:string;
    campo10:string;
    estado:string;

    constructor(){}
}