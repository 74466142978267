<div mat-dialog-content>
    <div fxLayout fxLayoutAlign="flex-end">
        <button mat-icon-button (click)="close(false)">
            <mat-icon>clear</mat-icon>
        </button>
    </div>
    <p class="title">{{title}}</p>
    <form [formGroup]="form" fxLayout="row wrap" class="form">
        <div fxLayout="column" fxFlex="calc(50% - 20px)" class="group">
            <label class="primaryColor">Nombre</label>
            <input formControlName="name" type="text" class="form-control" placeholder="Nombre">
        </div>
        <div fxLayout="column" fxFlex="calc(50% - 20px)" class="group">
            <label class="primaryColor">Prefijo</label>
            <input formControlName="prefix" type="text" class="form-control" placeholder="Prefijo">
        </div>
        <div fxLayout="column" fxFlex="calc(50% - 20px)" class="group">
            <label class="primaryColor">Pais</label>
            <select formControlName="countryId" class="form-control" (change)="getCustomer()">
                <option class="ligthColor" value="">Seleccione un pais</option>
                <option *ngFor="let c of countryList" value="{{c.id}}">{{c.name}}</option>
            </select>
        </div>
        <div fxLayout="column" fxFlex="calc(50% - 20px)" class="group">
            <label class="primaryColor">Cliente</label>
            <select formControlName="customerId" class="form-control">
                <option class="ligthColor" value="">Seleccione un cliente</option>
                <option *ngFor="let c of customerList" value="{{c.customerId}}">{{c.customer}}</option>
            </select>
        </div>
        <div fxLayout="column" fxFlex="calc(50% - 20px)" class="group">
            <label class="primaryColor">Servidor</label>
            <select formControlName="serverId" class="form-control" (change)="getDataBase($event.srcElement)">
                <option class="ligthColor" value="">Seleccione un servidor</option>
                <option *ngFor="let s of serverList" value="{{s.id}}">{{s.ip}}</option>
            </select>
        </div>
        <div fxLayout="column" fxFlex="calc(50% - 20px)" class="group">
            <label class="primaryColor">Base de datos</label>
            <select formControlName="dataBaseName" class="form-control" (change)="connection=''">
                <option class="ligthColor" value="">Seleccione una base de datos</option>
                <option *ngFor="let d of dataBaseList" value="{{d.name}}">{{d.name}}</option>
            </select>
        </div>
        <div *ngIf="data.proyectEntity!=null" class="group-checkbox">
            <label class="primaryColor">Activo</label>
            <div class="container-checkbox" matRipple [matRippleCentered]="true" [matRippleRadius]="40">
                <div class="checkbox">
                    <input id="active" formControlName="active" type="checkbox">
                    <label class="label" for="active">
                        <mat-icon>done</mat-icon>
                    </label>
                </div>
            </div>
        </div>
        <div *ngIf="serverIp!='' && form.value.dataBaseName!=''" fxLayout fxLayoutAlign="center center" class="group">
            <button mat-raised-button class="green-color" [ngStyle]="{'margin-right':'20px'}" (click)="testConnection()">
                <span class="primaryColor">Probar conexion</span>
            </button>
            <span [ngStyle]="{'color':connection==='Prueba exitosa!'?'var(--green)':'var(--red)'}">{{connection}}</span>
        </div>
        <button mat-mini-fab class="button green" matTooltip="Guardar" matTooltipPosition="left"
            matTooltipClass="tooltip" [disabled]="!form.valid"
            [ngClass]="{'ligthBackground':!form.valid,'green':form.valid}" (click)="save()">
            <mat-icon>done</mat-icon>
        </button>
    </form>
</div>