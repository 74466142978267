<div class="wls-container">
    <p class="title">Prealertas</p>
    <button fxFlexAlign="flex-end" mat-mini-fab class="green" matTooltip="Crear Prealerta" matTooltipPosition="left"
        matTooltipClass="tooltip" (click)="create()">
        <mat-icon>add</mat-icon>
    </button>
    <div *ngIf="loading1" class="wls-container-loader">
        <div class="wls-loader"></div>
    </div>
    <table class="wls-table" mat-table [dataSource]="dataSource1">
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Nombre</th>
            <td mat-cell *matCellDef="let item" (click)="getSerials(item.id)">{{item.name}}</td>
        </ng-container>
        <ng-container matColumnDef="proyect">
            <th mat-header-cell *matHeaderCellDef>Proyecto</th>
            <td mat-cell *matCellDef="let item" (click)="getSerials(item.id)">{{item.proyect}}</td>
        </ng-container>
        <ng-container matColumnDef="creationDate">
            <th mat-header-cell *matHeaderCellDef>Fecha creacion</th>
            <td mat-cell *matCellDef="let item" (click)="getSerials(item.id)">{{item.creationDate}}</td>
        </ng-container>
        <ng-container matColumnDef="creationUser">
            <th mat-header-cell *matHeaderCellDef>Usuario creacion</th>
            <td mat-cell *matCellDef="let item" (click)="getSerials(item.id)">{{item.creationUser}}</td>
        </ng-container>
        <ng-container matColumnDef="active">
            <th mat-header-cell *matHeaderCellDef>Activo</th>
            <td mat-cell *matCellDef="let item" (click)="getSerials(item.id)">
                <mat-icon *ngIf="item.active" class="green-color">done</mat-icon>
                <mat-icon *ngIf="!item.active" class="red-color">clear</mat-icon>
            </td>
        </ng-container>
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Acciones</th>
            <td mat-cell *matCellDef="let item">
                <input #serials hidden type="file" accept=".xlsx" (change)="loadSerials(item.id,$event.target.files)">
                <button mat-icon-button matTooltip="{{uploading?'Cargando Seriales':'Cargar Seriales'}}" matTooltipPosition="above" matTooltipClass="tooltip" (click)="serials.click()">
                    <mat-icon class="blue-color" [ngClass]="{'wls-rotate':uploading}">cloud_upload</mat-icon>
                </button>
                <button mat-icon-button matTooltip="Eliminar" matTooltipPosition="above" matTooltipClass="tooltip" (click)="delete(item.id)">
                    <mat-icon class="red-color">delete</mat-icon>
                </button>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columns1"></tr>
        <tr mat-row *matRowDef="let row; columns: columns1;"></tr>
    </table>
    <mat-paginator #paginator1 [pageSizeOptions]="[5, 10, 15, 20, 30, 40, 50, 100]" showFirstLastButtons>
    </mat-paginator>
    <p class="title">Listado de Seriales</p>
    <button *ngIf="dataSource2.data.length>0" fxFlexAlign="flex-end" mat-mini-fab class="red" matTooltip="Eliminar Seriales" matTooltipPosition="left"
        matTooltipClass="tooltip" (click)="deleteSerials()">
        <mat-icon>delete</mat-icon>
    </button>
    <div *ngIf="loading2" class="wls-container-loader">
        <div class="wls-loader"></div>
    </div>
    <table *ngIf="!loading2" class="wls-table" mat-table [dataSource]="dataSource2">
        <ng-container matColumnDef="serial">
            <th mat-header-cell *matHeaderCellDef>Serial</th>
            <td mat-cell *matCellDef="let item">{{item.serial}}</td>
        </ng-container>
        <ng-container matColumnDef="mac">
            <th mat-header-cell *matHeaderCellDef>Mac</th>
            <td mat-cell *matCellDef="let item">{{item.mac}}</td>
        </ng-container>
        <ng-container matColumnDef="sapCode">
            <th mat-header-cell *matHeaderCellDef>Codigo Sap</th>
            <td mat-cell *matCellDef="let item">{{item.sapCode}}</td>
        </ng-container>
        <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef>Descripcion</th>
            <td mat-cell *matCellDef="let item">{{item.description}}</td>
        </ng-container>
        <ng-container matColumnDef="quantity">
            <th mat-header-cell *matHeaderCellDef>Cantidad</th>
            <td mat-cell *matCellDef="let item">{{item.quantity}}</td>
        </ng-container>
        <ng-container matColumnDef="failure">
            <th mat-header-cell *matHeaderCellDef>Falla</th>
            <td mat-cell *matCellDef="let item">{{item.failure}}</td>
        </ng-container>
        <ng-container matColumnDef="origin">
            <th mat-header-cell *matHeaderCellDef>Origen</th>
            <td mat-cell *matCellDef="let item">{{item.origin}}</td>
        </ng-container>
        <ng-container matColumnDef="order">
            <th mat-header-cell *matHeaderCellDef>Pedido</th>
            <td mat-cell *matCellDef="let item">{{item.order}}</td>
        </ng-container>
        <ng-container matColumnDef="procedure">
            <th mat-header-cell *matHeaderCellDef>Tramite</th>
            <td mat-cell *matCellDef="let item">{{item.procedure}}</td>
        </ng-container>
        <ng-container matColumnDef="novelty">
            <th mat-header-cell *matHeaderCellDef>Novedad</th>
            <td mat-cell *matCellDef="let item">{{item.novelty}}</td>
        </ng-container>
        <ng-container matColumnDef="warranty">
            <th mat-header-cell *matHeaderCellDef>Garantia</th>
            <td mat-cell *matCellDef="let item">{{item.warranty}}</td>
        </ng-container>
        <ng-container matColumnDef="originType">
            <th mat-header-cell *matHeaderCellDef>Tipo Origen</th>
            <td mat-cell *matCellDef="let item">{{item.originType}}</td>
        </ng-container>
        <ng-container matColumnDef="guide">
            <th mat-header-cell *matHeaderCellDef>Guia</th>
            <td mat-cell *matCellDef="let item">{{item.guide}}</td>
        </ng-container>
        <ng-container matColumnDef="seq">
            <th mat-header-cell *matHeaderCellDef>SEQ</th>
            <td mat-cell *matCellDef="let item">{{item.seq}}</td>
        </ng-container>
        <ng-container matColumnDef="ssid">
            <th mat-header-cell *matHeaderCellDef>SSID</th>
            <td mat-cell *matCellDef="let item">{{item.ssid}}</td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columns2"></tr>
        <tr mat-row *matRowDef="let row; columns: columns2;"></tr>
    </table>
    <mat-paginator #paginator2 [pageSizeOptions]="[5, 10, 15, 20, 30, 40, 50, 100]" showFirstLastButtons>
    </mat-paginator>
</div>