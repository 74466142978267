export class MilicomLoadBaseEntity{
    id:number;
    idPais:number;
    reporte:string;
    columnaR:string;
    extension:string;
    opcion1:string;
    opcion2:string;
    opcion3:string;
    opcion4:string;
    opcion5:string;
    horaInicio:string;
    horaFinal:string;
    dias:string;
    active:number;
    countryName:string;
    constructor(){}
}