export class PqrFormTableEntity{

    id:number;
    titleTable:string;
    columnOne:string;
    columnTwo:string;
    columnTheer:string;
    columnFour:string;
    columnFive:string;
    buttonOne:string;
    buttonTwo:string;
    languageId:number;
    cretionDate:string;
    updateDate:string;
    active: boolean;

}