export class PqrPqrsEntity{
    id:number;
    number:string;
    ticket:string;
    customerTypeId:number;
    customerType:string;
    proyectId:number;
    proyect:string;
    firstName:string;
    lastName:string;
    creationPersonId:number;
    creationPerson:string;
    assignedPersonId:number;
    assignedPerson:string;
    countryId:number;
    country:string;
    departmentId:number;
    department:string;
    cityId:number;
    city:string;
    regionalId:number;
    regional:string;
    tradeMarkId:number;
    tradeMark:string;
    contactMethodId:number;
    contactMethod:string;
    contactEmailId:number;
    contactEmail:string;
    categoryId:number;
    category:string;
    responsibleAreaId:number;
    responsibleArea:string;
    serial:string;
    typeId:number;
    type:string;
    levelNumber:number;
    summary:string;
    creationDate:string;
    entryNumber:number;
    statusId:number;
    status:string;
    finalStatusId:number;
    finalStatus:string;
    technicalId:number;
    technical:string;
    photographicRecordId:number;
    photographicRecord:String;
    diagnosticId:number;
    diagnostic:string;
    customerEscalationDate:string;
    customerEscalationResponseDate:string;
    internalEscalationAgentId:number;
    internalEscalationAgent:string;
    internalEscalationTime:number;
    internalEscalationActive:boolean;
    internalEscalationDate:string;
    internalEscalationResponseDate:string;
    responseDateCustomerPqrs:string;
    managementStatusId:number;
    managementStatus:string;
    finalContactMethodId:number;
    finalContactMethod:string;
    equipmentQuantity:number;
    attributablePqrsId:number;
    attributablePqrs:string;
    observations:string;
    unitShipmentDate:string;
    unitArrivalDate:string;
    userPqrs: string;
    creationDateFile: string;
    automaticManual:boolean;
    summaryAlternative:string;
    filesId:string;
    datefiles: string;
    dateFile: string;
    numberId:string;
    observation:string;
    constructor(){}
}