<div mat-dialog-content>
    <div class="lod-container-button">
        <div class="lod-container-button">
    </div>
        <button mat-icon-button (click)="close()">
            <mat-icon>clear</mat-icon>
        </button>
    </div>
    <p class="title">Tipo de cliente</p>
    <div class="lod-container-button">
    <div class="lod-group">
        <input type="text" class="forms-control" placeholder="" (keyup)="filter($event)">
        <mat-icon class="blue-color">search</mat-icon>
    </div>
</div>
    <table class="lod-table" mat-table [dataSource]="dataSource" #table>
        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef>Id</th>
            <td mat-cell *matCellDef="let item">{{item.id}}</td>
        </ng-container>
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Nombre</th>
            <td mat-cell *matCellDef="let item">{{item.name}}</td>
        </ng-container>
        <ng-container matColumnDef="asignar">
            <th mat-header-cell *matHeaderCellDef>Asignar</th>
            <td mat-cell *matCellDef="let item">
                <div class="lod-container-checkbox" matRipple [matRippleCentered]="true" [matRippleRadius]="40">
                    <div class="lod-checkbox">
                        <input [id]="item.id" type="checkbox" [checked]="item.active" (click)="checked($event.srcElement,item.id)">
                        <label class="lod-label" [for]="item.id">
                            <mat-icon>done</mat-icon>
                        </label>
                    </div>
                </div>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns;"></tr>
    </table>
</div>