<div class="pro-container">
    <p class="title">Listado de Seguimientos</p>
    <div class="pro-form">
        <div class="pro-group">
            <label class="primaryColor">Año</label>
            <select [(ngModel)]="year" class="form-control" (click)="getPerspective()">
                <option class="ligthColor" value="0">Seleccione un año</option>
                <option *ngFor="let y of yearList" value="{{y}}">{{y}}</option>
            </select>
        </div>
        <div class="pro-group">
            <label class="primaryColor">Proceso</label>
            <select [(ngModel)]="perspectiveId" class="form-control" (click)="getStrategicObjetive()">
                <option class="ligthColor" value="0">Seleccione un proceso</option>
                <option *ngFor="let p of perspectiveList" value="{{p.id}}">{{p.name}}</option>
            </select>
        </div>
        <div class="pro-group">
            <label class="primaryColor">Objetivo </label>
            <select [(ngModel)]="strategicObjetiveId" class="form-control" (click)="getIndicator()">
                <option class="ligthColor" value="0">Seleccione un objetivo </option>
                <option *ngFor="let s of strategicObjetiveList" value="{{s.id}}">{{s.name}}</option>
            </select>
        </div>
        <div class="pro-group">
            <label class="primaryColor">Indicador</label>
            <select [(ngModel)]="indicatorId" class="form-control" (click)="getMeasurement()">
                <option class="ligthColor" value="0">Seleccione un indicador</option>
                <option *ngFor="let i of indicatorList" value="{{i.id}}">{{i.name}}</option>
            </select>
        </div>
        <div class="pro-group">
            <label class="primaryColor">Mediciones</label>
            <select [(ngModel)]="measurementId" class="form-control" (click)="getMonth()">
                <option class="ligthColor" value="0">Seleccione una medicion</option>
                <option *ngFor="let m of measurementList" value="{{m.id}}">{{m.proyectPlan}}</option>
            </select>
        </div>
        <div class="pro-group">
            <label class="primaryColor">Mes</label>
            <select [(ngModel)]="monthId" class="form-control" (click)="getAnalysis()">
                <option class="ligthColor" value="0">Seleccione un mes</option>
                <option *ngFor="let m of monthList" value="{{m.id}}">{{m.name}}</option>
            </select>
        </div>
        <div class="pro-group">
            <label class="primaryColor">Analisis</label>
            <select [(ngModel)]="analysisId" class="form-control" (click)="getActionPlan()">
                <option class="ligthColor" value="0">Seleccione un analisis</option>
                <option *ngFor="let a of analysisList" value="{{a.id}}">{{a.analysis}}</option>
            </select>
        </div>
        <div class="pro-group">
            <label class="primaryColor">Plan de Accion</label>
            <select [(ngModel)]="actionPlanId" class="form-control" (change)="getTracking()">
                <option class="ligthColor" value="0">Seleccione un plan de accion</option>
                <option *ngFor="let a of actionPlanList" value="{{a.id}}">{{a.name}}</option>
            </select>
        </div>
    </div>
    <table #table class="pro-table" mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Descripcion</th>
            <td mat-cell *matCellDef="let item" (click)="getActions(item.actionPlan)">{{item.description}}</td>
        </ng-container>
        <ng-container matColumnDef="startDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha Inicio</th>
            <td mat-cell *matCellDef="let item" (click)="getActions(item.actionPlan)">{{item.startDate}}</td>
        </ng-container>
        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Estado</th>
            <td mat-cell *matCellDef="let item" [ngClass]="{'green-color': item.status==='Aprobado','red-color':item.status==='Rechazado'}" (click)="getActions(item.actionPlan)">{{item.status}}</td>
        </ng-container>
        <ng-container matColumnDef="actionPlan">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Plan de Accion</th>
            <td mat-cell *matCellDef="let item" (click)="getActions(item.actionPlan)">{{item.actionPlan}}</td>
        </ng-container>
        <ng-container matColumnDef="creationUser">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Usuario Creacion</th>
            <td mat-cell *matCellDef="let item" (click)="getActions(item.actionPlan)">{{item.creationUser}}</td>
        </ng-container>
        <ng-container matColumnDef="analysis">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Analisis</th>
            <td mat-cell *matCellDef="let item" (click)="getActions(item.actionPlan)">{{item.analysis}}</td>
        </ng-container>
        <ng-container matColumnDef="month">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Mes</th>
            <td mat-cell *matCellDef="let item" (click)="getActions(item.actionPlan)">{{item.month}}</td>
        </ng-container>
        <ng-container matColumnDef="measurement">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Medicion</th>
            <td mat-cell *matCellDef="let item" (click)="getActions(item.actionPlan)">{{item.measurement}}</td>
        </ng-container>
        <ng-container matColumnDef="perspective">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Proceso</th>
            <td mat-cell *matCellDef="let item" (click)="getActions(item.actionPlan)">{{item.perspective}}</td>
        </ng-container>
        <ng-container matColumnDef="strategicObjetive">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>objetivo </th>
            <td mat-cell *matCellDef="let item" (click)="getActions(item.actionPlan)">{{item.strategicObjetive}}</td>
        </ng-container>
        <ng-container matColumnDef="indicator">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Indicador</th>
            <td mat-cell *matCellDef="let item" (click)="getActions(item.actionPlan)">{{item.indicator}}</td>
        </ng-container>
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Acciones</th>
            <td mat-cell *matCellDef="let item">
                <button [disabled]="item.status==='Aprobado' || item.status==='Rechazado'" mat-icon-button
                    matTooltip="Aprobar" matTooltipPosition="above" matTooltipClass="tooltip"
                    (click)="approveReject(item.id,'Aprobado')">
                    <mat-icon [ngStyle]="{'color': item.status==='Rechazado' || item.status==='Aprobado'?'var(--ligth)':'var(--green)'}">
                        done</mat-icon>
                </button>
                <button [disabled]="item.status==='Aprobado' || item.status==='Rechazado'" mat-icon-button
                    matTooltip="Rechazar" matTooltipPosition="above" matTooltipClass="tooltip"
                    (click)="approveReject(item.id,'Rechazado')">
                    <mat-icon [ngStyle]="{'color': item.status==='Rechazado' || item.status==='Aprobado'?'var(--ligth)':'var(--red)'}">
                        close</mat-icon>
                </button>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns;"></tr>
    </table>
    <div *ngIf="loading" class="pro-container-loader">
        <div class="pro-loader"></div>
    </div>
    <mat-paginator [pageSizeOptions]="[5, 10, 15, 20, 30, 40, 50, 100]" showFirstLastButtons></mat-paginator>
    <div *ngIf="actionList.length>0" class="pro-container-action">
        <p class="title">{{title}}</p>
        <div *ngFor="let a of actionList" class="pro-action">
            <p class="pro-action-title">Nombre</p>
            <p class="pro-action-subtitle">{{a.name}}</p>
            <p class="pro-action-title">Entregables</p>
            <p class="pro-action-subtitle">{{a.deliverables}}</p>
            <p class="pro-action-title">Fecha Fin</p>
            <p class="pro-action-subtitle">{{a.endDate}}</p>
            <p class="pro-action-title">Estado</p>
            <p class="pro-action-subtitle" [ngClass]="{'green-color': a.status==='Cerrado','red-color':a.status==='Abierto'}">{{a.status}}</p>
            <p class="pro-action-title">Responsable</p>
            <p class="pro-action-subtitle">{{a.responsibleUser}}</p>
        </div>
    </div>
</div>