<div class="agent-container">
    <p class="title">Nuevo Agente</p>
    <form class="agent-form">
        <div class="agent-group">
            <label class="primaryColor">Seleccionar Usuario</label>
            <div class="agent-form-control">
                <input [(ngModel)]="person" name="person" type="text" class="form-control" disabled placeholder="Seleccionar Usuario">
                <button mat-mini-fab class="green" matTooltip="Usuarios" matTooltipPosition="above"
                    matTooltipClass="tooltip" (click)="getPerson()">
                    <mat-icon>add</mat-icon>
                </button>
            </div>
        </div>
        <div class="agent-container-button">
            <button class="agent-button" mat-raised-button [disabled]="personId === 0" (click)="onClick()">
                <mat-icon>save</mat-icon>
            </button>
        </div>
    </form>
    <p class="title">Listado Agentes</p>
    <div class="agent-search-container-button">
        <div class="agent-search-group">
            <input type="text" class="form-control" placeholder="Filtrar" (keyup)="filter($event)">
            <mat-icon class="blue-color">search</mat-icon>
        </div>
    </div>
    <table #table class="agent-table" mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="firstName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Nombres</th>
            <td mat-cell *matCellDef="let item">{{item.firstName}}</td>
        </ng-container>
        <ng-container matColumnDef="lastName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Apellidos</th>
            <td mat-cell *matCellDef="let item">{{item.lastName}}</td>
        </ng-container>
        <ng-container matColumnDef="mail">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Correo</th>
            <td mat-cell *matCellDef="let item">{{item.mail}}</td>
        </ng-container>
        <ng-container matColumnDef="customer">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Cliente</th>
            <td mat-cell *matCellDef="let item">{{item.customer}}</td>
        </ng-container>
        <ng-container matColumnDef="Acciones">
            <th mat-header-cell *matHeaderCellDef>Acciones</th>
            <td mat-cell *matCellDef="let item">
                <button mat-icon-button matTooltip="Eliminar" matTooltipPosition="above"
                    matTooltipClass="tooltip" (click)="delete(item.id)">
                    <mat-icon class="red-color">delete</mat-icon>
                </button>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns;"></tr>
    </table>
    <div *ngIf="loading" class="agent-container-loader">
        <div class="agent-loader"></div>
    </div>
    <mat-paginator [pageSizeOptions]="[5, 10, 15, 20, 30, 40, 50, 100]" showFirstLastButtons></mat-paginator>
</div>