export class InvPalletEntity{
    id:number;
    cyclicId:number;
    pallet:string;
    sapCode:string;
    location:string;
    type:string;
    status:string;

    constructor(){}
}