export class InvGeneralGoodLogEntity{
    
    id:number;
    userId:number;
    dateDelete:string;
    partNumber:string;
    location:string;
    warehouse:string;
    constructor (){}
}
