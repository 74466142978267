<div class="meeg-container">
    <p class="title">{{title}}</p>
    <form [formGroup]="form" class="meeg-form">
        <div class="meeg-group">
            <label class="primaryColor">Codigo</label>
            <input name="cod" formControlName="cod" class="form-control text-may" placeholder="Nombre">
            <div class="invalid-text" *ngIf="form.get('cod').invalid && form.get('cod').touched">Ingrese un codigo valido</div>
        </div>
        <div class="meeg-group">
            <label class="primaryColor">Nombre</label>
            <input name="name" formControlName="name" class="form-control text-may" placeholder="Nombre">
            <div class="invalid-text" *ngIf="form.get('name').invalid && form.get('name').touched">Ingrese un nombre valido</div>
        </div>
     
        <div class="meeg-group">
            <label class="primaryColor">Descripcion</label>
            <input formControlName="description" type="text" class="form-control text-may" placeholder="Descripcion">
            <div class="invalid-text" *ngIf="form.get('description').invalid && form.get('description').touched">Ingrese una descripcion valida</div>
        </div>
        <div *ngIf="genPlantformId != 0" class="meeg-group-checkbox">
            <label class="primaryColor">Activo</label>
            <div class="meeg-container-checkbox" matRipple [matRippleCentered]="true" [matRippleRadius]="40">
                <div class="meeg-checkbox">
                    <input id="check" formControlName="active" type="checkbox">
                    <label class="meeg-label" for="check">
                        <mat-icon>done</mat-icon>
                    </label>
                </div>
            </div>
        </div>

        <div class="meeg-group">
            <label class="primaryColor">Guardar</label>
            <div class="meeg-container-button">
                <button type="reset" class="meeg-button" mat-raised-button matTooltip="Guardar" mat-raised-button [disabled]="!form.valid" matTooltipPosition="right" matTooltipClass="tooltip" (click)="save()">
                    <mat-icon>save</mat-icon>
                </button>
            </div>
        </div>
    </form>
</div>