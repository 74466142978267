<div class="bsc-container">
    <p class="title">Nueva Actividad</p>
    <form [formGroup]="form" class="bsc-form">
        <div class="bsc-group">
            <label class="primaryColor">Nombre</label>
            <input formControlName="name" type="text" class="form-control" placeholder="Nombre">
        </div>
        <div class="bsc-group">
            <label class="primaryColor">Descripcion</label>
            <input formControlName="description" type="text" class="form-control" placeholder="Descripcion">
        </div>
        <div class="bsc-group">
            <label class="primaryColor">Porcentaje</label>
            <input formControlName="percentage" type="number" class="form-control" placeholder="Porcentaje">
        </div>
        <div class="bsc-group">
            <label class="primaryColor">Entregables</label>
            <input formControlName="deliverables" type="text" class="form-control" placeholder="Entregables">
        </div>
        <div class="bsc-group">
            <label class="primaryColor">Fecha de entrega</label>
            <div class="bsc-form-control">
                <input formControlName="deliverDate" matInput [matDatepicker]="deliverDate" disabled class="form-control">
                <mat-datepicker-toggle matSuffix [for]="deliverDate"></mat-datepicker-toggle>
                <mat-datepicker #deliverDate disabled="false"></mat-datepicker>
            </div>
        </div>
        <div class="bsc-group">
            <label class="primaryColor">Año</label>
            <select [(ngModel)]="year1" [ngModelOptions]="{standalone: true}" class="form-control" (change)="getPerspective(year1,1)">
                <option class="ligthColor" value="0">Seleccione un año</option>
                <option *ngFor="let y of yearList1" value="{{y}}">{{y}}</option>
            </select>
        </div>
        <div class="bsc-group">
            <label class="primaryColor">Perspectiva</label>
            <select [(ngModel)]="perspectiveId1" [ngModelOptions]="{standalone: true}" class="form-control" (change)="getStrategicObjetive(perspectiveId1,1)">
                <option class="ligthColor" value="0">Seleccione una perspectiva</option>
                <option *ngFor="let p of perspectiveList1" value="{{p.id}}">{{p.name}}</option>
            </select>
        </div>
        <div class="bsc-group">
            <label class="primaryColor">Objetivo Estrategico</label>
            <select [(ngModel)]="strategicObjetiveId1" [ngModelOptions]="{standalone: true}" class="form-control" (change)="getWorkPlan(strategicObjetiveId1,1)">
                <option class="ligthColor" value="0">Seleccione un objetivo estrategico</option>
                <option *ngFor="let s of strategicObjetiveList1" value="{{s.id}}">{{s.name}}</option>
            </select>
        </div>
        <div class="bsc-group">
            <label class="primaryColor">Plan de Trabajo</label>
            <select formControlName="workPlanId" class="form-control">
                <option class="ligthColor" value="">Seleccione un plan de trabajo</option>
                <option *ngFor="let w of workPlanList1" value="{{w.id}}">{{w.name}}</option>
            </select>
        </div>
        <div class="bsc-group">
            <label class="primaryColor">Responsable</label>
            <select formControlName="responsibleUserId" class="form-control">
                <option class="ligthColor" value="">Seleccione un responsable</option>
                <option *ngFor="let u of userList" value="{{u.id}}">{{u.firstName}} {{u.lastName}}</option>
            </select>
        </div>
        <div class="bsc-container-button">
            <button class="bsc-button" mat-raised-button matTooltip="Guardar" matTooltipPosition="right" matTooltipClass="tooltip" [disabled]="!form.valid" (click)="save()">
                <mat-icon>save</mat-icon>
            </button>
        </div>
    </form>
    <p class="title">Listado de Actividades</p>
    <div class="bsc-form">
        <div class="bsc-group">
            <label class="primaryColor">Año</label>
            <select [(ngModel)]="year2" class="form-control" (change)="getPerspective(year2,2)">
                <option class="ligthColor" value="0">Seleccione un año</option>
                <option *ngFor="let y of yearList2" value="{{y}}">{{y}}</option>
            </select>
        </div>
        <div class="bsc-group">
            <label class="primaryColor">Perspectiva</label>
            <select [(ngModel)]="perspectiveId2" class="form-control" (change)="getStrategicObjetive(perspectiveId2,2)">
                <option class="ligthColor" value="0">Seleccione una perspectiva</option>
                <option *ngFor="let p of perspectiveList2" value="{{p.id}}">{{p.name}}</option>
            </select>
        </div>
        <div class="bsc-group">
            <label class="primaryColor">Objetivo Estrategico</label>
            <select [(ngModel)]="strategicObjetiveId2" class="form-control" (change)="getWorkPlan(strategicObjetiveId2,2)">
                <option class="ligthColor" value="0">Seleccione un objetivo estrategico</option>
                <option *ngFor="let s of strategicObjetiveList2" value="{{s.id}}">{{s.name}}</option>
            </select>
        </div>
        <div class="bsc-group">
            <label class="primaryColor">Plan de Trabajo</label>
            <select [(ngModel)]="workPlanId" class="form-control" (change)="getActivity()">
                <option class="ligthColor" value="0">Seleccione un plan de trabajo</option>
                <option *ngFor="let w of workPlanList2" value="{{w.id}}">{{w.name}}</option>
            </select>
        </div>
    </div>
    <table #table class="bsc-table" mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let item">
                <button *ngIf="item.id === editing" mat-icon-button matTooltip="Cerrar Edicion"
                    matTooltipPosition="above" matTooltipClass="tooltip" (click)="closeEditing()">
                    <mat-icon class="ligthColor">close</mat-icon>
                </button>
            </td>
        </ng-container>
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Nombre</th>
            <td mat-cell *matCellDef="let item">{{item.name}}</td>
        </ng-container>
        <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Descripcion</th>
            <td mat-cell *matCellDef="let item">{{item.description}}</td>
        </ng-container>
        <ng-container matColumnDef="percentage">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Porcentaje</th>
            <td mat-cell *matCellDef="let item">{{item.percentage}}%</td>
        </ng-container>
        <ng-container matColumnDef="deliverables">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Entregables</th>
            <td mat-cell *matCellDef="let item">{{item.deliverables}}</td>
        </ng-container>
        <ng-container matColumnDef="deliverDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha Entrega</th>
            <td mat-cell *matCellDef="let item">{{item.deliverDate}}</td>
        </ng-container>
        <ng-container matColumnDef="workPlan">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Plan de Trabajo</th>
            <td mat-cell *matCellDef="let item">{{item.workPlan}}</td>
        </ng-container>
        <ng-container matColumnDef="responsibleUser">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Usuario Responsable</th>
            <td mat-cell *matCellDef="let item">{{item.responsibleUser}}</td>
        </ng-container>
        <ng-container matColumnDef="creationUser">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Usuario Creacion</th>
            <td mat-cell *matCellDef="let item">{{item.creationUser}}</td>
        </ng-container>
        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Estado</th>
            <td mat-cell *matCellDef="let item">{{item.status}}</td>
        </ng-container>
        <ng-container matColumnDef="acciones">
            <th mat-header-cell *matHeaderCellDef>Acciones</th>
            <td mat-cell *matCellDef="let item">
                <button mat-icon-button matTooltip="Eliminar" matTooltipPosition="above"
                    matTooltipClass="tooltip" (click)="delete(item.id)">
                    <mat-icon class="red-color">delete</mat-icon>
                </button>
                <button mat-icon-button matTooltip="Editar" matTooltipPosition="above"
                    matTooltipClass="tooltip" (click)="edit(item)">
                    <mat-icon class="blue-color">create</mat-icon>
                </button>
                <button *ngIf="item.status==='Abierto'" mat-icon-button matTooltip="Cerrar" matTooltipPosition="above"
                    matTooltipClass="tooltip" (click)="openClose(item.id,'Cerrado')">
                    <mat-icon class="red-color">close</mat-icon>
                </button>
                <button *ngIf="item.status==='Cerrado'" mat-icon-button matTooltip="Abrir" matTooltipPosition="above"
                    matTooltipClass="tooltip" (click)="openClose(item.id,'Abierto')">
                    <mat-icon class="orange-color">vpn_key</mat-icon>
                </button>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns;"></tr>
    </table>
    <div *ngIf="loading" class="bsc-container-loader">
        <div class="bsc-loader"></div>
    </div>
    <mat-paginator [pageSizeOptions]="[5, 10, 15, 20, 30, 40, 50, 100]" showFirstLastButtons></mat-paginator>
</div>

