<div class="dai-container">
    <p class="title">Nueva Meta</p>
    <form [formGroup]="form" class="dai-form">
        <div class="dai-group">
            <label class="primaryColor">Año</label>
            <select formControlName="year" class="form-control">
                <option class="ligthColor" value="">Seleccione un año</option>
                <option *ngFor="let y of yearList1" value="{{y}}">{{y}}</option>
            </select>
        </div>
        <div class="dai-group">
            <label class="primaryColor">Mes</label>
            <select formControlName="monthId" class="form-control">
                <option class="ligthColor" value="">Seleccione un mes</option>
                <option *ngFor="let m of monthList" value="{{m.id}}">{{m.name}}</option>
            </select>
        </div>
        <div class="dai-group">
            <label class="primaryColor">Pais</label>
            <select [(ngModel)]="countryId"  formControlName="countryId" class="form-control" (change)="getCustomer()">
                <option class="ligthColor" value="0">Seleccione un pais</option>
                <option *ngFor="let c of countryList" value="{{c.id}}">{{c.name}}</option>
            </select>
        </div>
        <div class="dai-group">
            <label class="primaryColor">Cliente</label>
            <select [(ngModel)]="customer" formControlName="countryCustomerId" class="form-control"  (change)="getCodeFammily()">
                <option class="ligthColor" value="">Seleccione un cliente</option>
                <option *ngFor="let c of customerList" value="{{c.customer}}">{{c.customer}}</option>
            </select>
        </div>
        <div class="dai-group">
            <label class="primaryColor">Codigo de familia</label>
            <select [(ngModel)]="codeFamily" formControlName="codeFamily" class="form-control">
                <option class="ligthColor" value="">Seleccione un codigo</option>
                <option *ngFor="let c of codeFamilyList" value="{{c.codigoFamilia}}">{{c.codigoFamilia}}</option>
            </select>
        </div>
        <div class="dai-group">
            <label class="primaryColor">Meta</label>
            <input formControlName="goal" type="number" class="form-control" placeholder="Meta">
        </div>
        <div class="dai-container-button">
            <button class="dai-button" mat-raised-button matTooltip="Guardar" matTooltipPosition="right"
                matTooltipClass="tooltip" [disabled]="!form.valid" (click)="save()">
                <mat-icon>save</mat-icon>
            </button>
        </div>
    </form>
    <p class="title">Listado de Metas</p>
    <div class="dai-form">
        <div class="dai-group">
            <label class="primaryColor">Año</label>
            <select [(ngModel)]="year" class="form-control" (change)="getGoal()">
                <option class="ligthColor" value="0">Seleccione un año</option>
                <option *ngFor="let y of yearList2" value="{{y}}">{{y}}</option>
            </select>
        </div>
    </div>
    <table #table class="dai-table" mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let item">
                <button *ngIf="item.id === editing" mat-icon-button matTooltip="Cerrar Edicion"
                    matTooltipPosition="above" matTooltipClass="tooltip" (click)="closeEditing()">
                    <mat-icon class="ligthColor">close</mat-icon>
                </button>
            </td>
        </ng-container>
        <ng-container matColumnDef="year">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Año</th>
            <td mat-cell *matCellDef="let item">{{item.year}}</td>
        </ng-container>
        <ng-container matColumnDef="month">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Mes</th>
            <td mat-cell *matCellDef="let item">{{item.month}}</td>
        </ng-container>
        <ng-container matColumnDef="country">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Pais</th>
            <td mat-cell *matCellDef="let item">{{item.country}}</td>
        </ng-container>
        <ng-container matColumnDef="customer">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Cliente</th>
            <td mat-cell *matCellDef="let item">{{item.customer}}</td>
        </ng-container>
        <ng-container matColumnDef="codeFamily">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Codigo de familia</th>
            <td mat-cell *matCellDef="let item">{{item.codeFamily}}</td>
        </ng-container>
        <ng-container matColumnDef="goal">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Meta</th>
            <td mat-cell *matCellDef="let item">{{item.goal}}</td>
        </ng-container>
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Acciones</th>
            <td mat-cell *matCellDef="let item">
                <button mat-icon-button matTooltip="Editar" matTooltipPosition="above" matTooltipClass="tooltip"
                    (click)="edit(item)">
                    <mat-icon class="blue-color">create</mat-icon>
                </button>
                <button mat-icon-button matTooltip="Eliminar" matTooltipPosition="above" matTooltipClass="tooltip"
                    (click)="delete(item.id)">
                    <mat-icon class="red-color">delete</mat-icon>
                </button>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns;"></tr>
    </table>
    <div *ngIf="loading" class="dai-container-loader">
        <div class="dai-loader"></div>
    </div>
</div>