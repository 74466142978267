<div class="cyclic-container">
    <p class="title">Auditoria Pallets</p>
    <div class="cyclic-form">
        <div class="cyclic-group">
            <label class="primaryColor">Cliente</label>
            <select [(ngModel)]="customer" class="form-control" (change)="getCyclic()">
                <option class="ligthColor" value="0">Seleccione un cliente</option>
                <option *ngFor="let c of customerList" value="{{c.customerId}}_{{c.customer}}">{{c.customer}}</option>
            </select>
        </div>
        <div class="cyclic-group">
            <label class="primaryColor">Ciclico</label>
            <select [(ngModel)]="cyclicId" class="form-control" (change)="selectCyclic()">
                <option class="ligthColor" value="0">Seleccione un ciclico</option>
                <option *ngFor="let c of cyclicList" value="{{c.id}}">{{c.name}}</option>
            </select>
        </div>
        <div class="cyclic-group">
            <label class="primaryColor">Tipo Pallet</label>
            <select [(ngModel)]="type" class="form-control" (change)="getPallet()">
                <option class="ligthColor" value="0">Seleccione un tipo de pallet</option>
                <option value="Accesorios">Accesorios</option>
                <option value="Seriales">Seriales</option>
            </select>
        </div>
    </div>
    <ng-container *ngIf="dataSourcePallet.data.length>0">
        <p class="title">Listado de Pallets</p>
        <div class="cyclic-container-button" [ngStyle]="{'margin-top':'30px'}">
            <div class="cyclic-control-group">
                <input type="text" class="form-control" placeholder="Filtrar" (keyup)="filter($event)">
                <mat-icon class="blue-color">search</mat-icon>
            </div>
        </div>
        <table class="cyclic-table" mat-table [dataSource]="dataSourcePallet">
            <ng-container matColumnDef="number">
                <th mat-header-cell *matHeaderCellDef>Numero</th>
                <td mat-cell *matCellDef="let item" matTooltip="Dar click aqui" (click)="getCouting(item.id)">{{item.number}}</td>
            </ng-container>
            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef>Tipo</th>
                <td mat-cell *matCellDef="let item" matTooltip="Dar click aqui" (click)="getCouting(item.id)">{{item.type}}</td>
            </ng-container>
            <ng-container matColumnDef="sapCode">
                <th mat-header-cell *matHeaderCellDef>Codigo Sap</th>
                <td mat-cell *matCellDef="let item" matTooltip="Dar click aqui" (click)="getCouting(item.id)">{{item.sapCode}}</td>
            </ng-container>
            <ng-container matColumnDef="location">
                <th mat-header-cell *matHeaderCellDef>Ubicacion</th>
                <td mat-cell *matCellDef="let item" matTooltip="Dar click aqui" (click)="getCouting(item.id)">{{item.location}}</td>
            </ng-container>
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef>Estado</th>
                <td mat-cell *matCellDef="let item" matTooltip="Dar click aqui" (click)="getCouting(item.id)">{{item.status}}</td>
            </ng-container>
            <ng-container matColumnDef="quantity">
                <th mat-header-cell *matHeaderCellDef>Cantidad</th>
                <td mat-cell *matCellDef="let item" matTooltip="Dar click aqui" (click)="getCouting(item.id)">{{item.quantity}}</td>
            </ng-container>
            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef>Acciones</th>
                <td mat-cell *matCellDef="let item">
                    <button mat-icon-button matTooltip="Terminar" matTooltipPosition="above" matTooltipClass="tooltip" (click)="finish(item.id,item.status)">
                        <mat-icon class="green-color">done</mat-icon>
                    </button>
                    <button mat-icon-button matTooltip="Eliminar" matTooltipPosition="above" matTooltipClass="tooltip" (click)="delete(item.number)">
                        <mat-icon class="red-color">delete</mat-icon>
                    </button>
                    <button mat-icon-button matTooltip="Crear Conteo" matTooltipPosition="above" matTooltipClass="tooltip" (click)="createCouting(item.id,item.number,item.status)">
                        <mat-icon class="blue-color">slow_motion_video</mat-icon>
                    </button>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="columnsPallet"></tr>
            <tr mat-row *matRowDef="let row; columns: columnsPallet;"></tr>
        </table>
        <mat-paginator #paginatorPallet [pageSizeOptions]="[5, 10, 15, 20, 30, 40, 50, 100]"
            [length]="dataSourcePallet.data.length" showFirstLastButtons></mat-paginator>
    </ng-container>
    <div *ngIf="loadingPallet" class="cyclic-container-loader">
        <div class="cyclic-loader"></div>
    </div>
    <ng-container *ngIf="dataSourceCouting.data.length>0">
        <p class="title">Listado de Conteos</p>
        <table class="cyclic-table" [ngStyle]="{'margin-top':'30px'}" mat-table [dataSource]="dataSourceCouting">
            <ng-container matColumnDef="coutingType">
                <th mat-header-cell *matHeaderCellDef>Tipo Conteo</th>
                <td mat-cell *matCellDef="let item">{{item.coutingType}}</td>
            </ng-container>
            <ng-container matColumnDef="sampling">
                <th mat-header-cell *matHeaderCellDef>Porcentaje Muestra</th>
                <td mat-cell *matCellDef="let item">{{item.sampling}}%</td>
            </ng-container>
            <ng-container matColumnDef="total">
                <th mat-header-cell *matHeaderCellDef>Total</th>
                <td mat-cell *matCellDef="let item">{{item.total}}%</td>
            </ng-container>
            <ng-container matColumnDef="quantity">
                <th mat-header-cell *matHeaderCellDef>Cantidad</th>
                <td mat-cell *matCellDef="let item">{{item.quantity}}</td>
            </ng-container>
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef>Estado</th>
                <td mat-cell *matCellDef="let item"
                    [ngClass]="{'red-color':item.status==='Pendiente','orange-color':item.status==='Iniciado' || item.status==='Terminado','green-color':item.status==='Cerrado'}">
                    {{item.status}}</td>
            </ng-container>
            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef>Tipo</th>
                <td mat-cell *matCellDef="let item">{{item.type}}</td>
            </ng-container>
            <ng-container matColumnDef="creationDate">
                <th mat-header-cell *matHeaderCellDef>Fecha Creacion</th>
                <td mat-cell *matCellDef="let item">{{item.creationDate}}</td>
            </ng-container>
            <ng-container matColumnDef="startDate">
                <th mat-header-cell *matHeaderCellDef>Fecha Inicio</th>
                <td mat-cell *matCellDef="let item">{{item.startDate}}</td>
            </ng-container>
            <ng-container matColumnDef="endDate">
                <th mat-header-cell *matHeaderCellDef>Fecha Fin</th>
                <td mat-cell *matCellDef="let item">{{item.endDate}}</td>
            </ng-container>
            <ng-container matColumnDef="assistant">
                <th mat-header-cell *matHeaderCellDef>Auxiliar</th>
                <td mat-cell *matCellDef="let item">{{item.assistant}}</td>
            </ng-container>
            <ng-container matColumnDef="card">
                <th mat-header-cell *matHeaderCellDef>Tarjeta</th>
                <td mat-cell *matCellDef="let item">{{item.card}}</td>
            </ng-container>
            <ng-container matColumnDef="creationUser">
                <th mat-header-cell *matHeaderCellDef>Usuario Creacion</th>
                <td mat-cell *matCellDef="let item">{{item.creationUser}}</td>
            </ng-container>
            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef>Acciones</th>
                <td mat-cell *matCellDef="let item">
                    <button mat-icon-button matTooltip="Auxiliares" matTooltipPosition="above" matTooltipClass="tooltip" (click)="getAssistant(item.id,item.status)">
                        <mat-icon class="orange-color">account_circle</mat-icon>
                    </button>
                    <button mat-icon-button matTooltip="Eliminar" matTooltipPosition="above" matTooltipClass="tooltip" (click)="deleteCouting(item.id,item.status)">
                        <mat-icon class="red-color">delete</mat-icon>
                    </button>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="columnsCouting"></tr>
            <tr mat-row *matRowDef="let row; columns: columnsCouting;"></tr>
        </table>
        <mat-paginator #paginatorCouting [pageSizeOptions]="[5, 10, 15, 20, 30, 40, 50, 100]"
            [length]="dataSourceCouting.data.length" showFirstLastButtons></mat-paginator>
    </ng-container>
    <div *ngIf="loadingCouting" class="cyclic-container-loader">
        <div class="cyclic-loader"></div>
    </div>
</div>