
export class ComCommodityEntryArticlesPreviousEntity {

    id:number;
    idCommodityEntry:number;
    sapCode:string;
    sapCodeDescription:string;
    quantity:number;
    userId:number;
    creationDate:string;

    constructor(){ }
}