<div mat-dialog-content>
    <div class="report-container-button">
        <button mat-icon-button mat-dialog-close>
            <mat-icon>clear</mat-icon>
        </button>
    </div>
    <p class="title" #top>Configuracion de columnas del reporte</p>
    <ng-container *ngIf="admin">
        <form [formGroup]="form" class="report-form">
            <div class="report-group">
                <mat-form-field appearance="fill">
                    <mat-label *ngIf="nameDisabled==''">Columna</mat-label>
                    <mat-label *ngIf="nameDisabled!=''">{{nameDisabled}}</mat-label>
                    <mat-select formControlName="name">
                        <mat-option *ngFor="let i of ColumnsSQL" [value]="i">{{i}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="report-group">
                <mat-form-field appearance="fill">
                    <mat-label>Tipo</mat-label>
                    <mat-select formControlName="tipo">
                        <mat-option value="string">Texto</mat-option>
                        <mat-option value="number">Numero</mat-option>
                        <mat-option value="boolean">Booleano</mat-option>
                        <mat-option value="null">Nulo</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="report-group">
                <label class="primaryColor">Maximo Caracteres</label>
                <input formControlName="caracteres" type="number" class="form-control" min="0" max="250" maxlength="3"
                    placeholder="Maximo de caracteres">
            </div>
            <div class="report-group">
                <mat-form-field appearance="fill">
                    <mat-label>Validacion</mat-label>
                    <mat-select formControlName="validacion">
                        <mat-option value="">Sin Validacion Adicional</mat-option>
                        <mat-option value="NOTNULL">No aceptar valores vacios</mat-option>
                        <mat-option value="DATE">Tener formato de fecha DD/MM/YYYY</mat-option>
                        <mat-option value="OP1">Validar con lista OP1</mat-option>
                        <mat-option value="OP2">Validar con lista OP2</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-checkbox (change)="check($event.checked)" [(checked)]="checkStatus" color="primary">Asignar de primeras</mat-checkbox>
            </div>
            <button mat-mini-fab class="green" tabindex="1" [matTooltip]="opEdit === true?'Actualizar':'Guardar'"
                matTooltipPosition="right" matTooltipClass="tooltip" (keyup.enter)="save()" (click)="save()">
                <mat-icon *ngIf="!opEdit">add</mat-icon>
                <mat-icon *ngIf="opEdit">mode_edit_outline</mat-icon>
            </button>
            <button mat-mini-fab class="red" *ngIf="opEdit" matTooltip="Cancelar" matTooltipPosition="right"
                matTooltipClass="tooltip" (click)="ngOnInit()">
                <mat-icon>edit_off</mat-icon>
            </button>
        </form>
    </ng-container>
    <table class="report-table" mat-table [dataSource]="dataSource" #table>
        <ng-container matColumnDef="0">
            <th mat-header-cell *matHeaderCellDef>Nombre Columna</th>
            <td mat-cell *matCellDef="let item">{{item[0]}}</td>
        </ng-container>
        <ng-container matColumnDef="1">
            <th mat-header-cell *matHeaderCellDef>Tipo</th>
            <td mat-cell *matCellDef="let item">{{item[1]}}</td>
        </ng-container>
        <ng-container matColumnDef="2">
            <th mat-header-cell *matHeaderCellDef>Maximo Caracteres</th>
            <td mat-cell *matCellDef="let item">{{item[2]}}</td>
        </ng-container>
        <ng-container matColumnDef="3">
            <th mat-header-cell *matHeaderCellDef>Validacion</th>
            <td mat-cell *matCellDef="let item">{{item[3]}}</td>
        </ng-container>
        <ng-container matColumnDef="4">
            <th mat-header-cell *matHeaderCellDef>Acciones</th>
            <td mat-cell *matCellDef="let item;let i = index">
                <button *ngIf="admin" mat-icon-button matTooltip="Eliminar" matTooltipPosition="above" matTooltipClass="tooltip" (click)="delete(i)">
                    <mat-icon class="red-color">delete</mat-icon>
                </button>
                <button *ngIf="admin" mat-icon-button matTooltip="Editar" matTooltipPosition="above" matTooltipClass="tooltip" (click)="edit(item[0],item[1],item[2],item[3],i)">
                    <mat-icon class="blue-color">create</mat-icon>
                </button>
            </td>
        </ng-container>
        
        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns;"></tr>
    </table>
</div>
<mat-dialog-actions>
    <button mat-stroked-button [(disabled)]="!trueChange" color="primary" (click)="close()">Guardar</button>
    <button mat-stroked-button [(disabled)]="!trueChange" mat-dialog-close color="warn">Cancelar</button>
</mat-dialog-actions>