<div class="agent-container">
    <p class="title">Nuevo Suscriptor</p>
    <div class="agent-form">
        <div class="agent-group">
            <label class="primaryColor">Cliente</label>
            <select [(ngModel)]="customerId" class="form-control">
                <option class="ligthColor" value="0">Seleccione un cliente</option>
                <option *ngFor="let c of customerList" value="{{c.id}}">{{c.nombre}}</option>
            </select>
        </div>
        <input #file hidden type="file" (change)="loadFile($event.target.files)">
        <button class="blue" mat-mini-fab matTooltip="Cargar Archivo" matTooltipPosition="right"
            matTooltipClass="tooltip" (click)="file.click()">
            <mat-icon *ngIf="!loadingFile">backup</mat-icon>
            <div *ngIf="loadingFile" class="loader-mini"></div>
        </button>
    </div>
    <p class="title">Listado Suscriptores</p>
    <div class="agent-search-container-button">
        <div class="agent-search-group">
            <input type="text" class="form-control" placeholder="Filtrar" (keyup)="filter($event)">
            <mat-icon class="blue-color">search</mat-icon>
        </div>
    </div>
    <table #table class="agent-table" mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="cliente">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Cliente</th>
            <td mat-cell *matCellDef="let item">{{item.cliente}}</td>
        </ng-container>
        <ng-container matColumnDef="identificacion">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Identificacion</th>
            <td mat-cell *matCellDef="let item">{{item.identificacion}}</td>
        </ng-container>
        <ng-container matColumnDef="nombre">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>nombre</th>
            <td mat-cell *matCellDef="let item">{{item.nombre}}</td>
        </ng-container>
        <ng-container matColumnDef="correo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Correo</th>
            <td mat-cell *matCellDef="let item">{{item.correo}}</td>
        </ng-container>
        <ng-container matColumnDef="telefono1">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Telefono1</th>
            <td mat-cell *matCellDef="let item">{{item.telefono1}}</td>
        </ng-container>
        <ng-container matColumnDef="telefono2">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Telefono2</th>
            <td mat-cell *matCellDef="let item">{{item.telefono2}}</td>
        </ng-container>
        <ng-container matColumnDef="direccion">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Direccion</th>
            <td mat-cell *matCellDef="let item">{{item.direccion}}</td>
        </ng-container>
        <ng-container matColumnDef="departamento">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Departamento</th>
            <td mat-cell *matCellDef="let item">{{item.departamento}}</td>
        </ng-container>
        <ng-container matColumnDef="ciudad">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Ciudad</th>
            <td mat-cell *matCellDef="let item">{{item.ciudad}}</td>
        </ng-container>
        <ng-container matColumnDef="rowIdPedido">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>RowIdPedido</th>
            <td mat-cell *matCellDef="let item">{{item.rowIdPedido}}</td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns;"></tr>
    </table>
    <div *ngIf="loading" class="agent-container-loader">
        <div class="agent-loader"></div>
    </div>
    <mat-paginator [pageSizeOptions]="[5, 10, 15, 20, 30, 40, 50, 100]" showFirstLastButtons></mat-paginator>
</div>