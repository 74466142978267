<div class="coms-container">
    <p class="title">{{title}}</p>

    <div class="coms-container-button">
        <div class="coms-group">
            <input type="text" class="form-control" placeholder="Filtrar" (keyup)="filter($event)">
            <mat-icon class="blue-color">search</mat-icon>
        </div>
    </div>

    <table #table class="coms-table" mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="number">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Numero</th>
            <td mat-cell *matCellDef="let item" (click)="selectEntry(item)">{{item.number}}</td>
        </ng-container>
        <ng-container matColumnDef="customerName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Cliente</th>
            <td mat-cell *matCellDef="let item" (click)="selectEntry(item)">{{item.customerName}}</td>
        </ng-container>
        <ng-container matColumnDef="city">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Ciudad</th>
            <td mat-cell *matCellDef="let item" (click)="selectEntry(item)">{{item.city}}</td>
        </ng-container>
        <ng-container matColumnDef="origin">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Origen</th>
            <td mat-cell *matCellDef="let item" (click)="selectEntry(item)">{{item.origin}}</td>
        </ng-container>
        <ng-container matColumnDef="originType">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Tipo Origen</th>
            <td mat-cell *matCellDef="let item" (click)="selectEntry(item)">{{item.originType}}</td>
        </ng-container>
        <ng-container matColumnDef="userName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Usuario</th>
            <td mat-cell *matCellDef="let item" (click)="selectEntry(item)">{{item.userName}}</td>
        </ng-container>
        <ng-container matColumnDef="creationDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha de Creacion</th>
            <td mat-cell *matCellDef="let item" (click)="selectEntry(item)">{{item.creationDate}}</td>
        </ng-container>
        <ng-container matColumnDef="state">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Estado</th>
            <td mat-cell *matCellDef="let item" (click)="selectEntry(item)">{{item.state}}</td>
        </ng-container>
        <ng-container matColumnDef="active">
            <th mat-header-cell *matHeaderCellDef>Activo</th>
            <td mat-cell *matCellDef="let item">
                <mat-icon *ngIf="item.active" class="green-color">done</mat-icon>
                <mat-icon *ngIf="!item.active" class="red-color">clear</mat-icon>
            </td>
        </ng-container>
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Tipo Ingreso</th>
            <td mat-cell *matCellDef="let item">
                <mat-icon *ngIf="item.automatic" matTooltip="WFSM" class="green-color">precision_manufacturing</mat-icon>
                <mat-icon *ngIf="!item.automatic" matTooltip="WODEN" class="red-color">pan_tool</mat-icon>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns;"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="[5, 10, 15, 20, 30, 40, 50, 100]" showFirstLastButtons></mat-paginator>

    <div *ngIf="loading" class="coms-container-loader">
        <div class="coms-loader"></div>
    </div>

    <div class="coms-attachment-content">
        <div  *ngIf="chargeBotton" class="coms-group">
            <label class="primaryColor">Validar Base</label>
            <input id="base" #base hidden type="file" accept=".xlsx" (change)="load($event.target.files)">
            <button mat-mini-fab class="blue" matTooltip="Cargar" matTooltipPosition="above"
                matTooltipClass="tooltip" (click)="base.click()" >
                <mat-icon >cloud_upload</mat-icon>
            </button>
        </div>
        <div  *ngIf="uploading" class="coms-group">
            <label  class="primaryColor">Cargar Base</label>
            <button  mat-mini-fab class="blue" matTooltip="Subir" matTooltipPosition="above"
                matTooltipClass="tooltip" (click)="charge()" >
                <mat-icon >file_download_done</mat-icon>
            </button>
        </div>
    </div>

    <div *ngIf="selected" class="coms-group">
        <label class="primaryColor">Numero de Entrada</label>
        <label class="primaryColor">{{this.comCommodityEntryNumber}}</label>
    </div>

    <div *ngIf="loadingPreAlert" class="coms-container-loader">
        <div class="coms-loader"></div>
    </div>

    <table *ngIf="selected" #tableCharge class="coms-table" mat-table [dataSource]="listSerialPreAlert" matSortCharge>
        <ng-container matColumnDef="idCommodityEntryNumber">
            <th mat-header-cell *matHeaderCellDef>Codigo Endara</th>
            <td mat-cell *matCellDef="let charge">{{charge.idCommodityEntryNumber}}</td>
        </ng-container>
        <ng-container matColumnDef="orders">
            <th mat-header-cell *matHeaderCellDef>Pedido</th>
            <td mat-cell *matCellDef="let charge">{{charge.orders}}</td>
        </ng-container>
        <ng-container matColumnDef="transact">
            <th mat-header-cell *matHeaderCellDef>Tramite</th>
            <td mat-cell *matCellDef="let charge">{{charge.transact}}</td>
        </ng-container>
        <ng-container matColumnDef="codeAccesory">
            <th mat-header-cell *matHeaderCellDef>Codigo Accesorio</th>
            <td mat-cell *matCellDef="let charge">{{charge.codeAccesory}}</td>
        </ng-container>
        <ng-container matColumnDef="nameAccesory">
            <th mat-header-cell *matHeaderCellDef>Nombre Accesorio</th>
            <td mat-cell *matCellDef="let charge">{{charge.nameAccesory}}</td>
        </ng-container>
        <ng-container matColumnDef="quantity">
            <th mat-header-cell *matHeaderCellDef>Cantidad</th>
            <td mat-cell *matCellDef="let charge">{{charge.quantity}}</td>
        </ng-container>
        <ng-container matColumnDef="assignDate">
            <th mat-header-cell *matHeaderCellDef>Fecha Asignacion</th>
            <td mat-cell *matCellDef="let charge">{{charge.assignDate}}</td>
        </ng-container>
        <ng-container matColumnDef="recolectionDate">
            <th mat-header-cell *matHeaderCellDef>Fecha Recoleccion</th>
            <td mat-cell *matCellDef="let charge">{{charge.recolectionDate}}</td>
        </ng-container>
        <ng-container matColumnDef="active">
            <th mat-header-cell *matHeaderCellDef>Activo</th>
            <td mat-cell *matCellDef="let charge">
                <mat-icon *ngIf="charge.active" class="green-color">done</mat-icon>
                <mat-icon *ngIf="!charge.active" class="red-color">clear</mat-icon>
            </td>
        </ng-container>
        <ng-container matColumnDef="creationDate">
            <th mat-header-cell *matHeaderCellDef>Fecha Cargue</th>
            <td mat-cell *matCellDef="let charge">{{charge.creationDate}}</td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columnsPreAlert"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsPreAlert;"></tr>
    </table>
    <mat-paginator #paginatorCharge [pageSizeOptions]="[5, 10, 15, 20, 30, 40, 50, 100]" showFirstLastButtons></mat-paginator>
</div>