<div class="loc-container">
    <p class="title">Listado</p>
      <br>
      <div class="loc-group">
        <label class="primaryColor">Cliente</label>
        <select class="form-control" [(ngModel)]="opcionSeleccionado"   >
            <!--(change)="capturar()"-->
            <option class="ligthColor" value=0>Seleccione un cliente</option>
            <option *ngFor="let c of customerList" value="{{c.id}}">{{c.description}}</option>
        </select>
    </div>
        <div class>
        <button reset mat-mini-fab class="blue loc-but" matTooltip="Exportar" matTooltipPosition="above"
            matTooltipClass="tooltip" (click)="download()">
            <mat-icon>cloud_download</mat-icon>
        </button>
        <button mat-mini-fab class="loc-color-button loc-but" matTooltip="Generar IQ O9" matTooltipPosition="above"
            matTooltipClass="tooltip" (click)="getData()">
            <mat-icon>loop</mat-icon>
        </button>
    </div>
    <br>
    <table class="loc-table" mat-table  [dataSource]="dataSource">
        <ng-container matColumnDef="CLIENTE" >
            <th mat-header-cell *matHeaderCellDef>CLIENTE</th>
            <td mat-cell *matCellDef="let item">{{item.customer}}</td>
        </ng-container>
        <ng-container matColumnDef="SERIAL EQUIPO" >
            <th mat-header-cell *matHeaderCellDef>SERIAL EQUIPO</th>
            <td mat-cell *matCellDef="let item" >{{item.serial}}</td>
        </ng-container>
        <ng-container matColumnDef="CODIGO SAP">
            <th mat-header-cell *matHeaderCellDef>CODIGO SAP</th>
            <td mat-cell *matCellDef="let item">{{item.codSap}}</td>
        </ng-container>
        <ng-container matColumnDef="ESTADO">
            <th mat-header-cell *matHeaderCellDef>ESTADO</th>
            <td mat-cell *matCellDef="let item">{{item.status}}</td>
        </ng-container>
        <ng-container matColumnDef="TIPOLOGIA">
            <th mat-header-cell *matHeaderCellDef>TIPOLOGIA</th>
            <td mat-cell *matCellDef="let item">{{item.typology}}</td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns;"></tr>
    </table>
    <div *ngIf="loading==true" class="loc-container-loader">
        <div class="loc-loader"></div>
    </div>
    <mat-paginator #paginator [pageSizeOptions]="[5, 10, 15, 20, 30, 40, 50, 100]" showFirstLastButtons>
    </mat-paginator>
</div>