import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { ResponseModel } from 'src/app/shared/models/response.model';
import { FileGroupCustomerEntity} from '../entities/fileGroupCustomer.entity';

@Injectable({
    providedIn:'root'
})
export class FileGroupCustomerService{
    
    private url:string;

    constructor(private http:HttpClient){
    this.url=environment.api;
        //this.url=environment.apiIq09;
    }
    public create(groupId:number,customerId:number):Observable<ResponseModel>{
        var headers = new HttpHeaders().set('content-Type', 'application/json');
        var body=new FileGroupCustomerEntity;
        body.groupId=groupId;
        body.customerId=customerId;
        
        return this.http.post<ResponseModel>(this.url+'FileGroupCustomer/create',JSON.stringify(body),{headers:headers});
    }

    public delete(groupId:number,customerId:number):Observable<ResponseModel>{
        var headers = new HttpHeaders().set('content-Type', 'application/x-www-form-urlencoded');

        return this.http.delete<ResponseModel>(this.url+'FileGroupCustomer/delete/'+groupId+'/'+customerId,{ headers: headers });
    }

    public list(groupId:number):Observable<ResponseModel>{
        var headers = new HttpHeaders().set('content-Type', 'application/x-www-form-urlencoded');
        return this.http.get<ResponseModel>(this.url+'FileGroupCustomer/list/'+groupId,{headers:headers});
    }
}