import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { event } from 'jquery';
import * as moment from 'moment';
import { AlertService } from 'src/app/shared/services/alert.service';
import { MeeGroupEntity } from '../../entities/meeGroup.entity';
import { MeeGroupService } from '../../services/meeGroup.service';
import { MeegroupnewModal } from '../meegroupnew/meegroupnew.modal';

@Component({
  selector: 'app-program-meegroup',
  templateUrl: './meegroupProg.modal.html',
  styleUrls: ['./meegroupProg.modal.css'],
})
export class MeegroupProg implements OnInit {
  title: string;
  form = new FormGroup({
    id: new FormControl(''),
    name: new FormControl(''),
    description: new FormControl(''),
    daysPro: new FormControl(''),
    hoursPro: new FormControl(''),
    dateMaxPro: new FormControl(''),
    active: new FormControl(''),
  });
  fechaMax: Date;
  opcion1 = false;
  opcion2 = false;
  opcion3 = false;
  opcion4 = false;
  opcion5 = false;
  opcion6 = false;
  opcion7 = false;
  tiempo1: number;
  tiempo2: number;
  estatus: boolean = false;
  daysTotal: string;
  meeGroupEntity: MeeGroupEntity;
  constructor(
    private alertS: AlertService,
    private meeGroupS: MeeGroupService,
    public dialogRef: MatDialogRef<MeegroupProg>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.title = '';
    dialogRef.disableClose = true;
    this.meeGroupEntity = new MeeGroupEntity();
  }
  ngOnInit(): void {
    if (this.data.meetingGroup.daysPro != null && this.data.meetingGroup.daysPro != '') 
    {
      if (this.data.meetingGroup.hoursPro != null) {
        let hora = this.data.meetingGroup.hoursPro;
        let horas = hora.split(';');
        this.tiempo1 = horas[0];
        this.tiempo2 = horas[1];
      }
      let dia = this.data.meetingGroup.daysPro;
      let dias = dia.split(';');
      this.verificar(dias);
      this.operar();
      if (this.data.meetingGroup.dateMaxPro != null) {
        this.onCheckboxChange(null);
      }
      
      this.title = 'Editar Programacion';
    } else {
      this.title = 'Nueva Programacion';
    }
  }

  onCheckboxChange($event: Event) {
    this.estatus = !this.estatus;
    this.fechaMax = this.data.meetingGroup.dateMaxPro;
  }

  verificar(obj: any) {
    if (obj.includes('Monday')) {
      this.opcion1 = true;
    }
    if (obj.includes('Tuesday')) {
      this.opcion2 = true;
    }
    if (obj.includes('Wednesday')) {
      this.opcion3 = true;
    }
    if (obj.includes('Thursday')) {
      this.opcion4 = true;
    }
    if (obj.includes('Friday')) {
      this.opcion5 = true;
    }
    if (obj.includes('Saturday')) {
      this.opcion6 = true;
    }
    if (obj.includes('Sunday')) {
      this.opcion7 = true;
    }
  }
  operar() {
    this.daysTotal = '';
    if (this.opcion1) {
      this.daysTotal = `;Monday`;
    }
    if (this.opcion2) {
      this.daysTotal += `;Tuesday`;
    }
    if (this.opcion3) {
      this.daysTotal += `;Wednesday`;
    }
    if (this.opcion4) {
      this.daysTotal += `;Thursday`;
    }
    if (this.opcion5) {
      this.daysTotal += `;Friday`;
    }
    if (this.opcion6) {
      this.daysTotal += `;Saturday`;
    }
    if (this.opcion7) {
      this.daysTotal += `;Sunday`;
    }
  }

  save() {
    this.operar();
    this.form.setValue({
      id: this.data.meetingGroup.id,
      name: this.data.meetingGroup.name,
      description: this.data.meetingGroup.description,
      daysPro:this.daysTotal != null && this.daysTotal != '' ? this.daysTotal : null,
      hoursPro:this.tiempo1 == undefined ||this.tiempo2 == undefined ||this.tiempo1 === null ||this.tiempo2 === null? null: this.tiempo1 + ';' + this.tiempo2,
      dateMaxPro:this.fechaMax != null? moment(this.fechaMax).format('YYYY-MM-DD'): null,
      active: this.data.meetingGroup.active,
    });
    this.meeGroupS.update(this.form.value).subscribe(
      (res) => {
        if (res.message === 'OK') {
          if (res.object != 0) {
            this.alertS.open('Programa actualizado', 'success');
            this.close(true);
          } else {
            this.alertS.open('Error al actualizar el programa', 'error');
          }
        } else {
          this.alertS.open(res.message, 'error');
        }
      },
      (err) => {
        this.alertS.open(err.message, 'error');
      }
    );
  }

  close(status: boolean): void {
    this.dialogRef.close(status);
  }
}
