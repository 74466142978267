<div mat-dialog-content>
    <div class="person-container-button">
        <button mat-icon-button (click)="close(0,'','','')">
            <mat-icon>clear</mat-icon>
        </button>
    </div>
    <p class="title">Listado de Usuarios</p>
    <div class="person-container-search">
        <div class="person-group">
            <input type="text" class="form-control" placeholder="Filtrar" (keyup)="filter($event)">
            <mat-icon class="blue-color">search</mat-icon>
        </div>
    </div>
    <table #table class="person-table" mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="firstName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Nombres</th>
            <td mat-cell *matCellDef="let item" (click)="close(item.id,item.firstName,item.lastName,item.mail)">{{item.firstName}}</td>
        </ng-container>
        <ng-container matColumnDef="lastName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Apellidos</th>
            <td mat-cell *matCellDef="let item" (click)="close(item.id,item.firstName,item.lastName,item.mail)">{{item.lastName}}</td>
        </ng-container>
        <ng-container matColumnDef="userName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Usuario</th>
            <td mat-cell *matCellDef="let item" (click)="close(item.id,item.firstName,item.lastName,item.mail)">{{item.userName}}</td>
        </ng-container>
        <ng-container matColumnDef="mail">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Correo</th>
            <td mat-cell *matCellDef="let item" (click)="close(item.id,item.firstName,item.lastName,item.mail)">{{item.mail}}</td>
        </ng-container>
        <ng-container matColumnDef="position">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Cargo</th>
            <td mat-cell *matCellDef="let item" (click)="close(item.id,item.firstName,item.lastName,item.mail)">{{item.position}}</td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns;"></tr>
    </table>
    <div *ngIf="loading" class="person-container-loader">
        <div class="person-loader"></div>
    </div>
    <mat-paginator [pageSizeOptions]="[5, 10, 15, 20, 30, 40, 50, 100]" showFirstLastButtons></mat-paginator>
</div>