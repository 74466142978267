export class PqrEscalationEntity {

    id:number;
    message: string;
    number: string;
    creationDate: string;
    userId: number;
    name: string;
    active: boolean;
    customerId: number;


    constructor (){}
}