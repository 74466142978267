<div class="coms-heid-tam">
    <div >

        <div class="coms-end">
            <button mat-icon-button (click)="closeEntry()" >
                <mat-icon>clear</mat-icon>
            </button>
        </div>

        <div class="coms-container-button">
            <div class="coms-group">
                <input type="text" class="form-control" placeholder="Filtrar" (keyup)="filter($event)">
                <mat-icon class="blue-color">search</mat-icon>
            </div>
        </div>
        
        <table #table class="coms-table" mat-table [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="number">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Numero</th>
                <td mat-cell *matCellDef="let item" (click)="close(item)">{{item.number}}</td>
            </ng-container>
            <ng-container matColumnDef="customerName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Cliente</th>
                <td mat-cell *matCellDef="let item" (click)="close(item)">{{item.customerName}}</td>
            </ng-container>
            <ng-container matColumnDef="city">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Ciudad</th>
                <td mat-cell *matCellDef="let item" (click)="close(item)">{{item.city}}</td>
            </ng-container>
            <ng-container matColumnDef="origin">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Origen</th>
                <td mat-cell *matCellDef="let item" (click)="close(item)">{{item.origin}}</td>
            </ng-container>
            <ng-container matColumnDef="originType">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Tipo Origen</th>
                <td mat-cell *matCellDef="let item" (click)="close(item)">{{item.originType}}</td>
            </ng-container>
            <ng-container matColumnDef="userName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Usuario</th>
                <td mat-cell *matCellDef="let item" (click)="close(item)">{{item.userName}}</td>
            </ng-container>
            <ng-container matColumnDef="creationDate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha de Creacion</th>
                <td mat-cell *matCellDef="let item" (click)="close(item)">{{item.creationDate}}</td>
            </ng-container>
            <ng-container matColumnDef="state">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Estado</th>
                <td mat-cell *matCellDef="let item" (click)="close(item)">{{item.state}}</td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="columns"></tr>
            <tr mat-row *matRowDef="let row; columns: columns;"></tr>
        </table>
        <div *ngIf="loading" class="report-container-loader">
            <div class="report-loader"></div>
        </div>
        <mat-paginator [pageSizeOptions]="[5, 10, 15, 20, 30, 40, 50, 100]" showFirstLastButtons></mat-paginator>    
    </div>
</div>