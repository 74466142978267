<div class="agent-container">
    <p class="title">Nuevo Pais</p>
    <form [formGroup]="form" class="agent-form">
        <div class="agent-group">
            <label class="primaryColor">Codigo</label>
            <input formControlName="codigo" type="text" class="form-control" placeholder="Codigo">
        </div>
        <div class="agent-group">
            <label class="primaryColor">Codigo 1</label>
            <input formControlName="codigo1" type="text" class="form-control" placeholder="Codigo 1">
        </div>
        <div class="agent-group">
            <label class="primaryColor">Nombre</label>
            <input formControlName="nombre" type="text" class="form-control" placeholder="Nombre">
        </div>
        <div *ngIf="editing !=0" class="agent-group-checkbox">
            <label class="primaryColor">Activo</label>
            <div class="agent-container-checkbox" matRipple [matRippleCentered]="true" [matRippleRadius]="40">
                <div class="agent-checkbox">
                    <input id="check" formControlName="activo" type="checkbox">
                    <label class="agent-label" for="check">
                        <mat-icon>done</mat-icon>
                    </label>
                </div>
            </div>
        </div>
        <div class="agent-container-button">
            <button class="agent-button" mat-raised-button matTooltip="Guardar" matTooltipPosition="right" matTooltipClass="tooltip" [disabled]="!form.valid" (click)="onClick()">
                <mat-icon>save</mat-icon>
            </button>
        </div>
    </form>
    <p class="title">Listado Paises</p>
    <div class="agent-search-container-button">
        <div class="agent-search-group">
            <input type="text" class="form-control" placeholder="Filtrar" (keyup)="filter($event)">
            <mat-icon class="blue-color">search</mat-icon>
        </div>
    </div>
    <table #table class="agent-table" mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let item">
                <button *ngIf="item.id === editing" mat-icon-button matTooltip="Cerrar Edicion"
                    matTooltipPosition="above" matTooltipClass="tooltip" (click)="closeEditing()">
                    <mat-icon class="ligthColor">close</mat-icon>
                </button>
            </td>
        </ng-container>
        <ng-container matColumnDef="codigo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Codigo</th>
            <td mat-cell *matCellDef="let item">{{item.codigo}}</td>
        </ng-container>
        <ng-container matColumnDef="codigo1">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Codigo 1</th>
            <td mat-cell *matCellDef="let item">{{item.codigo1}}</td>
        </ng-container>
        <ng-container matColumnDef="nombre">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Nombre</th>
            <td mat-cell *matCellDef="let item">{{item.nombre}}</td>
        </ng-container>
        <ng-container matColumnDef="activo">
            <th mat-header-cell *matHeaderCellDef>Activo</th>
            <td mat-cell *matCellDef="let item">
                <mat-icon *ngIf="item.activo" class="green-color">done</mat-icon>
                <mat-icon *ngIf="!item.activo" class="red-color">clear</mat-icon>
            </td>
        </ng-container>
        <ng-container matColumnDef="acciones">
        <th mat-header-cell *matHeaderCellDef>Acciones</th>
        <td mat-cell *matCellDef="let item">
            <button mat-icon-button matTooltip="Editar" matTooltipPosition="above" matTooltipClass="tooltip" (click)="edit(item)">
                <mat-icon class="blue-color">create</mat-icon>
            </button>
            <button mat-icon-button matTooltip="Eliminar" matTooltipPosition="above" matTooltipClass="tooltip"
                (click)="delete(item.id)">
                <mat-icon class="red-color">delete</mat-icon>
            </button>
        </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns;"></tr>
    </table>
    <div *ngIf="loading" class="agent-container-loader">
        <div class="agent-loader"></div>
    </div>
    <mat-paginator [pageSizeOptions]="[5, 10, 15, 20, 30, 40, 50, 100]" showFirstLastButtons></mat-paginator>
</div>