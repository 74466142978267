export class DocumentTypeEntity{
    id: number;
    groupDocument: string;
    description: string;
    idGroup: number;
    createUserId: number;
    active: boolean;
    idCode: number;

    constructor(){}
}
 