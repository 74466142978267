<div class="docType-container">
    <p class="title">{{tittle}}</p>
    <form [formGroup]="form" class="docType-form">
        <div class="docType-group">
            <label class="primaryColor">Nombre Tipo de documento</label>
            <input name="groupDocument" formControlName="groupDocument" type="text" class="form-control text-may"
                placeholder="Nombre Tipo de documento">
            <div class="invalid-text" *ngIf="form.get('groupDocument').invalid && form.get('groupDocument').touched">
                Ingrese grupo valido</div>
        </div>

        <div class="docType-group">
            <label class="primaryColor">Descripción</label>
            <input name="description" formControlName="description" type="text" class="form-control text-may"
                placeholder="Descripción">
            <div class="invalid-text" *ngIf="form.get('description').invalid && form.get('description').touched">Ingrese
                una descripción valida</div>
        </div>
        <div class="docType-group">
            <label class="primaryColor">Grupo Documento</label>
            <select formControlName="idGroup" class="form-control">
                <option class="ligthColor" value=null>Seleccione un grupo de documento</option>
                <option *ngFor="let c of TypeList" value="{{c.id}}">{{c.description}}</option>
            </select>
            <div class="invalid-text" *ngIf="form.get('idGroup').invalid && form.get('idGroup').touched">Seleccione una
                opcion
                valida</div>
        </div>

        <div class="docType-group">
            <label class="primaryColor">Nivel de acceso</label>
            <select formControlName="idCode" class="form-control">
                <option class="ligthColor" value=null>Seleccione Nivel de acceso</option>
                <option *ngFor="let c of LevelList" value="{{c.id}}">{{c.description}}</option>
            </select>
            <div class="invalid-text" *ngIf="form.get('idCode').invalid && form.get('idCode').touched">Seleccione una
                opcion
                valida</div>
        </div>


        <div *ngIf="docTypeformId != 0" class="docType-group-checkbox">
            <label class="primaryColor">Activo</label>
            <div class="docType-container-checkbox" matRipple [matRippleCentered]="true" [matRippleRadius]="40">
                <div class="docType-checkbox">
                    <input id="check" formControlName="active" type="checkbox">
                    <label class="docType-label" for="check">
                        <mat-icon>done</mat-icon>
                    </label>
                </div>
            </div>
        </div>

        <div class="docType-group">
            <label class="primaryColor">Guardar</label>
            <div class="docType-container-button">
                <button class="docType-button" mat-raised-button matTooltip="Guardar" mat-raised-button
                    [disabled]="!form.valid" matTooltipPosition="right" matTooltipClass="tooltip" (click)="save()">
                    <mat-icon>save</mat-icon>
                </button>
            </div>
        </div>
    </form>
</div>