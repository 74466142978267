<div class="con-container">
    <p class="title">{{title}}</p>
    <form [formGroup]="form" class="con-form">

        <div class="con-group">
            <label class="primaryColor">Cliente</label>
            <select formControlName="customerId" class="form-control">
                <option class="ligthColor" value=null>Seleccione un cliente</option>
                <option *ngFor="let c of customerList" value="{{c.id}}">{{c.description}}</option>
            </select>
        </div>
        
        <div class="con-group">
            <label class="primaryColor">Pais</label>
            <select formControlName="countryId" class="form-control">
                <option class="ligthColor" value=null>Seleccione un pais</option>
                <option *ngFor="let c of countryList" value="{{c.id}}">{{c.name}}</option>
            </select>
        </div>

        <div class="con-group">
            <label class="primaryColor">Driver</label>
            <input name="driver" formControlName="driver" class="form-control text-may" placeholder="Driver">
            <div class="invalid-text" *ngIf="form.get('driver').invalid && form.get('driver').touched">Ingrese un driver
                valido</div>
        </div>

        <div class="con-group">
            <label class="primaryColor">Url</label>
            <input formControlName="url" type="text" class="form-control text-may" placeholder="Descripcion">
            <div class="invalid-text" *ngIf="form.get('url').invalid && form.get('url').touched">Ingrese un url valida
            </div>
        </div>

        <div class="con-group">
            <label class="primaryColor">Nombre de usuario</label>
            <input formControlName="name" type="text" class="form-control text-may" placeholder="Nombre de usuario">
            <div class="invalid-text" *ngIf="form.get('name').invalid && form.get('name').touched">Ingrese una
                descripcion valida</div>
        </div>

        <div class="con-group">
            <label class="primaryColor">Contraseña</label>
            <input formControlName="password" type="text" class="form-control text-may" placeholder="Contraseña">
            <div class="invalid-text" *ngIf="form.get('password').invalid && form.get('password').touched">Ingrese una
                contraseña valida</div>
        </div>
        <div *ngIf="formId != 0" class="con-group-checkbox">
            <label class="primaryColor">Activo</label>
            <div class="con-container-checkbox" matRipple [matRippleCentered]="true" [matRippleRadius]="40">
                <div class="con-checkbox">
                    <input id="check" formControlName="active" type="checkbox">
                    <label class="con-label" for="check">
                        <mat-icon>done</mat-icon>
                    </label>
                </div>
            </div>
        </div>
        <div class="con-group">
            <label class="primaryColor">Guardar</label>
            <div class="con-container-button">
                <button type="reset" class="con-button" mat-raised-button matTooltip="Guardar" mat-raised-button
                    [disabled]="!form.valid" matTooltipPosition="right" matTooltipClass="tooltip" (click)="save()">
                    <mat-icon>save</mat-icon>
                </button>
            </div>
        </div>
    </form>
</div>