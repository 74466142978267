<div mat-dialog-content>
    <div class="files-container-button pqr-close">
        <button mat-icon-button (click)="close()">
           <mat-icon>clear</mat-icon>
        </button>
    </div>  
    <p class="title">Documentacion</p>
    <div class="pqr-container-files">
        <p class="title" >Archivos</p>
        <div *ngIf="loadingDay" class="pqr-container-loader">
            <div class="pqr-loader"></div>
        </div>
        <div *ngIf="!loadingDay" class="pqr-content-files">
            <div *ngFor="let f of fileListStart" class="pqr-card-files" matTooltipPosition="above"
                matTooltipClass="tooltip">
                <p>{{f.type}}</p>
                <button mat-icon-button matTooltip="Descargar" matTooltipPosition="below" matTooltipClass="tooltip"
                        (click)="download(f)">
                        <mat-icon class="blue-color">cloud_download</mat-icon>
                    </button>
            </div>
        </div>
    </div>
</div>