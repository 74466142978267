<div class="pqr-container">
    <p class="title">Consultar PQRS</p>
    <div class="pqr-form">
        <div class="pqr-group">
            <label class="primaryColor">Consultar por:</label>
            <select [(ngModel)]="searchType" class="form-control">
                <option class="ligthColor" value="">Seleccione una opcion</option>
                <option value="Numero de identificacion">Numero de identificacion</option>
                <option value="Numero de ticket">Numero de ticket</option>
                <option value="Numero de PQRS">Numero de PQRS</option>
                <option value="Serial/Imei">Serial/Imei</option>
            </select>
        </div>
        <div *ngIf="searchType != ''" class="pqr-group">
            <label class="primaryColor">{{searchType}}</label>
            <input [(ngModel)]="searchData" type="text" class="form-control" placeholder="{{searchType}}">
        </div>
        <div class="pqr-container-button">
            <button class="pqr-button" [disabled]="searchType === ''" mat-raised-button matTooltip="Buscar" matTooltipPosition="right"
                matTooltipClass="tooltip" (click)="search()">
                <mat-icon>search</mat-icon>
            </button>
        </div>
    </div>
    <div *ngIf="loadingPqrs" class="pqr-container-loader">
        <div class="pqr-loader"></div>
    </div>
    <div *ngIf="pqrsList.length > 0 && !loadingPqrs" class="pqr-content">
        <div *ngFor="let p of pqrsList" class="pqr-card" (click)="detail(p.number)">
            <div (click)="tracing(p.number)"></div>
            <p class="pqr-card-title">Tramite</p>
            <p class="pqr-card-content">{{p.type}}</p>
            <p class="pqr-card-title">Ticket</p>
            <p class="pqr-card-content">{{p.ticket}}</p>
            <p class="pqr-card-title">Numero</p>
            <p class="pqr-card-content">{{p.number}}</p>
        </div>
    </div>
    <p *ngIf="tracingList.length > 0" class="title" [ngStyle]="{'text-align':'center'}">Seguimiento PQRS</p>
    <div *ngIf="loadingTracing" class="pqr-container-loader">
        <div class="pqr-loader"></div>
    </div>
    <div *ngIf="tracingList.length > 0 && !loadingTracing" class="pqr-container-tracing">
        <ng-container *ngFor="let t of tracingList">
            <div class="pqr-tracking-content">
                <div class="pqr-tracking-circle" matTooltip="{{t.observations}}" matTooltipPosition="above" matTooltipClass="tooltip">
                    <mat-icon>done</mat-icon>
                </div>
                <div class="pqr-tracking-information">
                    <span class="pqr-tracking-title">{{t.eventStatus}}</span>
                    <span class="pqr-tracking-subtitle">{{t.user}}</span>
                    <span class="pqr-tracking-subtitle">{{t.date}}</span>
                </div>
                <div class="pqr-tracking-line"></div>
            </div>
        </ng-container>
    </div>
    <p *ngIf="pqrPqrsEntity!=null" class="title" [ngStyle]="{'text-align':'center'}">Detalle PQRS</p>
    <div *ngIf="pqrPqrsEntity!=null" class="pqr-detail-container">
        <div class="detail-card">
            <div class="detail-content-icon">
                <mat-icon [ngStyle]="{'color':'#f44336'}">local_offer</mat-icon>
            </div>
            <div class="detail-content">
                <p class="detail-title">Ticket</p>
                <p class="detail-subtitle">{{pqrPqrsEntity.ticket}}</p>
            </div>
            <div class="detail-content-icon">
                <mat-icon [ngStyle]="{'color':'#2196f3'}">format_list_numbered</mat-icon>
            </div>
            <div class="detail-content">
                <p class="detail-title">Numero</p>
                <p class="detail-subtitle">{{pqrPqrsEntity.number}}</p>
            </div>
        </div>
        <div class="detail-card">
            <div class="detail-content-icon">
                <mat-icon [ngStyle]="{'color':'#ff9800'}">account_box</mat-icon>
            </div>
            <div class="detail-content">
                <p class="detail-title">Usuario de Creacion</p>
                <p class="detail-subtitle">{{pqrPqrsEntity.creationPerson}}</p>
            </div>
            <div class="detail-content-icon">
                <mat-icon [ngStyle]="{'color':'#00e5ff'}">date_range</mat-icon>
            </div>
            <div class="detail-content">
                <p class="detail-title">Fecha de Reporte</p>
                <p class="detail-subtitle">{{pqrPqrsEntity.creationDate}}</p>
            </div>
        </div>
        <div class="detail-card">
            <div class="detail-content-icon">
                <mat-icon [ngStyle]="{'color':'#00bcd4'}">how_to_reg</mat-icon>
            </div>
            <div class="detail-content">
                <p class="detail-title">Tipo de Cliente</p>
                <p class="detail-subtitle">{{pqrPqrsEntity.customerType}}</p>
            </div>
            <div class="detail-content-icon">
                <mat-icon [ngStyle]="{'color':'#009688'}">leaderboard</mat-icon>
            </div>
            <div class="detail-content">
                <p class="detail-title">Proyecto</p>
                <p class="detail-subtitle">{{pqrPqrsEntity.proyect}}</p>
            </div>
        </div>
        <div class="detail-card">
            <div class="detail-content-icon">
                <mat-icon [ngStyle]="{'color':'#fdd835'}">account_circle</mat-icon>
            </div>
            <div class="detail-content">
                <p class="detail-title">Usuario Solicitante</p>
                <p class="detail-subtitle">{{pqrPqrsEntity.firstName}} {{pqrPqrsEntity.lastName}}</p>
            </div>
            <div class="detail-content-icon">
                <mat-icon [ngStyle]="{'color':'#607d8b'}">contact_page</mat-icon>
            </div>
            <div class="detail-content">
                <p class="detail-title">Agente Asignado</p>
                <p class="detail-subtitle">{{pqrPqrsEntity.assignedPerson}}</p>
            </div>
        </div>
        <div class="detail-card">
            <div class="detail-content-icon">
                <mat-icon [ngStyle]="{'color':'#673ab7'}">airplanemode_active</mat-icon>
            </div>
            <div class="detail-content">
                <p class="detail-title">Pais</p>
                <p class="detail-subtitle">{{pqrPqrsEntity.country}}</p>
            </div>
            <div class="detail-content-icon">
                <mat-icon [ngStyle]="{'color':'#ff80ab'}">flight_takeoff</mat-icon>
            </div>
            <div class="detail-content">
                <p class="detail-title">Departamento</p>
                <p class="detail-subtitle">{{pqrPqrsEntity.department}}</p>
            </div>
        </div>
        <div class="detail-card">
            <div class="detail-content-icon">
                <mat-icon [ngStyle]="{'color':'#00e676'}">flight_land</mat-icon>
            </div>
            <div class="detail-content">
                <p class="detail-title">Ciudad</p>
                <p class="detail-subtitle">{{pqrPqrsEntity.city}}</p>
            </div>
            <div class="detail-content-icon">
                <mat-icon [ngStyle]="{'color':'#e040fb'}">flight_takeoff</mat-icon>
            </div>
            <div class="detail-content">
                <p class="detail-title">Regional</p>
                <p class="detail-subtitle">{{pqrPqrsEntity.regional}}</p>
            </div>
        </div>
        <div class="detail-card">
            <div class="detail-content-icon">
                <mat-icon [ngStyle]="{'color':'#f44336'}">class</mat-icon>
            </div>
            <div class="detail-content">
                <p class="detail-title">Marca/Modelo</p>
                <p class="detail-subtitle">{{pqrPqrsEntity.tradeMark}}</p>
            </div>
            <div class="detail-content-icon">
                <mat-icon [ngStyle]="{'color':'#2196f3'}">list</mat-icon>
            </div>
            <div class="detail-content">
                <p class="detail-title">Categoria</p>
                <p class="detail-subtitle">{{pqrPqrsEntity.category}}</p>
            </div>
        </div>
        <div class="detail-card">
            <div class="detail-content-icon">
                <mat-icon [ngStyle]="{'color':'#ff9800'}">trending_down</mat-icon>
            </div>
            <div class="detail-content">
                <p class="detail-title">Metodo de Contacto</p>
                <p class="detail-subtitle">{{pqrPqrsEntity.contactMethod}}</p>
            </div>
            <div class="detail-content-icon">
                <mat-icon [ngStyle]="{'color':'#00e5ff'}">email</mat-icon>
            </div>
            <div class="detail-content">
                <p class="detail-title">Correo de contacto</p>
                <p class="detail-subtitle">{{pqrPqrsEntity.contactEmail}}</p>
            </div>
        </div>
        <div class="detail-card">
            <div class="detail-content-icon">
                <mat-icon [ngStyle]="{'color':'#00bcd4'}">supervisor_account</mat-icon>
            </div>
            <div class="detail-content">
                <p class="detail-title">Area Responsable</p>
                <p class="detail-subtitle">{{pqrPqrsEntity.responsibleArea}}</p>
            </div>
            <div class="detail-content-icon">
                <mat-icon [ngStyle]="{'color':'#009688'}">grading</mat-icon>
            </div>
            <div class="detail-content">
                <p class="detail-title">Seriales</p>
                <p class="detail-subtitle">{{pqrPqrsEntity.serial}}</p>
            </div>
        </div>
        <div class="detail-card">
            <div class="detail-content-icon">
                <mat-icon [ngStyle]="{'color':'#fdd835'}">gavel</mat-icon>
            </div>
            <div class="detail-content">
                <p class="detail-title">Tipo de PQRS</p>
                <p class="detail-subtitle">{{pqrPqrsEntity.type}}</p>
            </div>
            <div class="detail-content-icon">
                <mat-icon [ngStyle]="{'color':'#607d8b'}">assignment</mat-icon>
            </div>
            <div *ngIf="pqrPqrsEntity.summary == null; else notequal " class="detail-content">
                <p class="detail-title">Resumen de la Solicitud</p>
                <p class="detail-subtitle">{{pqrPqrsEntity.observation}}</p>
            </div>
            <ng-template #notequal>
                <div class="detail-content">
                    <p class="detail-title">Resumen de la Solicitud</p>
                    <p class="detail-subtitle">{{pqrPqrsEntity.summary}}</p>
                </div>
            </ng-template>
        </div>
        <div class="detail-card">
            <div class="detail-content-icon">
                <mat-icon [ngStyle]="{'color':'#673ab7'}">looks_one</mat-icon>
            </div>
            <div class="detail-content">
                <p class="detail-title">Cantidad de Ingresos</p>
                <p class="detail-subtitle">{{pqrPqrsEntity.entryNumber}}</p>
            </div>
            <div class="detail-content-icon">
                <mat-icon [ngStyle]="{'color':'#ff80ab'}">donut_large</mat-icon>
            </div>
            <div class="detail-content">
                <p class="detail-title">Estado</p>
                <p class="detail-subtitle">{{pqrPqrsEntity.status}}</p>
            </div>
        </div>
        <div class="detail-card">
            <div class="detail-content-icon">
                <mat-icon [ngStyle]="{'color':'#00e676'}">close_fullscreen</mat-icon>
            </div>
            <div class="detail-content">
                <p class="detail-title">Estado Final</p>
                <p class="detail-subtitle">{{pqrPqrsEntity.finalStatus}}</p>
            </div>
            <div class="detail-content-icon">
                <mat-icon [ngStyle]="{'color':'#e040fb'}">face</mat-icon>
            </div>
            <div class="detail-content">
                <p class="detail-title">Tecnico Responsable</p>
                <p class="detail-subtitle">{{pqrPqrsEntity.technical}}</p>
            </div>
        </div>
        <div class="detail-card">
            <div class="detail-content-icon">
                <mat-icon [ngStyle]="{'color':'#f44336'}">add_a_photo</mat-icon>
            </div>
            <div class="detail-content">
                <p class="detail-title">Registro Fotografico</p>
                <p class="detail-subtitle">{{pqrPqrsEntity.photographicRecord}}</p>
            </div>
            <div class="detail-content-icon">
                <mat-icon [ngStyle]="{'color':'#2196f3'}">build</mat-icon>
            </div>
            <div class="detail-content">
                <p class="detail-title">Diagnostico Tecnico-Reparacion</p>
                <p class="detail-subtitle">{{pqrPqrsEntity.diagnostic}}</p>
            </div>
        </div>
        <div class="detail-card">
            <div class="detail-content-icon">
                <mat-icon [ngStyle]="{'color':'#ff9800'}">date_range</mat-icon>
            </div>
            <div class="detail-content">
                <p class="detail-title">Fecha Escalamiento Cliente Woden</p>
                <p class="detail-subtitle">{{pqrPqrsEntity.customerEscalationDate}}</p>
            </div>
            <div class="detail-content-icon">
                <mat-icon [ngStyle]="{'color':'#00e5ff'}">date_range</mat-icon>
            </div>
            <div class="detail-content">
                <p class="detail-title">Fecha Respuesta Escalamiento Cliente Woden</p>
                <p class="detail-subtitle">{{pqrPqrsEntity.customerEscalationResponseDate}}</p>
            </div>
        </div>
        <div class="detail-card">
            <div class="detail-content-icon">
                <mat-icon [ngStyle]="{'color':'#00bcd4'}">face</mat-icon>
            </div>
            <div class="detail-content">
                <p class="detail-title">Agente Escalamiento Interno</p>
                <p class="detail-subtitle">{{pqrPqrsEntity.internalEscalationAgent}}</p>
            </div>
            <div class="detail-content-icon">
                <mat-icon [ngStyle]="{'color':'#009688'}">date_range</mat-icon>
            </div>
            <div class="detail-content">
                <p class="detail-title">Fecha Escalamiento Interno</p>
                <p class="detail-subtitle">{{pqrPqrsEntity.internalEscalationDate}}</p>
            </div>
        </div>


        <div class="detail-card">
            <div class="detail-content-icon">
                <mat-icon [ngStyle]="{'color':'#fdd835'}">date_range</mat-icon>
            </div>
            <div class="detail-content">
                <p class="detail-title">Fecha Respuesta Escalamiento Interno</p>
                <p class="detail-subtitle">{{pqrPqrsEntity.internalEscalationResponseDate}}</p>
            </div>
            <div class="detail-content-icon">
                <mat-icon [ngStyle]="{'color':'#607d8b'}">format_list_numbered</mat-icon>
            </div>
            <div class="detail-content">
                <p class="detail-title">Nivel</p>
                <p class="detail-subtitle">{{pqrPqrsEntity.levelNumber}}</p>
            </div>
        </div>
        <div class="detail-card">
            <div class="detail-content-icon">
                <mat-icon [ngStyle]="{'color':'#673ab7'}">date_range</mat-icon>
            </div>
            <div class="detail-content">
                <p class="detail-title">Fecha Respuesta Cliente PQRS</p>
                <p class="detail-subtitle">{{pqrPqrsEntity.responseDateCustomerPqrs}}</p>
            </div>
            <div class="detail-content-icon">
                <mat-icon [ngStyle]="{'color':'#ff80ab'}">timeline</mat-icon>
            </div>
            <div class="detail-content">
                <p class="detail-title">Estado Gestion</p>
                <p class="detail-subtitle">{{pqrPqrsEntity.managementStatus}}</p>
            </div>
        </div>
        <div class="detail-card">
            <div class="detail-content-icon">
                <mat-icon [ngStyle]="{'color':'#00e676'}">trending_down</mat-icon>
            </div>
            <div class="detail-content">
                <p class="detail-title">Metodo Contacto Final</p>
                <p class="detail-subtitle">{{pqrPqrsEntity.finalContactMethod}}</p>
            </div>
            <div class="detail-content-icon">
                <mat-icon [ngStyle]="{'color':'#e040fb'}">looks_one</mat-icon>
            </div>
            <div class="detail-content">
                <p class="detail-title">Cantidad de Equipos</p>
                <p class="detail-subtitle">{{pqrPqrsEntity.equipmentQuantity}}</p>
            </div>
        </div>
        <div class="detail-card">
            <div class="detail-content-icon">
                <mat-icon [ngStyle]="{'color':'#f44336'}">list</mat-icon>
            </div>
            <div class="detail-content">
                <p class="detail-title">PQRS Atribuible</p>
                <p class="detail-subtitle">{{pqrPqrsEntity.attributablePqrs}}</p>
            </div>
            <div class="detail-content-icon">
                <mat-icon [ngStyle]="{'color':'#2196f3'}">assignment</mat-icon>
            </div>
            <div class="detail-content">
                <p class="detail-title">Observaciones Woden</p>
                <p class="detail-subtitle">{{pqrPqrsEntity.observations}}</p>
            </div>
        </div>
        <div class="detail-card">
            <div class="detail-content-icon">
                <mat-icon [ngStyle]="{'color':'#ff9800'}">date_range</mat-icon>
            </div>
            <div class="detail-content">
                <p class="detail-title">Fecha Envio Unidades</p>
                <p class="detail-subtitle">{{pqrPqrsEntity.unitShipmentDate}}</p>
            </div>
            <div class="detail-content-icon">
                <mat-icon [ngStyle]="{'color':'#00e5ff'}">date_range</mat-icon>
            </div>
            <div class="detail-content">
                <p class="detail-title">Fecha Llegada Unidades</p>
                <p class="detail-subtitle">{{pqrPqrsEntity.unitArrivalDate}}</p>
            </div>
        </div>
    </div>
</div>