<div class="report-container">
    <p class="title">Conteo General Resultados</p>
    <div class="inv-container-button">
        <div class="inv-group">
            <input type="text" class="form-control" placeholder="Filtrar" (keyup)="filter($event)">
            <mat-icon class="blue-color">search</mat-icon>
        </div>
    </div>
    <table #table class="inv-table" mat-table [dataSource]="dataSource" matSort>

        <ng-container matColumnDef="countingType">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Tipo de conteo</th>
            <td mat-cell *matCellDef="let item">{{item.countingType}}</td>
        </ng-container>
        <ng-container matColumnDef="goodDeft">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Almacen</th>
            <td mat-cell *matCellDef="let item">{{item.goodDeft}}</td>
        </ng-container>
        <ng-container matColumnDef="tipo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Part Number</th>
            <td mat-cell *matCellDef="let item">{{item.tipo}}</td>
        </ng-container>
        <ng-container matColumnDef="parametrizacion">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Ubicacion</th>
            <td mat-cell *matCellDef="let item">{{item.parametrizacion}}</td>
        </ng-container>
        <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Tipo de inventario</th>
            <td mat-cell *matCellDef="let item">{{item.type}}</td>
        </ng-container>
        <ng-container matColumnDef="userName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Usuario de creacion</th>
            <td mat-cell *matCellDef="let item">{{item.userName}}</td>
        </ng-container>
        <ng-container matColumnDef="creationDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha de registro</th>
            <td mat-cell *matCellDef="let item">{{item.creationDate}}</td>
        </ng-container>
        <ng-container matColumnDef="active">
            <th mat-header-cell *matHeaderCellDef>Activo</th>
            <td mat-cell *matCellDef="let item">
                <mat-icon *ngIf="item.active" class="green-color">done</mat-icon>
                <mat-icon *ngIf="!item.active" class="red-color">clear</mat-icon>
            </td>
        </ng-container>
        <ng-container matColumnDef="Acciones">
            <th mat-header-cell *matHeaderCellDef>Acciones</th>
            <td mat-cell *matCellDef="let item">
                <div *ngIf="item.type == 'Serializado'">
                    <button mat-icon-button matTooltip="Eliminar" matTooltipPosition="above" matTooltipClass="tooltip"
                        (click)="delete(item.id, item.tipo, item.parametrizacion, item.goodDeft)">
                        <mat-icon class="red-color">delete</mat-icon>
                    </button>

                    <button mat-icon-button matTooltip="Serializar" matTooltipPosition="above" matTooltipClass="tooltip"
                        (click)="serial(item.id)">
                        <mat-icon class="yellow-color">view_week</mat-icon>
                    </button>
                </div>
                <div *ngIf="item.type == 'Conteo'">
                    <button mat-icon-button matTooltip="Eliminar" matTooltipPosition="above" matTooltipClass="tooltip"
                        (click)="delete(item.id, item.tipo, item.parametrizacion, item.goodDeft)">
                        <mat-icon class="red-color">delete</mat-icon>
                    </button>
                    <button mat-icon-button matTooltip="Conteo" matTooltipPosition="above" matTooltipClass="tooltip"
                        (click)="counting(item.id)">
                        <mat-icon class="blue-color">calculate</mat-icon>
                    </button>
                </div>
                <div *ngIf="item.type == 'DEFT'">
                    <button mat-icon-button matTooltip="Eliminar" matTooltipPosition="above" matTooltipClass="tooltip"
                        (click)="deleteDeft(item.id, item.tipo, item.parametrizacion, item.goodDeft)">
                        <mat-icon class="red-color">delete</mat-icon>
                    </button>
                    <button mat-icon-button matTooltip="Serializar" matTooltipPosition="above" matTooltipClass="tooltip"
                        (click)="partNumber(item.id)">
                        <mat-icon class="orange-color">menu_book</mat-icon>
                    </button>
                </div>

            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns;"></tr>
    </table>
    <div *ngIf="loading" class="report-container-loader">
        <div class="report-loader"></div>
    </div>
    <mat-paginator [pageSizeOptions]="[5, 10, 15, 20, 30, 40, 50, 100]" showFirstLastButtons></mat-paginator>
    <br>
</div>