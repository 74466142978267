<div class="loc-container">
    <div *ngIf="this.dataSource.data.length == 0; else notEqual">
        <div class="loc-close">
            <button mat-icon-button (click)="close()">
                <mat-icon>clear</mat-icon>
            </button>
        </div>
    </div>
    <ng-template #notEqual>
    </ng-template>
    <p class="title">Registro de inventario serializado </p>
    <br>
    <p class="primaryColor">Cantidad de seriales registrados: {{this.dataSource.data.length}}</p>
    <br> 
    
        <div class="loc-form">  
        <div class="loc-group">
            <label class="primaryColor">Serial</label>
            <input class="form-control"[(ngModel)]="serial" #serials  type="text" placeholder="Serial" (keyup.enter)="validador($event)" (keydown.tab)="validador($event)" >
            <br>
        </div>
        <div *ngIf="this.dataSource.data.length > 0">
        <div class="loc-group loc-end">
            <label class="primaryColor"></label>
                <br>
            <button class="inv-button" mat-raised-button matTooltip="Cerrar conteo" (click)="init()">
                <mat-icon>highlight_off</mat-icon>
            </button>
        </div>
        </div>
    </div>
    <table #table class="loc-table" mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="conteo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Conteo</th>
            <td mat-cell *matCellDef="let item">{{item.conteo}}</td>
        </ng-container>
        <ng-container matColumnDef="codigo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Codigo</th>
            <td mat-cell *matCellDef="let item">{{item.codigo}}</td>
        </ng-container>
        <ng-container matColumnDef="goodDeft">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Good / Deft</th>
            <td mat-cell *matCellDef="let item">{{item.goodDeft}}</td>
        </ng-container>
        <ng-container matColumnDef="serial">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>serial</th>
            <td mat-cell *matCellDef="let item">{{item.serial}}</td>
        </ng-container>
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Usuario de creacion</th>
            <td mat-cell *matCellDef="let item">{{item.name}}</td>
        </ng-container>
        <ng-container matColumnDef="creationDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha de creacion</th>
            <td mat-cell *matCellDef="let item">{{item.creationDate}}</td>
        </ng-container>
        <ng-container matColumnDef="active">
            <th mat-header-cell *matHeaderCellDef>Activo</th>
            <td mat-cell *matCellDef="let item">
                <mat-icon *ngIf="item.active" class="green-color">done</mat-icon>
                <mat-icon *ngIf="!item.active" class="red-color">clear</mat-icon>
            </td>
        </ng-container>
        <ng-container matColumnDef="Acciones">
            <th mat-header-cell *matHeaderCellDef>Acciones</th>
            <td mat-cell *matCellDef="let item">
                
                <button mat-icon-button matTooltip="Eliminar" matTooltipPosition="above" matTooltipClass="tooltip"
                    (click)="delete(item.id)">
                    <mat-icon class="red-color">delete</mat-icon>
                </button>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns;"></tr>
    </table>
    <mat-paginator #paginator [pageSizeOptions]="[5, 10, 15, 20, 30, 40, 50, 100]" showFirstLastButtons>
    </mat-paginator>
</div>