import { Component, OnInit } from "@angular/core";

@Component({
    selector: 'app-covFormHome',
    templateUrl: './covFormHome.component.html',
    styleUrls: ['./covFormHome.component.css']

})

export class CovFormHomeComponent implements OnInit{
    constructor(){}
    ngOnInit(): void {
    
       
    }

}
