<div class="dis-container">
    <p class="title">Nueva Asistencia</p>
    <div class="dis-form">
        <div class="dis-group">
            <label class="primaryColor">Año</label>
            <div class="dis-form-group">
                <select [(ngModel)]="year" class="form-control">
                    <option class="ligthColor" value="0">Seleccione un año</option>
                    <option *ngFor="let y of yearList" value="{{y}}">{{y}}</option>
                </select>
                <button class="blue" mat-mini-fab matTooltip="Buscar" matTooltipPosition="above"
                    matTooltipClass="tooltip" (click)="search()">
                    <mat-icon>search</mat-icon>
                </button>
            </div>
        </div>
        <p *ngIf="monthList.length>0" class="title" [ngStyle]="{'width': '100%','margin-top':'20px'}">Listado de Meses</p>
        <div *ngIf="monthList.length>0" class="dis-container-month">
            <div *ngFor="let m of monthList" class="dis-card-month" (click)="selectMonth(m)">
                <p class="dis-card-title">Mes</p>
                <p class="dis-card-subtitle">{{m.month}}</p>
                <p class="dis-card-title">Total Dias</p>
                <p class="dis-card-subtitle">{{m.days}}</p>
                <p class="dis-card-title">Dias Excluidos</p>
                <p class="dis-card-subtitle">{{m.daysExcluded}}</p>
            </div>
        </div>
    </div>
    <div *ngIf="dataSource.data.length>0" class="dis-container-table">
        <p class="title">Listado Asistencias</p>
        <div class="dis-search-container-button">
            <div class="dis-search-group">
                <input type="text" class="form-control" placeholder="Filtrar" (keyup)="filter($event)">
                <mat-icon class="blue-color">search</mat-icon>
            </div>
        </div>
        <table #table class="dis-table" mat-table [dataSource]="dataSource">
            <ng-container *ngFor="let c of columns;let i=index">
                <ng-container matColumnDef="{{columns[i]}}">
                    <th mat-header-cell *matHeaderCellDef>
                        <ng-container *ngIf="i===0">Identificacion</ng-container>
                        <ng-container *ngIf="i===1">Nombres</ng-container>
                        <ng-container *ngIf="i>1">{{columns[i]}}</ng-container>
                    </th>
                    <td mat-cell *matCellDef="let item" (click)="addRemove(item['headCountId'],columns[i],item[columns[i]])">
                        <ng-container *ngIf="item[columns[i]]!='0' && item[columns[i]]!='1'">{{item[columns[i]]}}</ng-container>
                        <ng-container *ngIf="item[columns[i]]==='0'">
                            <div class="dis-day" [ngStyle]="{'background-color': 'var(--red)'}"></div>
                        </ng-container>
                        <ng-container *ngIf="item[columns[i]]==='1'">
                            <div class="dis-day" [ngStyle]="{'background-color': 'var(--green)'}"></div>
                        </ng-container>
                    </td>
                </ng-container>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="columns"></tr>
            <tr mat-row *matRowDef="let row; columns: columns;"></tr>
        </table>
        <div *ngIf="loading" class="dis-container-loader">
            <div class="dis-loader"></div>
        </div>
        <mat-paginator [pageSizeOptions]="[5, 10, 15, 20, 30, 40, 50, 100]" showFirstLastButtons></mat-paginator>
    </div>
</div>