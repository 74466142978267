<div class="meeg-container">
    <p class="title">Grupos</p>
    <div class="meeg-container-button">
        <div class="meeg-group">
            <input type="text" class="form-control" placeholder="Filtrar" (keyup)="filter($event)">
            <mat-icon class="blue-color">search</mat-icon>
        </div>
        <button fxFlexAlign="flex-end" mat-mini-fab class="green" matTooltip="Crear Grupo" matTooltipPosition="left"
        matTooltipClass="tooltip" (click)="edit(null)">
        <mat-icon>add</mat-icon>
        </button>
    </div>
    <table #table class="meeg-table" mat-table [dataSource]="dataSource" matSort>
     
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Nombre</th>
            <td mat-cell *matCellDef="let item">{{item.name}}</td>
        </ng-container>
        <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Descripcion</th>
            <td mat-cell *matCellDef="let item">{{item.description}}</td>
        </ng-container>
        <ng-container matColumnDef="active">
            <th mat-header-cell *matHeaderCellDef>Activo</th>
            <td mat-cell *matCellDef="let item">
                <mat-icon *ngIf="item.active" class="green-color">done</mat-icon>
                <mat-icon *ngIf="!item.active" class="red-color">clear</mat-icon>
            </td>
        </ng-container>
        <ng-container matColumnDef="Acciones">
            <th mat-header-cell *matHeaderCellDef>Acciones</th>
            <td mat-cell *matCellDef="let item">
                <button mat-icon-button [matTooltip]="item.daysPro != null? 'Editar Programacion' :'Programacion'" matTooltipPosition="above" matTooltipClass="tooltip" (click)="program(item)" >
                    <mat-icon *ngIf="item.daysPro === null || item.daysPro === ''"><span class="material-icons" >event</span></mat-icon>
                    <mat-icon *ngIf="item.daysPro != null && item.daysPro != ''" class="green-color"><span class="material-icons" >edit_calendar</span></mat-icon>
                </button>
                <button mat-icon-button matTooltip="Editar" matTooltipPosition="above" matTooltipClass="tooltip" (click)="edit(item)">
                    <mat-icon color="editar">create</mat-icon>
                </button>
                <button mat-icon-button matTooltip="Eliminar" matTooltipPosition="above" matTooltipClass="tooltip" (click)="delete(item.id)">
                    <mat-icon class="red-color">delete</mat-icon>
                </button>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns;"></tr>
    </table>
    <div *ngIf="loading" class="report-container-loader">
        <div class="report-loader"></div>
    </div>
    <mat-paginator [pageSizeOptions]="[5, 10, 15, 20, 30, 40, 50, 100]" showFirstLastButtons></mat-paginator>

</div>

