<div class="report-container">
    <p class="title">Listado de Ciclicos</p>
    <div class="report-form">
        <div class="report-group">
            <label class="primaryColor">Clientes</label>
            <select [(ngModel)]="customerId" class="form-control" (change)="getCyclic()">
                <option class="ligthColor" value="0">Seleccione un cliente</option>
                <option *ngFor="let c of customerList" value="{{c.customerId}}">{{c.customer}}</option>
            </select>
        </div>
    </div>
    <table #table class="report-table" mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Nombre</th>
            <td mat-cell *matCellDef="let item">{{item.name}}</td>
        </ng-container>
        <ng-container matColumnDef="typeSampling">
            <th mat-header-cell *matHeaderCellDef>Tipo Muestra</th>
            <td mat-cell *matCellDef="let item">{{item.typeSampling}}</td>
        </ng-container>
        <ng-container matColumnDef="sampling">
            <th mat-header-cell *matHeaderCellDef>Porcentaje Muestra</th>
            <td mat-cell *matCellDef="let item">{{item.sampling}}%</td>
        </ng-container>
        <ng-container matColumnDef="total">
            <th mat-header-cell *matHeaderCellDef>Total Muestra</th>
            <td mat-cell *matCellDef="let item">{{item.total}}%</td>
        </ng-container>
        <ng-container matColumnDef="crossCustomer">
            <th mat-header-cell *matHeaderCellDef>Cruce Cliente</th>
            <td mat-cell *matCellDef="let item">
                <mat-icon *ngIf="item.crossCustomer" class="green-color">done</mat-icon>
                <mat-icon *ngIf="!item.crossCustomer" class="red-color">clear</mat-icon>
            </td>
        </ng-container>
        <ng-container matColumnDef="crossWms">
            <th mat-header-cell *matHeaderCellDef>Cruce WMS CLOUD</th>
            <td mat-cell *matCellDef="let item">
                <mat-icon *ngIf="item.crossWms" class="green-color">done</mat-icon>
                <mat-icon *ngIf="!item.crossWms" class="red-color">clear</mat-icon>
            </td>
        </ng-container>
        <ng-container matColumnDef="creationDate">
            <th mat-header-cell *matHeaderCellDef>Fecha Creacion</th>
            <td mat-cell *matCellDef="let item">{{item.creationDate}}</td>
        </ng-container>
        <ng-container matColumnDef="startDate">
            <th mat-header-cell *matHeaderCellDef>Fecha Inicio</th>
            <td mat-cell *matCellDef="let item">{{item.startDate}}</td>
        </ng-container>
        <ng-container matColumnDef="endDate">
            <th mat-header-cell *matHeaderCellDef>Fecha Fin</th>
            <td mat-cell *matCellDef="let item">{{item.endDate}}</td>
        </ng-container>
        <ng-container matColumnDef="customer">
            <th mat-header-cell *matHeaderCellDef>Cliente</th>
            <td mat-cell *matCellDef="let item">{{item.customer}}</td>
        </ng-container>
        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>Estado</th>
            <td mat-cell *matCellDef="let item">{{item.status}}</td>
        </ng-container>
        <ng-container matColumnDef="active">
            <th mat-header-cell *matHeaderCellDef>Activo</th>
            <td mat-cell *matCellDef="let item">
                <mat-icon *ngIf="item.active" class="green-color">done</mat-icon>
                <mat-icon *ngIf="!item.active" class="red-color">clear</mat-icon>
            </td>
        </ng-container>
        <ng-container matColumnDef="Acciones">
            <th mat-header-cell *matHeaderCellDef>Acciones</th>
            <td mat-cell *matCellDef="let item">
                <button mat-icon-button matTooltip="Aprobar" matTooltipPosition="above"
                    matTooltipClass="tooltip" (click)="approveReject(item.id,'Aprobado')">
                    <mat-icon class="green-color">done</mat-icon>
                </button>
                <button mat-icon-button matTooltip="Rechazar" matTooltipPosition="above"
                    matTooltipClass="tooltip" (click)="approveReject(item.id,'Rechazado')">
                    <mat-icon class="red-color">close</mat-icon>
                </button>
                <button mat-icon-button matTooltip="Abrir" matTooltipPosition="above"
                    matTooltipClass="tooltip" (click)="approveReject(item.id,'Pendiente')">
                    <mat-icon class="orange-color">vpn_key</mat-icon>
                </button>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns;"></tr>
    </table>
    <div *ngIf="loading" class="report-container-loader">
        <div class="report-loader"></div>
    </div>
    <mat-paginator [pageSizeOptions]="[5, 10, 15, 20, 30, 40, 50, 100]" showFirstLastButtons></mat-paginator>
</div>