<div class="dis-container">
    <p class="title">Cargar Head Count</p>
    <div class="dis-form">
        <div class="dis-group">
            <label class="primaryColor">Año</label>
            <select [(ngModel)]="year" class="form-control">
                <option class="ligthColor" value="0">Seleccione un año</option>
                <option *ngFor="let y of yearList" value="{{y}}">{{y}}</option>
            </select>
        </div>
        <div class="dis-group">
            <label class="primaryColor">Mes</label>
            <div class="dis-form-group">
                <select [(ngModel)]="month" class="form-control">
                    <option class="ligthColor" value="0">Seleccione un mes</option>
                    <option *ngFor="let m of monthList" value="{{m.id}}">{{m.name}}</option>
                </select>
                <input #file hidden type="file" (change)="load($event.target.files)">
                <button class="blue" mat-mini-fab matTooltip="Cargar Archivo" matTooltipPosition="above"
                    matTooltipClass="tooltip" (click)="file.click()">
                    <mat-icon>backup</mat-icon>
                </button>
            </div>
        </div>
    </div>
    <p class="title">Listado Head Count</p>
    <table #table class="dis-table" mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="year">
            <th mat-header-cell *matHeaderCellDef>Año</th>
            <td mat-cell *matCellDef="let item">{{item.year}}</td>
        </ng-container>
        <ng-container matColumnDef="monthName">
            <th mat-header-cell *matHeaderCellDef>Mes</th>
            <td mat-cell *matCellDef="let item">{{item.monthName}}</td>
        </ng-container>
        <ng-container matColumnDef="quantity">
            <th mat-header-cell *matHeaderCellDef>Cantidad</th>
            <td mat-cell *matCellDef="let item">{{item.quantity}}</td>
        </ng-container>
        <ng-container matColumnDef="acciones">
        <th mat-header-cell *matHeaderCellDef>Acciones</th>
        <td mat-cell *matCellDef="let item">
            <button mat-icon-button matTooltip="Eliminar" matTooltipPosition="above" matTooltipClass="tooltip" (click)="delete(item.year,item.month)">
                <mat-icon class="red-color">delete</mat-icon>
            </button>
        </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns;"></tr>
    </table>
    <div *ngIf="loading" class="dis-container-loader">
        <div class="dis-loader"></div>
    </div>
    <mat-paginator [pageSizeOptions]="[5, 10, 15, 20, 30, 40, 50, 100]" showFirstLastButtons></mat-paginator>
</div>