<div class="pqr-container">
    <p class="title">Nueva Notificacion</p>
    <form [formGroup]="form" class="pqr-form">
        <div class="pqr-group">
            <label class="primaryColor">Asunto</label>
            <input formControlName="subject" type="text" class="form-control" placeholder="Asunto">
        </div>
        <div class="pqr-group">
            <label class="primaryColor">Destinatarios</label>
            <input formControlName="variables" type="text" class="form-control" placeholder="Destinatarios">
        </div>
        <div class="pqr-group">
            <label class="primaryColor">Mensaje</label>
            <!--<angular-editor formControlName="message" [config]="config"></angular-editor>-->
            <editor apiKey="bkuz5ueok7zigqjele2henv1yyi6pkkkk4hkcqnh71iyp2tb" formControlName="message" plugins="wordcount image code"></editor>
        </div>
        <div *ngIf="editing !=0" class="pqr-group-checkbox">
            <label class="primaryColor">Activo</label>
            <div class="pqr-container-checkbox" matRipple [matRippleCentered]="true" [matRippleRadius]="40">
                <div class="pqr-checkbox">
                    <input id="check" formControlName="active" type="checkbox">
                    <label class="pqr-label" for="check">
                        <mat-icon>done</mat-icon>
                    </label>
                </div>
            </div>
        </div>
        <div class="pqr-container-button">
            <button class="pqr-button" mat-raised-button matTooltip="Guardar" matTooltipPosition="right"
                matTooltipClass="tooltip" [disabled]="!form.valid" (click)="onClick()">
                <mat-icon>save</mat-icon>
            </button>
        </div>
    </form>
    <p class="title">Listado Notificaciones</p>
    <div class="pqr-search-container-button">
        <div class="pqr-search-group">
            <input type="text" class="form-control" placeholder="Filtrar" (keyup)="filter($event)">
            <mat-icon class="blue-color">search</mat-icon>
        </div>
    </div>
    <table #table class="pqr-table" mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let item">
                <button *ngIf="item.id === editing" mat-icon-button matTooltip="Cerrar Edicion"
                    matTooltipPosition="above" matTooltipClass="tooltip" (click)="closeEditing()">
                    <mat-icon class="ligthColor">close</mat-icon>
                </button>
            </td>
        </ng-container>
        <ng-container matColumnDef="subject">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Asunto</th>
            <td mat-cell *matCellDef="let item">{{item.subject}}</td>
        </ng-container>
        <ng-container matColumnDef="variables">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Variables</th>
            <td mat-cell *matCellDef="let item">{{item.variables}}</td>
        </ng-container>
        <ng-container matColumnDef="active">
            <th mat-header-cell *matHeaderCellDef>Activo</th>
            <td mat-cell *matCellDef="let item">
                <mat-icon *ngIf="item.active" class="green-color">done</mat-icon>
                <mat-icon *ngIf="!item.active" class="red-color">clear</mat-icon>
            </td>
        </ng-container>
        <ng-container matColumnDef="Acciones">
            <th mat-header-cell *matHeaderCellDef>Acciones</th>
            <td mat-cell *matCellDef="let item">
                <button mat-icon-button matTooltip="Editar" matTooltipPosition="above" matTooltipClass="tooltip"
                    (click)="edit(item.id,item.subject,item.message,item.variables,item.active)">
                    <mat-icon class="blue-color">create</mat-icon>
                </button>
                <button mat-icon-button matTooltip="Eliminar" matTooltipPosition="above" matTooltipClass="tooltip"
                    (click)="delete(item.id)">
                    <mat-icon class="red-color">delete</mat-icon>
                </button>
                <button mat-icon-button matTooltip="Mas" matTooltipPosition="above" matTooltipClass="tooltip" [matMenuTriggerFor]="menuMail">
                    <mat-icon class="orange-color">more_vert</mat-icon>
                </button>
                <mat-menu #menuMail="matMenu" xPosition="before">
                    <button mat-menu-item (click)="getStatus(item.id)">
                        <mat-icon>timeline</mat-icon>
                        <span>Estados</span>
                    </button>
                    <button mat-menu-item (click)="getPerson(item.id)">
                        <mat-icon>people</mat-icon>
                        <span>Usuarios</span>
                    </button>
                </mat-menu>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns;"></tr>
    </table>
    <div *ngIf="loading" class="pqr-container-loader">
        <div class="pqr-loader"></div>
    </div>
    <mat-paginator [pageSizeOptions]="[5, 10, 15, 20, 30, 40, 50, 100]" showFirstLastButtons></mat-paginator>
</div>