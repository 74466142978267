<div class="loc-container">

    <div class="loc-close">
        <button mat-icon-button (click)="close()">
            <mat-icon>clear</mat-icon>
        </button>
    </div>
    
    <table #table class="loc-table" mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="pallet">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Pallet</th>
            <td mat-cell *matCellDef="let item">{{item.pallet}}</td>
        </ng-container>
        <ng-container matColumnDef="tipo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Tipo</th>
            <td mat-cell *matCellDef="let item">{{item.type}}</td>
        </ng-container>
        <ng-container matColumnDef="cantidad">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Cantidad</th>
            <td mat-cell *matCellDef="let item">{{item.quantity}}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns;"></tr>
    </table>
</div>