<div mat-dialog-content>
    <div class="dis-container-button">
        <button mat-icon-button (click)="close(false)">
            <mat-icon>clear</mat-icon>
        </button>
    </div>
    <p class="title">Dias no laborales</p>
    <div class="dis-content">
        <div *ngFor="let d of daysList" class="dis-day-card" [ngStyle]="{'background-color': d.active?'white':'var(--red)'}" (click)="addRemove(d.day,d.active)">
            <p [ngStyle]="{'color': d.active?'var(--primary)':'white'}">{{d.day}}</p>
        </div>
    </div>
</div>