<div class="report-container">
    <p class="title">Diario Covid</p>
    <div class="report-container-button">
        <div class="report-group">
            <input type="text" class="form-control" placeholder="Filtrar" (keyup)="filter($event)">
            <mat-icon class="blue-color">search</mat-icon>
            <button mat-mini-fab class="blue cov-butonsearch" matTooltip="Recargar" matTooltipPosition="above" matTooltipClass="tooltip"
                (click)="search()">
                <mat-icon >find_replace</mat-icon>
            </button>
            <button mat-mini-fab class="blue" matTooltip="Exportar" matTooltipPosition="above" matTooltipClass="tooltip"
                (click)="download()">
                <mat-icon>cloud_download</mat-icon>
            </button>
        </div>
    </div>
    <table #table class="report-table" mat-table [dataSource]="dataSource" matSort>
     
        <ng-container matColumnDef="identificationUser">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>N° identificacion</th>
            <td mat-cell *matCellDef="let item">{{item.identificationUser}}</td>
        </ng-container>
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Nombres y apellidos</th>
            <td mat-cell *matCellDef="let item">{{item.name}}</td>
        </ng-container>
        <ng-container matColumnDef="hasSymptoms">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Sintomas</th>
            <td mat-cell *matCellDef="let item">{{item.hasSymptoms}}</td>
        </ng-container>
        <ng-container matColumnDef="symptomsDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha Sintomas</th>
            <td mat-cell *matCellDef="let item">{{item.symptomsDate}}</td>
        </ng-container>
        <ng-container matColumnDef="descripSymptims">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Descrp. Sintomas</th>
            <td mat-cell *matCellDef="let item">{{item.descripSymptims}}</td>
        </ng-container>
        <ng-container matColumnDef="typeDocument">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Documentos</th>
            <td mat-cell *matCellDef="let item">{{item.typeDocument}}</td>
        </ng-container>
        <ng-container matColumnDef="creationDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha de Creacion</th>
            <td mat-cell *matCellDef="let item">{{item.creationDate}}</td>
        </ng-container>

        <ng-container matColumnDef="active">
            <th mat-header-cell *matHeaderCellDef>Activo</th>
            <td mat-cell *matCellDef="let item">
                <mat-icon *ngIf="item.active" class="green-color">done</mat-icon>
                <mat-icon *ngIf="!item.active" class="red-color">clear</mat-icon>
            </td>
        </ng-container>
        <ng-container matColumnDef="Acciones">
            <th mat-header-cell *matHeaderCellDef>Acciones</th>
            <td mat-cell *matCellDef="let item">
                <button mat-icon-button matTooltip="Eliminar" matTooltipPosition="above" matTooltipClass="tooltip"
                    (click)="delete(item.id)">
                    <mat-icon class="red-color">delete</mat-icon>
                </button>
                <button mat-icon-button matTooltip="Archivos" matTooltipPosition="above" matTooltipClass="tooltip"
                    (click)="files(item.identificationUser,item.creationDate)">
                    <mat-icon class="purple-color">attach_file</mat-icon>
                </button>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns;"></tr>
    </table>
    <div *ngIf="loading" class="report-container-loader">
        <div class="report-loader"></div>
    </div>
    <mat-paginator [pageSizeOptions]="[5, 10, 15, 20, 30, 40, 50, 100]" showFirstLastButtons></mat-paginator>
</div>