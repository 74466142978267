<div class="loc-container">
    <p class="title">Listado</p>
    <br>
    <br>
    <div fxLayout fxLayoutAlign="flex-start flex-end">
        <div fxFlex="50%" fxLayout="column" class="group">
            <label class="primaryColor">Tipo de archivo</label>
            <select [(ngModel)]="fileType" class="form-control">
                <option value="" disabled>Selecciona un tipo de archivo</option>
                <option value="csv">csv</option>
                <option value="json">json</option>
                <option value="txt">txt</option>
                <option value="xlsx">xlsx</option>
            </select>
        </div>
        <button [disabled]="fileType===''" [ngClass]="{'ligthBackground':fileType==='','blue':fileType!=''}" mat-mini-fab class="loc-but" matTooltip="Exportar" matTooltipPosition="above"
            matTooltipClass="tooltip" (click)="export()">
            <mat-icon>cloud_download</mat-icon>
        </button>
    </div>
    <br>
    <div *ngIf="loading" class="loc-container-loader">
        <div class="loc-loader"></div>
    </div>
    <table id="tabla" class="loc-table" mat-table [dataSource]="dataSource" matTableExporter
        #exporter="matTableExporter">
        <ng-container matColumnDef="CLIENTE">
            <th mat-header-cell *matHeaderCellDef>CLIENTE</th>
            <td mat-cell *matCellDef="let item">{{item.cliente}}</td>
        </ng-container>
        <ng-container matColumnDef="SERIAL EQUIPO">
            <th mat-header-cell *matHeaderCellDef>SERIAL EQUIPO</th>
            <td mat-cell *matCellDef="let item">{{item.serial_EQUIPO}}</td>
        </ng-container>
        <ng-container matColumnDef="CODIGO SAP">
            <th mat-header-cell *matHeaderCellDef>CODIGO SAP</th>
            <td mat-cell *matCellDef="let item">{{item.codigo_SAP}}</td>
        </ng-container>
        <ng-container matColumnDef="ESTADO">
            <th mat-header-cell *matHeaderCellDef>ESTADO</th>
            <td mat-cell *matCellDef="let item">{{item.estado}}</td>
        </ng-container>
        <ng-container matColumnDef="TIPOLOGIA">
            <th mat-header-cell *matHeaderCellDef>TIPOLOGIA</th>
            <td mat-cell *matCellDef="let item">{{item.tipologia}}</td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns;"></tr>
    </table>
    <mat-paginator #paginator [pageSizeOptions]="[5, 10, 15, 20, 30, 40, 50, 100]" showFirstLastButtons>
    </mat-paginator>
</div>