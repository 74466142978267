<div class="actfix-container">
    <p class="title">Listado de devolucion</p>
    <div class="actfix-container-button">
        <div class="actfix-group">
            <input type="text" class="form-control" placeholder="Filtrar" (keyup)="filter($event)">
            <mat-icon class="blue-color">search</mat-icon>
            <button mat-mini-fab class="blue" matTooltip="Exportar" matTooltipPosition="above" matTooltipClass="tooltip"
                (click)="download()">
                <mat-icon>cloud_download</mat-icon>
            </button>
        </div>
    </div>
    <table #table class="actfix-table" mat-table [dataSource]="dataSource" matSort>
     
        <ng-container matColumnDef="identification">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>identificacion</th>
            <td mat-cell *matCellDef="let item">{{item.identification}}</td>
        </ng-container>
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Nombre</th>
            <td mat-cell *matCellDef="let item">{{item.name}}</td>
        </ng-container>
        <ng-container matColumnDef="mail">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
            <td mat-cell *matCellDef="let item">{{item.mail}}</td>
        </ng-container>
        <ng-container matColumnDef="costCenter">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Centro de costo</th>
            <td mat-cell *matCellDef="let item">{{item.costCenter}}</td>
        </ng-container>
        <ng-container matColumnDef="position">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Cargo</th>
            <td mat-cell *matCellDef="let item">{{item.position}}</td>
        </ng-container>
        <ng-container matColumnDef="city">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Ciudad</th>
            <td mat-cell *matCellDef="let item">{{item.city}}</td>
        </ng-container>
        <ng-container matColumnDef="personRes">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Persona Responsable</th>
            <td mat-cell *matCellDef="let item">{{item.personRes}}</td>
        </ng-container>
        <ng-container matColumnDef="statusEquipament">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Estado del equipo</th>
            <td mat-cell *matCellDef="let item">{{item.statusEquipament}}</td>
        </ng-container>
        <ng-container matColumnDef="equipment">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Equipo de devolucion</th>
            <td mat-cell *matCellDef="let item">{{item.equipment}}</td>
        </ng-container>
        <ng-container matColumnDef="serial">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Serial</th>
            <td mat-cell *matCellDef="let item">{{item.serial}}</td>
        </ng-container>
        <ng-container matColumnDef="commentary">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Comentario y/o observaciones</th>
            <td mat-cell *matCellDef="let item">{{item.commentary}}</td>
        </ng-container>
        <ng-container matColumnDef="creationDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha de registro</th>
            <td mat-cell *matCellDef="let item">{{item.creationDate}}</td>
        </ng-container>
        <ng-container matColumnDef="active">
            <th mat-header-cell *matHeaderCellDef>Activo</th>
            <td mat-cell *matCellDef="let item">
                <mat-icon *ngIf="item.active" class="green-color">done</mat-icon>
                <mat-icon *ngIf="!item.active" class="red-color">clear</mat-icon>
            </td>
        </ng-container>
        <ng-container matColumnDef="Acciones">
            <th mat-header-cell *matHeaderCellDef>Acciones</th>
            <td mat-cell *matCellDef="let item">
                <button mat-icon-button matTooltip="Eliminar" matTooltipPosition="above" matTooltipClass="tooltip"
                    (click)="delete(item.id)">
                    <mat-icon class="red-color">delete</mat-icon>
                </button>
                <button mat-icon-button matTooltip="Detalles" matTooltipPosition="above"
                matTooltipClass="tooltip" (click)="detail(item.id,item.identification,item.creationDate)">
                <mat-icon class="blue-color">receipt</mat-icon>
            </button>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns;"></tr>
    </table>
    <div *ngIf="loading" class="report-container-loader">
        <div class="report-loader"></div>
    </div>
    <mat-paginator [pageSizeOptions]="[5, 10, 15, 20, 30, 40, 50, 100]" showFirstLastButtons></mat-paginator>
</div>

