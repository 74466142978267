<div class="scp-container">
    <p class="title">Carga de Pallet</p>
    <div class="scp-container-button">
        <div class="scp-group">
            <input type="text" class="form-control" placeholder="Filtrar" (keyup)="filter($event)">
            <mat-icon class="blue-color">search</mat-icon>
        </div>
    </div>
    <table class="scp-table" mat-table [dataSource]="dataSource" matSort>
     
        <ng-container matColumnDef="codeAudit">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Codigo</th>
            <td mat-cell *matCellDef="let item" (click)="searchAudit(item)" >{{item.codeAudit}}</td>
        </ng-container>
        <ng-container matColumnDef="auditPreviousName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Auditoria Previa</th>
            <td mat-cell *matCellDef="let item" (click)="searchAudit(item)" >{{item.auditPreviousName}}</td>
        </ng-container>
        <ng-container matColumnDef="typeAuditName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Tipo Auditoria</th>
            <td mat-cell *matCellDef="let item" (click)="searchAudit(item)" >{{item.typeAuditName}}</td>
        </ng-container>
        <ng-container matColumnDef="state">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Estado</th>
            <td mat-cell *matCellDef="let item" (click)="searchAudit(item)" >{{item.state}}</td>
        </ng-container>
        <ng-container matColumnDef="levelRuleName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Nivel</th>
            <td mat-cell *matCellDef="let item" (click)="searchAudit(item)" >{{item.levelRuleName}}</td>
        </ng-container>
        <ng-container matColumnDef="userName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Usuario Creacion</th>
            <td mat-cell *matCellDef="let item" (click)="searchAudit(item)" >{{item.userName}}</td>
        </ng-container>
        <ng-container matColumnDef="active">
            <th mat-header-cell *matHeaderCellDef>Activo</th>
            <td mat-cell *matCellDef="let item">
                <mat-icon *ngIf="item.active" (click)="searchAudit(item)" class="green-color">done</mat-icon>
                <mat-icon *ngIf="!item.active" (click)="searchAudit(item)" class="red-color">clear</mat-icon>
            </td>
        </ng-container>
        <ng-container matColumnDef="acciones">
            <th mat-header-cell *matHeaderCellDef>Acciones</th>
            <td mat-cell *matCellDef="let item">
                <button mat-icon-button matTooltip="Cerrar" matTooltipPosition="above" matTooltipClass="tooltip" (click)="close(item)" >
                    <mat-icon class="red-color">block</mat-icon>
                </button>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns;"></tr>
    </table>
    <div *ngIf="loading" class="report-container-loader">
        <div class="report-loader"></div>
    </div>
    <mat-paginator [pageSizeOptions]="[5, 10, 15, 20, 30, 40, 50, 100]" showFirstLastButtons></mat-paginator>

    <!-- CARGA DE PALLET -->
    <div *ngIf="this.ScpAuditE != null" class="scp-container-serials">

        <div class="scp-count">
            <p class="scp-count-title">Ingresados</p>
            <p class="scp-count-subtitleSerial">{{this.dataSourcePallet.data.length}}</p>
        </div>

        <div class="scp-countAudit">
            <p class="scp-count-title">Codigo Auditoria</p>
            <p class="scp-count-subtitle">{{this.ScpAuditE.codeAudit}}</p>
        </div>
        <div *ngIf="this.ScpAuditE.openPallet == true" class="scp-group">
            <label class="primaryColor">Pallet</label>
            <input [(ngModel)]="pallet" id="pallet" class="form-control text-may" (keydown)="searchPallet($event)">
        </div>

        <div class="scp-group">
            <label class="primaryColor">Cantidad de Equipos</label>
            <label class="primaryColor">{{this.quantity}}</label>
        </div>

        <div *ngIf="this.ScpAuditE.openPallet == true" class="scp-group">
            <label class="primaryColor">Guardar</label>
            <div class="scp-container-button">
                <button class="scp-button" mat-raised-button matTooltip="Guardar" mat-raised-button [disabled]="dataSourcePallet.data.length == 0" matTooltipPosition="right" matTooltipClass="tooltip" (click)="savePallets()">
                    <mat-icon>save</mat-icon>
                </button>
            </div>
        </div>
    

        <table class="scp-table" mat-table matSort [dataSource]="dataSourcePallet">
            <ng-container matColumnDef="pallet">
                <th mat-header-cell *matHeaderCellDef>Pallet</th>
                <td mat-cell *matCellDef="let charge">{{charge.pallet}}</td>
            </ng-container>
            <ng-container matColumnDef="typology">
                <th mat-header-cell *matHeaderCellDef>Tipologia</th>
                <td mat-cell *matCellDef="let charge">{{charge.typology}}</td>
            </ng-container>
            <ng-container matColumnDef="quantity">
                <th mat-header-cell *matHeaderCellDef>Cantidad</th>
                <td mat-cell *matCellDef="let charge">{{charge.quantity}}</td>
            </ng-container>
            <ng-container matColumnDef="state">
                <th mat-header-cell *matHeaderCellDef>Estado</th>
                <td mat-cell *matCellDef="let charge">
                    <mat-icon matTooltip="{{charge.state}}" *ngIf="charge.state !='Aceptado'" class="red-color">priority_high</mat-icon>
                    <mat-icon matTooltip="{{charge.state}}" *ngIf="charge.state == 'Aceptado'" class="green-color">done</mat-icon>
                </td>
            </ng-container>
            <ng-container matColumnDef="acciones">
                <th mat-header-cell *matHeaderCellDef>Acciones</th>
                <td mat-cell *matCellDef="let i=index;">
                    <button *ngIf="this.ScpAuditE.openPallet == true" mat-icon-button matTooltip="Eliminar" matTooltipPosition="above" matTooltipClass="tooltip" (click)="deleteRow(i)">
                        <mat-icon class="red-color">delete</mat-icon>
                    </button>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="columnsPallet"></tr>
            <tr mat-row *matRowDef="let row; columns: columnsPallet;"></tr>
        </table>
    </div>

</div>