<div mat-dialog-content>
    <div class="close" fxLayout fxLayoutAlign="flex-end">
        <button mat-icon-button (click)="close(false)">
            <mat-icon>clear</mat-icon>
        </button>
    </div>
    <p class="title">Detalle Anexos</p>
    <div class="act-detail-container">
        <div *ngFor="let c  of covFormEntity">
            <div class="act-detail-card">
                <div class="act-detail-content-icon">
                    <mat-icon [ngStyle]="{'color':'#1A5A0C'}">gavel</mat-icon>
                </div>
                <div class="act-detail-content">
                    <p class="act-detail-title">Contrato</p>
                    <p class="act-detail-subtitle">{{c.contract}}</p>
                </div>
                <div class="act-detail-content-icon">
                    <mat-icon [ngStyle]="{'color':'#0C5A4F'}">badge</mat-icon>
                </div>
                <div class="act-detail-content">
                    <p class="act-detail-title">Segmento</p>
                    <p class="act-detail-subtitle">{{c.segment}}</p>
                </div>
            </div>
             <div class="act-detail-card">
                <div class="act-detail-content-icon">
                    <mat-icon [ngStyle]="{'color':'#411881'}">people_alt</mat-icon>
                </div>
                <div class="act-detail-content">
                    <p class="act-detail-title">Cliente</p>
                    <p class="act-detail-subtitle">{{c.customer}}</p>
                </div>
                <div class="act-detail-content-icon">
                    <mat-icon [ngStyle]="{'color':'#5A0C17'}">fact_check</mat-icon>
                </div>
                <div class="act-detail-content">
                    <p class="act-detail-title">Centro de costo</p>
                    <p class="act-detail-subtitle">{{c.costCenter}}</p>
                </div>
            </div>
            <div class="act-detail-card">
                <div class="act-detail-content-icon">
                    <mat-icon [ngStyle]="{'color':'#D43BE6'}">supervised_user_circle</mat-icon>
                </div>
                <div class="act-detail-content">
                    <p class="act-detail-title">Aplica</p>
                    <p class="act-detail-subtitle">{{c.noApply}}</p>
                </div>
            </div>
            <div *ngIf="loading" class="act-detail-container-loader">
                <div class="act-detail-loader"></div>
            </div>
        </div>
    </div>
</div>