<div mat-dialog-content>
    <div class="close" fxLayout fxLayoutAlign="flex-end">
        <button mat-icon-button (click)="close(false)">
            <mat-icon>clear</mat-icon>
        </button>
    </div>
    <p class="title">Registro de inicio</p>
    <br>
    
    <form [formGroup]="form" class="acts-form form" >
        <div class="groupDir">
            <label class="primaryColor">Fecha notificacion al area</label>
            <input formControlName="dateArea" class="form-control" type="date">
        </div>
        <div class="groupDir">
            <label class="primaryColor">Fecha de inicio de cuarentena</label>
            <input formControlName="dateInitiation" class="form-control" type="date">
        </div>
        <div class="groupDir">
            <label class="primaryColor">Tipo de caso</label>
            <input formControlName="typeCase" class="form-control" type="text" placeholder="Tipo de caso">
        </div>
        <div class="groupDir">
            <label class="primaryColor">Cita programada</label>
            <input formControlName="scheduledAppointment" class="form-control" type="text" placeholder="Cita">
        </div>
        <div class="groupDir">
            <label class="primaryColor">Fecha de cita</label>
            <input formControlName="dateAppointment" class="form-control" type="date">
        </div>
        <div fxFlex="100%" fxLayout="column" class="group">
            <label class="primaryColor">Estado de salud puntual</label>
            <input formControlName="statusHealthPunctual" class="form-control input-group" type="text" placeholder="Descripcion">
        </div>
        <button mat-mini-fab class="button-float green" matTooltip="Guardar" matTooltipPosition="left"
            matTooltipClass="tooltip" (click)="save()">
            <mat-icon>done</mat-icon>
        </button>
    </form>
</div>