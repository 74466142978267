<div mat-dialog-content>
    <div class="bsc-close-button">
        <button mat-icon-button (click)="close()">
            <mat-icon>clear</mat-icon>
        </button>
    </div>
    <p class="title">{{title}}</p>
    <div class="bsc-form">
        <div class="bsc-group">
            <label class="primaryColor">Proceso</label>
            <input [(ngModel)]="measurementEntity.perspective" disabled type="text" class="form-control">
        </div>
        <div class="bsc-group">
            <label class="primaryColor">Objetivo</label>
            <input [(ngModel)]="measurementEntity.strategicObjetive" disabled type="text" class="form-control">
        </div>
        <div class="bsc-group">
            <label class="primaryColor">Indicador</label>
            <input [(ngModel)]="measurementEntity.indicator" disabled type="text" class="form-control">
        </div>
        <div class="bsc-group">
            <label class="primaryColor">Mes</label>
            <input [(ngModel)]="proAnalysisEntity.month" disabled type="text" class="form-control">
        </div>
        <div class="bsc-group-text">
            <label class="primaryColor">Analisis</label>
            <textarea [(ngModel)]="proAnalysisEntity.analysis" class="form-control" placeholder="Analisis"></textarea>
        </div>
        <button mat-mini-fab class="bsc-float-button green" matTooltip="Guardar" matTooltipPosition="left"
            matTooltipClass="tooltip" (click)="save()">
            <mat-icon>save</mat-icon>
        </button>
    </div>
</div>