export class InvGeneraCountingEntity{

    id:number;
    counting:number;
    typeId:number;
    creationDate:string;
    userId:number;
    active:boolean;
    result:number;

    constructor (){}
}