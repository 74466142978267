<mat-toolbar class="dev-toolbar primaryBackground">
    <div class="dev-toolbar-image primaryBackground">
        <img src="./assets/images/Logo.png" (click)="goWoden()">   
    <span class="dev-toolbar-title secundaryColor">WIP</span>
    </div>
    <div class="dev-toolbar-options">
        <button class="secundaryColor" mat-icon-button (click)="onToogle()">
            <mat-icon>menu</mat-icon>
        </button>
        <div>
            <button class="secundaryColor" mat-icon-button [matMenuTriggerFor]="apps">
                <mat-icon>apps</mat-icon>
            </button>
            <button class="secundaryColor" mat-icon-button [matMenuTriggerFor]="closeSeccion">
                <mat-icon>more_vert</mat-icon>
            </button>            
        </div>
    </div>
</mat-toolbar>
<mat-menu #apps="matMenu">
    <button *ngFor="let app of applicationList" routerLink="/{{app.link}}" class="ligthColor" mat-menu-item>
      <mat-icon [ngStyle]="{'color':app.color}">{{app.icon}}</mat-icon>
      <span>{{app.name}}</span>
    </button>
</mat-menu>
<mat-menu #closeSeccion="matMenu">
    <button class="ligthColor" mat-menu-item (click)="updatePassword()">
      <mat-icon>cached</mat-icon>
      <span>Cambiar contraseña</span>
    </button>
    <button class="ligthColor" mat-menu-item (click)="logout()">
      <mat-icon class="red-color">power_settings_new</mat-icon>
      <span>Cerrar sesión</span>
    </button>
</mat-menu>.
<div class="dev-container">
    <div class="dev-sidenav" [ngClass]="{'dev-sidenav-close' : !isOpen}">
        <div class="dev-sidenav-header secundaryBackground">
            <input #image hidden type="file" accept="images" (change)="loadImage($event.target.files)">
            <img src={{photo}} (click)="image.click()">
            <div class="dev-sidenav-title">
                <span class="primaryColor">{{genPersonEntity.firstName |titlecase}}
                    {{genPersonEntity.lastName |titlecase}}</span>
                <span class="ligthColor">{{genPersonEntity.position |titlecase}}</span>
            </div>
        </div>
        <div #sidenavContent class="dev-sidenav-content secundaryBackground" (mouseover)="enableScroll()" (mouseleave)="disableScroll()">
            <span class="dev-navigation primaryColor">Navegación</span>
            <ng-container>
                <div *ngFor="let s of sectionList" class="dev-section-container" [ngClass]="{'dev-section-container-active' : open === s.name}">
                    <ng-container *ngIf="s.active">
                        <div id="s.name" class="dev-section primaryColor" (click)="onClick(s.name)">
                            <div class="dev-section-title">
                                <mat-icon class="dev-section-icon primaryColor" [ngStyle]="{'color' : s.color}">
                                    {{s.icon}}
                                </mat-icon>
                                <a>{{s.name |titlecase}}</a>
                            </div>
                            <mat-icon class="dev-section-icon primaryColor" [ngClass]="{'dev-section-icon-open' : open === s.name}">
                                keyboard_arrow_down</mat-icon>
                        </div>
                        <ul id="module_{{s.name}}" class="dev-module" [ngStyle]="{'height': open === s.name? height :'1px','transition':'height 0.2s linear'}">
                            <ng-container *ngFor="let mod of moduleList">
                                <a *ngIf="mod.sectionId === s.id" [routerLink]="[mod.link]" class="ligthColor">{{mod.name}}</a>
                            </ng-container>
                        </ul>
                    </ng-container>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="dev-content" [ngClass]="{'dev-content-close' : !isOpen}">
        <router-outlet></router-outlet>
    </div>
</div>