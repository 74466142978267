<div class="actfix-container">
    <p class="title">Clientes</p>
    <div class="actfix-container-button">
        <div class="actfix-group">
            <input type="text" class="form-control" placeholder="Filtrar" (keyup)="filter($event)">
            <mat-icon class="blue-color">search</mat-icon>
        </div>
    </div>
    <table #table class="actfix-table" mat-table [dataSource]="dataSource" matSort>

        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Nombres y apellidos del cliente o solicitante </th>
            <td mat-cell *matCellDef="let item" (click)="getInformation(item.ticketId, item.id)">{{item.name}}</td>
        </ng-container>
        <ng-container matColumnDef="ticketId">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>N° de Ticket</th>
            <td mat-cell *matCellDef="let item" (click)="getInformation(item.ticketId, item.id)">{{item.ticketId}}</td>
        </ng-container>
        <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Detalle general de la solicitud</th>
            <td mat-cell *matCellDef="let item" (click)="getInformation(item.ticketId, item.id)">{{item.description}}
            </td>
        </ng-container>

        <ng-container matColumnDef="Acciones">
            <th mat-header-cell *matHeaderCellDef>Archivos adjuntos</th>
            <td mat-cell *matCellDef="let item">
                <button mat-icon-button class="mee-button" matTooltip="Archivos" matTooltipPosition="above"
                    matTooltipClass="tooltip" (click)="getFiles(item.id,item.creationDate)">
                    <mat-icon class="orange-color">assignment</mat-icon>
                </button>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns;"></tr>
    </table>
    <div *ngIf="loading" class="report-container-loader">
        <div class="report-loader"></div>
    </div>
    <mat-paginator [pageSizeOptions]="[5, 10, 15, 20, 30, 40, 50, 100]" showFirstLastButtons></mat-paginator>
    <ng-container *ngIf="id!=0">
        <p class="title">Registro</p>
        <div *ngIf="loadingResponse" class="mee-container-loader">
            <div class="mee-loader"></div>
        </div>
        <div fxFlex fxLayout fxLayoutAlign="center center">
            <span *ngIf="dataResponse.data.length===0" class="mee-title">¡Sin Registros!</span>
        </div>
        <table *ngIf="dataResponse.data.length>0" class="mee-table" mat-table [dataSource]="dataResponse">
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>
                    <mat-icon [ngStyle]="{'color':'#3276DF'}">person</mat-icon> Nombres
                </th>
                <td mat-cell *matCellDef="let item">{{item.name}}</td>
            </ng-container>
            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef>
                    <mat-icon [ngStyle]="{'color':'#F92713'}">subject</mat-icon> Descripción
                </th>
                <td mat-cell *matCellDef="let item">{{item.description}}</td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="columnsResponse"></tr>
            <tr mat-row *matRowDef="let row; columns: columnsResponse;"></tr>
        </table>
        <mat-paginator #paginatorResponse [pageSizeOptions]="[5, 10, 15, 20, 30, 40, 50, 100]" showFirstLastButtons>
        </mat-paginator>
    </ng-container>
    <ng-container *ngIf="id!=0">
        <div *ngIf="pqrsList.length > 0 && !loadingPqrs" class="pqr-content">
            <div *ngFor="let p of pqrsList" class="pqr-card" (click)="getinformation(p.number,p.ticket,p.creationDate)">
                <div (click)="tracing(p.number)"></div>
                <p class="pqr-card-title">Trámite</p>
                <p class="pqr-card-content">{{p.type}}</p>
                <p class="pqr-card-title">Ticket</p>
                <p class="pqr-card-content">{{p.ticket}}</p>
                <p class="pqr-card-title">Número de PQRS</p>
                <p class="pqr-card-content">{{p.number}}</p>
                <p class="pqr-card-title">Seriales</p>
                <p class="pqr-card-content">{{p.serial}}</p>
            </div>
        </div>
    </ng-container>
    <p *ngIf="tracingList.length > 0" class="title" [ngStyle]="{'text-align':'center'}">Seguimiento PQRS</p>
    <div *ngIf="loadingTracing" class="pqr-container-loader">
        <div class="pqr-loader"></div>
    </div>
    <div *ngIf="tracingList.length > 0 && !loadingTracing" class="pqr-container-tracing">
        <ng-container *ngFor="let t of tracingList">
            <div class="pqr-tracking-content">
                <div class="pqr-tracking-circle" matTooltip="{{t.observations}}" matTooltipPosition="above"
                    matTooltipClass="tooltip">
                    <mat-icon>done</mat-icon>
                </div>
                <div class="pqr-tracking-information">
                    <span class="pqr-tracking-title">{{t.eventStatus}}</span>
                    <span class="pqr-tracking-subtitle">{{t.user}}</span>
                    <span class="pqr-tracking-subtitle">{{t.date}}</span>
                </div>
                <div class="pqr-tracking-line"></div>
            </div>
        </ng-container>
    </div>
    <p *ngIf="pqrPqrsEntity!=null" class="title" [ngStyle]="{'text-align':'center'}">Detalle PQRS</p>
    <div *ngIf="pqrPqrsEntity!=null" class="pqr-detail-container">
        <div class="detail-card">
            <div class="detail-content-icon">
                <mat-icon [ngStyle]="{'color':'#f44336'}">local_offer</mat-icon>
            </div>
            <div class="detail-content">
                <p class="detail-title">Ticket</p>
                <p class="detail-subtitle">{{pqrPqrsEntity.ticket}}</p>
            </div>
            <div class="detail-content-icon">
                <mat-icon [ngStyle]="{'color':'#2196f3'}">format_list_numbered</mat-icon>
            </div>
            <div class="detail-content">
                <p class="detail-title">Número de PQRS</p>
                <p class="detail-subtitle">{{pqrPqrsEntity.number}}</p>
            </div>
        </div>
        <div class="detail-card">
            <div class="detail-content-icon">
                <mat-icon [ngStyle]="{'color':'#ff9800'}">account_box</mat-icon>
            </div>
            <div class="detail-content">
                <p class="detail-title">Usuario de creación</p>
                <p class="detail-subtitle">{{pqrPqrsEntity.creationPerson}}</p>
            </div>
            <div class="detail-content-icon">
                <mat-icon [ngStyle]="{'color':'#00e5ff'}">date_range</mat-icon>
            </div>
            <div class="detail-content">
                <p class="detail-title">Fecha de Reporte</p>
                <p class="detail-subtitle">{{pqrPqrsEntity.creationDate}}</p>
            </div>
        </div>
        <div class="detail-card">
            <div class="detail-content-icon">
                <mat-icon [ngStyle]="{'color':'#673ab7'}">airplanemode_active</mat-icon>
            </div>
            <div class="detail-content">
                <p class="detail-title">País</p>
                <p class="detail-subtitle">{{pqrPqrsEntity.country}}</p>
            </div>
            <div class="detail-content-icon">
                <mat-icon [ngStyle]="{'color':'#ff80ab'}">flight_takeoff</mat-icon>
            </div>
            <div class="detail-content">
                <p class="detail-title">Departamento</p>
                <p class="detail-subtitle">{{pqrPqrsEntity.department}}</p>
            </div>
        </div>
        <div class="detail-card">
            <div class="detail-content-icon">
                <mat-icon [ngStyle]="{'color':'#00e676'}">flight_land</mat-icon>
            </div>
            <div class="detail-content">
                <p class="detail-title">Ciudad</p>
                <p class="detail-subtitle">{{pqrPqrsEntity.city}}</p>
            </div>
            <div class="detail-content-icon">
                <mat-icon [ngStyle]="{'color':'#009688'}">grading</mat-icon>
            </div>
            <div class="detail-content">
                <p class="detail-title">Seriales</p>
                <p class="detail-subtitle">{{pqrPqrsEntity.serial}}</p>
            </div>
        </div>
        <div class="detail-card">
            <div class="detail-content-icon">
                <mat-icon [ngStyle]="{'color':'#607d8b'}">assignment</mat-icon>
            </div>
            <div *ngIf="pqrPqrsEntity.summary == null; else notequal " class="detail-content">
                <p class="detail-title">Resumen de la Solicitud</p>
                <p class="detail-subtitle">{{pqrPqrsEntity.observation}}</p>
            </div>
            <ng-template #notequal>
                <div class="detail-content">
                    <p class="detail-title">Resumen de la Solicitud</p>
                    <p class="detail-subtitle">{{pqrPqrsEntity.summary}}</p>
                </div>
            </ng-template>
            <div class="detail-content-icon">
                <mat-icon [ngStyle]="{'color':'#2196f3'}">assignment</mat-icon>
            </div>
            <div class="detail-content">
                <p class="detail-title">Observaciones Woden</p>
                <p class="detail-subtitle">{{pqrPqrsEntity.observations}}</p>
            </div>
        </div>
        <div class="detail-card">
            <div class="detail-content-icon">
                <mat-icon [ngStyle]="{'color':'#00e5ff'}">date_range</mat-icon>
            </div>
            <div class="detail-content">
                <p class="detail-title">Fecha de Respuesta al cliente</p>
                <p class="detail-subtitle">{{pqrPqrsEntity.customerEscalationResponseDate}}</p>
            </div>
            <div class="detail-content-icon">
                <mat-icon [ngStyle]="{'color':'#2196f3'}">quiz</mat-icon>
            </div>
            <div class="detail-content">
                <p class="detail-title">Estado Final PQR</p>
                <p class="detail-subtitle">{{pqrPqrsEntity.finalStatus}}</p>
            </div>
        </div>
        <div class="detail-card">
            <div class="detail-content-icon">
                <mat-icon [ngStyle]="{'color':'#ff80ab'}">analytics</mat-icon>
            </div>
            <div class="detail-content">
                <p class="detail-title">Responsable</p>
                <p class="detail-subtitle">{{pqrPqrsEntity.attributablePqrs}}</p>
            </div>
        </div>
        <div class="detail-card">
            <div class="detail-content-icon">
                <mat-icon [ngStyle]="{'color':'#0B2387'}">text_snippet</mat-icon>
            </div>
            <div class="detail-content">
                <p class="detail-title">Archivos apertura</p>
                <div class="detail-container-file">
                    <p *ngIf="fileList.length===0" class="detail-subtitle"></p>
                    <div *ngFor="let f of fileList;" class="detail-content-file" matTooltip="{{f.name}}"
                        matTooltipPosition="above" matTooltipClass="tooltip">
                        <p>{{f.type}}</p>
                        <button mat-icon-button matTooltip="Descargar" matTooltipPosition="right"
                            matTooltipClass="tooltip" (click)="downloadFile(f)">
                            <mat-icon class="blue-color">cloud_download</mat-icon>
                        </button>
                    </div>
                    <p *ngIf="fileStartList.length ===0" class="detail-subtitle"></p>
                    <div *ngFor="let f of fileStartList;" class="detail-content-file" matTooltip="{{f.name}}"
                        matTooltipPosition="above" matTooltipClass="tooltip">
                        <p>{{f.type}}</p>
                        <button mat-icon-button matTooltip="Descargar" matTooltipPosition="right"
                            matTooltipClass="tooltip" (click)="downloadFile(f)">
                            <mat-icon class="blue-color">cloud_download</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
            <div class="detail-content-icon">
                <mat-icon [ngStyle]="{'color':'#2196f3'}">date_range</mat-icon>
            </div>
            <div class="detail-content">
                <p class="detail-title">Archivos cierre</p>
                <div class="detail-container-file">
                    <p *ngIf="fileEndList.length===0" class="detail-subtitle">Sin Archivos</p>
                    <div *ngFor="let f of fileEndList;" class="detail-content-file" matTooltip="{{f.name}}"
                        matTooltipPosition="above" matTooltipClass="tooltip">
                        <p>{{f.type}}</p>
                        <button mat-icon-button matTooltip="Descargar" matTooltipPosition="right"
                            matTooltipClass="tooltip" (click)="downloadFile(f)">
                            <mat-icon class="blue-color">cloud_download</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>