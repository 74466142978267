<div class="city-container">
    <p class="title">Listado Ciudades</p>
    <div class="city-container-button">
        <div class="city-group">
            <input type="text" class="form-control" placeholder="Filtrar" (keyup)="filter($event)">
            <mat-icon class="blue-color">search</mat-icon>
        </div>
        <button routerLink="/principal/city/new" mat-mini-fab class="green" matTooltip="Crear" matTooltipPosition="left"
            matTooltipClass="tooltip">
            <mat-icon>add</mat-icon>
        </button>
    </div>
    <table #table class="city-table" mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Nombre</th>
            <td mat-cell *matCellDef="let item">{{item.name}}</td>
        </ng-container>
        <ng-container matColumnDef="department">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Departamento</th>
            <td mat-cell *matCellDef="let item">{{item.department}}</td>
        </ng-container>
        <ng-container matColumnDef="active">
            <th mat-header-cell *matHeaderCellDef>Activo</th>
            <td mat-cell *matCellDef="let item">
                <mat-icon *ngIf="item.active" class="green-color">done</mat-icon>
                <mat-icon *ngIf="!item.active" class="red-color">clear</mat-icon>
            </td>
        </ng-container>
        <ng-container matColumnDef="Acciones">
            <th mat-header-cell *matHeaderCellDef>Acciones</th>
            <td mat-cell *matCellDef="let item">
                <button mat-icon-button matTooltip="Eliminar" matTooltipPosition="above"
                    matTooltipClass="tooltip" (click)="delete(item.id)">
                    <mat-icon class="red-color">delete</mat-icon>
                </button>
                <button mat-icon-button matTooltip="Editar" matTooltipPosition="above"
                    matTooltipClass="tooltip" (click)="edit(item.id)">
                    <mat-icon class="blue-color">create</mat-icon>
                </button>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns;"></tr>
    </table>
    <div *ngIf="loading" class="city-container-loader">
        <div class="city-loader"></div>
    </div>
    <mat-paginator [pageSizeOptions]="[5, 10, 15, 20, 30, 40, 50, 100]" showFirstLastButtons></mat-paginator>
</div>