<div mat-dialog-content>
    <div class="files-container-button">
        <button mat-icon-button (click)="close()">
            <mat-icon>clear</mat-icon>
        </button>
    </div>
    <p class="title">Archivos</p>
    <div class="files-container-files">
        <p class="title" [ngStyle]="{'text-align':'center'}">Archivos de Inicio</p>
        <div *ngIf="loading" class="files-container-loader">
            <div class="files-loader"></div>
        </div>
        <div *ngIf="!loading" class="files-content-files">
            <div *ngFor="let f of fileList" class="files-card-files" matTooltip="{{f.name}}" matTooltipPosition="above"
                matTooltipClass="tooltip">
                <p>{{f.type}}</p>
                <div class="files-card-actions">
                    <button mat-icon-button matTooltip="Descargar" matTooltipPosition="below" matTooltipClass="tooltip" (click)="download(f)">
                        <mat-icon class="blue-color">cloud_download</mat-icon>
                    </button>
                    <button mat-icon-button matTooltip="Eliminar" matTooltipPosition="below" matTooltipClass="tooltip" (click)="delete(f.name)">
                        <mat-icon class="red-color">delete</mat-icon>
                    </button>
                </div>
            </div>
        </div>
        <div class="files-container-float-button">
            <input #files hidden type="file" multiple (change)="load($event.target.files)">
            <button mat-mini-fab class="green" matTooltip="Cargar archivo" matTooltipPosition="above" matTooltipClass="tooltip" (click)="files.click()">
                <mat-icon>add</mat-icon>
            </button>
        </div>
    </div>
</div>