<mat-toolbar class="rep-toolbar primaryBackground">
    <div class="rep-toolbar-image primaryBackground">
        <img src="./assets/images/Logo.png">
        <span class="rep-toolbar-title secundaryColor">CARGUES</span>
    </div>
    <div class="rep-toolbar-options">
        <button class="secundaryColor" mat-icon-button (click)="onToogle()">
            <mat-icon>menu</mat-icon>
        </button>
        <div>
            <button class="secundaryColor" mat-icon-button [matMenuTriggerFor]="apps">
                <mat-icon>apps</mat-icon>
            </button>
            <button class="secundaryColor" mat-icon-button [matMenuTriggerFor]="closeSeccion">
                <mat-icon>more_vert</mat-icon>
            </button>
        </div>
    </div>
</mat-toolbar>
<mat-menu #apps="matMenu">
    <button *ngFor="let app of applicationList" routerLink="/{{app.link}}" class="ligthColor" mat-menu-item>
        <mat-icon [ngStyle]="{'color':app.color}">{{app.icon}}</mat-icon>
        <span>{{app.name}}</span>
    </button>
</mat-menu>
<mat-menu #closeSeccion="matMenu">
    <button class="ligthColor" mat-menu-item (click)="updatePassword()">
        <mat-icon>cached</mat-icon>
        <span>Cambiar contraseña</span>
    </button>
    <button class="ligthColor" mat-menu-item (click)="logout()">
        <mat-icon class="red-color">power_settings_new</mat-icon>
        <span>Cerrar sesión</span>
    </button>
</mat-menu>
<div class="rep-container">
    <div class="rep-sidenav" [ngClass]="{'rep-sidenav-close' : !isOpen}">
        <div class="rep-sidenav-header secundaryBackground">
            <input #image hidden type="file" accept="images" (change)="loadImage($event.target.files)">
            <img src={{photo}} (click)="image.click()">
            <div class="rep-sidenav-title">
                <span class="primaryColor">{{genPersonEntity.firstName |titlecase}}
                    {{genPersonEntity.lastName |titlecase}}</span>
                <span class="ligthColor">{{genPersonEntity.position |titlecase}}</span>
            </div>
        </div>
        <div #sidenavContent class="rep-sidenav-content secundaryBackground" (mouseover)="enableScroll()"
            (mouseleave)="disableScroll()">
            <span class="rep-navigation primaryColor">Navegación</span>
            <div *ngFor="let s of sectionList" class="rep-section-container"
                [ngClass]="{'rep-section-container-active' : open === s.name}">
                <ng-container *ngIf="s.active">
                    <div id="s.name" class="rep-section primaryColor" (click)="onClick(s.name)">
                        <div class="rep-section-title">
                            <mat-icon class="rep-section-icon primaryColor" [ngStyle]="{'color' : s.color}">
                                {{s.icon}}
                            </mat-icon>
                            <a>{{s.name |titlecase}}</a>
                        </div>
                        <mat-icon class="rep-section-icon primaryColor"
                            [ngClass]="{'rep-section-icon-open' : open === s.name}">
                            keyboard_arrow_down</mat-icon>
                    </div>
                    <ul id="module_{{s.name}}" class="rep-module"
                        [ngStyle]="{'height': open === s.name? height :'1px','transition':'height 0.2s linear'}">
                        <ng-container *ngFor="let mod of moduleList">
                            <a *ngIf="mod.sectionId === s.id" [routerLink]="[mod.link]"
                                class="ligthColor">{{mod.name}}</a>
                        </ng-container>
                    </ul>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="rep-content" [ngClass]="{'rep-content-close' : !isOpen}">
        <router-outlet></router-outlet>
        <p class="title">Interprete De Datos</p>
        <div class="user-container-button">
            <div class="user-group">
                <input type="text" class="form-control" placeholder="Filtrar" (keyup)="filter($event)">

            </div>
        </div>
        <table mat-table [dataSource]="dataSource" matSort>
            <ng-container *ngFor="let c of columns">
                <ng-container matColumnDef="{{c.field}}">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{c.header}}</th>
                    <td mat-cell *matCellDef="let item">{{item[c.field]}}</td>
                </ng-container>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[5, 10, 15, 20, 30, 40, 50, 100]" showFirstLastButtons></mat-paginator>
    </div>
</div>