<div mat-dialog-content>
    <div class="person-container-button">
        <button mat-icon-button (click)="close('')">
            <mat-icon>clear</mat-icon>
        </button>
    </div>
    <p class="title">{{this.title}}</p>
    <div class="person-container-search">
        <div class="person-group">
            <input type="text" class="form-control" placeholder="Filtrar" (keyup)="filter($event)">
            <mat-icon class="blue-color">search</mat-icon>
        </div>
    </div>
    <table #table class="person-table" mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="code">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Codigo </th>
            <td mat-cell *matCellDef="let item" (click)="close(item.code)">{{item.code}}</td>
        </ng-container>
        <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Descripcion</th>
            <td mat-cell *matCellDef="let item" (click)="close(item.code)">{{item.description}}</td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns;"></tr>
    </table>
    <div *ngIf="loading" class="person-container-loader">
        <div class="person-loader"></div>
    </div>
    <mat-paginator [pageSizeOptions]="[5, 10, 15, 20, 30, 40, 50, 100]" showFirstLastButtons></mat-paginator>
</div>