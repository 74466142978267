export class BscMeasurementDetailEntity{
    id:number;
    measurementId:number;
    monthId:number;
    month:string;
    goal:number;
    result:number;
    compliance:number;
    statusId:number;
    status:string;

    constructor(){}
}