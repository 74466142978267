<div class="report-container">
    <p class="title">Listado de Ciclicos</p>
    <div class="report-form">
        <div class="report-control-group">
            <label class="primaryColor">Clientes</label>
            <select [(ngModel)]="customerId" class="form-control" (change)="getCyclic()">
                <option class="ligthColor" value="0">Seleccione un cliente</option>
                <option *ngFor="let c of customerList" value="{{c.customerId}}">{{c.customer}}</option>
            </select>
        </div>
    </div>
    <div class="report-container-button">
        <div class="report-group">
            <input type="text" class="form-control" placeholder="Filtrar" (keyup)="filter($event)">
            <mat-icon class="blue-color">search</mat-icon>
        </div>
        <button routerLink="/invPrincipal/cyclic/new" mat-mini-fab class="green" matTooltip="Crear" matTooltipPosition="left"
            matTooltipClass="tooltip">
            <mat-icon>add</mat-icon>
        </button>
    </div>
    <table #table class="report-table" mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Nombre</th>
            <td mat-cell *matCellDef="let item">{{item.name}}</td>
        </ng-container>
        <ng-container matColumnDef="typeSampling">
            <th mat-header-cell *matHeaderCellDef>Tipo Muestra</th>
            <td mat-cell *matCellDef="let item">{{item.typeSampling}}</td>
        </ng-container>
        <ng-container matColumnDef="sampling">
            <th mat-header-cell *matHeaderCellDef>Porcentaje Muestra</th>
            <td mat-cell *matCellDef="let item">{{item.sampling}}%</td>
        </ng-container>
        <ng-container matColumnDef="total">
            <th mat-header-cell *matHeaderCellDef>Total Muestra</th>
            <td mat-cell *matCellDef="let item">{{item.total}}%</td>
        </ng-container>
        <ng-container matColumnDef="system">
            <th mat-header-cell *matHeaderCellDef>Sistema</th>
            <td mat-cell *matCellDef="let item">{{item.system}}</td>
        </ng-container>
        <ng-container matColumnDef="crossSap">
            <th mat-header-cell *matHeaderCellDef>Cruce SAP</th>
            <td mat-cell *matCellDef="let item">
                <mat-icon *ngIf="item.crossSap" class="green-color">done</mat-icon>
                <mat-icon *ngIf="!item.crossSap" class="red-color">clear</mat-icon>
            </td>
        </ng-container>
        <ng-container matColumnDef="crossWms">
            <th mat-header-cell *matHeaderCellDef>Cruce WMS CLOUD</th>
            <td mat-cell *matCellDef="let item">
                <mat-icon *ngIf="item.crossWms" class="green-color">done</mat-icon>
                <mat-icon *ngIf="!item.crossWms" class="red-color">clear</mat-icon>
            </td>
        </ng-container>
        <ng-container matColumnDef="crossBase">
            <th mat-header-cell *matHeaderCellDef>Cruce Base</th>
            <td mat-cell *matCellDef="let item">
                <mat-icon *ngIf="item.crossBase" class="green-color">done</mat-icon>
                <mat-icon *ngIf="!item.crossBase" class="red-color">clear</mat-icon>
            </td>
        </ng-container>
        <ng-container matColumnDef="creationDate">
            <th mat-header-cell *matHeaderCellDef>Fecha Creacion</th>
            <td mat-cell *matCellDef="let item">{{item.creationDate}}</td>
        </ng-container>
        <ng-container matColumnDef="customer">
            <th mat-header-cell *matHeaderCellDef>Cliente</th>
            <td mat-cell *matCellDef="let item">{{item.customer}}</td>
        </ng-container>
        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>Estado</th>
            <td mat-cell *matCellDef="let item">{{item.status}}</td>
        </ng-container>
        <ng-container matColumnDef="active">
            <th mat-header-cell *matHeaderCellDef>Activo</th>
            <td mat-cell *matCellDef="let item">
                <mat-icon *ngIf="item.active" class="green-color">done</mat-icon>
                <mat-icon *ngIf="!item.active" class="red-color">clear</mat-icon>
            </td>
        </ng-container>
        <ng-container matColumnDef="Acciones">
            <th mat-header-cell *matHeaderCellDef>Acciones</th>
            <td mat-cell *matCellDef="let item">
                <button mat-icon-button matTooltip="Eliminar" matTooltipPosition="above"
                    matTooltipClass="tooltip" (click)="delete(item.id)">
                    <mat-icon class="red-color">delete</mat-icon>
                </button>
                <button mat-icon-button matTooltip="Editar" matTooltipPosition="above"
                    matTooltipClass="tooltip" (click)="edit(item.id)">
                    <mat-icon class="blue-color">create</mat-icon>
                </button>
                <button mat-icon-button matTooltip="Mas" matTooltipPosition="above" matTooltipClass="tooltip" [matMenuTriggerFor]="menuUser">
                    <mat-icon class="orange-color">more_vert</mat-icon>
                </button>
                <mat-menu #menuUser="matMenu" xPosition="before">
                    <button *ngIf="item.typeSampling==='Ubicaciones'" mat-menu-item (click)="getSampling(item.id,item.system,item.typeSampling,item.customer)">
                        <mat-icon >people</mat-icon>
                        <span>Ubicaciones</span>
                    </button>
                    <button *ngIf="item.typeSampling==='Codigos Sap'" mat-menu-item (click)="getSampling(item.id,item.system,item.typeSampling,item.customer)">
                        <mat-icon>settings_ethernet</mat-icon>
                        <span>Codigo Sap</span>
                    </button>
                    <button mat-menu-item (click)="getAuditer(item.id)">
                        <mat-icon>verified</mat-icon>
                        <span>Auditores</span>
                    </button>
                </mat-menu>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns;"></tr>
    </table>
    <div *ngIf="loading" class="report-container-loader">
        <div class="report-loader"></div>
    </div>
    <mat-paginator [pageSizeOptions]="[5, 10, 15, 20, 30, 40, 50, 100]" showFirstLastButtons></mat-paginator>
</div>