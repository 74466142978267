export class WlsPrealertEntity{
    id:number;
    name:string;
    proyectId:number;
    proyect:string;
    creationDate:string;
    creationUserId:number;
    creationUser:string;
    active:boolean;

    constructor(){}
}