<div class="report-container">
    <p class="title">Conteo General</p>
    <form [formGroup]="form">

        <div class="report-form">

            <div class="report-control-group">
                <input [(ngModel)]="pallet" name="pallet" formControlName="pallet" type="text" class="form-control"
                    disabled placeholder="Genere un pallet">
                <button mat-mini-fab class="blue" matTooltip="Pallet" matTooltipPosition="above"
                    matTooltipClass="tooltip" (click)="getPallet()">
                    <mat-icon>restart_alt</mat-icon>
                </button>
            </div>
            <div class="report-control-group">
                <input [(ngModel)]="codigoSap" name="codigoSap" formControlName="codigoSap" type="text"
                    class="form-control" disabled placeholder="Seleccione un codigo sap">
                <button mat-mini-fab class="green" matTooltip="Codigo Sap" matTooltipPosition="above"
                    matTooltipClass="tooltip" (click)="getCodigoSap()">
                    <mat-icon>add</mat-icon>
                </button>
            </div>
            <div class="report-control-group">
                <input [(ngModel)]="typology" name="typology" formControlName="typology" type="text"
                    class="form-control" disabled placeholder="Seleccione una tipologia">
                <button mat-mini-fab class="green" matTooltip="Tipologia" matTooltipPosition="above"
                    matTooltipClass="tooltip" (click)="getTipology()">
                    <mat-icon>add</mat-icon>
                </button>
            </div>
            <div class="report-control-group">
                <label class="primaryColor"></label>
                <br>
                <button class="inv-button" mat-raised-button matTooltip="Iniciar conteo" [disabled]="!form.valid"
                    (click)="save()">
                    <mat-icon>save</mat-icon>
                </button>
            </div>
        </div>
    </form>

    <div class="inv-container-button">
        <div class="inv-group">
            <input type="text" class="form-control" placeholder="Filtrar" (keyup)="filter($event)">
            <mat-icon class="blue-color">search</mat-icon>
        </div>
    </div>
    <table #table class="inv-table" mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="pallet">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Pallet</th>
            <td mat-cell *matCellDef="let item">{{item.pallet}}</td>
        </ng-container>
        <ng-container matColumnDef="codigoSap">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Codigo Sap</th>
            <td mat-cell *matCellDef="let item">{{item.codigoSap}}</td>
        </ng-container>
        <ng-container matColumnDef="location">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Ubicacion</th>
            <td mat-cell *matCellDef="let item">{{item.location}}</td>
        </ng-container>
        <ng-container matColumnDef="typology">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Tipologia</th>
            <td mat-cell *matCellDef="let item">{{item.typology}}</td>
        </ng-container>
        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Estado</th>
            <td mat-cell *matCellDef="let item">{{item.status}}</td>
        </ng-container>
        <ng-container matColumnDef="creationDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha de registro</th>
            <td mat-cell *matCellDef="let item">{{item.creationDate}}</td>
        </ng-container>
        <ng-container matColumnDef="userCreation">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Usuario de creacion</th>
            <td mat-cell *matCellDef="let item">{{item.userCreation}}</td>
        </ng-container>
        <ng-container matColumnDef="userUpdate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Usuario de modificacion</th>
            <td mat-cell *matCellDef="let item">{{item.userUpdate}}</td>
        </ng-container>
        <ng-container matColumnDef="active">
            <th mat-header-cell *matHeaderCellDef>Activo</th>
            <td mat-cell *matCellDef="let item">
                <mat-icon *ngIf="item.active" class="green-color">done</mat-icon>
                <mat-icon *ngIf="!item.active" class="red-color">clear</mat-icon>
            </td>
        </ng-container>
        <ng-container matColumnDef="Acciones">
            <th mat-header-cell *matHeaderCellDef>Acciones</th>
            <td mat-cell *matCellDef="let item">
                <button mat-icon-button matTooltip="Eliminar" matTooltipPosition="above" matTooltipClass="tooltip"
                    (click)="delete(item.id, item.pallet)">
                    <mat-icon class="red-color">delete</mat-icon>
                </button>
                <button mat-icon-button matTooltip="Serializar" matTooltipPosition="above" matTooltipClass="tooltip"
                    (click)="serial(item.id, item.codigoSap)">
                    <mat-icon class="orange-color">menu_book</mat-icon>
                </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns;"></tr>
    </table>
    <div *ngIf="loading" class="report-container-loader">
        <div class="report-loader"></div>
    </div>
    <mat-paginator [pageSizeOptions]="[5, 10, 15, 20, 30, 40, 50, 100]" showFirstLastButtons></mat-paginator>

</div>