<div fxLayout fxLayoutAlign="flex-end">
  <button mat-icon-button mat-dialog-close>
    <mat-icon>clear</mat-icon>
  </button>
</div>
<h2 mat-dialog-title>{{ title }}</h2>
<mat-dialog-content>
  <div fxLayout="row" fxFlex="100%">
    <div fxFlex="50%" fxFlexOrder="1">
      <ul>
        <li>
          <mat-checkbox color="primary" [(ngModel)]="opcion1" class="">Lunes</mat-checkbox>
        </li>
        <li>
          <mat-checkbox color="primary" [(ngModel)]="opcion2" class="">Martes</mat-checkbox>
        </li>
        <li>
          <mat-checkbox color="primary" [(ngModel)]="opcion3" class="">Miercoles</mat-checkbox>
        </li>
        <li>
          <mat-checkbox color="primary" [(ngModel)]="opcion4" class="">Jueves</mat-checkbox>
        </li>
        <li>
          <mat-checkbox color="primary" [(ngModel)]="opcion5" class="">Viernes</mat-checkbox>
        </li>
        <li>
          <mat-checkbox color="primary" [(ngModel)]="opcion6" class="">Sabado</mat-checkbox>
        </li>
        <li>
          <mat-checkbox color="primary" [(ngModel)]="opcion7" class="">Domingo</mat-checkbox>
        </li>
      </ul>
    </div>
    <div fxFlex="50%" fxFlexOrder="2">
      <div fxLayout="column" fxLayoutAlign="center">
        <div class="time">
          <label for="tiempo1">Hora Inicio</label>
          <input required matInput type="time" [(ngModel)]="tiempo1" name="tiempo1" />
        </div>
        <div class="time">
          <label for="tiempo2">Horal Final</label>
          <input required matInput type="time" [(ngModel)]="tiempo2" name="tiempo2" />
        </div>
        <mat-divider></mat-divider>
        <div class="time">
          <input type="checkbox" [checked]="estatus" [value]="estatus" (change)="onCheckboxChange($event)">
          <label for="">Fecha Vencimiento</label>
        </div>
        
          <div *ngIf="estatus==true" class="time">
            <input readonly id="startDate" [(ngModel)]="fechaMax" matInput [matDatepicker]="startDate" class="form-control">
            <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
            <mat-datepicker #startDate disabled="false"></mat-datepicker>
          </div>
        
      </div>
    </div>
  </div>

</mat-dialog-content>
<mat-dialog-actions fxLayoutAlign="flex-end">
  <button mat-button mat-dialog-close>Cancel</button>
  <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
  <button mat-button (click)="save()">Guardar</button>
</mat-dialog-actions>