import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ResponseModel } from "src/app/shared/models/response.model";
import { environment } from "src/environments/environment";
import { CovFormSegPerEntity } from "../entities/covFormSegPer.entity";

@Injectable({
    providedIn:'root'
})
export class CovFormSegPerService{

    private url:string;

    constructor(private http:HttpClient){
        this.url=environment.api;
    }
    public create(covFormSegPerEntity:CovFormSegPerEntity):Observable<ResponseModel>{
        var headers = new HttpHeaders().set('content-Type', 'application/json');

        return this.http.post<ResponseModel>(this.url+'CovFormSegper/create',JSON.stringify(covFormSegPerEntity),{headers:headers});
    }
    public delete(id:number):Observable<ResponseModel>{
        var headers = new HttpHeaders().set('content-Type', 'application/x-www-form-urlencoded');

        return this.http.delete<ResponseModel>(this.url+'CovFormSegper/delete/'+id,{headers:headers});
    }
    
    public list():Observable<ResponseModel>{
        var headers = new HttpHeaders().set('content-Type', 'application/json');

        return this.http.get<ResponseModel>(this.url+'CovFormSegper/list',{headers:headers});
    }

}