<div mat-dialog-content>
    <div class="lod-container-button">
        <div class="lod-container-button">
        </div>
        <button mat-icon-button (click)="close()">
            <mat-icon>clear</mat-icon>
        </button>
    </div>
    <p class="title">Titulo, parrafo de introduccion y entradas de texto (Pagina cliente inicio)</p>
    <div class="lod-container-button-flex">
        <div class="lod-group">
            <input type="text" class="forms-control" placeholder="" (keyup)="filter($event)">
            <mat-icon class="blue-color">search</mat-icon>
        </div>
    </div>
    <table class="lod-table" mat-table [dataSource]="dataSource" #table>
        <ng-container matColumnDef="titleTable">
            <th mat-header-cell *matHeaderCellDef>Titulo de la tabla</th>
            <td mat-cell *matCellDef="let item">{{item.titleTable}}</td>
        </ng-container>
        <ng-container matColumnDef="columnOne">
            <th mat-header-cell *matHeaderCellDef>Nombre de la columna N° de ticket</th>
            <td mat-cell *matCellDef="let item">{{item.columnOne}}</td>
        </ng-container>
        <ng-container matColumnDef="columnTwo">
            <th mat-header-cell *matHeaderCellDef>Nombre de la columna N° de PQRS</th>
            <td mat-cell *matCellDef="let item">{{item.columnTwo}}</td>
        </ng-container>
        <ng-container matColumnDef="columnTheer">
            <th mat-header-cell *matHeaderCellDef>Nombre de la columna serial</th>
            <td mat-cell *matCellDef="let item">{{item.columnTheer}}</td>
        </ng-container>
        <ng-container matColumnDef="columnFour">
            <th mat-header-cell *matHeaderCellDef>Nombre de la columna descripcion</th>
            <td mat-cell *matCellDef="let item">{{item.columnFour}}</td>
        </ng-container>
        <ng-container matColumnDef="columnFive">
            <th mat-header-cell *matHeaderCellDef>Nombre de la columna de las acciones que puede generar el sistema (Eliminar/Editar)</th>
            <td mat-cell *matCellDef="let item">{{item.columnFive}}</td>
        </ng-container>
        <ng-container matColumnDef="buttonOne">
            <th mat-header-cell *matHeaderCellDef>Nombre del boton del registro de seriales</th>
            <td mat-cell *matCellDef="let item">{{item.buttonOne}}</td>
        </ng-container>
        <ng-container matColumnDef="buttonTwo">
            <th mat-header-cell *matHeaderCellDef>Nombre del boton que permite almacenar los registros</th>
            <td mat-cell *matCellDef="let item">{{item.buttonTwo}}</td>
        </ng-container>
        
        <ng-container matColumnDef="language">
            <th mat-header-cell *matHeaderCellDef>Lenguaje</th>
            <td mat-cell *matCellDef="let item">{{item.language}}</td>
        </ng-container>
        <ng-container matColumnDef="cretionDate">
            <th mat-header-cell *matHeaderCellDef>Fecha de registro</th>
            <td mat-cell *matCellDef="let item">{{item.cretionDate}}</td>
        </ng-container>
        <ng-container matColumnDef="active">
            <th mat-header-cell *matHeaderCellDef>Activo</th>
            <td mat-cell *matCellDef="let item">
                <mat-icon *ngIf="item.active" class="green-color">done</mat-icon>
                <mat-icon *ngIf="!item.active" class="red-color">clear</mat-icon>
            </td>
        </ng-container>
        <ng-container matColumnDef="Acciones">
            <th mat-header-cell *matHeaderCellDef>Acciones</th>
            <td mat-cell *matCellDef="let item">
                <button mat-icon-button matTooltip="Eliminar" matTooltipPosition="above" matTooltipClass="tooltip"
                    (click)="delete(item.id)">
                    <mat-icon class="red-color">delete</mat-icon>
                </button>
                <button mat-icon-button matTooltip="Editar" matTooltipPosition="above" matTooltipClass="tooltip"
                    (click)="edit(item.id)">
                    <mat-icon class="blue-color">create</mat-icon>
                </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns;"></tr>
    </table>
</div>