<div class="loc-container">
    <p class="title">Listado</p>
      <br>
       
        <br>
        <div class>
        <input id="serials" #serials hidden type="file" accept=".xlsx" (change)="load($event.target.files)">
        <button mat-mini-fab class="blue" matTooltip="{{uploading?'Cargando ':'Cargar '}}" matTooltipPosition="above"
            matTooltipClass="tooltip" (click)="serials.click()" >
            <mat-icon >cloud_upload</mat-icon>
        </button>

        <button reset mat-mini-fab class="blue loc-but" matTooltip="Exportar" matTooltipPosition="above"
            matTooltipClass="tooltip" (click)="download()">
            <mat-icon>cloud_download</mat-icon>
        </button>

       <!-- <button mat-mini-fab class="red loc-but" matTooltip="Eliminar" matTooltipPosition="above"
            matTooltipClass="tooltip" (click)="delete()">
            <mat-icon>delete</mat-icon>
        </button>-->

    </div>
    <br>
    <div *ngIf="loading" class="loc-container-loader">
        <div class="loc-loader"></div>
    </div>
    <table class="loc-table" mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="ID CALL">
            <th mat-header-cell *matHeaderCellDef>ID CALL</th>
            <td mat-cell *matCellDef="let item">{{item.idCall}}</td>
        </ng-container>
        <ng-container matColumnDef="AGENT NAME">
            <th mat-header-cell *matHeaderCellDef>AGENT NAME</th>
            <td mat-cell *matCellDef="let item">{{item.agentName}}</td>
        </ng-container>
        <ng-container matColumnDef="ID CUSTOMER">
            <th mat-header-cell *matHeaderCellDef>ID CUSTOMER</th>
            <td mat-cell *matCellDef="let item">{{item.id}}</td>
        </ng-container>
        <ng-container matColumnDef="DATE">
            <th mat-header-cell *matHeaderCellDef>DATE</th>
            <td mat-cell *matCellDef="let item">{{item.date}}</td>
        </ng-container>
        <ng-container matColumnDef="DESCRIPTION COD1">
            <th mat-header-cell *matHeaderCellDef>DESCRIPTION COD1</th>
            <td mat-cell *matCellDef="let item">{{item.description}}</td>
        </ng-container>
        <ng-container matColumnDef="CALL COMMENTS">
            <th mat-header-cell *matHeaderCellDef>CALL COMMENTS</th>
            <td mat-cell *matCellDef="let item">{{item.comments}}</td>
        </ng-container>
        <ng-container matColumnDef="TYPE OF CALL">
            <th mat-header-cell *matHeaderCellDef>TYPE OF CALL</th>
            <td mat-cell *matCellDef="let item">{{item.typeCall}}</td>
        </ng-container>
        <ng-container matColumnDef="ESTADO">
            <th mat-header-cell *matHeaderCellDef>ESTADO</th>
            <td mat-cell *matCellDef="let item">{{item.estado}}</td>
        </ng-container>
        <ng-container matColumnDef="TELEPHONE">
            <th mat-header-cell *matHeaderCellDef>TELEPHONE</th>
            <td mat-cell *matCellDef="let item">{{item.telephone}}</td>
        </ng-container>
        <ng-container matColumnDef="DURATION(SEG)">
            <th mat-header-cell *matHeaderCellDef>DURATION(SEG)</th>
            <td mat-cell *matCellDef="let item">{{item.duration}}</td>
        </ng-container>
        <ng-container matColumnDef="ID CAMPAING">
            <th mat-header-cell *matHeaderCellDef>ID CAMPAING</th>
            <td mat-cell *matCellDef="let item">{{item.idCampaing}}</td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns;"></tr>
    </table>
    <mat-paginator #paginator [pageSizeOptions]="[5, 10, 15, 20, 30, 40, 50, 100]" showFirstLastButtons>
    </mat-paginator>
</div>