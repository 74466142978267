<div class="scp-container">
    <p class="title">Auditoria</p>
    <div class="scp-container-button">
        <div class="scp-group">
            <input type="text" class="form-control" placeholder="Filtrar" (keyup)="filter($event)">
            <mat-icon class="blue-color">search</mat-icon>
        </div>
    </div>
    <table class="scp-table" mat-table [dataSource]="dataSource" matSort>

        <ng-container matColumnDef="codeAudit">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Codigo</th>
            <td mat-cell *matCellDef="let item" (click)="getInformation(item.id)" matTooltip="Seleccionar auditoria">
                {{item.codeAudit}}</td>
        </ng-container>
        <ng-container matColumnDef="auditPreviousName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Auditoria Previa</th>
            <td mat-cell *matCellDef="let item" (click)="getInformation(item.id)" matTooltip="Seleccionar auditoria">
                {{item.auditPreviousName}}</td>
        </ng-container>
        <ng-container matColumnDef="typeAuditName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Tipo Auditoria</th>
            <td mat-cell *matCellDef="let item" (click)="getInformation(item.id)" matTooltip="Seleccionar auditoria">
                {{item.typeAuditName}}</td>
        </ng-container>
        <ng-container matColumnDef="state">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Estado</th>
            <td mat-cell *matCellDef="let item" (click)="getInformation(item.id)" matTooltip="Seleccionar auditoria">
                {{item.state}}</td>
        </ng-container>
        <ng-container matColumnDef="levelRuleName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Nivel</th>
            <td mat-cell *matCellDef="let item" (click)="getInformation(item.id)" matTooltip="Seleccionar auditoria">
                {{item.levelRuleName}}</td>
        </ng-container>
        <ng-container matColumnDef="userName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Usuario Creacion</th>
            <td mat-cell *matCellDef="let item" (click)="getInformation(item.id)" matTooltip="Seleccionar auditoria">
                {{item.userName}}</td>
        </ng-container>
        <ng-container matColumnDef="active">
            <th mat-header-cell *matHeaderCellDef>Activo</th>
            <td mat-cell *matCellDef="let item">
                <mat-icon *ngIf="item.active" matTooltip="Seleccionar auditoria" class="green-color">done</mat-icon>
                <mat-icon *ngIf="!item.active" matTooltip="Seleccionar auditoria" class="red-color">clear</mat-icon>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns;"></tr>
    </table>
    <div *ngIf="loading" class="report-container-loader">
        <div class="report-loader"></div>
    </div>
    <mat-paginator [pageSizeOptions]="[5, 10, 15, 20, 30, 40, 50, 100]" showFirstLastButtons></mat-paginator>

    <div *ngIf="this.scpAuditSerialM != null" class="scp-container-serials">

        <div class="scp-countAudit">
            <p class="scp-count-title">Codigo Auditoria</p>
            <p class="scp-count-subtitle">{{this.scpAuditSerialM.codeAudit}}</p>
        </div>
        <div class="scp-flex">
            <div class="scp-group">
                <label class="primaryColor">Iniciar Auditoria</label>
                <div class="scp-container-button">
                    <button [disabled]="this.dataSourceSerial.data.length ===this.scpAuditSerialM.levelRuleQuantity"
                        [ngClass]="{'ligthBackground':this.dataSourceSerial.data.length !=this.scpAuditSerialM.levelRuleQuantity}"
                        class="scp-button" mat-raised-button matTooltip="Iniciar Auditoria" mat-raised-button
                        matTooltipPosition="right" matTooltipClass="tooltip"
                        (click)="getRegisSerial(this.scpAuditSerialM.id)">
                        <mat-icon>flag</mat-icon>
                    </button>
                </div>
            </div>

            <div class="scp-group" *ngIf="this.report >= this.scpAuditSerialM.noveltyRejected; else not">
                <label class="primaryColor">Cerrar Auditoria</label>
                <div class="scp-container-button">
                    <button [disabled]="this.dataSourceSerial.data.length !=this.scpAuditSerialM.levelRuleQuantity"
                        [ngClass]="{'ligthBackground':this.dataSourceSerial.data.length ===this.scpAuditSerialM.levelRuleQuantity}"
                        class="scp-button-red" mat-raised-button matTooltip="Cerrar" mat-raised-button
                        matTooltipPosition="right" matTooltipClass="tooltip"
                        (click)="updateClose(this.scpAuditSerialM.id)"
                        (click)="updateRejected(this.scpAuditSerialM.id)">
                        <mat-icon>highlight_off</mat-icon>
                    </button>
                </div>
            </div>
            <ng-template #not>
                <div class="scp-group">
                    <label class="primaryColor">Cerrar Auditoria</label>
                    <div class="scp-container-button">
                        <button [disabled]="this.dataSourceSerial.data.length !=this.scpAuditSerialM.levelRuleQuantity"
                            [ngClass]="{'ligthBackground':this.dataSourceSerial.data.length ===this.scpAuditSerialM.levelRuleQuantity}"
                            class="scp-button-red" mat-raised-button matTooltip="Cerrar" mat-raised-button
                            matTooltipPosition="right" matTooltipClass="tooltip"
                            (click)="updateClose(this.scpAuditSerialM.id)"
                            (click)="updateApproved(this.scpAuditSerialM.id)">
                            <mat-icon>highlight_off</mat-icon>
                        </button>
                    </div>
                </div>
            </ng-template>

            <div class="scp-flex">


                <div class="scp-count">
                    <p class="scp-count-title">Rechazados Permitidos</p>
                    <p class="scp-count-subtitleSerial-blue">{{this.scpAuditSerialM.noveltyAccepted}}</p>
                </div>

                <div class="scp-count">
                    <p class="scp-count-title">Rechazados Maximos</p>
                    <div class="scp-flex">
                    <p class="scp-count-subtitleSerial-green">{{this.report}}</p>
                    <p class="scp-count-subtitleSerial-black">{{this.vars}}</p>
                    <p class="scp-count-subtitleSerial">{{this.scpAuditSerialM.noveltyRejected}}</p>
                    </div>
                </div>

                <div class="scp-count-serial">
                    <p class="scp-count-title">Seriales a Auditar</p>
                    <div class="scp-flex">
                        <p class="scp-count-subtitleSerial-green">{{this.dataSourceSerial.data.length}}</p>
                        <p class="scp-count-subtitleSerial-black">{{this.vars}}</p>
                        <p class="scp-count-subtitleSerial">{{this.scpAuditSerialM.levelRuleQuantity}}</p>
                    </div>
                </div>

            </div>
        </div>
        <div>
            <table class="scp-table-crossing" mat-table [dataSource]="dataSourceSerial" matSort>

                <ng-container matColumnDef="serial">
                    <th mat-header-cell *matHeaderCellDef>Serial</th>
                    <td mat-cell *matCellDef="let item">{{item.serial}}</td>
                </ng-container>
                <ng-container matColumnDef="mac">
                    <th mat-header-cell *matHeaderCellDef>Mac</th>
                    <td mat-cell *matCellDef="let item">{{item.mac}}</td>
                </ng-container>
                <ng-container matColumnDef="sapCode">
                    <th mat-header-cell *matHeaderCellDef>Codigo Sap</th>
                    <td mat-cell *matCellDef="let item">{{item.sapCode}}</td>
                </ng-container>
                <ng-container matColumnDef="description">
                    <th mat-header-cell *matHeaderCellDef>Descripcion</th>
                    <td mat-cell *matCellDef="let item">{{item.description}}</td>
                </ng-container>
                <ng-container matColumnDef="technical">
                    <th mat-header-cell *matHeaderCellDef>Tecnico</th>
                    <td mat-cell *matCellDef="let item">{{item.technical}}</td>
                </ng-container>
                <ng-container matColumnDef="repairDate">
                    <th mat-header-cell *matHeaderCellDef>Fecha de reparacion</th>
                    <td mat-cell *matCellDef="let item">{{item.repairDate}}</td>
                </ng-container>
                <ng-container matColumnDef="scrapMotif">
                    <th mat-header-cell *matHeaderCellDef>Motivo de Scrap</th>
                    <td mat-cell *matCellDef="let item">{{item.scrapMotif}}</td>
                </ng-container>
                <ng-container matColumnDef="state">
                    <th mat-header-cell *matHeaderCellDef>Estado</th>
                    <td mat-cell *matCellDef="let item">{{item.state}}</td>
                </ng-container>
                <ng-container matColumnDef="stateMotif">
                    <th mat-header-cell *matHeaderCellDef>Motivo de estado</th>
                    <td mat-cell *matCellDef="let item">{{item.stateMotif}}</td>
                </ng-container>
                <ng-container matColumnDef="creationDate">
                    <th mat-header-cell *matHeaderCellDef>Fecha de registro</th>
                    <td mat-cell *matCellDef="let item">{{item.creationDate}}</td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="columnsSerial"></tr>
                <tr mat-row *matRowDef="let row; columns: columnsSerial;"></tr>
            </table>
            <div *ngIf="loading" class="report-container-loader">
                <div class="report-loader"></div>
            </div>
            <mat-paginator [pageSizeOptions]="[5, 10, 15, 20, 30, 40, 50, 100]" showFirstLastButtons></mat-paginator>
        </div>
    </div>
</div>