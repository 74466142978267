<div class="label-container">
    <p class="title">{{priLabelEntity.section}} - {{priLabelEntity.name}}</p>
    <div class="label-form">
        <div class="label-group">
            <label class="primaryColor">Impresora</label>
            <select [disabled]="block" [(ngModel)]="printerId" class="form-control">
                <option value="0">Seleccione una impresora</option>
                <option *ngFor="let p of printerList" value="{{p.id}}">{{p.name}}</option>
            </select>
            <mat-hint style="color: rgb(44, 135, 240);" *ngIf="printerId==0">El boton de busqueda se habilitara cuando seleccione</mat-hint>
        </div>
        <div class="label-group">
            <label class="primaryColor">Número de pallet</label>
            <input [disabled]="block" id="search" type="text" class="form-control" placeholder="Número de pallet">
            
        </div>
        <div class="label-group">
            <label class="primaryColor">Número de caja</label>
            <input [disabled]="block" id="box" type="text" class="form-control" placeholder="Número de caja">
        </div>
        <button [disabled]="block == true || printerId==0?true:false" mat-mini-fab [ngClass]="{'ligthBackground':printerId==0 || block==true,'blue':printerId!=0 && block==false}" [ngStyle]="{'margin-top':'20px'}" mat-raised-button matTooltip="Buscar" matTooltipPosition="above" matTooltipClass="tooltip" (click)="search()">
            <mat-icon>search</mat-icon>
        </button>
        <button [disabled]="block == true || data.length===0 || printerId==0?true:false" mat-mini-fab [ngClass]="{'ligthBackground':data.length===0,'green':data.length>0}" [ngStyle]="{'margin-top':'20px'}" mat-raised-button matTooltip="Imprimir" matTooltipPosition="above" matTooltipClass="tooltip" (click)="print()">
            <mat-icon>print</mat-icon>
        </button>
    </div>
    <div *ngIf="block">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
    <table mat-table [dataSource]="data" #table>
        <!-- Columnas de tabla -->
        <ng-container matColumnDef="Serial">
            <th mat-header-cell *matHeaderCellDef>Serial</th>
            <td mat-cell *matCellDef="let item;let i=index;">{{item.serial}}</td>
        </ng-container>
        <ng-container matColumnDef="Mac">
            <th mat-header-cell *matHeaderCellDef>Mac</th>
            <td mat-cell *matCellDef="let item;let i=index;">{{item.mac}}</td>
        </ng-container>
        <ng-container matColumnDef="Codigo Sap">
            <th mat-header-cell *matHeaderCellDef>Codigo Sap</th>
            <td mat-cell *matCellDef="let item;let i=index;">{{item.codigoSap}}</td>
        </ng-container>
        <ng-container matColumnDef="Descripcion">
            <th mat-header-cell *matHeaderCellDef>Descripcion</th>
            <td mat-cell *matCellDef="let item;let i=index;">{{item.descripcion}}</td>
        </ng-container>
        <ng-container matColumnDef="Pallet">
            <th mat-header-cell *matHeaderCellDef>Pallet</th>
            <td mat-cell *matCellDef="let item;let i=index;">{{item.pallet}}</td>
        </ng-container>
        <ng-container matColumnDef="Caja">
            <th mat-header-cell *matHeaderCellDef>Caja</th>
            <td mat-cell *matCellDef="let item;let i=index;">{{item.ncajaEmpaque}}</td>
        </ng-container>
        <ng-container matColumnDef="SmartCard">
            <th mat-header-cell *matHeaderCellDef>SmartCard</th>
            <td mat-cell *matCellDef="let item;let i=index;">{{item.campo1}}</td>
        </ng-container>
        <ng-container matColumnDef="Campo6">
            <th mat-header-cell *matHeaderCellDef>ID</th>
            <td mat-cell *matCellDef="let item;let i=index;">{{item.campo6}}</td>
        </ng-container>
        <ng-container matColumnDef="Estado">
            <th mat-header-cell *matHeaderCellDef>Estado</th>
            <td mat-cell *matCellDef="let item;let i=index;">{{item.estado}}</td>
        </ng-container>
        
        
        <tr mat-header-row *matHeaderRowDef="headers"></tr>
        <tr mat-row *matRowDef="let row; columns: headers;"></tr>
    </table>
    <div *ngIf="loading" class="label-container-loader">
        <div class="label-loader"></div>
    </div> 
</div>