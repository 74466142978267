import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthGuardService } from '../shared/services/authGuard.service';
import { FileGroupListComponent } from './components/fileGroup/list/fileGroupList.component';
import { FileGroupNewComponent } from './components/fileGroup/new/fileGroupNew.component';
import { FileGroupPrincipalComponent } from './components/principal/FlieGroupPrincipal.component';
import { GroupUserListComponent } from './components/user/list/groupUserList.component';
import { FileGroupAssignmentListComponent } from './components/fileGroupList/list/fileGroupAssignment.component';

const routes: Routes = [
  {
    path: 'fileGroupPrincipal', component: FileGroupPrincipalComponent,canActivate:[AuthGuardService],
    children: [
      { path: 'fileGroup/list', component: FileGroupListComponent },
      { path: 'fileGroup/new', component: FileGroupNewComponent},
      { path: 'fileGroup/user/list', component: GroupUserListComponent },
      { path: 'fileGroup/assignment', component: FileGroupAssignmentListComponent }
    ]
  },
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AppFileGroupRoutingModule { }