<div mat-dialog-content>
    <div class="close" fxLayout fxLayoutAlign="flex-end">
        <button mat-icon-button (click)="close(false)">
            <mat-icon>clear</mat-icon>
        </button>
    </div>
    <p class="title">Detalle Laboral</p>
    <div class="act-detail-container">
        <div *ngFor="let c  of covFormEntity">
            <div class="act-detail-card">
                <div class="act-detail-content-icon">
                    <mat-icon [ngStyle]="{'color':'#E8601C'}">date_range</mat-icon>
                </div>
                <div class="act-detail-content">
                    <p class="act-detail-title">Fecha de reintegro</p>
                    <p class="act-detail-subtitle">{{c.dateReinstate}}</p>
                </div>
                <div class="act-detail-content-icon">
                    <mat-icon [ngStyle]="{'color':'#1D7143'}">schedule</mat-icon>
                </div>

                <div class="act-detail-content">
                    <p class="act-detail-title">Tiempo de incapacidad</p>
                    <p class="act-detail-subtitle">{{c.timeIsolation}}</p>
                </div>
            </div>
            <div class="act-detail-card">
                <div class="act-detail-content-icon">
                    <mat-icon [ngStyle]="{'color':'#4EA3DF'}">note_alt</mat-icon>
                </div>
                <div class="act-detail-content">
                    <p class="act-detail-title">Obervacion de entrega</p>
                    <p class="act-detail-subtitle">{{c.observationDelivery}}</p>
                </div>
            </div>
            <div class="act-detail-card">
                <div class="act-detail-content-icon">
                    <mat-icon [ngStyle]="{'color':'#B34EDF'}">article</mat-icon>
                </div>
                <div class="act-detail-content">
                    <p class="act-detail-title">Observacion de laboral</p>
                    <p class="act-detail-subtitle">{{c.observationWorking}}</p>
                </div>
            </div>
            <div class="act-detail-card">
                <div class="act-detail-content-icon">
                    <mat-icon [ngStyle]="{'color':'#4ED4DF'}">light_mode</mat-icon>
                </div>
                <div class="act-detail-content">
                    <p class="act-detail-title">Dia de aislamiento</p>
                    <p class="act-detail-subtitle">{{c.dayIsolation}}</p>
                </div>
                <div class="act-detail-content-icon">
                    <mat-icon [ngStyle]="{'color':'#57DF4E'}">summarize</mat-icon>
                </div>

                <div class="act-detail-content">
                    <p class="act-detail-title">Total de aislamiento</p>
                    <p class="act-detail-subtitle">{{c.totalDayIsolation}}</p>
                </div>
            </div>
            <div class="act-detail-card">
                <div class="act-detail-content-icon">
                    <mat-icon [ngStyle]="{'color':'#DF4EB1'}">engineering</mat-icon>
                </div>
                <div class="act-detail-content">
                    <p class="act-detail-title">Consolidado de trabajo</p>
                    <p class="act-detail-subtitle">{{c.consolidatedWork}}</p>
                </div>
            </div>
            <div *ngIf="loading" class="act-detail-container-loader">
                <div class="act-detail-loader"></div>
            </div>
        </div>
    </div>
</div>