<div class="pro-container">
    <p class="title">Listado Planes de Trabajo</p>
    <div class="pro-form">
        <div class="pro-group">
            <label class="primaryColor">Año</label>
            <select [(ngModel)]="year" class="form-control" (change)="getPerspective()">
                <option class="ligthColor" value="0">Seleccione un año</option>
                <option *ngFor="let y of yearList" value="{{y}}">{{y}}</option>
            </select>
        </div>
        <div class="pro-group">
            <label class="primaryColor">Proceso</label>
            <select [(ngModel)]="perspectiveId" class="form-control" (change)="getStrategicObjetive()">
                <option class="ligthColor" value="0">Seleccione un proceso</option>
                <option *ngFor="let p of perspectiveList" value="{{p.id}}">{{p.name}}</option>
            </select>
        </div>
        <div class="pro-group">
            <label class="primaryColor">Objetivo </label>
            <select [(ngModel)]="strategicObjetiveId" class="form-control" (change)="getWorkPlan()">
                <option class="ligthColor" value="0">Seleccione un objetivo </option>
                <option *ngFor="let s of strategicObjetiveList" value="{{s.id}}">{{s.name}}</option>
            </select>
        </div>
    </div>
    <table #table class="pro-table" mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Nombre</th>
            <td mat-cell *matCellDef="let item">{{item.name}}</td>
        </ng-container>
        <ng-container matColumnDef="year">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Año</th>
            <td mat-cell *matCellDef="let item">{{item.year}}</td>
        </ng-container>
        <ng-container matColumnDef="perspective">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Proceso</th>
            <td mat-cell *matCellDef="let item">{{item.perspective}}</td>
        </ng-container>
        <ng-container matColumnDef="strategicObjetive">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Objetivo </th>
            <td mat-cell *matCellDef="let item">{{item.strategicObjetive}}</td>
        </ng-container>
        <ng-container matColumnDef="creationUser">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Usuario Creacion</th>
            <td mat-cell *matCellDef="let item">{{item.creationUser}}</td>
        </ng-container>
        <ng-container matColumnDef="active">
            <th mat-header-cell *matHeaderCellDef>Activo</th>
            <td mat-cell *matCellDef="let item">
                <mat-icon *ngIf="item.active" class="green-color">done</mat-icon>
                <mat-icon *ngIf="!item.active" class="red-color">clear</mat-icon>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns;"></tr>
    </table>
    <div *ngIf="loading" class="pro-container-loader">
        <div class="pro-loader"></div>
    </div>
    <mat-paginator [pageSizeOptions]="[5, 10, 15, 20, 30, 40, 50, 100]" showFirstLastButtons></mat-paginator>
</div>