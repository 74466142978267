import { Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MeeMeetingService } from 'src/app/appMeeting/services/meeMeeting.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { AnswerNewModal } from 'src/app/appMeeting/modals/answerNew/answerNew.modal';
import { GenPersonEntity } from 'src/app/appGeneral/entities/genPerson.entity';
import { MeeGroupEntity } from 'src/app/appMeeting/entities/meeGroup.entity';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-meeAnswerFillOut',
  templateUrl: './meeAnswerFillOut.component.html',
  styleUrls: ['./meeAnswerFillOut.component.css'],
})
export class MeeAnswerFillOutComponent {
  loading: boolean;
  columns: string[];
  dataSource: MatTableDataSource<any>;
  @ViewChild('paginator') paginator: MatPaginator;
  date1: string;
  date2: string;
  id: number;
  person: GenPersonEntity;
  group: MeeGroupEntity;

  //Formato de fecha
  formatDate: string = 'yyyy-MM-dd';
  LocateDate: string = 'en-US';

  //Botones acciones
  search_Es: Boolean;
  clear_Es: Boolean;

  constructor(
    private meeMeetingS: MeeMeetingService,
    private alertS: AlertService,
    private dialog: MatDialog
  ) {
    this.loading = false;
    this.columns = [
      'name',
      'creationDate',
      'creationUser',
      'nameGroup',
      'actions',
    ];
    this.dataSource = new MatTableDataSource([]);
    this.date1 = formatDate(Date.now(), this.formatDate, this.LocateDate);
    this.date2 = formatDate(Date.now(), this.formatDate, this.LocateDate);
    this.id = 0;
    this.person = new GenPersonEntity();
    this.group = new MeeGroupEntity();
    this.search_Es = true;
    this.clear_Es = false;
    this.search();
  }

  clear() {
    this.date1 = '';
    this.date2 = '';
    this.search_Es = true;
    this.clear_Es = false;
  }

  search() {
    this.person = JSON.parse(localStorage.getItem('user'));
    this.id = 0;
    this.loading = true;
    if (this.date1 != '' && (this.date2 == '' || this.date2 == null)) {
      this.alertS.open('Error seleccione una fecha final', 'warning');
      this.loading = false;
      return;
    } else if ((this.date1 == '' || this.date1 == null) && this.date2 != '') {
      this.alertS.open('Error seleccione una fecha inicio', 'warning');
      this.loading = false;
      return;
    }
    this.meeMeetingS
      .list(
        this.date1 != '' && this.date1 != null
          ? moment(this.date1).format('YYYY-MM-DD')
          : '0',
        this.date2 != '' && this.date2 != null
          ? moment(this.date2).format('YYYY-MM-DD')
          : '0',
        this.person.id
      )
      .subscribe(
        (res) => {
          if (res.message === 'OK') {
            this.dataSource = new MatTableDataSource(res.object);
            this.dataSource.paginator = this.paginator;
            this.loading = false;
            this.search_Es = false;
            this.clear_Es = true;
          } else {
            this.alertS.open(res.message, 'error');
            this.loading = false;
          }
        },
        (err) => {
          this.alertS.open(err.message, 'error');
          this.loading = false;
        }
      );
  }
  fillOut(meetingId: number, name: string, periodicity: string) {
    this.dialog.open(AnswerNewModal, {
      data: {
        meetingId: meetingId,
        meetingName: name,
        meetingType: periodicity,
        userId: null,
      },
      width: '100%',
    });
  }
}
